import React, { PropsWithChildren, useLayoutEffect, useMemo } from "react";
import { useCurrentUserControllerQuery } from "../api/endpoints/user";
import { hideAppLoader, showAppLoader } from "../components/appLoader";

interface Rights {
  mainAccess: boolean;
  makeTransfers: boolean;
  openBankingCard: boolean;
  openFinancialAccount: boolean;
  viewAllCardsAndFinancialAccounts: boolean;
}

interface UserContextProps {
  user: any;
  isSubUser: boolean;
  userIsLoading: boolean;
  currentUserRefetch: () => Promise<any>;
  subUserRights(
    rights: Partial<Rights>,
    options?: { matchAll?: boolean }
  ): boolean;
}

const Context = React.createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: PropsWithChildren) => {
  const { data, isLoading, refetch } = useCurrentUserControllerQuery({});

  useLayoutEffect(() => {
    if (isLoading && !data) {
      showAppLoader();
    } else {
      hideAppLoader();
    }
  }, [isLoading]);

  const value = useMemo((): UserContextProps => {
    const isSubUser = data ? data.type == 4 : false;

    return {
      user: data,
      userIsLoading: isLoading,
      isSubUser,
      currentUserRefetch: refetch,
      subUserRights(
        rights: Partial<Rights>,
        options?: { matchAll?: boolean }
      ): boolean {
        if (!isSubUser || !data?.accessRights) {
          return false;
        }

        if (Object.keys(rights).length === 0) {
          return true;
        }

        if (options?.matchAll) {
          return Object.entries(rights).every(([key, value]) => {
            return data?.accessRights[key] === value;
          });
        } else {
          return Object.entries(rights).some(([key, value]) => {
            return data?.accessRights[key] === value;
          });
        }
      },
    };
  }, [data]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCurrentUser = () =>
  React.useContext(Context) as UserContextProps;
