import { useState } from 'react';
import { useSnackBar } from 'providers/snackBar.provider';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import helper from 'services/helper';
import { TransitionAnimation } from 'shared/ui/animations';
import { useAddExternalPayeeMutation } from 'api/endpoints/fundsMovement';
import { selectNewExternalPayee, clearDataAch } from '../../model';
import { useAppDispatch, useAppSelector } from 'shared/models';
import { SecureOperationType, ShortMessageType } from 'enums';
import { AuntificateWithSms } from 'providers/mfa/auntificatorComponent/components/auntificate-with-sms';

export const AuntificatorExternalPayee = () => {
  const dispatch = useAppDispatch();
  const { setSnackBar } = useSnackBar();
  const { close } = useDrawerStepperContext();

  const [addExternalPayee, { isLoading: isLoadingAddExternalPayee }] =
    useAddExternalPayeeMutation();

  const [code, setCode] = useState<string>('');

  const newExternalPayee = useAppSelector(selectNewExternalPayee);

  const handleSubmit = async (secureOperationType: SecureOperationType) => {
    try {
      if (code) {
        await addExternalPayee({
          routingNumber: newExternalPayee.routingNumber,
          accountNumber: newExternalPayee.accountNumber,
          name: newExternalPayee.name,
          bankAccountType: newExternalPayee.type.toUpperCase(),
          oneTimeCode: code,
          secureOperationType: secureOperationType,
        }).unwrap();
        setSnackBar({
          type: 'success',
          message:
            'Success! Your payee has been added successfully! In less than a minute, you’ll find it listed in your payee list', //'Payee added successfully.',
          isShow: true,
        });

        dispatch(clearDataAch());
        close();
      }
    } catch (e: any) {
      setCode('');
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  return (
    <TransitionAnimation>
      <AuntificateWithSms
        code={code}
        setCode={setCode}
        onSubmit={handleSubmit}
        isLoadingSubmit={isLoadingAddExternalPayee}
        shortMessageType={ShortMessageType.ApproveTransaction}
        title={'Confirm Transfer'}
        sumbitButtonLabel={'Add payee'}
      />
    </TransitionAnimation>
  );
};
