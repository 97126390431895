import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { AuntificateWithSms } from 'providers/mfa/auntificatorComponent/components/auntificate-with-sms';
import { ShortMessageType } from 'enums';
import { useCreateGiftCardMutation } from 'api/endpoints/giftCards';
import { useGiftCards } from 'pages/reward/provider/useGiftCards';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { SecureOperationType } from 'enums';
import { useCreateGiftCardPointsMutation } from 'api/endpoints/rewards';
import { GiftCardMethod } from 'pages/reward/components/giftCard/types';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';

const successMessage = 'You have successfully purchased gift card';


export const AuntificatorSms = () => {
  const { close } = useDrawerStepperContext();
  const {
    selectBrand,
    amount,
    point,
    paymnetMethod,
    financialAccountId,
    refetchGiftCardsData,
    refetchRewards
  } = useGiftCards();
  const { setSnackBar } = useSnackBar();

  const [createGiftCard, { isLoading: isLoadingCreateGiftCard }] =
    useCreateGiftCardMutation();
  const [createGiftCardPoints, { isLoading: isLoadingCreateGiftCardPoints }] =
    useCreateGiftCardPointsMutation();

  const [code, setCode] = useState<string>('');

  const isFinancialAccountPayment =
    paymnetMethod === GiftCardMethod.PayUsingYourFinancialAccount;

  const createGiftCardWithFinAccount = async (
    secureOperationType: SecureOperationType
  ) => {
    try {
      await createGiftCard({
        brandId: selectBrand.id,
        faceAmount: Number(amount),
        oneTimeCode: code,
        secureOperationType: secureOperationType,
        fromFinancialAccountId: financialAccountId,
      }).unwrap();

      setSnackBar({
        type: 'success',
        message: successMessage,
        isShow: true,
      });
      close();
    } catch (e: any) {
      setCode('');
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const createGiftCardWithRewardPoints = async (
    secureOperationType: SecureOperationType
  ) => {
    try {
      await createGiftCardPoints({
        brandId: selectBrand.id,
        faceAmount: point,
        oneTimeCode: code,
        secureOperationType: secureOperationType,
      }).unwrap();

      setSnackBar({
        type: 'success',
        message: successMessage,
        isShow: true,
      });
      refetchGiftCardsData();
      refetchRewards();
      close();
    } catch (e: any) {
      setCode('');
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleSubmit = async (
    secureOperationType: SecureOperationType
  ): Promise<void> => {
    if (isFinancialAccountPayment) {
      await createGiftCardWithFinAccount(secureOperationType);
    } else {
      await createGiftCardWithRewardPoints(secureOperationType);
    }
  };

  return (
    <TransitionAnimation>
      <AuntificateWithSms
        code={code}
        setCode={setCode}
        shortMessageType={
          isFinancialAccountPayment
            ? ShortMessageType.ApproveTransaction
            : ShortMessageType.TransferPoints
        }
        onSubmit={handleSubmit}
        isLoadingSubmit={
          isLoadingCreateGiftCard || isLoadingCreateGiftCardPoints
        }
        title={'Confirm with one time password'}
      />
    </TransitionAnimation>
  );
};
