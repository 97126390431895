import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  Amount,
  FinancialAccount,
  SelectScheduleTransfer,
  FrequencyTitle
} from 'entities/transfers'
import moment from 'moment'
import { SearchClientsApi } from 'api/endpoints/fundsMovement'
interface NewPayee {
  name: string
  number: string
}

export type TransferNetevia = {
  fromFinancialAccount: FinancialAccount | null
  toCustomer: FinancialAccount | null
  amount: Amount
  selectPayeeId: string
  newPayee: NewPayee
  frequency: string
  dateTransfer: string
  selectScheduleTransfer: SelectScheduleTransfer | null
  notRegisteredPayee: SearchClientsApi | undefined
}

const initialState: TransferNetevia = {
  fromFinancialAccount: null,
  toCustomer: null,
  amount: {
    floatValue: undefined,
    formattedValue: undefined,
    value: undefined,
  },
  selectPayeeId: '',
  newPayee: {
    name: '',
    number: '',
  },
  frequency: FrequencyTitle.Once,
  dateTransfer: moment().toISOString(),
  selectScheduleTransfer: null,
  notRegisteredPayee: undefined
}

export const slice = createSlice({
  name: 'toNeteviaCustomer',
  initialState,
  reducers: {
    clearDataToNeteviaCustomer: () => {
      return initialState
    },
    clearDataNewPayee: (state) => {
      state.newPayee = initialState.newPayee
    },
    setFromFinancialAccount: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.fromFinancialAccount = payload.value
    },
    setToCustomer: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.toCustomer = payload.value
    },
    setAmount: (state, { payload }: PayloadAction<{ value: Amount }>) => {
      state.amount = payload.value
    },
    setSelectPayeeId: (
      state,
      { payload }: PayloadAction<{ value: string }>
    ) => {
      state.selectPayeeId = payload.value
    },
    setNewPayee: (state, { payload }: PayloadAction<{ value: NewPayee }>) => {
      state.newPayee = payload.value
    },

    setSelectFrequency: (
      state,
      { payload }: PayloadAction<{ value: string }>
    ) => {
      state.frequency = payload.value
    },

    setSelectDateTransfer: (
      state,
      { payload }: PayloadAction<{ value: string }>
    ) => {
      state.dateTransfer = payload.value
    },

    setSelectScheduleTransfer: (
      state,
      { payload }: PayloadAction<{ value: SelectScheduleTransfer }>
    ) => {
      state.selectScheduleTransfer = payload.value
    },

    setNotRegisteredPayee : (
      state,
      { payload }: PayloadAction<{ value: SearchClientsApi | undefined }>
    ) => {
      state.notRegisteredPayee = payload.value
    },
  },
})

export const selectFromFinancialAccount = (state: RootState) =>
  state.toNeteviaCustomer.fromFinancialAccount

export const selectToCustomer = (state: RootState) =>
  state.toNeteviaCustomer.toCustomer

export const selectAmount = (state: RootState) => state.toNeteviaCustomer.amount

export const selectPayeeId = (state: RootState) =>
  state.toNeteviaCustomer.selectPayeeId

export const selectNewPayee = (state: RootState) =>
  state.toNeteviaCustomer.newPayee

export const selectFrequency = (state: RootState) =>
  state.toNeteviaCustomer.frequency

export const selectDateTransfer = (state: RootState) =>
  state.toNeteviaCustomer.dateTransfer

export const selectScheduleTransfer = (state: RootState) =>
  state.toNeteviaCustomer.selectScheduleTransfer

export const selectNotRegisteredPayee = (state: RootState) =>
  state.toNeteviaCustomer.notRegisteredPayee

export const {
  clearDataToNeteviaCustomer,
  clearDataNewPayee,
  setFromFinancialAccount,
  setToCustomer,
  setAmount,
  setSelectPayeeId,
  setNewPayee,
  setSelectFrequency,
  setSelectDateTransfer,
  setSelectScheduleTransfer,
  setNotRegisteredPayee
} = slice.actions
