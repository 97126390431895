import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import styles from "./style.module.scss";
import LogoIcon from "assets/svg/logo-netevia-white.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "assets/svg/closeFilled-white.svg";

interface SideBarLogoProps {
  setIsShowDrawer?: (boolean) => void;
}

const SideBarLogo: FC<SideBarLogoProps> = ({ setIsShowDrawer }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));

  const handlerNavigateToHome = () => {
    navigate("/Home");
  };
  const handlerCloseNavBar = () => {
    if (setIsShowDrawer) {
      setIsShowDrawer(false);
    }
  };
  return (
    <div className={styles.container}>
      <img src={LogoIcon} onClick={handlerNavigateToHome} />
      {!matches && (
        <IconButton
          color="inherit"
          aria-label="Close main menu"
          className={styles.container_buttonClose}
          onClick={handlerCloseNavBar}
        >
          <img src={CloseIcon} />
        </IconButton>
      )}
    </div>
  );
};

export default SideBarLogo;
