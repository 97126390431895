import { useTranslationProvider } from "providers/translation/translation.provider";
import CasesOutlined from "../../../assets/svg/CasesOutlined.svg";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import helper from "../../../services/helper";
import styles from "./style.module.scss";
import { FinancialAccount } from "api/endpoints/financialAccounts";

type TConnectAccounts = {
  white?: any;
  edit?: any;
  financialAccountsArr: FinancialAccount[];
};

const PrimaryCarAccList = ({
  white,
  edit,
  financialAccountsArr,
}: TConnectAccounts) => {
  const { t } = useTranslationProvider();
  const warningText = t(
    "Card.Please select financial account for authorized users",
    {
      defaultValue:
        "Please select financial account for authorized user's primary card. Primary card is an optional card that could have contact information of the authorized user for card verification purposes.",
    }
  );
  const renderItems = () => {
    if (!financialAccountsArr?.length) {
      return <p className={styles.warningText}>{warningText}</p>;
    } else {
      return financialAccountsArr?.map((i, index) => {
        return (
          <AnimateWrapper delay={index * 100} key={i.financialAccountId}>
            <div
              key={i.financialAccountId}
              className={`${styles.item} ${
                white
                  ? styles.item_backGourndWhite
                  : styles.item_backGourndDefault
              }`}
            >
              <div className={styles.blockName}>
                <div className={styles.blockName_img}>
                  <img src={CasesOutlined} />
                </div>

                <p className={styles.blockName_text}>{i.name}</p>
              </div>
              <div className={styles.mobView}>
                <div className={styles.blockNumb}>
                  <p className={styles.blockNumb_text}>
                    ****{i.accountNumber.slice(-4)}
                  </p>
                </div>
                <div className={styles.blockMoney}>
                  <p className={styles.blockMoney_text}>
                    $ {helper.moneyFormat(i.availableCash?.value)}
                  </p>
                </div>
              </div>
            </div>
          </AnimateWrapper>
        );
      });
    }
  };

  return <div className={styles.wrapper}>{renderItems()}</div>;
};

export default PrimaryCarAccList;
