import { useSearchParams } from "react-router-dom";
import { CreateCardCmoponent } from "shared/components/CreateCardCmoponent";
import DrawerModal from "shared/components/drawerModal";
import { useBoolean } from '../../../../shared/hooks/useBoolean';
import { useDrawerBehavior } from '../../../../providers/drawer-behavior';

export const NewCardFromAccount = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const drawerBehavior = useDrawerBehavior();
  const avalibleMods = [
    { path: "openCard", title: "Open new card" },
    { path: "physicalCard", title: "Open physical card" },
    { path: "burnerCard", title: "Open burner card" },
    { path: "openVirtualcard", title: "Open virtual card" },
    { path: "openPrimaryCard", title: "Open primary card" },
  ];
  const currentMode = searchParams.get("mode") || "";
  const handleChangeMode = (mode) => {
    setSearchParams({ mode });
  };
  return (
    <DrawerModal
      isShow={avalibleMods.map((mode) => mode.path).includes(currentMode)}
      onClose={() => {}}
      titleText={
        avalibleMods.find((mode) => mode.path === currentMode)?.title ||
        avalibleMods[0].title
      }
      goBack={
        currentMode !== avalibleMods[0].path && !drawerBehavior.isCloseDisabled
          ? () => handleChangeMode(avalibleMods[0].path)
          : undefined
      }
    >
      <CreateCardCmoponent
        currentMode={currentMode}
        setCurrentMode={handleChangeMode}
        setDrawerLock={(isLocked => drawerBehavior.setLockForCloseStatus(isLocked))}
      />
    </DrawerModal>
  );
};
