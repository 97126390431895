import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";
import { TransactionData } from "./transaction";

interface CardTransactionsArg {
  paymentCardId: string;
  dateFrom: string;
  dateTo: string;
}

interface CardPendingTransactionsApi {
  openAuthorizations: any;
}
interface CardApprovedTransactionsApi {
  lastEvents: any;
  pageInfo: any;
}
export const transactionEventsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "transactionEvents",
  endpoints: (builder) => ({
    cardPendingTransactionsController: builder.mutation<TransactionData, any>({
      query: ({ paymentCardId, dateFrom, dateTo, filter }) => ({
        url: "api/transactionEvents/openAuthorizations/v2",
        method: "POST",
        body: {
          paymentCardId,
          dateFrom,
          dateTo,
          filter,
        },
      }),
    }),
    cardApprovedTransactionsController: builder.mutation<TransactionData, any>({
      query: ({ paymentCardId, dateFrom, dateTo, filter }) => ({
        url: "api/transactionEvents/last/v2",
        method: "POST",
        body: {
          paymentCardId,
          dateFrom,
          dateTo,
          filter,
        },
      }),
    }),
  }),
});

export const {
  useCardPendingTransactionsControllerMutation,
  useCardApprovedTransactionsControllerMutation,
} = transactionEventsApi;
