import { FC, ReactNode, CSSProperties } from "react";
import { css, styled, Typography, SxProps } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

interface FrameLoansProps {
  title: string;
  subTitle: string | ReactNode;
  img: string;
  buttonLabel?: string;
  buttonArrow?: boolean;
  imgStyles?: CSSProperties;
  wrapperStyles?: CSSProperties;
  buttonStyles?: SxProps;
  onSubmit?: (() => Promise<void>) | (() => void);
  isLoading?: boolean;
}
const FrameLoans: FC<FrameLoansProps> = ({
  title,
  subTitle,
  img,
  buttonLabel,
  buttonArrow,
  imgStyles,
  wrapperStyles,
  buttonStyles,
  onSubmit,
  isLoading,
}) => {
  return (
    <Frame wrapperStyles={wrapperStyles}>
      <FrameTitle>{title}</FrameTitle>
      <FrameSubtitle>{subTitle}</FrameSubtitle>
      {buttonLabel && (
        <StyledButton
          variant="contained"
          onClick={onSubmit}
          loading={isLoading}
          sx={{
            ...buttonStyles,
          }}
        >
          <Typography>{buttonLabel}</Typography> {buttonArrow && ">"}
        </StyledButton>
      )}
      <FrameImage src={img} imgStyles={imgStyles} />
    </Frame>
  );
};

export default FrameLoans;

const Frame = styled("div")<{ wrapperStyles?: any }>`
  ${({ theme, wrapperStyles }) => css`
    display: flex;
    flex-direction: column;
    width: 471px;
    height: 190px;
    border-radius: 10px;
    border: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
    background: linear-gradient(108deg, #023047 37.74%, #187ac9 129.5%);
    padding: 16px;
    margin-left: 20px;
    gap: 16px;
    position: relative;
    ${wrapperStyles};

    ${theme.breakpoints.down("sm")} {
      width: 100%;
      margin-left: 9px;
      height: 220px;
      ${wrapperStyles};
    }
  `}
`;
const FrameTitle = styled(Typography)`
  ${({ theme }) => css`
    color: #fff;
    font-family: "Noto Sans";
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  `}
`;
const FrameSubtitle = styled(Typography)`
  ${({ theme }) => css`
    width: 400px;
    color: #fff;
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
    z-index: 1;
    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;

const FrameImage = styled("img")<{ imgStyles? }>`
  ${({ theme, imgStyles }) => css`
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    ${imgStyles};
  `}
`;

const StyledButton = styled(LoadingButton)`
  ${({ theme }) => css`
    width: fit-content;
    gap: 8px;
    background-color: #187ac9;

    ${theme.breakpoints.down("sm")} {
      z-index: 1;
    }
  `}
`;
