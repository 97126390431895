export interface Amount {
  floatValue: number | undefined
  formattedValue: string | undefined
  value: string | undefined
}

export interface FinancialAccount {
  name: string
  id: string
  last4Number: string
  availableCash: { currencyCode: string; value: number }
}
export interface NewExternalPayee {
  name: string
  type: string
  accountNumber: string
  routingNumber: string
}

export interface SelectScheduleTransfer {
  id: number
  fromAccount: FinancialAccount
  toAccount: FinancialAccount
  amount: Amount
  frequency: string
  dateTransfer: string
}

export enum ScheduleTransferType {
  IncommingAchTransfer = 'IncommingAchTransfer',
  OutcommingAchTrasfer = 'OutcommingAchTrasfer',
  InternalTransfer = 'InternalTransfer',
  TransferBetweenOwnAccounts = 'TransferBetweenOwnAccounts',
  AutoPopup = 'AutoPopup',
}

export enum  FrequencyTitle {
  Once = 'Once',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}