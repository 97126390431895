import{ LoginLayoutRender } from '../../layouts/LoginLayout';
import { ReactComponent as Icon } from './icon.svg';
import styles from './style.module.scss';
import { Typography } from '../../shared/ui/typography';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useSendErrorLogMutation } from '../../api/endpoints/account';
import { getLocalStorageObject, LocalStorageKeys } from '../../shared/utils/local-storage';

interface Props {
  error?: any;
}

export const AppError = ({ error }: Props) => {
  const [sendError] = useSendErrorLogMutation();
  useEffect(() => {
    if (getLocalStorageObject(LocalStorageKeys.AuthToken)) {
      sendError({ error: error?.stack || error?.message });
    }
  }, [error]);
  return (
    <LoginLayoutRender>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Icon />
          <Typography variant="h5">
            Looks like part of our services are currently not available.
          </Typography>
          <div className={styles.errorMessage}>
            {error?.message || 'unknown error'}
          </div>
          <Button fullWidth={false} variant="contained" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      </div>
    </LoginLayoutRender>
  )
};
