import React, { FC } from "react";
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MyAutocomplete_v3 from "../../../autocomplete/MyAutocomplete_v3";
import { states } from "../../../../../constants/data";
import styles from "../style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Button } from "shared/ui/buttons";

interface EditBillingAddressProps {
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  setAddressFull: React.Dispatch<React.SetStateAction<any>>;
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  zip: string;
  setZip: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: () => Promise<void>;
  isLoading: boolean;
  disabled: boolean;
}

const EditBillingAddress: FC<EditBillingAddressProps> = ({
  address,
  setAddress,
  setAddressFull,
  city,
  setCity,
  state,
  setState,
  zip,
  setZip,
  handleSubmit,
  isLoading,
  disabled,
}) => {
  const { t } = useTranslationProvider();

  return (
    <div className={styles.wrapper}>
      <MyAutocomplete_v3
        address={address}
        label={t("Settings.Address", {
          defaultValue: "Address",
        })}
        onAddressChange={setAddress}
        setAddressFull={setAddressFull}
      />

      <TextField
        id="city-input"
        type="text"
        label={t("Settings.City", {
          defaultValue: "City",
        })}
        placeholder="Anytown, Idaho"
        value={city}
        fullWidth
        onChange={(e) => setCity(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          {t("Settings.State", {
            defaultValue: "State",
          })}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={state}
          label={t("Settings.State", {
            defaultValue: "State",
          })}
          onChange={(e) => setState(e.target.value)}
        >
          {states.map((i) => {
            return (
              <MenuItem key={i.value} value={i.label}>
                {i.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        id="outlined-required"
        label="Zip Code"
        value={zip}
        onChange={(e) => setZip(e.target.value)}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />

      <Button
        onClick={handleSubmit}
        loading={isLoading}
        variant="contained"
        className={styles.button}
        disabled={disabled}
      >
        Save Changes
      </Button>
    </div>
  );
};

export default EditBillingAddress;
