import { Select, SelectItem } from 'shared/ui/inputs'
import { Divider } from '@mui/material'
import { CurrencyInput } from 'shared/ui/inputs'
import { Control, useController } from 'react-hook-form'
import styles from './styles.module.scss'
import MenuItem from '@mui/material/MenuItem'
import { Typography } from 'shared/ui/typography'
import { DatePicker } from 'shared/ui/pickers'
import { CalendarOutlineIcon } from 'shared/ui/icons'
import { Payee } from 'api/hooksApi/useExternalPayees'
import { ScheduleTransfer } from 'entities/transfers/model'

interface MakeTransferFormProps {
  control: Control<ScheduleTransfer>
  optionsFromAcc: SelectItem[]
  optionsFrequency: SelectItem[]
  payees: Payee[]
}

export const ManageScheduleTransferForm = (props: MakeTransferFormProps) => {
  const { control, optionsFromAcc, optionsFrequency, payees } = props

  const fromFinancialAccount = useController({
    name: 'fromFinancialAccount',
    control,
  })
  const toAccount = useController({ name: 'toAccount', control })
  const amount = useController({ name: 'amount', control })
  const frequency = useController({ name: 'frequency', control })
  const dateTransfer = useController({
    name: 'dateTransfer',
    control,
  })

  return (
    <div className={styles.root}>
      <Select
        label='From'
        options={optionsFromAcc}
        {...fromFinancialAccount.field}
      />
      <Select
        label='To'
        {...toAccount.field}
      >
        {payees?.map((payee) => (
          <MenuItem
            key={payee.id}
            value={payee.id}
          >
            <Typography>
              {payee.name} ({payee.last4})
            </Typography>
          </MenuItem>
        ))}
      </Select>
      <Divider />
      <CurrencyInput
        variant='outlined'
        label='Amount'
        placeholder='$0.00'
        error={amount.fieldState.error?.message}
        getCaretBoundary={() => [false]}
        {...amount.field}
      />
      <Select
        label='Frequency'
        options={optionsFrequency}
        {...frequency.field}
      />
      <DatePicker
        label='Date'
        slots={{
          openPickerIcon: CalendarOutlineIcon,
        }}
        disablePast
        {...dateTransfer.field}
      />
    </div>
  )
}
