import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import EditEmailaddress from "./components/editEmailaddress";
import EditPhoneNumber from "./components/editPhoneNumber";
import EditBillingAddress from "./components/editBillingAddress";
import { useAccount } from "../accountSettings/provider";
import helper from "services/helper";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useSnackBar } from "providers/snackBar.provider";
import { useCreateTicketControllerMutation } from "api/endpoints/support";
import { useGetAllTicketsControllerQuery } from "api/endpoints/tickets";
import { useFormattedHolderData } from "shared/lib/hooks/useFormattedHolderData";
import { useGetActiveUpdateFieldRequestsQuery } from "api/endpoints/tickets";
import { useNavigate } from "react-router-dom";
import { appRoutes, SettingsRouteSubRoute } from "routes";
import { SupportModeEnum } from "pages/support/types/enums";
import { SectionPerson,EditType } from "../accountSettings/types";
import { CurrencyCode } from "enums";

const schemaEmail = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address"
    ),
});

const schemaPhone = yup.object().shape({
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Please enter a valid 10-digit phone number"),
});

interface OldData {
  countryCodeAlpha3: CurrencyCode,
email: string,
locality: string,
phone: string,
postalCode: string,
region: string,
statusUpdate: boolean,
streetAddress: string
}

const ChangeCompanyInfo = () => {
  const navigate = useNavigate()
  const { t } = useTranslationProvider();
  const { setSnackBar } = useSnackBar();
  const { setIsShowDrawer, errors, setErrors } = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();

  const mode = searchParams.get("mode");
  const section = searchParams.get("section") || "";
  const type = searchParams.get("type") || "";
  const title = searchParams.get("title") || "";

  const { formattedHolderData, formattedholderDataIsLoading } =
    useFormattedHolderData();

  const [createTickerMutation, { isLoading: createTicketIsLoading }] =
    useCreateTicketControllerMutation();
  const { refetch: allTicketsRefetch } = useGetAllTicketsControllerQuery({});
  const { refetch: updateTicketBadgeRefetch } =
    useGetActiveUpdateFieldRequestsQuery();
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [addressFull, setAddressFull] = useState<any>();
  const [oldData, setOldData] = useState<OldData | null>(null);
  
  let EmailError = errors.hasOwnProperty("email");
  let PhoneError = errors.hasOwnProperty("phone");

  useEffect(() => {
    if (!formattedholderDataIsLoading) {
      initialDataBySection(formattedHolderData, section);
    }
  }, [formattedholderDataIsLoading, section]);

  const initialDataBySection = (formattedHolderData, section: string) => {
    const { bussinessInfo, primaryAuthorizedInfo, owners } =
      formattedHolderData;

    switch (section) {
      case SectionPerson.Business:
        setInitialData({
          ...bussinessInfo.addressInfo,
          ...bussinessInfo.emailInfo,
          ...bussinessInfo.phoneInfo,
        });
        break;
      case SectionPerson.AuthorizedPerson:
        setInitialData({
          ...primaryAuthorizedInfo.addressInfo,
          ...primaryAuthorizedInfo.emailInfo,
          ...primaryAuthorizedInfo.phoneInfo,
        });
        break;
      default:
        const findOwnerPerson = owners?.find(
          (item) => item.ownerType === Number(section)
        );
        if (findOwnerPerson) {
          setInitialData({
            ...findOwnerPerson.info.addressInfo,
            ...findOwnerPerson.info.emailInfo,
            ...findOwnerPerson.info.phoneInfo,
          });
        }
        break;
    }
  };

  const setInitialData = (currentObject) => {
    setAddress(currentObject?.streetAddress);
    setCity(currentObject?.locality);
    setState(currentObject?.region);
    setZip(currentObject?.postalCode);
    setEmail(currentObject?.email);
    setPhone(currentObject?.phone);
    setOldData(currentObject)
  };

  useEffect(() => {
    if (addressFull) {
      transform(addressFull);
    }
  }, [addressFull]);

  const transform = (addressFull) => {
    setAddress(addressFull.address1);
    setCity(addressFull.locality);
    setState(addressFull.adminArea1Short);
    setZip(addressFull.postalCode);
  };

  const handleSubmitDataChenges = async () => {
    try {
      await validateFormEmailOrPhone(type);
      const formattedFieldName = formatFieldName(title);
      const template = generateFieldTemplate(formattedFieldName, type, {
        address,
        city,
        zip,
        email,
        phone,
      });

      const typePrefix = getTypePrefix(section);

      const { ticketId } =  await createTickerMutation({
        topicId: 7,
        text: template.message,
        updateFieldRequest: `${typePrefix}${template.type}`,
      }).unwrap();
      allTicketsRefetch();
      updateTicketBadgeRefetch();

      setSnackBar({
        type: 'success',
        width: '400px',
        message:
          'The request to update your information has been received. Our support team will be in touch shortly to confirm the changes.',
        isShow: true,
      });
     
      navigate(appRoutes.settings(SettingsRouteSubRoute.support,{
        pageMode:SupportModeEnum.ChatSupport,
        title:'Edit company information',
         ticket: ticketId
        }));
    } catch (e: any) {
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const validateFormEmailOrPhone = async (type) => {
    try {
      type === EditType.Email
        ? await schemaEmail.validate({ email }, { abortEarly: false })
        : await schemaPhone.validate({ phone }, { abortEarly: false });
    } catch (error: any) {
      if (error.name === "ValidationError") {
        const errors = {};
        error.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
        setErrors(errors);
      }
    }
  };

  const generateFieldTemplate = (
    formattedFieldName: string,
    type: string,
    data?: {
      address?: string;
      city?: string;
      zip?: string;
      email?: string;
      phone?: string;
    }
  ) => {
    if (type === EditType.Email) {
      return {
        message: `${formattedFieldName}\n update requested\n from ${oldData?.email}\n to ${data?.email}`,
        type,
      };
    } else if (type === EditType.Phone) {
      return {
        message: `${formattedFieldName}\n update requested\n from ${oldData?.phone}\n to ${data?.phone}`,
        type,
      };
    } else {
      return {
        message: `${formattedFieldName}\n update requested\n from ${oldData?.streetAddress}, ${oldData?.locality}, ${oldData?.postalCode}\n to ${data?.address}, ${data?.city}, ${data?.zip}`,
        type,
      };
    }
  };

  const getTypePrefix = (section) => {
    switch (section) {
      case SectionPerson.Business:
        return "";
      case SectionPerson.AuthorizedPerson:
        return "PrimAuth";
      default:
        return `Owner${section}`;
    }
  };
  const formatFieldName = (fieldName: string): string => {
    const withoutEdit = fieldName.replace(/^Edit/, "");
    const formatted = withoutEdit.replace(/([A-Z])/g, " $1");

    return formatted.trim();
  };

  const validateRequiredFieldsAddress = !address || !city || !state || !zip;

  return (
    <AnimateWrapper className="fade">
      <div>
        {mode === EditType.Email && (
          <EditEmailaddress
            errors={errors}
            email={email}
            setEmail={setEmail}
            setErrors={setErrors}
            emailError={EmailError}
            validateFormEmailOrPhone={validateFormEmailOrPhone}
            handleSubmit={handleSubmitDataChenges}
            isLoading={createTicketIsLoading}
          />
        )}
        {mode === EditType.Phone&& (
          <EditPhoneNumber
            phone={phone}
            setPhone={setPhone}
            setErrors={setErrors}
            phoneError={PhoneError}
            validateFormEmailOrPhone={validateFormEmailOrPhone}
            handleSubmit={handleSubmitDataChenges}
            isLoading={createTicketIsLoading}
          />
        )}
        {mode === EditType.Address && (
          <EditBillingAddress
            address={address}
            setAddress={setAddress}
            setAddressFull={setAddressFull}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            zip={zip}
            setZip={setZip}
            handleSubmit={handleSubmitDataChenges}
            isLoading={createTicketIsLoading}
            disabled={validateRequiredFieldsAddress}
          />
        )}
      </div>
    </AnimateWrapper>
  );
};

export default ChangeCompanyInfo;
