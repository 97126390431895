import { useEffect, useState } from 'react';
import helper from 'services/helper';
import { useSnackBar } from 'providers/snackBar.provider';
import { useUpdateScheduledTransferMutation } from 'api/endpoints/scheduledTransfer';
import { TransitionAnimation } from 'shared/ui/animations';
import { selectScheduleTransfer } from 'features/transfers/transfer-to-netevia-customer/model';
import { CurrencyCode, SecureOperationType, ShortMessageType } from 'enums';
import { ScheduleTransferType, findFrequencyByTitle } from 'entities/transfers';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useAppSelector } from 'shared/models';
import { useMfa } from '../../../../../providers/mfa/mfa';
import { Skeleton } from '../../../../../components/skeleton';

export const AuntificatorScheduleTransfer = () => {
  const { setSnackBar } = useSnackBar();
  const { goNext, goBack } = useDrawerStepperContext();
  const [
    updateScheduledTransferToPayee
  ] = useUpdateScheduledTransferMutation();

  const scheduleTransfer = useAppSelector(selectScheduleTransfer);
  const mfa = useMfa();

  const frequencyValue = scheduleTransfer?.frequency
    ? findFrequencyByTitle(scheduleTransfer?.frequency)
    : null;

  const updateScheduledTransfer = async (
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      if (scheduleTransfer && scheduleTransfer?.amount?.floatValue) {
        await updateScheduledTransferToPayee({
          fromFinancialAccountId: scheduleTransfer.fromAccount.id,
          toFinancialAccountId: scheduleTransfer.toAccount.id,
          amount: scheduleTransfer.amount.floatValue,
          currencyCode: CurrencyCode.USD,
          oneTimeCode: code,
          secureOperationType: secureOperationType,
          frequency: frequencyValue,
          nextDate: scheduleTransfer.dateTransfer,
          transferType: ScheduleTransferType.InternalTransfer,
          id: scheduleTransfer.id,
        }).unwrap();

        setSnackBar({
          type: 'success',
          message: 'Scheduled transfer edited successfully.',
          isShow: true,
        });
        mfa.close();
        goNext('7');
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  useEffect(() => {
    mfa.show({
      title: 'Confirm Transfer',
      shortMessageType: ShortMessageType.ApproveTransaction,
      onDrawerClose() {
        goBack();
      },
      async onCodeSubmit(secureOperationType: SecureOperationType, code: string) {
        await updateScheduledTransfer(secureOperationType, code)
      }
    })
  }, []);

  return (
    <TransitionAnimation>
      <Skeleton width="100%" height={100} />
    </TransitionAnimation>
  );
};
