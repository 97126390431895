import { forwardRef } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePickerProps as DatePickerPropsMUI } from '@mui/x-date-pickers/DatePicker'
import { DesktopDatePicker as DesktopDatePickerMui } from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs, { Dayjs } from 'dayjs'

type TProps = Omit<DatePickerPropsMUI<Dayjs>, 'onChange' | 'value'> & {
  value: string
  onChange: (values: string) => void
}

export const DatePicker = forwardRef<HTMLInputElement, TProps>((props, ref) => {
  const { value, onChange, label, ...rest } = props

  const handleOnChange = (newValue: Dayjs | null) => {
    if (newValue) {
      onChange(newValue.toISOString())
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePickerMui
        label={label}
        value={dayjs(value)}
        onChange={handleOnChange}
        {...rest}
        ref={ref}
      />
    </LocalizationProvider>
  )
})

DatePicker.displayName = 'DatePicker'
