import styles from "./style.module.scss";
import { AnimateWrapper } from "../animate-wrapper";
import { Skeleton } from "../skeleton";
import { Switch } from "@mui/material";

interface PropTypes {
  manageVisibleAccess: boolean;
  setManageVisibleAccess: (boolean) => void;
  bankingCardAccess: boolean;
  setBankingCardAccess: (boolean) => void;
  financialAccounAccess: boolean;
  setFinancialAccounAccess: (boolean) => void;
  allViewAccess: boolean;
  setAllViewAccess: (boolean) => void;
  makeTransferAccess: boolean;
  setMakeTransferAccess: (boolean) => void;
  fullAccess: boolean;
  setFullAccess: (boolean) => void;
  isLoading?: boolean;
}

const ManageAccess = ({
  manageVisibleAccess,
  setManageVisibleAccess,
  bankingCardAccess,
  setBankingCardAccess,
  financialAccounAccess,
  setFinancialAccounAccess,
  allViewAccess,
  setAllViewAccess,
  makeTransferAccess,
  setMakeTransferAccess,
  fullAccess,
  setFullAccess,
  isLoading,
}: PropTypes) => {
  const handleFullAccess = () => {
    setFullAccess((prevFullAccess) => {
      const updatedValue = !prevFullAccess;
      if (updatedValue) {
        setFinancialAccounAccess(true);
        setBankingCardAccess(true);
        setAllViewAccess(true);
        setMakeTransferAccess(true);
      }
      return updatedValue;
    });
  };

  const handleToggleManageAccess = () => {
    setManageVisibleAccess((prevManageVisibleAccess) => {
      const updatedValue = !prevManageVisibleAccess;
      if (!updatedValue) {
        setFullAccess(false);
        setFinancialAccounAccess(false);
        setBankingCardAccess(false);
        setAllViewAccess(false);
        setMakeTransferAccess(false);
      }
      return updatedValue;
    });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton width="432px" height="75px" />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.boxTitle}>
            <div>
              <p className={styles.title}>Manage access</p>
              <p className={styles.description}>
                Set permissions and access controls for stored card information
              </p>
            </div>

            <div className={styles.boxSwitch}>
              <div>
                <Switch
                  onClick={handleToggleManageAccess}
                  checked={manageVisibleAccess}
                />
              </div>
            </div>
          </div>

          {manageVisibleAccess && (
            <AnimateWrapper className="fade">
              <div className={styles.boxAccessItem}>
                <div className={styles.accessItem}>
                  <p>Full access</p>
                  <Switch onClick={handleFullAccess} checked={fullAccess} />
                </div>
                <div className={styles.accessItem}>
                  <p>Open new card</p>
                  <Switch
                    onClick={() => setBankingCardAccess(!bankingCardAccess)}
                    checked={bankingCardAccess}
                    disabled={fullAccess}
                  />
                </div>
                <div className={styles.accessItem}>
                  <p>Open financial account</p>
                  <Switch
                    onClick={() =>
                      setFinancialAccounAccess(!financialAccounAccess)
                    }
                    checked={financialAccounAccess}
                    disabled={fullAccess}
                  />
                </div>

                <div className={styles.accessItem}>
                  <p>View all cards and financial accounts</p>
                  <Switch
                    onClick={() => setAllViewAccess(!allViewAccess)}
                    checked={allViewAccess}
                    disabled={fullAccess}
                  />
                </div>
                <div className={styles.accessItem}>
                  <p>Make transfers</p>
                  <Switch
                    onClick={() => setMakeTransferAccess(!makeTransferAccess)}
                    checked={makeTransferAccess}
                    disabled={fullAccess}
                  />
                </div>
              </div>
            </AnimateWrapper>
          )}
        </div>
      )}
    </>
  );
};

export default ManageAccess;
