import React from 'react';
import { ReactComponent as LogoutOutlined } from './icons/LogoutOutlined.svg';
import { ReactComponent as ArrowDropDownFilter } from './icons/ArrowDropDownFilter.svg';
import { ReactComponent as InfoOutlined } from './icons/InfoOutlined.svg';
import { ReactComponent as SuccessMark } from './icons/SuccessMark.svg';
import { ReactComponent as ArrowUpwardFilled } from './icons/ArrowUpwardFilled.svg';

type IconType = 'LogoutOutlined'
  | 'ArrowDropDownFilter'
  | 'InfoOutlined'
  | 'SuccessMark'
  | 'ArrowUpwardFilled';

const IconsMap: { [iconName in IconType]: React.FC } = {
  LogoutOutlined,
  ArrowDropDownFilter,
  InfoOutlined,
  SuccessMark,
  ArrowUpwardFilled,
}

interface SvgIconProps {
  name: IconType;
  sizePx?: number;
}

const DEFAULT_SIZE_PX = 24;

const createStyles = (size: number) => {
  const result: React.CSSProperties = {};
  result.width = `${size}px`;
  result.height = `${size}px`;
  result.minWidth = `${size}px`;
  result.minHeight = `${size}px`;
  return result;
}

export const SvgIcon = ({ sizePx = DEFAULT_SIZE_PX, name }: SvgIconProps) => {
  const IconComponent = IconsMap[name] as any;
  return <IconComponent style={createStyles(sizePx)} />;
};
