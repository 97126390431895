export enum LocalStorageKeys {
  AuthToken = "auth_token",
}

export const getLocalStorageObject = (key: LocalStorageKeys) => {
  const json = localStorage.getItem(key);
  if (!json || json === "undefined") {
    return undefined;
  }

  return JSON.parse(json);
};

export const setLocalStorageObject = (
  key: LocalStorageKeys,
  object: unknown,
): void => {
  const json = JSON.stringify(object);
  localStorage.setItem(key, json);
};

export const removeLocalStorageObject = (key: LocalStorageKeys): void => {
  localStorage.removeItem(key);
};
