import { FC } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
interface FilterByDateProsp {
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
}
const FilterByDate: FC<FilterByDateProsp> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const { t } = useTranslationProvider();
  return (
    <div className={styles.wrapper}>
      <span className={styles.wrapper_title}>
        {t("Support.Filter by dates", {
          defaultValue: "Filter by dates",
        })}
      </span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <div className={styles.box}>
            <DatePicker
              format="MM/DD/YYYY"
              label="From"
              value={startDate && dayjs(startDate)}
              onChange={(newValue: dayjs.Dayjs | null) =>
                newValue && setStartDate(newValue.toDate())
              }
              slotProps={{
                textField: {
                  inputProps: { ["aria-placeholder"]: "MM/DD/YYYY" },
                  InputLabelProps: { shrink: true },
                },
              }}
            />
            <DatePicker
              label="To"
              value={endDate && dayjs(endDate)}
              onChange={(newValue: dayjs.Dayjs | null) =>
                newValue && setEndDate(newValue.toDate())
              }
              slotProps={{
                textField: {
                  inputProps: { ["aria-placeholder"]: "MM/DD/YYYY" },
                  InputLabelProps: { shrink: true },
                },
              }}
            />
          </div>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};
export default FilterByDate;
