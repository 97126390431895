import { RadioGroup, Button } from '@mui/material';
import { Radio } from 'shared/ui/inputs';
import { Select } from 'shared/ui/inputs';
import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';
import { GiftCardMethod } from 'pages/reward/components/giftCard/types';
import { SelectItem } from 'shared/ui/inputs';
import { FormikProps } from 'formik';
import { TextInput } from 'shared/ui/inputs';

interface FormValues {
  paymentMethod: GiftCardMethod;
  from: string;
  point: number;
}

interface FormPaymentMethodProps {
  formik: FormikProps<FormValues>;
  optionsFromAcc: SelectItem[];
  disabedBtn: boolean;
  setMethod: (method: GiftCardMethod) => void;
  subUserPermission: boolean;
}

export const FormPaymentMethod = ({
  formik,
  optionsFromAcc,
  disabedBtn,
  setMethod,
  subUserPermission,
}: FormPaymentMethodProps) => {
  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.wrapperForm}
    >
      {!subUserPermission && (
        <RadioGroup
          {...formik.getFieldProps('paymentMethod')}
          onChange={(event) => {
            setMethod(event.target.value as GiftCardMethod);
            formik.setFieldValue(
              'paymentMethod',
              event.target.value as GiftCardMethod
            );
          }}
        >
          <Radio
            LabelProps={{ className: styles.boxRadio }}
            value={GiftCardMethod.PayUsingYourFinancialAccount}
            label={
              <Typography>
                {GiftCardMethod.PayUsingYourFinancialAccount}
              </Typography>
            }
          />
          <Radio
            value={GiftCardMethod.PayWithRewardPoints}
            LabelProps={{ className: styles.boxRadio }}
            label={
              <Typography>{GiftCardMethod.PayWithRewardPoints}</Typography>
            }
          />
        </RadioGroup>
      )}
      {formik.values.paymentMethod ==
      GiftCardMethod.PayUsingYourFinancialAccount ? (
        <>
          {subUserPermission && (
            <Typography>Select account for payment:</Typography>
          )}
          <Select
            fullWidth
            label='Select account'
            options={optionsFromAcc}
            {...formik.getFieldProps('from')}
            error={formik.errors.from}
          />
        </>
      ) : (
        <TextInput
          fullWidth
          label='Amount in points'
          inputProps={{ readOnly: true }}
          {...formik.getFieldProps('point')}
          error={formik.errors.point}
        />
      )}

      <Button
        className={styles.btn}
        variant='contained'
        type='submit'
        disabled={disabedBtn}
      >
        confirm purchase
      </Button>
    </form>
  );
};
