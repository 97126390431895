import { TransitionAnimation } from 'shared/ui/animations';
import { Accordion } from '../accordion';
import { FormControlLabel, Checkbox, FormHelperText } from '@mui/material';
import styles from './style.module.scss';
import { useAccount } from 'pages/accountComponent/provider';
import { FilterProps } from 'pages/accountComponent/types';
import { Skeleton } from 'components/skeleton';

const selectAll = 'Select All';

export const FilterByMerchantType = (props: FilterProps) => {
  const { filterObj, setFilterObj, loading } = props;
  const { expandFilter, setExpandFilter } = useAccount();
  const { expandByMerchantType } = expandFilter;

  const transactionType = [
    {
      displayName: selectAll,
      value: filterObj.pos?.items?.every((item) => item.value),
    },
    ...(filterObj.pos?.items || []),
  ];

  const handleChangeExpand = (value: boolean) => {
    setExpandFilter({
      ...expandFilter,
      expandByMerchantType: value,
    });
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    const updatedItems =
      filterObj.pos.items?.map((item) => {
        if (name === selectAll) {
          return { ...item, value: checked };
        }
        if (item.displayName === name) {
          return { ...item, value: checked };
        }
        return item;
      }) || null;

    const updatedFilters = {
      ...filterObj,
      pos: {
        ...filterObj.pos,
        items: updatedItems,
      },
    };

    setFilterObj(updatedFilters);
  };

  const renderCheckBox = () => {
    return transactionType.map((item) => (
      <FormControlLabel
        key={item?.displayName}
        classes={{ root: styles.rootFormControlLabel }}
        control={
          <Checkbox
            classes={{
              checked: styles.checked,
            }}
            checked={item?.value}
            onChange={handleCheckBoxChange}
            name={item?.displayName}
          />
        }
        label={item?.displayName}
      />
    ));
  };

  return loading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <Accordion
        expanded={expandByMerchantType}
        onChangeExpanded={handleChangeExpand}
        summary='By merchant category'
      >
        {renderCheckBox()}
        {filterObj.pos.items?.every((item) => !item.value) && (
          <FormHelperText error={true}>
            Select at least one options
          </FormHelperText>
        )}
      </Accordion>
    </TransitionAnimation>
  );
};
