import { AnimateWrapper } from 'components/animate-wrapper';
import { useCardInformation } from './provider';
import { ActionTile } from 'shared/components/ActionTile';
import CreditCardOutlined from 'assets/svg/CreditCardOutlined.svg';
import EditFilled from 'assets/svg/EditFilled.svg';
import VerifiedUserOutlined from 'assets/svg/VerifiedUserOutlined.svg';
import AccountCircleOutlined from 'assets/svg/AccountCircleOutlined.svg';
import HomeOutlined from 'assets/svg/HomeOutlined.svg';
import CopyBlue from 'assets/svg/copy-blue.svg';
import ChevronRightGray from 'assets/svg/chevron-right-gray.svg';
import SpeedOutlined from 'assets/svg/SpeedOutlined.svg';
import BarChartOutlined from 'assets/svg/BarChartOutlined.svg';
import PasswordOutlined from 'assets/svg/PasswordOutlined.svg';
import LockOutlined from 'assets/svg/LockOutlined.svg';
import ReplayOutlined from 'assets/svg/ReplayOutlined.svg';
import LocalPlayOutlined from 'assets/svg/LocalPlayOutlined.svg';
import CancelOutlined from 'assets/svg/CancelOutlined.svg';
import { usePermission } from 'shared/hooks/usePermission';
import { triggerBase64Download } from 'common-base64-downloader-react';
import Card from 'shared/components/card';
import styled, { css } from 'styled-components';
import { useConfirmDialog } from 'providers/confirm.provider';
import SnackBarSuccess from 'shared/components/snackBar/snackBarSuccess';
import { LockCard } from './components/LockCard';
import { ReissueCard } from './components/ReissueCard';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DrawerModal from 'shared/components/drawerModal';
import { CardNumberType, EnumCardStatuses, EnumCardTypes, useActivateCardControllerMutation, useAttachedPaymentCardsControllerQuery, useCloseCardControllerMutation, useGetCardSpendingReportEXELMutation, useRenamePaymentCardNameControllerMutation, } from 'api/endpoints/paymentCards';
import { RenameCard } from 'shared/components/RenameCard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChangePin } from './components/ChangePin';
import { Skeleton } from '@mui/material';
import { CardLimits } from 'shared/components/CardLimits';
import { useSpendingReport } from 'api/hooksApi/useSpendingReport';
import moment from 'moment';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { SpendingReport } from 'pages/accountComponent/Actions/More/SpendingReport';
import { useBoolean } from '../../shared/hooks/useBoolean';
import helper from '../../services/helper';
import { useCardRouteParams } from '../../shared/hooks/useCardRouteParams';
import { useSnackBar } from '../../providers/snackBar.provider';
import { useIsMounted } from '../../shared/hooks/useIsMounted';
import { offExtFromFilename } from '../../shared/utils/pipes';
import { appRoutes } from '../../routes';
import { useCurrentUser } from '../../providers/user.provider';

interface ActionTileProps {
  title: string;
  subTitle?: string | JSX.Element;
  infoImg: any;
  actionImg?: any;
  onClick?: () => void;
  actionController?: JSX.Element;
  component?: JSX.Element | false;
  mode?: string[];
  isHide?: boolean;
  goBack?: () => void;
  isActionLoading?: boolean;
}

export const CardManage = () => {
  const confirm = useConfirmDialog();
  const snackbar = useSnackBar();
  const isMounted = useIsMounted();
  const { isSubUser, user } = useCurrentUser();
  const {
    currentCard,
    cardsIsLoading,
    paymentCardDetails,
    message,
    setMessage,
    refetchCard,
    currentCardIsLocked,
    openOrderStatus,
  } = useCardInformation();
  const {
    cardLimitsPermission,
    linkedAccountPermission,
    pinSetupPermission,
    reissueCardPermission,
    lockCardPermission,
    closeCardPermission,
  } = usePermission();
  const { unclosedFinancialAccounts } = useFinancialAccounts();
  const [currentAccountId, setCurrentAccountId] = useState(
    currentCard?.financialAccounts ? currentCard?.financialAccounts[0]?.id : undefined
  );
  const cardRouteParams = useCardRouteParams();
  const [date, setDate] = useState<any>(new Date());
  const [from, setFrom] = useState<any>(
    moment(date).startOf("month").format("YYYY-MM-DD 00:00:00Z")
  );
  const [to, setTo] = useState<any>(
    moment(date).endOf("month").format("YYYY-MM-DD 23:59:ssZ")
  );
  const { spendingReport, spendingReportIsLoading, refetch } =
    useSpendingReport(
      currentCard?.id,
      moment(from).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      moment(to).format("YYYY-MM-DDTHH:mm:ss[Z]")
    );
  useEffect(() => {
    refetch();
  }, [date, currentAccountId]);
  const [
    getSpendingReportEXELMutation,
    { isLoading: getSpendingReportEXELIsLoading },
  ] = useGetCardSpendingReportEXELMutation({});
  const getSpendingReportEXEL = async () => {
    try {
      const resEXEL = await getSpendingReportEXELMutation({
        paymentCardId: currentCard.id,
        dateFrom: moment(new Date(from)).format("YYYY-MM-DDTHH:mm:ssZ"),
        dateTo: moment(new Date(to)).format("YYYY-MM-DDT23:59:ssZ"),
      }).unwrap();
      if (resEXEL) {
        const { name, content, contentType } = resEXEL;
        const base64Str = content;
        const base64EXEL = `data:${contentType};base64, ${base64Str}`;
        triggerBase64Download(base64EXEL, offExtFromFilename(name));
      }
    } catch (e: any) {
      console.log("error", e);
      // setWarningText(helper.formatErrors(e));
      // setIsShowWarnign(true);
    }
  };
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentMode = searchParams.get("mode") || "";
  const [activateCardMutation, { isLoading: isLoadingActivateCardMutation }] =
    useActivateCardControllerMutation();
  const [closeCardMutation, { isLoading: isLoadingCloseCardMutation }] =
    useCloseCardControllerMutation();
  const [renamePaymentCard, { isLoading: renamePaymentCardIsLoading }] =
    useRenamePaymentCardNameControllerMutation({});
  const [isRenameMode, setIsRenameMode] = useState<boolean>(false);
  const [isChangePinMode, setIsChangePinMode] = useState<boolean>(false);
  const activateProcessingBool = useBoolean();
  const changeNicknameBool = useBoolean();
  const { setSnackBar } = useSnackBar();
  const { data: attachedCards, isLoading: isLoadingAttachedCards } = useAttachedPaymentCardsControllerQuery({})

  const handleRenameCard = async (nickName: string) => {
    changeNicknameBool.setTrue();
    try {
      await renamePaymentCard({
        paymentCardId: currentCard.id,
        nickName,
      }).unwrap();
      await refetchCard();
      setMessage("The card has been successfully renamed");
      setIsRenameMode(false);
      await helper.sleep(10000);
      if (isMounted()) {
        changeNicknameBool.setFalse();
      }
    } catch (error: any) {
      changeNicknameBool.setFalse();
      const errMessage =
        error?.data?.NickName?.join(',') || 'Change nickname error';
      snackbar.setSnackBar({
        type: 'error',
        message: errMessage,
        isShow: true,
      })

    } finally {
      setIsRenameMode(false);
    }
  };
  const handleAddressCopy = async (address: string) => {
    try {
      await navigator.clipboard.writeText(address);
      setMessage("Address copied");
    } catch (err) {}
  };

  const handleDateChange = useCallback(
    (date) => {
      setDate(date);
      const dateStart = moment(new Date(date))
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00Z");
      const dateEnd = moment(new Date(date))
        .endOf("month")
        .format("YYYY-MM-DD 23:59:ssZ");
      setFrom(dateStart);
      setTo(dateEnd);
    },
    [date]
  );

  const handleUpdateCardStatus = async (message: string) => {
    await refetchCard();
    setMessage(message);
  }

  const cardOrderInfo = useMemo(() => {
    if (paymentCardDetails && paymentCardDetails.physicalPaymentCardOrders?.length) {
      return paymentCardDetails.physicalPaymentCardOrders[0];
    }
  }, [paymentCardDetails]);

  const actionTiles: ActionTileProps[] = [
    {
      title: "Card Nickname",
      subTitle: (
        changeNicknameBool.value ? (
          <Skeleton/>
        ) : (
          currentCard?.cardName || 'Business Debit'
        )
      ),
      onClick: () => setIsRenameMode(true),
      infoImg: CreditCardOutlined,
      actionImg: EditFilled,
      component: isRenameMode && (
        <RenameCard
          onSubmit={handleRenameCard}
          currentName={currentCard?.cardName}
          isLoading={renamePaymentCardIsLoading}
        />
      ),
    },
    {
      title: "Linked Account",
      subTitle: currentCard?.financialAccounts && currentCard?.financialAccounts[0]?.name || undefined,
      onClick: () =>
        currentCard?.financialAccounts && currentCard?.financialAccounts[0]?.id &&
        navigate(`/account/${currentCard?.financialAccounts[0]?.id}`),
      infoImg: AccountCircleOutlined,
      actionImg: ChevronRightGray,
      isHide: !linkedAccountPermission,
      isActionLoading: !currentCard?.financialAccounts,
    },
    {
      title: "Authorized User",
      subTitle: paymentCardDetails?.attachedToSubProfile
        ? currentCard?.subProfile || currentCard?.cardholder
        : '',
      onClick: () => {},
      infoImg: VerifiedUserOutlined,
      isHide: !paymentCardDetails?.attachedToSubProfile,
    },

    {
      title: "Billing Address",
      subTitle: paymentCardDetails?.address,
      onClick: () => handleAddressCopy(paymentCardDetails?.address),
      infoImg: HomeOutlined,
      actionImg: CopyBlue,
    },
  ].filter((item) => !item.isHide);


  const isMutateActionsDisabled = useMemo(() => {
    if (!isSubUser) {
      return false;
    }
    const attachedCard = attachedCards?.find(ac => ac.paymentCardId === cardRouteParams.cardId);
    return !attachedCard || attachedCard.subProfileId !== user.id;
  }, [attachedCards, cardRouteParams, isSubUser])

  const actionsList: ActionTileProps[] = [
    {
      title: "Transaction Limits",
      onClick: () => {
        setSearchParams({ mode: "limits" });
      },
      infoImg: SpeedOutlined,
      actionImg: ChevronRightGray,
      component: (
        <CardLimits setMessage={setMessage} />
      ),
      goBack:
        currentMode !== "limits"
          ? () => setSearchParams({ mode: "limits" })
          : undefined,
      mode: ["limits", "limitsInfo", "setLimits"],
      isHide: !cardLimitsPermission || currentCard?.cardType === CardNumberType.burner,
    },
    {
      title: "Spending Report",
      onClick: () => {
        setSearchParams({ mode: "spendingReport" });
      },
      mode: ["spendingReport"],
      infoImg: BarChartOutlined,
      actionImg: ChevronRightGray,
      component: (
        <SpendingReport
          data={spendingReport}
          handleDateChange={handleDateChange}
          date={date}
          setCurrentAccountId={setCurrentAccountId}
          isLoading={spendingReportIsLoading}
          downloadReport={getSpendingReportEXEL}
          isLoadingDownloadReport={getSpendingReportEXELIsLoading}
        />
      ),
      isHide: isMutateActionsDisabled,
    },
    {
      title: "Set Up PIN",
      onClick: () => setIsChangePinMode(true),
      infoImg: PasswordOutlined,
      actionImg: ChevronRightGray,
      isHide: isMutateActionsDisabled || !pinSetupPermission,
    },
    {
      title: currentCardIsLocked ? "Unlock Card" : "Lock Card",
      onClick: () => {},
      infoImg: LockOutlined,
      actionController: (
        <LockCard
          cardId={currentCard?.id}
          onFinish={handleUpdateCardStatus}
          defaultState={currentCardIsLocked}
        />
      ),
      isHide: isMutateActionsDisabled
        || !lockCardPermission
        || currentCard?.status === EnumCardStatuses.ACTIVATION_REQUIRED,
    },
    {
      title: "Activate Card",
      onClick: () => {
        confirm.show({
          applyButtonText: "Confirm",
          cancelButtonText: "Cancel",
          dialogTitle: "Activate your card",
          dialogText:
            "Are you sure you want to activate your card? Click 'Activate' to proceed.",
          cancelButtonType: "text",
          cancelError: true,
          typeNew: true,
          async onApply() {
            activateProcessingBool.setTrue();
            try {
              await activateCardMutation({ cardId: currentCard.id }).unwrap();
              await refetchCard();
              await helper.sleep(5000);
              setMessage(
                "Your card has been activated and is now ready for use."
              );
            } finally {
              activateProcessingBool.setFalse();
            }
          },
        });
      },
      infoImg: LocalPlayOutlined,
      actionImg: ChevronRightGray,
      isHide: isMutateActionsDisabled || currentCard?.status !== EnumCardStatuses.ACTIVATION_REQUIRED,
      isActionLoading: activateProcessingBool.value,
    },
    {
      title: "Re-issue Card",
      onClick: () => {
        setSearchParams({ mode: "reissueCard" });
      },
      infoImg: ReplayOutlined,
      actionImg: ChevronRightGray,
      mode: ["reissueCard"],
      isHide: isMutateActionsDisabled || !reissueCardPermission || currentCard?.cardType === CardNumberType.burner,
    },
    {
      title: "Order status",
      onClick: () => openOrderStatus(cardOrderInfo?.id!),
      infoImg: CreditCardOutlined,
      actionImg: ChevronRightGray,
      isHide: currentCard?.formFactor !== EnumCardTypes.PHYSICAL || !cardOrderInfo,
    },
    {
      title: "Close Card",
      onClick: () => {
        confirm.show({
          applyButtonText: "Close card",
          cancelButtonText: "Cancel",
          dialogTitle: "Close your card",
          dialogText:
            "Are you sure you want to activate your card? Click 'Activate' to proceed.",
          cancelButtonType: "text",
          cancelError: true,
          typeNew: true,
          async onApply() {
            await closeCardMutation({ cardId: currentCard.id }).unwrap();
            setSnackBar({
              type: 'success',
              message: 'Your card has been successfully closed. It is now deactivated and can no longer be used for transactions.',
              isShow: true,
            });
            if (isSubUser) {
              navigate(appRoutes.home());
            } else {
              navigate(appRoutes.accountById(cardRouteParams.accountId, { accountTab: 'cards' }))
            }
          },
        });
      },
      infoImg: CancelOutlined,
      actionImg: ChevronRightGray,
      isHide: isMutateActionsDisabled || !closeCardPermission,
    },
  ].filter((item) => !item.isHide);

  return (
    <AnimateWrapper className="fade">
      <Container>
        <TopBlock>
          <CardViewContainer>
            <Card
              last4={currentCard?.last4}
              cardholder={currentCard?.cardholder}
              formFactor={currentCard?.formFactor}
              cardType={currentCard?.cardType}
              cardId={currentCard?.id}
              isClosed={currentCard?.status === EnumCardStatuses.CLOSED}
              isLocked={currentCardIsLocked}
              isActivate={currentCard?.status === EnumCardStatuses.ACTIVATION_REQUIRED}
              isAuthorizedUser={currentCard?.attachedToSubProfile}
              partnerIcons={currentCard?.partnerCardIcons}
              partnerName={currentCard?.partnerName}
              isCardLoading={!currentCard}
            />
          </CardViewContainer>

          {cardsIsLoading ? (
            <>
              <Skeleton height={200} width={"100%"} />
              <Skeleton height={200} width={"100%"} />
            </>
          ) : (
            <ActionBlock>
              {actionTiles.map((item) => {
                return item.component ? (
                  item.component
                ) : (
                  <ActionTile
                    key={item.title}
                    {...item}
                    isLoading={cardsIsLoading}
                    isFullClickable={true}
                  />
                );
              })}
            </ActionBlock>
          )}
        </TopBlock>
        {(cardsIsLoading || isLoadingAttachedCards) ? (
          <Skeleton width={530} height={400} />
        ) : (
          <ActionsList>
            {actionsList.map((item) => {
              return (
                <ActionTile
                  key={item.title}
                  {...item}
                  isLoading={cardsIsLoading}
                  isFullClickable={true}
                />
              );
            })}
          </ActionsList>
        )}
      </Container>
      {actionsList.map((item) => (
        <DrawerModal
          key={item.title}
          isShow={!!item.mode?.includes(currentMode) && currentMode !== 'reissueCard'}
          onClose={() => {}}
          titleText={item.title}
          goBack={item.goBack}
        >
          {item.component}
        </DrawerModal>
      ))}
    <ReissueCard isShow={currentMode == 'reissueCard'} onClose={() => setSearchParams({})}/>
      <SnackBarSuccess
        isOpen={!!message}
        message={message}
        onClose={() => setMessage("")}
      />
      {isChangePinMode && (
        <ChangePin
          cardId={currentCard.id}
          closeModal={() => setIsChangePinMode(false)}
        />
      )}
    </AnimateWrapper>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const TopBlock = styled("div")`
  display: flex;
  gap: 10px;
  @media (max-width: 1200px) {
    flex-direction: column;
    max-width: 530px;
  }
`;
const ActionBlock = styled("div")`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 100%;
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      gap: 0;
      border: 1px solid ${theme.colors.borderLightGrey};
      border-radius: 4px;
      & > div {
        border: none;
        border-bottom: 1px solid ${theme.colors.borderLightGrey};
        border-radius: 0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  `}
`;
const CardViewContainer = styled("div")`
  min-width: 310px;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;
const ActionsList = styled("div")`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.borderLightGrey};
    max-width: 530px;
    border-radius: 4px;
    & > div {
      border: none;
      border-bottom: 1px solid ${theme.colors.borderLightGrey};
      border-radius: 0;
      max-height: 60px;
      padding: 0;
      &:last-child {
        border-bottom: none;
      }
    }
  `}
`;
