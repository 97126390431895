import StorefrontOutlined from "assets/svg/StorefrontOutlined.svg";
import ChevronRightGray from "assets/svg/chevron-right-gray.svg";
import LocationonOutlined from "assets/svg/LocationonOutlined.svg";
import styled, { css } from "styled-components";
import { ActionTile } from "shared/components/ActionTile";
import {
  Button,
  Collapse,
  Fade,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { useLayoutEffect, useState } from 'react';
import helper from "services/helper";
import LimitsList from "../LimitsList";
import { LoadingButton } from "@mui/lab";
import { useCurrentUser } from '../../../../providers/user.provider';

interface SetUpLimitsProps {
  setCountries: any;
  setCategories: any;
  setAmountLimit: any;
  setAmountLimitMonthly: any;
  amountLimit: any;
  amountLimitMonthly: any;
  categories: any;
  countries: any;
  onSave: any;
  detachAllLimits: any;
  isLoading: boolean;
  isSaveDisabled?: boolean;
}

export const SetUpLimits = ({
  setCountries,
  setCategories,
  setAmountLimit,
  setAmountLimitMonthly,
  amountLimit,
  amountLimitMonthly,
  categories,
  countries,
  onSave,
  detachAllLimits,
  isLoading,
  isSaveDisabled,
}: SetUpLimitsProps) => {
  const [switchedLimits, setSwitchedLimits] = useState<string[]>([]);
  const [mode, setMode] = useState<string>();
  const { isSubUser } = useCurrentUser();

  useLayoutEffect(() => {
    // todo(aolenin) is is bull shit need to refactor
    setSwitchedLimits(cur => ["maxForCalendarMonth", "maxForTransaction"].filter(
      (item, index) => (!index && amountLimitMonthly || cur.includes(item)) || (index && amountLimit || cur.includes(item))
    ))
  }, [amountLimitMonthly, amountLimit]);

  const handleSwitchLimit = (key: string) => {
    if (switchedLimits.includes(key)) {
      if (key === 'maxForCalendarMonth') {
        setAmountLimitMonthly('');
      }
      if (key === 'maxForTransaction') {
        setAmountLimit('');
      }
      setSwitchedLimits((limits) => limits.filter((item) => item !== key));
    } else {
      setSwitchedLimits((limits) => [...limits, key]);
    }
  };

  const actionsList = [
    {
      title: "Merchant category",
      onClick: () => setMode("merchantCategory"),
      infoImg: StorefrontOutlined,
      actionImg: ChevronRightGray,
      mode: "merchantCategory",
    },
    {
      title: "Country",
      onClick: () => setMode("country"),
      infoImg: LocationonOutlined,
      actionImg: ChevronRightGray,
      mode: "country",
    },
  ];
  const limitsItems = [
    {
      title: "Max amount for transaction",
      onChange: (e) => {
        const value = e.target.value
          .replace(/[^\d.]/g, "")
          .split(".")
          .join("");
        if (/^\d+$/.test(value) || value === "") {
          setAmountLimit(value);
        }
      },
      key: "maxForTransaction",
      value: amountLimit,
      readonly: isSubUser,
    },
    {
      title: "Max amount for calendar month",
      onChange: (e) => {
        const value = e.target.value
          .replace(/[^\d.]/g, "")
          .split(".")
          .join("");
        if (/^\d+$/.test(value) || value === "") {
          setAmountLimitMonthly(value);
        }
      },
      key: "maxForCalendarMonth",
      value: amountLimitMonthly,
      readonly: isSubUser,
    },
  ];
  return (
    <>
      {!mode && (
        <>
          <ActionsList>
            {actionsList.map((item) => (
              <ActionTile key={item.title} {...item} />
            ))}
          </ActionsList>
          <SwithcList>
            {limitsItems.map((item) => (
              <div key={item.title}>
                <SwithcControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      disabled={item.readonly}
                      onChange={() => handleSwitchLimit(item.key)}
                      checked={switchedLimits.includes(item.key)}
                    />
                  }
                  label={item.title}
                  labelPlacement="start"
                />
                <Collapse timeout={90} in={switchedLimits.includes(item.key)}>
                  <Fade in={switchedLimits.includes(item.key)}>
                    <LimitTextField
                      disabled={isLoading || item.readonly}
                      label="Amount"
                      onChange={item.onChange}
                      value={`${item.value ? `$${helper.formatUsdValue(item.value)}` : ''}`}
                    />
                  </Fade>
                </Collapse>
              </div>
            ))}
          </SwithcList>
          <ButtonsGroup>
            <LoadingButton
              loading={isLoading}
              onClick={onSave}
              variant="contained"
              disabled={isSaveDisabled}
            >
              <div>Save changes</div>
            </LoadingButton>
            <Button
              disabled={isSubUser || isLoading || (!amountLimit && !amountLimitMonthly)}
              onClick={detachAllLimits}
              variant="outlined"
            >
              <div>remove all limits</div>
            </Button>
          </ButtonsGroup>
        </>
      )}
      {mode === actionsList[1].mode && (
        <LimitsList
          setLimitsPageMode={setMode}
          arr={countries}
          setArr={setCountries}
        />
      )}
      {mode === actionsList[0].mode && (
        <LimitsList
          setLimitsPageMode={setMode}
          arr={categories}
          setArr={setCategories}
        />
      )}
    </>
  );
};

const ActionsList = styled("div")`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.borderLightGrey};
    max-width: 530px;
    border-radius: 4px;
    & > div {
      border: none;
      border-bottom: 1px solid ${theme.colors.borderLightGrey};
      border-radius: 0;
      max-height: 60px;
      padding: 0;
      &:last-child {
        border-bottom: none;
      }
    }
  `}
`;
const SwithcList = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0;
`;
const LimitTextField = styled(TextField)`
  width: 100%;
`;

const SwithcControlLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
`;

const ButtonsGroup = styled("div")`
  display: flex;
  gap: 24px;
  margin: 24px 0;
`;
