import styles from './style.module.scss';
import { Popover } from '@mui/material';
import { useState } from 'react';
import React from 'react';
import { Typography } from '../../../../shared/ui/typography';
import { Button } from '../../../../shared/ui/buttons';
import { SvgIcon } from '../../../../shared/components/svgIcon';
import cx from 'classnames';
import { useCurrentUser } from '../../../../providers/user.provider';
import { useLoginManagement } from '../../../../shared/hooks/useLoginManagement';
import { useHolderV2ControllerQuery } from '../../../../api/endpoints/account';

const firstLastName = (first: string, last: string) => `${first ? first + ' ' : ''}${last}`

export const LoginAs = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | undefined>()
  const { user } = useCurrentUser();
  const { connectedAccountsToShow, ...loginManagement } = useLoginManagement();
  const { data: holder } = useHolderV2ControllerQuery();

  const handleOpenPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(undefined);
  }

  const handleLoginAs = (accountId: number) => {
    handleClosePopover();
    loginManagement.loginAsAccount(accountId);
  }

  const isLoginAsEnabled = connectedAccountsToShow?.length || !user?.isOriginalUser;
  const isPopoverOpen = Boolean(anchorEl);

  if (!user) {
    return null;
  }

  return (
    <div>
      <div
        tabIndex={0}
        className={
          cx(
            styles.title,
            isLoginAsEnabled && styles.titleActive,
            isPopoverOpen && styles.titleColorMain
          )
        }
        onClick={isLoginAsEnabled ? handleOpenPopover : undefined}
      >
        <>
          {holder &&
            (holder?.businessAccountHolder?.doingBusinessAsName ?? firstLastName(user.givenName, user.familyName))
          }
          {isLoginAsEnabled && <SvgIcon name="ArrowDropDownFilter" sizePx={24} />}
        </>
      </div>
      <Popover
        anchorEl={anchorEl}
        open={isPopoverOpen}
        onClose={handleClosePopover}
        anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          {!user.isOriginalUser && (
            <div className={styles.backToMyAccount}>
              <Typography variant='body2'>
                Currently, you are logged in using one of your connected accounts.
                To return to your main account, please click below:
              </Typography>
              <Button
                fullWidth={false}
                variant="contained"
                onClick={loginManagement.backToMyAccount}
                startIcon={<SvgIcon name="LogoutOutlined" sizePx={18} />}
              >
                back to my account
              </Button>
            </div>
          )}
          {connectedAccountsToShow && connectedAccountsToShow?.length > 0 && (
            <div className={styles.accounts}>
              <div className={styles.accountsTitle}>
                <Typography variant="body1">Connected accounts</Typography>
              </div>
              {connectedAccountsToShow.map(account => (
                <div
                  tabIndex={0}
                  key={account.id}
                  className={styles.accountItem}
                  onClick={() => handleLoginAs(account.id)}
                >
                  <Typography variant='body2'>
                    {account.dba}
                  </Typography>
                  <Typography className={styles.accountItemCaption} color="" variant='caption'>
                    {account.nickName}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
};
