import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from '../baseApi'
import { ApiTags } from '../api-tags'
import { SecureOperationType } from 'enums'

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  REVIEW = 'REVIEW',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
}
export enum ExternalBankAccountsProvider {
  PLAID = 'PLAID',
  FINICITY = 'FINICITY',
}
export interface IExternalBankAccountsItemApi {
  typename: string
  externalBankAccountType: number
  id: string
  name: string
  externalBankAccountDetails: IExternalBankAccountDetails
  provider: null | ExternalBankAccountsProvider
  createdAt: Date
  accountStatus: AccountStatus
  updatedAt: string
  bankName: null
  owners: null
  balanceInfo: null
}

export interface IExternalBankAccountDetails {
  last4: string
  type: string
  routingNumber: string
  createdAt: string
  updatedAt: string
}

export interface ACHTransferDetailsItemApi {
  node: any[]
  name: string
  date: string
}

export interface FinancialAccountTransfersArg {
  financialAccountId: string
  from: any
  to: any
}

interface initialTransfersArg {
  FromFinancialAccountId: string
  ToFinancialAccountId: string
  Amount: string
  CurrencyCode: number
  sameDay: boolean
}

interface initiateFundsDepositArg {
  FromFinancialAccountId: string
  ToFinancialAccountId: string
  Amount: string
  CurrencyCode: number
  sameDay: boolean
}

interface IAddExternalPayeeArg {
  routingNumber: string
  accountNumber: string
  name: string
  bankAccountType: string
  oneTimeCode: string
  secureOperationType: string
}

interface AddNeteviaCustomerPayeeArg {
  accountNumber: string
  name: string
}

export type PayeeType = {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  accountNumber: string
  routingNumber: string
  last4: string
  type: string
  typename: string
}

export type PayeeExternalType = {
  __typename: string
  idAccount: string
  name: string
  accountStatus: string
  provider: string
  createdAt: string
  updatedAt: string
  id: string
  last4: string
  routingNumber: string
  type: string
}

interface PayeesApi {
  payees: PayeeType[]
}

export interface InitiateTransferBetweenArg {
  fromFinancialAccountId: string
  toFinancialAccountId: string
  amount: number
  currencyCode: number
  memo: string
}

export interface AddNewNeteviaPayee {
  accountNumber: string
  name: string
  oneTimeCode: string
  secureOperationType: string
}

export interface InitiateTransferBetweenReturn {
  initiateTransferBetweenFinancialAccounts: {
    id: string
    createdAt: string
    updatedAt: string
    amount: {
      value: number
      currencyCode: string
    }
    status: string
    statusReason: null
    memo: string
  }
}

export interface SendCodeVerificationArg {
  shortMessageType: string
}

export interface SendCodeVerificationReturn {
  success: string
}

export interface CheckCodeVerificationArg {
  shortMessageType: string
  code: string
}

export interface CheckCodeVerificationReturn {
  isCodeValid: boolean
}

interface ACHTransferArg {
  fromFinancialAccountId: string
  toFinancialAccountId: string
  amount: string
  currencyCode: number
  sameDay: boolean
  oneTimeCode: string
  secureOperationType: string
}

interface DepositACHTransferArg {
  fromFinancialAccountId: string
  toFinancialAccountId: string
  amount: number
  currencyCode: number
  oneTimeCode: string
  secureOperationType: SecureOperationType
}

interface DepositACHTransferApi {
  id: string
  createdAt: Date
  updatedAt: Date
  companyName: string
  companyIdentifier: string
  settlementDate: Date
  fundsAvailableDate: Date
  status: Status
  fromFinancialAccount: FromFinancialAccount
  toFinancialAccount: ToFinancialAccount
}

interface FromFinancialAccount {
  __typename: string
  id: string
  name: string
  provider: string
  externalBankAccountDetails: ExternalBankAccountDetails
}

interface ExternalBankAccountDetails {
  id: string
  last4: string
  type: string
  routingNumber: string
}

interface Status {
  status: string
}

interface ToFinancialAccount {
  __typename: string
  id: string
  name: string
}

interface PayeeTransferArg {
  fromFinancialAccountId: string
  toFinancialAccountId: string
  amount: number
  currencyCode: number
  memo: string
  oneTimeCode: string
  secureOperationType: string
}

interface PayeeTransferReturn {
  initiateTransferBetweenFinancialAccounts: {
    id: string
    createdAt: string
    updatedAt: string
    amount: {
      value: number
      currencyCode: string
    }
    status: string
    statusReason: string
    memo: string
  }
}

export interface SearchClientsArg {
  whatsLookingFor: string
}
export interface IACHTransferManyArg {
  transferRequests: TransferRequest[]
  oneTimeCode: string
  secureOperationType: string
}

interface TransferRequest {
  fromFinancialAccountId: string
  toFinancialAccountId: string
  amount: number
  currencyCode: number
}

interface IDeleteExternalPayeeArg {
  financialAccountId: string
  force: boolean
}
interface IDeleteNeteviaPayeeArg {
  financialAccountId: string
  force: boolean
}
export interface TransferRequests {
  fromFinancialAccountId: string
  toFinancialAccountId: string
  amount: number
  currencyCode: number
  memo?: string
}

interface TransferManyArg {
  transferRequests: TransferRequests[]
  oneTimeCode: string
  secureOperationType: string
}
export interface SearchClientsApi {
  accountNumberLast4: string
  businessPhoneNumber: string
  doingBusinessName: string
  financialAccountId: string
  fullAccountNumber: string
  id: string
  last4: string
  name: string
  webSite: string
}

export const fundsMovementApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'fundsMovement',
  tagTypes: [ApiTags.GetExternalBankAccounts, ApiTags.GetPayees],
  endpoints: (builder) => ({
    externalBankAccountsController: builder.query<
      IExternalBankAccountsItemApi[],
      void
    >({
      providesTags: [ApiTags.GetPayees],
      //For this endpoint, I intentionally don't cache the data using the option keepUnusedDataFor: 0
      keepUnusedDataFor: 0,
      query: () => ({
        url: 'api/fundsMovement/externalBankAccounts/v2',
        method: 'GET',
      }),
    }),
    ACHTransferDetailsController: builder.mutation<string, any>({
      query: ({ transferId }) => ({
        url: `api/fundsMovement/ACHTransferDetails/${transferId}`,
        method: 'GET',
      }),
    }),
    financialAccountTransfersController: builder.mutation<
      any,
      FinancialAccountTransfersArg
    >({
      query: ({ financialAccountId, from, to }) => ({
        url: `api/fundsMovement/byFinancialAccount`,
        method: 'GET',
        params: {
          financialAccountId,
          from,
          to,
        },
      }),
    }),
    addExternalPayee: builder.mutation<void, IAddExternalPayeeArg>({
      query: (queryArg) => ({
        url: `api/fundsMovement/v2/connectNonVerifiedExternalBankAccount`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    addNeteviaCustomerPayee: builder.mutation<any, AddNeteviaCustomerPayeeArg>({
      invalidatesTags: [ApiTags.GetPayees],
      query: (queryArg) => ({
        url: `api/fundsMovement/createPayee`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    addNeteviaCustomerPayeeV2: builder.mutation<any, AddNewNeteviaPayee>({
      invalidatesTags: [ApiTags.GetPayees],
      query: (queryArg) => ({
        url: `api/fundsMovement/v2/Payee`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    neteviaPayeesController: builder.query<PayeesApi, void>({
      providesTags: [ApiTags.GetPayees],
      query: () => ({
        url: 'api/fundsMovement/Payees',
        method: 'GET',
      }),
    }),
    initiateTransferBetweenFinancialAccController: builder.mutation<
      InitiateTransferBetweenReturn,
      InitiateTransferBetweenArg
    >({
      query: (queryArg) => ({
        url: `api/fundsMovement/v2/initiateTransferBetweenFinancialAccounts`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    sendCodeVerification: builder.mutation<
      SendCodeVerificationReturn,
      SendCodeVerificationArg
    >({
      query: (queryArg) => ({
        url: `/api/CodeVerification/send`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    checkCodeVerification: builder.mutation<
      CheckCodeVerificationReturn,
      CheckCodeVerificationArg
    >({
      query: (queryArg) => ({
        url: `/api/CodeVerification/check`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    ACHTransferController: builder.mutation<any, ACHTransferArg>({
      query: (queryArg) => ({
        url: `api/fundsMovement/v2/ACHTransfer`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    depositACHTransfer: builder.mutation<
      DepositACHTransferApi,
      DepositACHTransferArg
    >({
      query: (queryArg) => ({
        url: `api/fundsMovement/v2/DepositACHTransfer`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    payeeTransferController: builder.mutation<
      PayeeTransferReturn,
      PayeeTransferArg
    >({
      query: (queryArg) => ({
        url: `api/fundsMovement/v2/PayeeTransfer`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    ACHTransferManyControllerr: builder.mutation<void, TransferManyArg>({
      query: (queryArg) => ({
        url: `api/fundsMovement/ACHTransferMany`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    payeeTransferManyController: builder.mutation<void, TransferManyArg>({
      query: (queryArg) => ({
        url: `api/fundsMovement/PayeeTransferMany`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    searchClients: builder.mutation<SearchClientsApi[],SearchClientsArg>({
      query: (queryArg) => ({
        url: `/api/Search/v2/clients`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    ///ScheduleTransfer
    initialTransfersController: builder.mutation<any, initialTransfersArg>({
      query: (queryArg) => ({
        url: `api/fundsMovement/initiateFundsWithdrawalACHTransfer`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    ///NeteviaTransfer
    makePayeeTransferController: builder.mutation<
      any,
      InitiateTransferBetweenArg
    >({
      query: (queryArg) => ({
        url: `api/fundsMovement/makePayeeTransfer`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    ///ConnectedTransfer
    initiateFundsDepositController: builder.mutation<
      any,
      initiateFundsDepositArg
    >({
      query: (queryArg) => ({
        url: `api/fundsMovement/initiateFundsDepositACHTransfer`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    //batchTransfer no OTP
    payeeTransferManyNoOtpController: builder.mutation<any, any>({
      query: (queryArg) => ({
        url: `api/fundsMovement/PayeeTransferManyNoOtp`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    //batchTransfer no OTP
    ACHTransferManyNoOtpControllerr: builder.mutation<any, any>({
      query: (queryArg) => ({
        url: `api/fundsMovement/ACHTransferManyNoOtp`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteExternalPayee: builder.mutation<void, IDeleteExternalPayeeArg>({
      invalidatesTags: [ApiTags.GetPayees],
      query: (queryArg) => ({
        url: `/api/fundsMovement/externalPayee`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    deleteNeteviaPayee: builder.mutation<void, IDeleteNeteviaPayeeArg>({
      invalidatesTags: [ApiTags.GetPayees],
      query: (queryArg) => ({
        url: `/api/fundsMovement/deletePayee`,
        method: 'POST',
        body: queryArg,
      }),
    }),

    ACHTransferMany: builder.mutation<void, IACHTransferManyArg>({
      query: (queryArg) => ({
        url: `api/fundsMovement/v2/ACHTransferMany`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
})
export const {
  useExternalBankAccountsControllerQuery,
  useACHTransferDetailsControllerMutation,
  useFinancialAccountTransfersControllerMutation,
  useAddExternalPayeeMutation,
  useAddNeteviaCustomerPayeeMutation,
  useNeteviaPayeesControllerQuery,
  useInitiateTransferBetweenFinancialAccControllerMutation,
  useSendCodeVerificationMutation,
  useCheckCodeVerificationMutation,
  useACHTransferControllerMutation,
  useDepositACHTransferMutation,
  usePayeeTransferControllerMutation,
  useACHTransferManyControllerrMutation,
  usePayeeTransferManyControllerMutation,
  useSearchClientsMutation,
  useInitialTransfersControllerMutation,
  useMakePayeeTransferControllerMutation,
  useInitiateFundsDepositControllerMutation,
  useACHTransferManyNoOtpControllerrMutation,
  usePayeeTransferManyNoOtpControllerMutation,
  useDeleteExternalPayeeMutation,
  useDeleteNeteviaPayeeMutation,
  useAddNeteviaCustomerPayeeV2Mutation,
  useACHTransferManyMutation,
} = fundsMovementApi
