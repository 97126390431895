import { buildQs } from './shared/utils/build-qs';

interface AccountByIdQs {
  accountTab?: 'cards' | 'transactions';
}

export enum CardSubRoute {
  manageCard = 'manage-card',
  cardActions = 'card-actions',
  limits = 'limits',
  autorizedCards = 'autorized-cards',
}

export enum TransferRoute {
  batchTransfer = 'batch-transfer',
}
export enum TransferQs {
  fromConnectedAccount = 'from-connected-account',
}

export enum VerifyDocsSubRoute {
  inProcess = 'in-process',
}

export enum VerifyInfoSubRoute {
  failure = 'failure',
}

export const CARD_ORDER_STATUS_QS_NAME = 'orderStatusId';

export enum RewardsRouteSubRoute {
  dashboard = 'dashboard',
  history = 'history',
  myGiftCard = 'myGiftCard',
}

export enum SettingsRouteSubRoute {
  support = 'support',
}

export const appRoutes = {
  home: () => `/home`,
  accountById: (accountId: string, qs?: AccountByIdQs) =>
    buildQs(`/account/${accountId}`, qs),
  cardById: (
    accountId: string,
    cardId: string,
    subRoute?: CardSubRoute,
    rest?: string
  ) =>
    `/account/${accountId}/card/${cardId}${subRoute ? `/${subRoute}` : ''}${
      rest ? rest : ''
    }`,

  // it is resolver for support old components
  // instead you need to use appRoutes.cardById
  cardTransactions: (cardId: string) => `/card-transactions/${cardId}`,

  transfer: (subRoute?: TransferRoute, qs?: any) => buildQs(`/transfes${subRoute ? `/${subRoute}` : ''}`, qs),

  verifyInfo: (subRoute?: VerifyInfoSubRoute) =>
    `/verify-your-info${subRoute ? `/${subRoute}` : ''}`,
  verifyDocs: (subRoute?: VerifyDocsSubRoute) =>
    `/verify-doc${subRoute ? `/${subRoute}` : ''}`,

  rewards: (subRoute?: RewardsRouteSubRoute, qs?: any) => buildQs(`/rewards${subRoute ? `/${subRoute}` : ''}`, qs),
  settings: (subRoute?: SettingsRouteSubRoute, qs?: any) => buildQs(`/settings${subRoute ? `/${subRoute}` : ''}`, qs),
} as const;
