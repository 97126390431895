import { TextInput } from 'shared/ui/inputs';
import { SearchIcon } from 'shared/ui/icons';
import { InputAdornment } from '@mui/material';
import styles from './style.module.scss';

interface FilterByName {
  searchTransactionByName: string;
  setSearchTransactionByName: (searchTransactionByName: string) => void;
}

export const FilterByName = (props: FilterByName) => {
  const { searchTransactionByName, setSearchTransactionByName } = props;

  return (
    <TextInput
     fullWidth
      placeholder='Search here'
      value={searchTransactionByName}
      onChange={(event) => setSearchTransactionByName(event.target.value)}
      autoComplete='off'
      InputProps={{
        startAdornment: (
          <InputAdornment
            position='start'
            className={styles.adornment}
          >
            <SearchIcon />
          </InputAdornment>
        ),
        className: styles.input
      }}
    />
  );
};
