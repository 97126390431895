import { FC } from "react";
import { TicketStatus } from "../../types";
import styles from "./style.module.scss";
import NewTicketIcon from "assets/svg/pen-blue.svg";
import InProgressIcon from "assets/svg/clock-orange.svg";
import ResolvedIcon from "assets/svg/success-green.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import cancelIcon from "assets/svg/cancel-gray.svg";
import { TicketStatusEnum } from "pages/support/types/enums";

const ICON_SIZE_DESKTOP = 16;
const ICON_SIZE_MOB = 14;

const HISTORY_STATUSES = {
  [TicketStatusEnum.NEW]: {
    type: TicketStatusEnum.NEW,
    icon: NewTicketIcon,
    color: "#1565C0",
    bgColor: " #E3F2FD",
  },
  [TicketStatusEnum.PENDING]: {
    type: TicketStatusEnum.PENDING,
    icon: InProgressIcon,
    color: "#EF6C00",
    bgColor: "#FFF3E0",
  },
  [TicketStatusEnum.WORKING_IN_PROGRESS]: {
    type: TicketStatusEnum.WORKING_IN_PROGRESS,
    icon: InProgressIcon,
    color: "#EF6C00",
    bgColor: "#FFF3E0",
  },
  [TicketStatusEnum.RESOLVED]: {
    type: TicketStatusEnum.RESOLVED,
    icon: ResolvedIcon,
    color: "#2E7D32",
    bgColor: "#E8F5E9",
  },
};

interface Props {
  status: TicketStatusEnum;
  handleDelete?: (boolean) => void;
}

const Status: FC<Props> = ({ status, handleDelete }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const type = HISTORY_STATUSES[status]?.type;
  const icon = HISTORY_STATUSES[status]?.icon;
  const color = HISTORY_STATUSES[status]?.color;
  const backgroundColor = HISTORY_STATUSES[status]?.bgColor;

  const ICON_SIZE = matches ? ICON_SIZE_DESKTOP : ICON_SIZE_MOB;

  return (
    <div className={styles.wrapper} style={{ backgroundColor }}>
      <img src={icon} width={ICON_SIZE} height={ICON_SIZE} />
      <p style={{ color }} className={styles.wrapper_typeText}>
        {type}
      </p>
      {handleDelete && <img src={cancelIcon} onClick={handleDelete} />}
    </div>
  );
};

export default Status;
