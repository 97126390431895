import { useEffect, useState } from "react";
import { EnumLoanLinkStatus } from "api/endpoints/lendingFront";
import { Skeleton } from "components/skeleton";
import { AnimateWrapper } from "components/animate-wrapper";
import { css, styled, Typography, Stack } from "@mui/material";
import Steps from "../../shared/components/steps";
import { useViewLoansFrame } from "shared/hooks/useViewLoansFrame";
import CancelOrDiclinedLoansLoans from "./components/cancelOrDiclinedLoans";
import { useLoans } from "shared/hooks/useLoans";
import PopupBusinessInfo from "./components/popupBusinessInfo";

const Loans = () => {
  const { loanInformation } = useViewLoansFrame({});
  const {
    loanLink,
    loanSettingIsLoading,
    hasBusinessInfo,
    activeStep,
    loansLabelForStepper,
    loansFrameContent,
  } = useLoans();
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  const renderStepper = !!loansFrameContent?.length;

  useEffect(() => {
    if (!hasBusinessInfo && !loanSettingIsLoading) {
      setIsShowPopup(true);
    }
  }, [hasBusinessInfo, loanSettingIsLoading]);

  return (
    <AnimateWrapper className="fade">
      <Stack>
        <Title>Loans</Title>
        {loanSettingIsLoading ? (
          <Skeleton width="35% " height="120px" />
        ) : (
          <>
            {isShowPopup && <PopupBusinessInfo onClose={setIsShowPopup} />}
            {loanLink?.status === EnumLoanLinkStatus.CANCELLED && (
              <CancelOrDiclinedLoansLoans cancel />
            )}
            {loanLink?.status === EnumLoanLinkStatus.DECLINED && (
              <CancelOrDiclinedLoansLoans />
            )}
            {loanLink?.link && loanInformation}
            {renderStepper && (
              <Steps
                orientation={"vertical"}
                activeStep={activeStep}
                steps={loansLabelForStepper}
                stepContent={loansFrameContent}
              />
            )}
          </>
        )}
      </Stack>
    </AnimateWrapper>
  );
};
export default Loans;

const Title = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  `}
`;
