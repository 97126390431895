import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { useAppDispatch, useAppSelector } from 'shared/models'
import {
  clearDataToMyOwnAccount,
  selectFromFinancialAccount,
  selectToFinancialAccount,
  selectAmount,
} from 'features/transfers/transfer-to-my-own-account/model'
import { DetailsTransfer } from 'entities/transfers/ui/detailsTransfer'
import { Button } from 'shared/ui/buttons'
import styles from './styles.module.scss'
import { TransitionAnimation } from 'shared/ui/animations'

export const ToMyOwnAccountStep3 = () => {
  const dispatch = useAppDispatch()
  const { close, goNext } = useDrawerStepperContext()

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const toFinancialAccount = useAppSelector(selectToFinancialAccount)
  const amount = useAppSelector(selectAmount)

  const handleClick = () => {
    dispatch(clearDataToMyOwnAccount())
    goNext('0')
    close()
  }

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <DetailsTransfer
          title='Transfer successfully completed'
          amount={amount?.formattedValue || ''}
          accountFrom={fromFinancialAccount}
          accountTo={toFinancialAccount}
        />
        <Button
          variant='contained'
          onClick={handleClick}
        >
          Done
        </Button>
      </div>
    </TransitionAnimation>
  )
}
