import { FC } from "react";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import helper from "services/helper";
import { useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
interface PrimaryAccItemProps {
  item: any;
  handleSumbit?: (string) => void;
  sumbitIsLoading?: boolean;
}

const PrimaryAccItem: FC<PrimaryAccItemProps> = ({
  item,
  handleSumbit,
  sumbitIsLoading,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));

  return (
    <div className={styles.item}>
      <div>
        <div className={styles.item_box}>
          <p className={styles.item_name}>{item?.name}</p>
          <p className={styles.item_chekingText}>Checking Account:</p>
        </div>
      </div>

      <div>
        {handleSumbit ? (
          <Button
            onClick={() => handleSumbit(item.financialAccountId)}
            disabled={sumbitIsLoading}
            sx={{
              color: "#187AC9",
            }}
          >
            Set as primary
          </Button>
        ) : (
          <>
            <p className={styles.item_amount}>
              ${helper.moneyFormat(item?.availableCash?.value)}
            </p>
            <p className={styles.item_last4}>
              ****
              {item?.accountNumber?.slice(-4)}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
export default PrimaryAccItem;
