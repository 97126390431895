import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../../baseApi";
import { ApiTags } from "../../api-tags";

interface DetachAllRulesArg {
  PaymentCardId: string;
}

interface PaymentCardLimitsArg {
  paymentCardId: string;
}
interface AddRuleCountriesOrCategoriesArg {
  paymentCardId: string;
  blocked: string[];
  allowed: string[];
}
interface AddRuleAmountLimitArg {
  paymentCardId: string;
  value: number;
}
export const spendRulesApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "spendRules",
  endpoints: (builder) => ({
    detachAllRulesController: builder.mutation<any, DetachAllRulesArg>({
      query: ({ PaymentCardId }) => ({
        url: "api/spendRules/detachAll",
        method: "POST",
        params: {
          PaymentCardId,
        },
      }),
    }),
    detachSpendRulesController: builder.mutation<any, any>({
      query: (queryArg) => ({
        url: `api/spendRules/detachSpendRules`,
        method: "POST",
        body: queryArg,
      }),
    }),
    detachVelocityController: builder.mutation<any, any>({
      query: (queryArg) => ({
        url: `api/spendRules/detachVelocity`,
        method: "POST",
        body: queryArg,
      }),
    }),

    paymentCardLimitsController: builder.query<any, PaymentCardLimitsArg>({
      query: ({ paymentCardId }) => ({
        url: `api/spendRules/byCard`,
        method: "GET",
        params: {
          paymentCardId,
        },
      }),
    }),
    addRuleCountriesController: builder.mutation<
      any,
      AddRuleCountriesOrCategoriesArg
    >({
      query: (queryArg) => ({
        url: "api/spendRules/merchantCountry",
        method: "POST",
        body: queryArg,
      }),
    }),
    addRuleMerchantCategoriesController: builder.mutation<
      any,
      AddRuleCountriesOrCategoriesArg
    >({
      query: (queryArg) => ({
        url: "api/spendRules/merchantCategory",
        method: "POST",
        body: queryArg,
      }),
    }),
    addRuleAmountLimitController: builder.mutation<any, AddRuleAmountLimitArg>({
      query: (queryArg) => ({
        url: "api/spendRules/amountLimit",
        method: "POST",
        body: queryArg,
      }),
    }),
    addAmountLimitMonthlyController: builder.mutation<
      any,
      AddRuleAmountLimitArg
    >({
      query: (queryArg) => ({
        url: "api/spendRules/amountLimitMonthly",
        method: "POST",
        body: queryArg,
      }),
    }),
  }),
});

export const {
  useDetachAllRulesControllerMutation,
  useDetachSpendRulesControllerMutation,
  useDetachVelocityControllerMutation,
  usePaymentCardLimitsControllerQuery,
  useAddRuleCountriesControllerMutation,
  useAddRuleMerchantCategoriesControllerMutation,
  useAddRuleAmountLimitControllerMutation,
  useAddAmountLimitMonthlyControllerMutation,
} = spendRulesApi;
