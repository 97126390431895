import { TransitionAnimation } from 'shared/ui/animations';
import styles from './styles.module.scss';
import { ChevronRightIcon } from 'shared/ui/icons';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { MobileBackIcon } from 'shared/ui/icons';
import { ButtonTab } from 'shared/ui/buttons';
import { useGetScheduledTransferQuery } from 'api/endpoints/scheduledTransfer';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import { ListButton } from 'shared/ui/lists';
import {
  ScheduledTransfer,
  findObjById,
  findFrequencyByValue,
  EmptyScheduledTransfer,
  FinancialAccount,
  createFormatAmountObj,
  filteredScheduleTransferByType,
  ScheduleTransferType,
} from 'entities/transfers';
import { ClockIcon } from 'shared/ui/icons';
import { Typography } from 'shared/ui/typography';
import { Skeleton } from 'components/skeleton';
import { useAppDispatch } from 'shared/models';
import { setSelectScheduleTransfer } from '../../model';
import { WalletIcon } from 'shared/ui/icons';

export const Manage = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();
  const { data: scheduledTransfers, isLoading: isLoadingScheduledTransfers } =
    useGetScheduledTransferQuery();

  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const { plaidPayees, isLoadingPayees } = useExternalPayees();

  const labeleForList = (
    <div className={styles.titleForList}>
      <ClockIcon />
      <Typography>Scheduled transfers</Typography>
    </div>
  );
  const handleNavigateToTransferDetail = (
    id: number,
    fromAccount: FinancialAccount,
    toAccount: FinancialAccount,
    amount: number,
    frequency: string,
    transferDate: string
  ) => {
    dispatch(
      setSelectScheduleTransfer({
        value: {
          id,
          fromAccount,
          toAccount,
          amount: createFormatAmountObj(amount),
          frequency,
          dateTransfer: transferDate,
        },
      })
    );

    goNext('4');
  };

  const renderScheduledTransferList = () => {
    const inCommingAchTrasfers = filteredScheduleTransferByType(
      scheduledTransfers ?? [],
      ScheduleTransferType.IncommingAchTransfer
    );

    return !!inCommingAchTrasfers?.length ? (
      <ListButton
        label={labeleForList}
        className={styles.list}
      >
        {inCommingAchTrasfers.map((transfer) => {
          const from = findObjById(
            transfer.fromFinancialAccountId,
            plaidPayees
          );
          const to = findObjById(
            transfer.toFinancialAccountId,
            unclosedFinancialAccounts
          );

          return (
            <ScheduledTransfer
              key={transfer.id}
              id={transfer.id}
              fromAccount={from}
              toAccount={to}
              transferDate={transfer.nextDate || ''}
              amount={transfer.amount}
              frequency={findFrequencyByValue(transfer.frequency) || ''}
              handleNavigate={handleNavigateToTransferDetail}
            />
          );
        })}
      </ListButton>
    ) : (
      <EmptyScheduledTransfer />
    );
  };

  const isLoading =
    isLoadingScheduledTransfers ||
    isLoadingPayees ||
    financialAccountsIsLoading;

  return isLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <ButtonTab
          startIcon={<MobileBackIcon />}
          endIcon={<ChevronRightIcon />}
          onClick={() => goNext('8')}
        >
          Manage connected accounts
        </ButtonTab>
        <ButtonTab
          startIcon={<WalletIcon />}
          endIcon={<ChevronRightIcon />}
          onClick={() => goNext('10')}
        >
          Manage automations
        </ButtonTab>
        {renderScheduledTransferList()}
      </div>
    </TransitionAnimation>
  );
};
