import { ReactNode, useCallback, useMemo, useState } from 'react';
import styles from './style.module.scss';
import { Drawer, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { ArrowBackIcon, CloseOutlinedIcon } from '../icons';
import { DrawerStepperContext } from './context';

export { useDrawerStepperContext } from './context';

export type TStepOfDrawerStepper = {
  id: string
  prevId: string | null
  Element: ReactNode
  title: string
  isCanGoBack: boolean
  resetStepsOnReach?: boolean
}

export type TDrawerStepper = {
  isShow: boolean
  onClose: () => void
  onGoBack?: (stepId: string) => void
  onGoNext?: (stepId: String) => void
  steps: Map<string, TStepOfDrawerStepper>
  startStep: string
}

export const DrawerStepper = (props: TDrawerStepper) => {
  const { isShow, onClose, steps, startStep, onGoBack, onGoNext } = props;
  const [prevStepId, setPrevStepId] = useState<undefined | string>();
  const [currentStepId, setCurrStepId] = useState(startStep);
  const currStepData = steps.get(currentStepId);

  const theme = useTheme();
  const isTabletOrHigher = useMediaQuery(theme.breakpoints.up('sm'));

  if (!currStepData) {
    throw new Error(`Step does not exist, id:${currentStepId}`);
  }

  const handleGoNext = useCallback(
    (step: string) => {
      if (currStepData && currStepData.id) {
        setPrevStepId(currStepData.id);
      }

      setCurrStepId(step);
      onGoNext && onGoNext(step);
    },
    [currStepData, onGoNext]
  );

  const handleGoBack = useCallback(() => {
    if (currStepData && currStepData.isCanGoBack && currStepData.prevId) {
      setPrevStepId(currStepData.id);
      setCurrStepId(currStepData.prevId);

      onGoBack && onGoBack(currStepData.prevId);
    }
  }, [currStepData, onGoBack]);

  const handleClose = useCallback(() => {
    onClose();

    const resetAfterClose = Array.from(steps.values())
      .find(s => s.id === currentStepId)?.resetStepsOnReach;

    if (resetAfterClose) {
      setCurrStepId(startStep);
    }
  }, [steps, currentStepId, startStep]);

  const contextValue = useMemo(
    () => ({
      goNext: handleGoNext,
      goBack: handleGoBack,
      close: handleClose,
      prevStepId: prevStepId,
    }),
    [handleGoNext, handleGoBack, prevStepId]
  );

  return (
    <DrawerStepperContext.Provider value={contextValue}>
      <Drawer
        anchor={isTabletOrHigher ? 'right' : 'bottom'}
        open={isShow}
        onClose={handleClose}
        variant="temporary"
        PaperProps={{ className: styles.drawerPaper }}
        ModalProps={{
          slotProps: { backdrop: { className: styles.backdrop } },
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.boxTitle}>
            {currStepData.isCanGoBack && currStepData.prevId && (
              <IconButton
                color="inherit"
                aria-label="Go Back"
                onClick={() => handleGoBack()}
                className={styles.arrowBack}
              >
                <ArrowBackIcon/>
              </IconButton>
            )}

            <span className={styles.title}>{currStepData.title}</span>

            <IconButton
              color="inherit"
              aria-label="Close Drawer"
              onClick={handleClose}
              className={styles.close}
            >
              <CloseOutlinedIcon/>
            </IconButton>
          </div>

          <div className={styles.contentWrapper}>{currStepData.Element}</div>
        </div>
      </Drawer>
    </DrawerStepperContext.Provider>
  );
};
