import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';
import { ApiTags } from '../api-tags';

export interface GetScheduledTransfer {
  id: number;
  name: string | null;
  fromFinancialAccountId: string;
  toFinancialAccountId: string;
  isActive: boolean;
  transferType: string;
  amount: number;
  currencyCode: number;
  frequency: string;
  lastDate: string | null;
  nextDate: string | null;
  endDate: string | null;
  memo: string | null;
}

export interface AddScheduledTransferArg {
  fromFinancialAccountId: string;
  toFinancialAccountId: string;
  amount: number;
  currencyCode: number;
  oneTimeCode: string;
  secureOperationType: string;
  frequency: string | null;
  nextDate: string;
  transferType: string;
}

export interface AddScheduledTransfer {
  id: number;
  name: string | null;
  fromFinancialAccountId: string;
  toFinancialAccountId: string;
  isActive: boolean;
  transferType: string;
  amount: number;
  currencyCode: number;
  frequency: string;
  lastDate: string | null;
  nextDate: string;
  endDate: string | null;
  memo: string | null;
}

export interface UpdateScheduledTransferApi {
  id: number;
  name: string | null;
  fromFinancialAccountId: string;
  toFinancialAccountId: string;
  isActive: boolean;
  transferType: string;
  amount: number;
  currencyCode: number;
  frequency: string | null;
  lastDate: null;
  nextDate: string;
  endDate: string | null;
  memo: string | null;
}

export interface UpdateScheduledTransferArg {
  fromFinancialAccountId: string;
  toFinancialAccountId: string;
  amount: number;
  currencyCode: number;
  oneTimeCode: string;
  secureOperationType: string;
  frequency: string | null;
  nextDate: string;
  transferType: string;
  id: number;
}

export interface DeleteScheduledTransferArg {
  id: number;
}

export const scheduledTransferApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'scheduledTransfer',
  tagTypes: [ApiTags.GetScheduledTransfer],
  endpoints: (builder) => ({
    getScheduledTransfer: builder.query<GetScheduledTransfer[], void>({
      providesTags: [ApiTags.GetScheduledTransfer],
      query: () => ({
        url: 'api/scheduledTransfer',
        method: 'GET',
      }),
    }),
    addScheduledTransfer: builder.mutation<
      AddScheduledTransfer,
      AddScheduledTransferArg
    >({
      invalidatesTags: [ApiTags.GetScheduledTransfer],
      query: (arg) => ({
        url: 'api/scheduledTransfer',
        method: 'POST',
        body: arg,
      }),
    }),
    updateScheduledTransfer: builder.mutation<
      UpdateScheduledTransferApi,
      UpdateScheduledTransferArg
    >({
      invalidatesTags: [ApiTags.GetScheduledTransfer],
      query: (arg) => ({
        url: 'api/scheduledTransfer',
        method: 'PUT',
        body: arg,
      }),
    }),
    deleteScheduledTransfer: builder.mutation<void, DeleteScheduledTransferArg>(
      {
        invalidatesTags: [ApiTags.GetScheduledTransfer],
        query: (arg) => ({
          url: 'api/scheduledTransfer',
          method: 'DELETE',
          body: arg,
        }),
      }
    ),
  }),
});

export const {
  useGetScheduledTransferQuery,
  useAddScheduledTransferMutation,
  useUpdateScheduledTransferMutation,
  useDeleteScheduledTransferMutation,
} = scheduledTransferApi;
