import { createContext, useContext } from 'react'

type TDrawerStepperContext = {
  goNext: (step: string) => void
  goBack: () => void
  close: () => void
  prevStepId: string | undefined
}

export const DrawerStepperContext = createContext<TDrawerStepperContext>({
  goNext: () => {},
  goBack: () => {},
  close: () => {},
  prevStepId: undefined,
})

export const useDrawerStepperContext = () => useContext(DrawerStepperContext)
