import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import EditEmailaddress from "../changeCompanyInformation/components/editEmailaddress";
import EditPhoneNumber from "../changeCompanyInformation/components/editPhoneNumber";
import * as yup from "yup";
import {
  useSubUserMEControllerQuery,
  useUpdateSubUserPhoneNumberControllerMutation,
  useUpdateSubUserControllerMutation,
  useUpdateSubUserAddressControllerMutation,
} from "../../../api/endpoints/subProfiles";
import EditBillingAddress from "../changeCompanyInformation/components/editBillingAddress";
import { states } from "../../../constants/data";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useAccount } from "../accountSettings/provider";
import helper from "services/helper";
import { EditType } from "../accountSettings/types";
import { useSnackBar } from "providers/snackBar.provider";
import { useCreateTicketControllerMutation } from "api/endpoints/support";

const schemaEmail = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address"
    ),
});

const schemaPhone = yup.object().shape({
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Please enter a valid 10-digit phone number"),
});

const ChangePersonalInformation = () => {
  const { t } = useTranslationProvider();
  const { setSnackBar } = useSnackBar();
  const { setIsShowDrawer, errors, setErrors } = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  // const section = searchParams.get("section");
  // const type = searchParams.get("type");

  const [createTickerMutation, { isLoading: createTicketIsLoading }] =
    useCreateTicketControllerMutation();

  const { data: subUserInfo } = useSubUserMEControllerQuery({});
  const [
    updateSubUserPhoneNumberMutation,
    { isLoading: updateSubUserPhoneNumberIsLoading },
  ] = useUpdateSubUserPhoneNumberControllerMutation();
  const [updateSubUserMutation, { isLoading: updateSubUserMutationIsLoading }] =
    useUpdateSubUserControllerMutation();
  const [
    updateSubUserAddressMutation,
    { isLoading: updateSubUserAddressMutationIsLoading },
  ] = useUpdateSubUserAddressControllerMutation();
  const [email, setEmail] = useState<any>(subUserInfo?.email);
  const [phone, setPhone] = useState<any>(subUserInfo?.phoneNumber);
  const [address, setAddress] = useState<any>(subUserInfo?.streetAddress);
  const [city, setCity] = useState<any>(subUserInfo?.city);
  const regionLabel = states.find((state) => state.value === subUserInfo?.state)
    ?.label;
  const [state, setState] = useState<any>(regionLabel);
  const [zip, setZip] = useState<any>(subUserInfo?.postalCode);
  const [addressFull, setAddressFull] = useState<any>();

  let EmailError = errors.hasOwnProperty("email");
  let PhoneError = errors.hasOwnProperty("phone");

  useEffect(() => {
    if (addressFull) {
      transform(addressFull);
    }
  }, [addressFull]);

  const transform = (addressFull) => {
    setAddress(addressFull.address1);
    setCity(addressFull.locality);
    setState(addressFull.adminArea1Short);
    setZip(addressFull.postalCode);
  };
  const handleSumbitEmail = async () => {
    try {
      await schemaEmail.validate({ email }, { abortEarly: false });
      const res = await updateSubUserMutation({
        email,
        givenName: subUserInfo?.givenName,
        familyName: subUserInfo?.familyName,
        department: subUserInfo?.department,
        subProfileId: subUserInfo?.id,
      }).unwrap();
      setIsShowDrawer(false);
      setSnackBar({
        type: "success",
        message: "Email is updated",
        isShow: true,
      });
    } catch (e: any) {
      console.log("error", e);
      if (e.name === "ValidationError") {
        validateFormEmailOrPhone("Email");
      } else {
        setIsShowDrawer(false);
        setSnackBar({
          type: "error",
          message: helper.formatErrors(e.data),
          isShow: true,
        });
      }
    }
  };

  const handleSumbitPhone = async () => {
    try {
      await schemaPhone.validate({ phone }, { abortEarly: false });

      await updateSubUserPhoneNumberMutation({
        subProfileId: subUserInfo?.id,
        phoneNumber: phone,
        phoneNumberCountryCode: subUserInfo?.phoneNumberCountryCode,
      }).unwrap();
      setIsShowDrawer(false);
      setSnackBar({
        type: "success",
        message: "Phone is updated",
        isShow: true,
      });
    } catch (e: any) {
      console.log("error", e);
      if (e.name === "ValidationError") {
        validateFormEmailOrPhone("Phone");
      } else {
        setIsShowDrawer(false);
        setSnackBar({
          type: "error",
          message: helper.formatErrors(e.data),
          isShow: true,
        });
      }
    }
  };

  const handleSumbitAdress = async () => {
    try {
      const regionToNumb = states.find((i) => i.label === state)?.value;
      const res = await updateSubUserAddressMutation({
        subProfileId: subUserInfo?.id,
        streetAddress: address,
        countryCodeAlpha3: subUserInfo?.countryCodeAlpha3,
        locality: city,
        postalCode: zip,
        state: regionToNumb!,
        updatePaymentCardBillingAddress: false,
      }).unwrap();
      setIsShowDrawer(false);
      setSnackBar({
        type: "success",
        message: "Address is updated",
        isShow: true,
      });
    } catch (e: any) {
      console.log("error", e);
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };
  const validateRequiredFieldsAddress = !address || !city || !state || !zip;
  const validateFormEmailOrPhone = async (typeValid) => {
    try {
      typeValid === "Email"
        ? await schemaEmail.validate({ email }, { abortEarly: false })
        : await schemaPhone.validate({ phone }, { abortEarly: false });
    } catch (error: any) {
      if (error.name === "ValidationError") {
        const errors = {};
        error.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
        setErrors(errors);
      }
    }
  };
  return (
    <AnimateWrapper className="fade">
      <div>
        {mode === EditType.Email && (
          <EditEmailaddress
            errors={errors}
            email={email}
            setEmail={setEmail}
            setErrors={setErrors}
            emailError={EmailError}
            validateFormEmailOrPhone={validateFormEmailOrPhone}
            handleSubmit={handleSumbitEmail}
            isLoading={updateSubUserMutationIsLoading}
          />
        )}
        {mode === EditType.Phone && (
          <EditPhoneNumber
            phone={phone}
            setPhone={setPhone}
            setErrors={setErrors}
            phoneError={PhoneError}
            validateFormEmailOrPhone={validateFormEmailOrPhone}
            handleSubmit={handleSumbitPhone}
            isLoading={updateSubUserPhoneNumberIsLoading}
          />
        )}
        {mode === EditType.Address && (
          <EditBillingAddress
            address={address}
            setAddress={setAddress}
            setAddressFull={setAddressFull}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            zip={zip}
            setZip={setZip}
            handleSubmit={handleSumbitAdress}
            isLoading={updateSubUserAddressMutationIsLoading}
            disabled={validateRequiredFieldsAddress}
          />
        )}
      </div>
    </AnimateWrapper>
  );
};

export default ChangePersonalInformation;
