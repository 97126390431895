import { useEffect } from 'react'
import { Typography } from 'shared/ui/typography'
import { Button } from 'shared/ui/buttons'
import { NeteviaPayeeAddForm } from './netevia-payee-add-form'
import { TransitionAnimation } from 'shared/ui/animations'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  TPayeeAdd,
  PayeeAddSchema,
} from 'features/transfers/transfer-to-netevia-customer/model'
import styles from './styles.module.scss'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { useAppSelector, useAppDispatch } from 'shared/models'
import {
  setNewPayee,
  selectNewPayee,
  selectNotRegisteredPayee
} from 'features/transfers/transfer-to-netevia-customer/model'
import { useNeteviaPayeesControllerQuery } from 'api/endpoints/fundsMovement'
import { Skeleton } from 'components/skeleton'

export const ManagePayeeAdd = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()
  const { data: neteviaPayees, isFetching: isFetchingNeteviaPayees } =
    useNeteviaPayeesControllerQuery()
  const newPayee = useAppSelector(selectNewPayee)
  const notRegisteredPayee = useAppSelector(selectNotRegisteredPayee)

  const PayeeSchema = PayeeAddSchema.refine(
    (data) => {
      if (neteviaPayees?.payees.length) {
        return !neteviaPayees.payees.some(
          (payee) => payee.accountNumber === data.accountNumber
        );
      } else {
        return true;
      }
    },
    {
      message: 'Payee with this account number already exists',
      path: ['accountNumber'],
    }
  );

  const {
    control,
    formState,
    getValues,
    trigger: validateForm,
    watch,
  } = useForm<TPayeeAdd>({
    resolver: zodResolver(PayeeSchema),
    mode: 'all',
    defaultValues: {
      accountName: newPayee.name || notRegisteredPayee?.name || '',
      accountNumber: newPayee.number || notRegisteredPayee?.fullAccountNumber ||'',
    },
  });
  const accountNumber = watch('accountNumber')

  useEffect(() => {
    if (accountNumber) {
      validateForm();
    }
  }, [accountNumber]);

  const handleGoToAuntificator = () => {
    const value = getValues()
    dispatch(
      setNewPayee({
        value: {
          name: value.accountName,
          number: value.accountNumber,
        },
      })
    )
    goNext('11')
  }

  return isFetchingNeteviaPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography>Netevia’s account payee details</Typography>
        <NeteviaPayeeAddForm control={control} />
        <Button
          className={styles.button}
          variant='contained'
          onClick={handleGoToAuntificator}
          disabled={!formState.isValid}
        >
          add payee
        </Button>
      </div>
    </TransitionAnimation>
  )
}
