


export const resetAccountTransactionFilter ={
    all: {
        displayName: "Select all",
        value: true,
        items: null
    },
    deposit: {
        displayName: "Deposit",
        value: true,
        items: null
    },
    withdraw: {
        displayName: "Withdraw",
        value: true,
        items: null
    },
    transfers: {
        displayName: "Transfers",
        value: true,
        items: [
            {
                displayName: "ACH transfers",
                value: true,
                items: null
            },
            {
                displayName: "Netevia transfers",
                value: true,
                items: null
            }
        ]
    },
    pos: {
        displayName: "Card transactions",
        value: true,
        items: [
            {
                displayName: "Agricultural Services",
                value: true,
                items: null
            },
            {
                displayName: "Airlines, Air Carriers",
                value: true,
                items: null
            },
            {
                displayName: "ATM transactions",
                value: true,
                items: null
            },
            {
                displayName: "Business Services",
                value: true,
                items: null
            },
            {
                displayName: "Car Rental Agencies",
                value: true,
                items: null
            },
            {
                displayName: "Clothing Stores",
                value: true,
                items: null
            },
            {
                displayName: "Contracted Services",
                value: true,
                items: null
            },
            {
                displayName: "Government Services",
                value: true,
                items: null
            },
            {
                displayName: "Hotels, Motels, and Resorts",
                value: true,
                items: null
            },
            {
                displayName: "Miscellaneous Stores",
                value: true,
                items: null
            },
            {
                displayName: "Professional Services",
                value: true,
                items: null
            },
            {
                displayName: "Retail Outlet Services",
                value: true,
                items: null
            },
            {
                displayName: "Transportation Services",
                value: true,
                items: null
            },
            {
                displayName: "Utility Services",
                value: true,
                items: null
            }
        ]
    }
}