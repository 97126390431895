import { useState } from 'react';
import { useSubUsersControllerQuery } from 'api/endpoints/subProfiles';
import styles from './style.module.scss';
import ConnectedCards from 'shared/components/cardList';
import NavigateButton from 'components/button/NavigateButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Skeleton } from 'components/skeleton';
import { AnimateWrapper } from 'components/animate-wrapper';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import chevronRigth from 'assets/svg/chevron-right-gray.svg';
import DrawerModal from 'shared/components/drawerModal';
import { RenderDetails } from '../RenderDetails';

const SubUserListItem = () => {
  const { t } = useTranslationProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '';
  const navigate = useNavigate();
  const { data: subUsers, isLoading: subUsersIsLoading, isFetching: isSubusersFetching } =
    useSubUsersControllerQuery();
  const [openDetailsArr, setOpenDetailsArr] = useState<number[]>([]);

  const handleItemBoolean = (id) => openDetailsArr.includes(id);

  const handleItemToggle = (id) => {
    if (handleItemBoolean(id)) {
      // Удаление итема из массива
      setOpenDetailsArr(openDetailsArr.filter((item) => item !== id));
    } else {
      // Добавление итема в массив
      setOpenDetailsArr([...openDetailsArr, id]);
    }
  };

  const handleShowInfo = (id, title) => {
    setSearchParams({ page, mode: 'details', id, title });
  };

  const renderItems = () => {
    return subUsers?.map((item, index) => (
      <AnimateWrapper
        delay={index * 100}
        key={item.id}
      >
        <div
          key={item.id}
          className={styles.wrapperItem}
        >
          <div
            className={styles.boxItem}
            onClick={() =>
              handleShowInfo(
                item.id,
                `Authorized User ${item.givenName} ${item.familyName}`
              )
            }
          >
            <div>
              <div className={styles.text}>
                {item.givenName} {item.familyName}
              </div>
              <div className={styles.cardsLenght}>
                {item?.attachedPaymentCards?.length &&
                  item?.attachedPaymentCards?.length + ' connected cards'}
              </div>
            </div>

            <div
              className={styles.boxChooseDetails}
              id={item.id.toString()}
            >
              <img src={chevronRigth} />
            </div>
          </div>
          <DrawerModal
            isShow={
              searchParams.get('mode') === 'details' &&
              searchParams.get('id') == item.id.toString()
            }
            onClose={() => setSearchParams({ page })}
          >
            <RenderDetails isConnectedCardsLoading={isSubusersFetching} item={item} />
          </DrawerModal>
        </div>
      </AnimateWrapper>
    ));
  };

  const handleNavigate = (subUserId) => {
    navigate(`/settings/subuser/${subUserId}/manage`);
  };

  return (
    <div className={styles.wrapper}>
      {subUsersIsLoading ? (
        <>
          <Skeleton
            width='100%'
            height='75px'
          />
        </>
      ) : (
        renderItems()
      )}
    </div>
  );
};

export default SubUserListItem;
