import { TransitionAnimation } from 'shared/ui/animations';
import { Button } from 'shared/ui/buttons';
import {
  filteredScheduleTransferByType,
  ScheduleTransferType,
  findObjById,
} from 'entities/transfers';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import { useGetScheduledTransferQuery } from 'api/endpoints/scheduledTransfer';
import { Skeleton } from 'components/skeleton';
import { AutomationsItem } from './components/automations-item';
import { EmptyPayees } from 'entities/transfers';
import { WalletIcon } from 'shared/ui/icons';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import styles from './styles.module.scss';
import { clearDataAutomations, setAutomations } from '../../model';
import { useAppDispatch } from 'shared/models';
import { FinancialAccount } from 'entities/transfers';

export const ManageAutomations = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { plaidPayees, isLoadingPayees } = useExternalPayees();
  const { data: scheduledTransfers, isLoading: isLoadingScheduledTransfers } =
    useGetScheduledTransferQuery();

  const handleNavigateToEditAutomations = (
    id: number,
    fromAccount: FinancialAccount,
    toAccount: FinancialAccount,
    amount: number
  ) => {
    dispatch(
      setAutomations({
        value: {
          id,
          from: fromAccount.id,
          to: toAccount.id,
          amount: amount.toString(),
        },
      })
    );
    goNext('13');
  };

  const renderAutoPopupList = () => {
    const autoPopupTransfers = filteredScheduleTransferByType(
      scheduledTransfers ?? [],
      ScheduleTransferType.AutoPopup
    );

    return !!autoPopupTransfers.length ? (
      <div className={styles.box}>
        {autoPopupTransfers.map((transfer) => {
          const from = findObjById(
            transfer.fromFinancialAccountId,
            plaidPayees
          );
          const to = findObjById(
            transfer.toFinancialAccountId,
            unclosedFinancialAccounts
          );

          return (
            <AutomationsItem
              key={transfer.id}
              id={transfer.id}
              fromAccount={from}
              toAccount={to}
              amount={transfer.amount}
              handleNavigate={handleNavigateToEditAutomations}
            />
          );
        })}
      </div>
    ) : (
      <EmptyPayees
        icon={<WalletIcon />}
        title={'Automations list is empty'}
        subTitle={'Automations will be displayed here.'}
      />
    );
  };

  const isLoading =
    financialAccountsIsLoading ||
    isLoadingPayees ||
    isLoadingScheduledTransfers;

  const navigateToAddNewAutomation = () => {
    dispatch(clearDataAutomations());
    goNext('11');
  };

  return isLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        {renderAutoPopupList()}
        <Button
          className={styles.btn}
          variant='contained'
          onClick={navigateToAddNewAutomation}
        >
          Add new automation
        </Button>
      </div>
    </TransitionAnimation>
  );
};
