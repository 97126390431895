import React, { FC } from "react";
import { Skeleton } from "components/skeleton";
import PrimaryAccItem from "../primaryAccItem";
import styles from "./style.module.scss";
interface YourPrimaryAccListProps {
  item: any;
  isLoading: boolean;
}

const YourPrimaryAccList: FC<YourPrimaryAccListProps> = ({
  item,
  isLoading,
}) => {
  const helperText =
    "Your primary account is where incoming funds are deposited, including cases when other clients of Netevia send you money via email or phone. To change your primary account, please select one below.";
  return (
    <>
      <p className={styles.title}>Your Primary Account</p>
      {isLoading ? (
        <Skeleton width="100%" height="63px" />
      ) : (
        <PrimaryAccItem item={item} />
      )}
      <p className={styles.helperText}>{helperText}</p>
    </>
  );
};

export default YourPrimaryAccList;
