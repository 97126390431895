import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Amount,
  FinancialAccount,
  SelectScheduleTransfer,
  FrequencyTitle
} from 'entities/transfers';
import moment from 'moment';
interface Automations {
  id?: number | null;
  from: string;
  to: string;
  amount: string;
}

type FromConnectedAccount = {
  fromPayee: FinancialAccount | null;
  toFinancialAccount: FinancialAccount | null;
  amount: Amount;
  frequency: string;
  dateTransfer: string;
  selectScheduleTransfer: SelectScheduleTransfer | null;
  automations: Automations;
};

const initialState: FromConnectedAccount = {
  fromPayee: null,
  toFinancialAccount: null,
  amount: {
    floatValue: undefined,
    formattedValue: undefined,
    value: undefined,
  },
  frequency: FrequencyTitle.Once,
  dateTransfer: moment().toISOString(),
  selectScheduleTransfer: null,
  automations: {
    id: null,
    from: '',
    to: '',
    amount: '',
  },
};

export const slice = createSlice({
  name: 'fromConnectedAccount',
  initialState,
  reducers: {
    clearDataFromConnectedAccount: () => {
      return initialState;
    },

    clearDataAutomations: (state) => {
      state.automations = initialState.automations;
    },

    setFromPayee: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.fromPayee = payload.value;
    },

    setToFinancialAccount: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.toFinancialAccount = payload.value;
    },

    setAmount: (state, { payload }: PayloadAction<{ value: Amount }>) => {
      state.amount = payload.value;
    },

    setSelectFrequency: (
      state,
      { payload }: PayloadAction<{ value: string }>
    ) => {
      state.frequency = payload.value;
    },

    setSelectDateTransfer: (
      state,
      { payload }: PayloadAction<{ value: string }>
    ) => {
      state.dateTransfer = payload.value;
    },

    setSelectScheduleTransfer: (
      state,
      { payload }: PayloadAction<{ value: SelectScheduleTransfer }>
    ) => {
      state.selectScheduleTransfer = payload.value;
    },

    setAutomations: (
      state,
      { payload }: PayloadAction<{ value: Automations }>
    ) => {
      state.automations = payload.value;
    },
  },
});

export const selectFromPayee = (state: RootState) =>
  state.fromConnectedAccount.fromPayee;

export const selectToFinancialAccount = (state: RootState) =>
  state.fromConnectedAccount.toFinancialAccount;

export const selectAmount = (state: RootState) =>
  state.fromConnectedAccount.amount;

export const selectFrequency = (state: RootState) =>
  state.fromConnectedAccount.frequency;

export const selectDateTransfer = (state: RootState) =>
  state.fromConnectedAccount.dateTransfer;

export const selectScheduleTransfer = (state: RootState) =>
  state.fromConnectedAccount.selectScheduleTransfer;

export const selectAutomations = (state: RootState) =>
  state.fromConnectedAccount.automations;

export const {
  clearDataFromConnectedAccount,
  clearDataAutomations,
  setFromPayee,
  setToFinancialAccount,
  setAmount,
  setSelectFrequency,
  setSelectDateTransfer,
  setSelectScheduleTransfer,
  setAutomations,
} = slice.actions;
