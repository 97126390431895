import React from 'react'
import styles from './style.module.scss'
import Badge from '../../../../pages/accountComponent/components/Badge'
import { CardView } from 'shared/components/CardView'
import { Divider } from '@mui/material'
import { Typography } from 'shared/ui/typography'
import helper from 'services/helper'
import { PaymentCard } from 'api/endpoints/paymentCards'

interface TableBlockProps {
  cards: PaymentCard[]
  Action?: any
  onClick?: (id: string) => void
}

export const MobileCards = ({ cards, Action, onClick }: TableBlockProps) => {
  const handleClick = (id: string) => {
    onClick && onClick(id)
  }

  return (
    <div>
      <div className={styles.title}>
        <Action />
      </div>

      <div className={styles.cardsContainer}>
        {cards.map((item, index) => (
          <React.Fragment key={item.id}>
            <div
              className={styles.cardItem}
              onClick={() => handleClick(item.id)}
            >
              <CardView
                card={item}
                isSmallSize
              />
              <div className={styles.box}>
                <div className={styles.boxName}>
                  <Typography>
                    {item.cardName} <span>****{item.last4}</span>
                  </Typography>
                  <Typography>{item.cardholder}</Typography>
                </div>
                <div className={styles.boxInfo}>
                  <Typography>
                    {helper.capitalizeFirstLetter(item.formFactor)}
                  </Typography>
                  <Typography>{item.department}</Typography>
                </div>
                <div className={styles.badge}>
                  {item.subProfile && (
                    <div className={styles.adgeFirst}>
                      <Badge
                        item={item}
                        text='Authorized user'
                      />
                    </div>
                  )}
                  <div className={styles.badgeSecond}>
                    <Badge item={item} />
                  </div>
                </div>
              </div>
            </div>
            {index !== cards.length - 1 && (
              <Divider className={styles.divider} />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
