import { FC } from "react";
import { useNavigate } from "react-router-dom";
import LogoIcon from "assets/svg/logo-netevia-white.svg";
import styles from "./style.module.scss";
import cx from "classnames";
import IconButton from "@mui/material/IconButton";
import MenuBurger from "assets/svg/burger-menu-white.svg";
import { appRoutes } from '../../../../routes';
import { BellNotificationWidget } from "widget/bellNotificationWidget";

interface NavBarProps {
  children: React.ReactNode;
  isShowDrawer?: boolean;
  setIsShowDrawer?: (boolean) => void;
}

const Navbar: FC<NavBarProps> = ({
  children,
  isShowDrawer,
  setIsShowDrawer,
}) => {
  const navigate = useNavigate();

  const handlerNavigateToHome = () => {
    navigate(appRoutes.home());
  };
  const handlerOpenNavBar = () => {
    if (setIsShowDrawer) {
      setIsShowDrawer(true);
    }
  };
  return (
    <nav className={styles.nav}>
      <div className={styles.containerNavBar}>
        <div className={styles.box}>
        <IconButton
          color="inherit"
          aria-label="Open main menu"
          className={styles.boxButtonOpen}
          onClick={handlerOpenNavBar}
        >
          <img src={MenuBurger} />
        </IconButton>
        <img
          src={LogoIcon}
          width={78}
          height={32}
          onClick={handlerNavigateToHome}
        />
        </div>
       <div>
          <BellNotificationWidget/>
       </div>
      
      </div>
      <div
        className={cx(styles.containerDropDown, {
          [styles.isOpen]: isShowDrawer,
          [styles.isClosed]: !isShowDrawer,
        })}
      >
        {children}
      </div>
    </nav>
  );
};
export default Navbar;
