import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";
import { ApiTags } from "../api-tags";

interface LoanArg {
  arg: boolean;
}
export enum EnumLoanLinkStatus {
  DECLINED = "DECLINED",
  CANCELLED = "CANCELLED",
  BOARDED = "BOARDED",
  FUNDED = "FUNDED",
}
export interface LoanSettingsApi {
  id: number;
  marketingLink: string;
  isNotLoan: boolean;
  exportedLoanServie: Date;
  isShowRequestLoan: boolean;
  marketingLinkOpened: boolean;
  amount: number;
  terms: string;
  revenue: number;
  businessStartDate: Date;
}
export interface LoanLinkApi {
  link: string;
  status: EnumLoanLinkStatus;
}
interface SendBusinessInfoArg {
  businessStartDate: string;
  annualBusinessRevenue: string;
}
export const lendingFrontApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "lendingFront",
  tagTypes: [ApiTags.GetLoanSettings],
  endpoints: (builder) => ({
    loanSettings: builder.query<LoanSettingsApi, any>({
      providesTags: [ApiTags.GetLoanSettings],
      query: () => ({
        url: "api/LendingFront/loanSettings",
        method: "GET",
      }),
    }),

    loanLink: builder.query<any, void>({
      query: () => ({
        url: "api/LendingFront/LoanLink",
        method: "GET",
        // responseHandler: "text",
      }),
    }),
    isNotLoan: builder.mutation<boolean, LoanArg>({
      invalidatesTags: [ApiTags.GetLoanSettings],
      query: ({ arg }) => ({
        url: `api/LendingFront/IsNotLoan?${arg}`,
        method: "POST",
      }),
    }),

    loanRequest: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetLoanSettings],
      query: () => ({
        url: "api/LendingFront/loanRequest",
        method: "GET",
      }),
    }),
    marketingWasOpened: builder.mutation<boolean, LoanArg>({
      invalidatesTags: [ApiTags.GetLoanSettings],
      query: ({ arg }) => ({
        url: `api/LendingFront/marketingWasOpened?${arg}`,
        method: "POST",
      }),
    }),
    sendBusinessInfo: builder.mutation<any, SendBusinessInfoArg>({
      invalidatesTags: [ApiTags.GetLoanSettings],
      query: (queryArg) => ({
        url: "businessInfo",
        method: "POST",
        body: queryArg,
      }),
    }),
  }),
});

export const {
  useLoanSettingsQuery,
  useLoanLinkQuery,
  useIsNotLoanMutation,
  useLoanRequestMutation,
  useMarketingWasOpenedMutation,
  useSendBusinessInfoMutation,
} = lendingFrontApi;
