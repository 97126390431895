import { useState } from 'react';
import { useRewards } from 'pages/reward/provider/useRewards';
import { ChangeEventHandler } from 'react';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import styles from './styles.module.scss';
import {
  useLastTransferPointsControllerQuery,
  useClientNameControllerMutation,
  ClientNameApi,
} from 'api/endpoints/search';
import SelectTransferPoints from '../selectTransferAcc';
import SelectTransferPointList from '../selectTransferPointList';
import { useDebounce } from 'shared/hooks/useDebounce';
import { useRewardTransfersControllerMutation } from 'api/endpoints/rewards';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { useSearchParams } from 'react-router-dom';
import RecentTransfersList from '../recentTransfersList';
import { useBoolean, ReturnType } from 'shared/hooks/useBoolean';
import { SecureOperationType, ShortMessageType } from 'enums';
import { TextInput } from 'shared/ui/inputs';
import { InputAdornment } from '@mui/material';
import errorIcon from 'assets/svg/error-red.svg';
import { Button } from 'shared/ui/buttons';
import { useMfa } from '../../../../../../../../providers/mfa/mfa';
import { Skeleton } from '../../../../../../../../components/skeleton';

interface RewardTransferProps {
  showAuntificatorBool: ReturnType;
  pointTo: string;
  setPointsTo: (pointTo: string) => void;
}

export const RewardTransfer = ({
  showAuntificatorBool,
  pointTo,
  setPointsTo,
}: RewardTransferProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '';
  const { t } = useTranslationProvider();
  const { setSnackBar } = useSnackBar();
  const { totalPoints, refetchRewardsHistory, refetchRecentTransactions } =
    useRewards();
  const [searchClientName, { isLoading: searchClientNameIsLoading }] =
    useClientNameControllerMutation({});
  const [rewardTransfers, { isLoading: rewardTransfersIsLoading }] =
    useRewardTransfersControllerMutation();

  const [searchAcc, setSearchAcc] = useState<string>('');

  const { data: lastTransferPoints, isLoading: lastTransferPointsIsLoading } =
    useLastTransferPointsControllerQuery({}, { skip: !searchAcc });

  const [searchList, setSearchList] = useState<ClientNameApi[] | []>([]);
  const [selectAcc, setSelectAcc] = useState<ClientNameApi | undefined>(
    undefined
  );
  const [errorInput, setErrorInputn] = useState<string>('');
  const showListBool = useBoolean();
  const mfa = useMfa();

  const rewardTransfer = async (secureOperationType: SecureOperationType, code: string) => {
    try {
      if (selectAcc) {
        await rewardTransfers({
          oneTimeCode: code,
          secureOperationType: secureOperationType,
          toProfileId: selectAcc.profileId,
          points: Number(pointTo),
        }).unwrap();
        setPointsTo('');
        refetchRecentTransactions();
        refetchRewardsHistory();
        showAuntificatorBool.setFalse();
        mfa.close();
        setSearchParams({ page });
        setSnackBar({
          type: 'success',
          message: ` Points have been successfully transferred to *${selectAcc.doingBusinessName}*.`,
          isShow: true,
        });
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleSetPointsTo: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const { value } = event.target;
    setPointsTo(value === '0' ? '' : value);
    if (Number(value) > totalPoints) {
      setErrorInputn(
        t('Rewards.Not enough points', {
          defaultValue: 'Not enough points',
        })
      );
    } else if (value && Number(value) <= 99) {
      setErrorInputn('The minimum amount for transferring points is 100');
    } else {
      setErrorInputn('');
    }
  };

  const handleOnChangeTransferTo: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const { value } = event.target;
    setSearchAcc(value);
    getSearchList(value);
    if (!value) {
      showListBool.setFalse();
    }
  };

  const getSearchList = useDebounce(async (value: string) => {
    setSelectAcc(undefined);
    if (value?.length > 4) {
      try {
        const res = await searchClientName({
          arg: value,
        }).unwrap();
        setSearchList(res);

        showListBool.setTrue();
      } catch (e: any) {
        console.log('error', e);
        setSnackBar({
          type: 'error',
          message: helper.formatErrors(e.data),
          isShow: true,
        });
      }
    } else {
      setSearchList([]);
    }
  }, 800);

  const clearInputSearchAcc = () => {
    setSearchAcc('');
    setSelectAcc(undefined);
    setSearchList([]);
  };

  const handleChooseAcc = (id: number) => {
    const temptInSearchList = searchList?.find((i) => i.profileId === id);
    const temptInLastTransferPoints = lastTransferPoints?.find(
      (i) => i.profileId === id
    );
    const tempt = temptInSearchList || temptInLastTransferPoints;
    if (tempt) {
      setSelectAcc(tempt);
    }
  };

  const openMfa = () => {
    showAuntificatorBool.setTrue();
    mfa.show({
      title: 'Confirm Transfer',
      shortMessageType: ShortMessageType.TransferPoints,
      onDrawerClose() {
        showAuntificatorBool.setFalse();
      },
      async onCodeSubmit(secureOperationType: SecureOperationType, code: string) {
        await rewardTransfer(secureOperationType, code)
      }
    })
  }

  return (
    <div className={styles.container}>
      {showAuntificatorBool.value ? (
        <Skeleton width="100%" height={100} />
      ) : (
        <>
          <div>
            <TextInput
              fullWidth
              autoComplete='off'
              label={t('Rewards.Points to share', {
                defaultValue: 'Points to share',
              })}
              value={pointTo}
              onChange={handleSetPointsTo}
              error={errorInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {errorInput && (
                      <img
                        src={errorIcon}
                        alt='Error'
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <SelectTransferPoints
            handleOnChangeTransferTo={handleOnChangeTransferTo}
            value={searchAcc}
            clearInput={clearInputSearchAcc}
          />
          {showListBool.value && !!lastTransferPoints?.length && (
            <RecentTransfersList
              dataArr={lastTransferPoints}
              title={'Reсent transfers'}
              selectItem={selectAcc}
              handleChooseAcc={handleChooseAcc}
              isLoading={lastTransferPointsIsLoading}
            />
          )}
          {showListBool.value && (
            <SelectTransferPointList
              dataArr={searchList}
              title={'Search results'}
              selectItem={selectAcc}
              handleChooseAcc={handleChooseAcc}
              isLoading={searchClientNameIsLoading}
            />
          )}
          <Button
            className={styles.btn}
            disabled={!pointTo || !selectAcc || !!errorInput}
            onClick={openMfa}
          >
            {t('Rewards.Transfer points', {
              defaultValue: 'Transfer points',
            })}
          </Button>
        </>
      )}
    </div>
  );
};
