import { FC } from "react";
import { useMediaQuery } from "@mui/material";
import Navbar from "./components/navbar";
import Sidebar from "../../shared/components/sidebar";
import PopupGroup from "./components/popupGroup";

interface AdaptivenNavbarProps {
  isShowDrawer?: boolean;
  setIsShowDrawer?: (boolean) => void;
}

const AdaptivenNavbar: FC<AdaptivenNavbarProps> = ({
  isShowDrawer,
  setIsShowDrawer,
}) => {

  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <>
      {isMobile && (
        <Navbar isShowDrawer={isShowDrawer} setIsShowDrawer={setIsShowDrawer}>
          <Sidebar setIsShowDrawer={setIsShowDrawer} />
        </Navbar>
      )}
      <PopupGroup />
    </>
  );
};

export default AdaptivenNavbar;
