import { useState, useEffect } from 'react';
import { useSendCodeVerificationMutation } from 'api/endpoints/fundsMovement';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { CodeInput } from 'shared/ui/inputs/code-input';
import styles from './styles.module.scss';
import BeatLoader from 'react-spinners/BeatLoader';
import { Typography } from 'shared/ui/typography';
import { Button } from 'shared/ui/buttons';
import TimerSMS from 'pages/login/components/forgotPassword/components/timerSMS';
import { TransitionAnimation } from 'shared/ui/animations';
import moment from 'moment';
import { INPUT_LENGTH, TIMER  } from '../../config/constants';
import { ShortMessageType, SecureOperationType } from 'enums';

interface AuntificateWithSmsProps {
  toggleType?: () => Promise<void>;
  code: string;
  setCode: (code: string) => void;
  isLoadingSubmit: boolean;
  onSubmit: (secureOperationType: SecureOperationType) => Promise<void> | void;
  shortMessageType: ShortMessageType;
  sumbitButtonLabel?: string | React.ReactNode;
  title?: string;
}

export const AuntificateWithSms = (props: AuntificateWithSmsProps) => {
  const {
    toggleType,
    onSubmit,
    isLoadingSubmit,
    code,
    setCode,
    shortMessageType,
    sumbitButtonLabel = 'Confirm',
    title,
  } = props;
  const { setSnackBar } = useSnackBar();

  const [sendCodeVerification, { isLoading: isLoadingSendVerification }] =
    useSendCodeVerificationMutation();

  const [nextSendDate, setNextSendDate] = useState<Date | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string>();

  useEffect(() => {
    handleSendCodeVerification();
  }, []);

  const handleSendCodeVerification = async () => {
    try {
      const answer: { success: string } = await sendCodeVerification({
        shortMessageType: shortMessageType,
      }).unwrap();
      const nextSendDate = moment().add(TIMER, 'seconds').toDate();
      setPhoneNumber(answer.success);
      setNextSendDate(nextSendDate);
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const renderTimerOrResend = () => {
    return nextSendDate ? (
      <TimerSMS
        targetDate={nextSendDate}
        onTimerComplete={setNextSendDate}
      />
    ) : (
      <Button
        onClick={handleSendCodeVerification}
        variant='text'
        className={styles.btn}
      >
        Resend code
      </Button>
    );
  };
  const isButtonDisabled = code.length < INPUT_LENGTH;

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        {title && (
          <Typography className={styles.title}>
            {title}
          </Typography>
        )}
        <Typography className={styles.subTitle}>
          Please enter the 6-digit security code received via text message sent
          to number ending <span>{phoneNumber}</span>
        </Typography>
        <CodeInput
          value={code}
          setValue={setCode}
          inputLength={INPUT_LENGTH}
        />
        <div>
          {isLoadingSendVerification ? <BeatLoader /> : renderTimerOrResend()}
        </div>
        {toggleType && (
          <Button
            onClick={toggleType}
            variant='text'
            className={styles.btn}
          >
            Confirm transaction via authenticator security code
          </Button>
        )}
        <Button
          className={styles.onSubmitBtn}
          variant='contained'
          onClick={() => onSubmit(SecureOperationType.ShortMessageCode)}
          disabled={isButtonDisabled}
          loading={isLoadingSubmit}
        >
          {sumbitButtonLabel}
        </Button>
      </div>
    </TransitionAnimation>
  );
};
