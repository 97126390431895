import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';
import {
  StepContent,
  selectTotalAmount,
  selectTransferType,
  selectTransfers,
  TransferType,
} from '../../model';
import { useAppSelector } from 'shared/models';
import SimpleContainer from 'shared/components/containers/simpleContainer';
import helper from 'services/helper';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import { findObjById } from 'entities/transfers';
import { BatchTransferItem } from 'entities/transfers';
import { Button } from 'shared/ui/buttons';
import DrawerModal from 'shared/components/drawerModal';
import { AuntificatorTransfer } from '../auntificatorTransfer';
import { useNeteviaPayeesControllerQuery } from 'api/endpoints/fundsMovement';
import cn from 'classnames'
interface BatchTransferConfirmProps {
  setStepContent: (step: StepContent) => void;
}

export const BatchTransferConfirm = ({
  setStepContent,
}: BatchTransferConfirmProps) => {
  const { unclosedFinancialAccounts } = useFinancialAccounts();
  const { externalPayees } = useExternalPayees();
  const { data: neteviaPayees } = useNeteviaPayeesControllerQuery();
  const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);

  const transferType = useAppSelector(selectTransferType);
  const total = useAppSelector(selectTotalAmount);
  const transfers = useAppSelector(selectTransfers);

  const isNeteviaTransfer = transferType === TransferType.toNetevia;
  const renderTransferItem = () => {
    return transfers?.map((item, index) => {
      const fromAcc = findObjById(
        item.fromFinancialAccount,
        unclosedFinancialAccounts
      );
      const toPayee = isNeteviaTransfer
        ? findObjById(item.toPayee, neteviaPayees?.payees)
        : findObjById(item.toPayee, externalPayees);

      return (
        <BatchTransferItem
          key={index}
          transferIndex={index}
          fromAccName={fromAcc.name}
          fromAccLast4={fromAcc.last4Number}
          toPayeeName={toPayee.name}
          toPayeeLast4={toPayee.last4Number}
          amount={`$${helper.moneyFormat(item.amount)}`}
          isNeteviaTransfer={isNeteviaTransfer}
          memo={item?.memo}
        />
      );
    });
  };
  const goBack = () => {
    setStepContent(StepContent.form);
  };
  return (
    <TransitionAnimation>
      <SimpleContainer
        title={`Batch transfer - ${transferType}`}
        onTitleClick={goBack}
      >
        <div className={styles.container}>
          <Typography>Review & confirm</Typography>
          <div className={isNeteviaTransfer ? 
            cn(styles.isNeteviaWrapper, styles.wrapper) 
            : styles.wrapper}
            >
            <div className={styles.boxTotal}>
              <Typography>${helper.moneyFormat(total)}</Typography>
              <Typography>Total batch amount</Typography>
            </div>
            {renderTransferItem()}
          </div>
          <div className={styles.boxBtn}>
            <Button
              variant='text'
              onClick={goBack}
              disabled={isShowDrawer}
            >
              Back
            </Button>
            <Button
              variant='contained'
              onClick={() => setIsShowDrawer(true)}
              disabled={isShowDrawer}
            >
              confirm transfer
            </Button>
          </div>
        </div>
        <DrawerModal
          isShow={isShowDrawer}
          onClose={() => setIsShowDrawer(false)}
          titleText={'Confirm transfer'}
        >
          <AuntificatorTransfer setStepContent={setStepContent} />
        </DrawerModal>
      </SimpleContainer>
    </TransitionAnimation>
  );
};
