import { useState } from 'react';
import { triggerBase64Download } from "common-base64-downloader-react";
import styles from "./style.module.scss";
import { useAccount } from "pages/accountComponent/provider";
import moment from "moment";
import SelectYear from "./SelectУear";
import { useCurrentUser } from "providers/user.provider";
import {
  Period,
  FileFormat,
  useStatementsPeriodsQuery,
  useStatementsV2Mutation,
  useStatementsV2PDFMutation,
  useStatementsV2AllMutation,
} from "api/endpoints/statements";
import { Skeleton } from "components/skeleton";
import { offExtFromFilename } from "../../../../shared/utils/pipes";
import helper from "services/helper";
import { SelectAccount } from "./components/selectAccount";
import { MonthList } from "./components/monthList";
import { TransitionAnimation } from "shared/ui/animations";
import { OneAccountModal } from "./components/oneAccountModal";
import { useSnackBar } from "providers/snackBar.provider";
import { AllAccountsModal } from "./components/allAccountsModal";

const Statements = () => {
  const { user } = useCurrentUser();
  const { setSnackBar } = useSnackBar();
  const { financialAccountId, unclosedFinancialAccounts } = useAccount();
  const [currentAccountId, setCurrentAccountId] = useState(financialAccountId);

  const allAccounts = currentAccountId === 'All accounts';

  //Get Period
  const { data: statementsPeriods, isFetching: statementsPeriodsIsLoading } =
    useStatementsPeriodsQuery({
      financialAccountId: allAccounts ? undefined : currentAccountId 
    });

  //only Csv
  const [statementRequisitesV2Mutation, { isLoading: isLoadingStatementRequisitesV2 }] =
  useStatementsV2Mutation();

  //only PDF
  const [statementsPDFDownloadMutation, { isLoading: isLoadingStatementPDF}] =
  useStatementsV2PDFMutation();

  //All
  const [statementsAllDownloadV2, { isLoading: isLoadingStatementsAllDownloadV2 },
  ] = useStatementsV2AllMutation();

  const today = moment();
  const [currentYear, setCurrentYear] = useState(today.year().toString());
  const [confirmPeriod, setConfirmPeriod] = useState<Period | undefined>();

  const period =
    confirmPeriod && moment(confirmPeriod.value).utc().format('YYYY-MM-DD');

  const handlChangeAccount = (id: string) => {
    setCurrentAccountId(id);
  };

  const handleDownloadStatement = (month: Period) => {
    setConfirmPeriod(month);
  };

  const downLoadAllStatemen = async (email: string, format: FileFormat) => {
    try {
      if (period) {
        await statementsAllDownloadV2({
          period,
          financialAccountIds: unclosedFinancialAccounts.map(
            (account) => account.financialAccountId
          ),
          email: email,
          fileFormat: format,
        }).unwrap();
  
        setSnackBar({
          isShow: true,
          type: 'success',
          message: 'Your statement will be sent',
        });
        setConfirmPeriod(undefined);
      } 
      
    } catch (e: any) {
      if (e.status === 'FETCH_ERROR') {
        setSnackBar({
          isShow: true,
          type: 'success',
          message: 'Your statement will be sent',
        })
        setConfirmPeriod(undefined);
    }else{
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      })
      setConfirmPeriod(undefined);
    }
  }
  };
  
  const downLoadCsv = async (format) => {
    try {
      if (period) {
      const res = await statementRequisitesV2Mutation({
        period,
        financialAccountId,
        fileFormat: format,
      }).unwrap();
      const { name, content } = res;
      const blob = new Blob([atob(content)], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setConfirmPeriod(undefined);
    }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const downLoadPdf = async () => {
    try {
      if (period) {
      const res = await statementsPDFDownloadMutation({
        period,
        financialAccountId: currentAccountId,
      }).unwrap();

      const { content, name } = res;
      const base64Str = content;
      const base64PDF = `data:application/pdf;base64, ${base64Str}`;
      triggerBase64Download(base64PDF, offExtFromFilename(name));
      setConfirmPeriod(undefined);
    }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const downLoadStatementForOneAccount = (format: FileFormat) => {
    if (format === FileFormat.PDF) {
      downLoadPdf();
    }
    if (format === FileFormat.CSV) {
      downLoadCsv(format);
    }
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <SelectAccount
          value={currentAccountId}
          onChange={handlChangeAccount}
        />
        {statementsPeriodsIsLoading ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          <>
            <SelectYear
              onChange={setCurrentYear}
              value={currentYear}
              periods={statementsPeriods?.map((item) => item.year) || []}
            />
            <MonthList
              statementsPeriods={statementsPeriods}
              currentYear={currentYear}
              handleClick={handleDownloadStatement}
            />
          </>
        )}
        {confirmPeriod && !allAccounts && (
          <OneAccountModal
            confirmPeriod={confirmPeriod}
            setConfirmPeriod={setConfirmPeriod}
            isLoading={isLoadingStatementRequisitesV2 || isLoadingStatementPDF}
            onSubmit={downLoadStatementForOneAccount}
          />
        )}
        {confirmPeriod && allAccounts && (
          <AllAccountsModal
            email={user.email}
            confirmPeriod={confirmPeriod}
            setConfirmPeriod={setConfirmPeriod}
            isLoading={isLoadingStatementsAllDownloadV2}
            handleSubmit={downLoadAllStatemen}
          />
        )}
      </div>
    </TransitionAnimation>
  );
};

export default Statements;
