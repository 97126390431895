import helper from 'services/helper'
import InfoTooltip from 'assets/svg/InfoTooltip.svg'
import { Typography } from 'shared/ui/typography'
import { AvailableToSpend } from 'pages/cardComponent/provider'
import TooltipChildren from 'shared/components/toolTip/toolTipChildren'
import styles from './style.module.scss'
interface BalanceBlockProps {
  title: string
  value: number
  availableToSpend: AvailableToSpend
}

const toolTipText =
  'This card has a monthly transaction limit. The available spending balance may differ from the available balance in your financial account'

export const BalanceBlock = ({
  value,
  availableToSpend,
}: BalanceBlockProps) => {
  const balance = () => {
    const hasLimit = Number.isInteger(availableToSpend?.availableWithLimit)
    return hasLimit
      ? availableToSpend.availableWithLimit < availableToSpend.available
        ? availableToSpend.availableWithLimit
        : availableToSpend.available
      : availableToSpend.available
  }

  return (
    <div className={styles.container}>
      <div className={styles.boxBalance}>
        <Typography className={styles.amount}>
          ${helper.formatUsdValue(balance())}
        </Typography>
        <div className={styles.boxTitle}>
          <Typography>Available to spend</Typography>
          {availableToSpend.hasLimit && (
            <TooltipChildren description={toolTipText}>
              <img src={InfoTooltip} />
            </TooltipChildren>
          )}
        </div>
      </div>

      {!!availableToSpend.spend && (
        <div className={styles.spentThisMonth}>
          <Typography>Spent this month:</Typography>
          <Typography>
            {helper.formatUsdValue(availableToSpend.spend)}
          </Typography>
        </div>
      )}
    </div>
  )
}
