import { useEffect, useState, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavigationTitle from 'shared/components/navigationTitle';
import OpenPhysicalCard from './deliveryForm';
import FinancialAccountsSelect from 'shared/components/financialAccountsSelect';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { AnimateWrapper } from 'components/animate-wrapper';
import moment from 'moment';
import {
  useOpenPhysicalCardControllerMutation,
  usePaymentCardsControllerQuery,
} from 'api/endpoints/paymentCards';
import { useSubUsersAvailablePaymentCards } from 'api/hooksApi/useSubUsersAvailablePaymentCards';
import helper from 'services/helper';
import styles from './style.module.scss';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useCurrentUser } from 'providers/user.provider';
import { useSubUserMEControllerQuery } from 'api/endpoints/subProfiles';
import { PhysicalCardSummary } from './PhysicalCardSummary';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { useSnackBar } from 'providers/snackBar.provider';
import { useHolderV2ControllerQuery } from 'api/endpoints/account';
import { Skeleton } from 'components/skeleton';
import { appRoutes } from '../../../routes';
import { OPEN_NEW_CARD_SLEEP_MS } from '../../constants';
import { useBoolean } from '../../hooks/useBoolean';
import { usePaymentCards } from '../../../api/hooksApi/usePaymentCards';
import { Button } from 'shared/ui/buttons';
import { CountryCodeAlpha3 } from 'enums';

export interface Person {
  firstName: string;
  lastName: string;
}
export interface Adress {
  streetAddress: string;
  postalCode: string;
  locality: string;
  region: string;
}
interface NewPhisicalCardProp {
  backRoute?: string;
  setChooseCardsMode?: (string) => void;
  isAuthorizedUsers?: boolean;
  onStart?(): void;
  onFinish?(): void;
  subUserId?: string;

  onCardOpened?(newCardId: string): void;
}

const NewPhisicalCard: FC<NewPhisicalCardProp> = ({
  backRoute,
  setChooseCardsMode,
  isAuthorizedUsers,
  onStart,
  onFinish,
  onCardOpened,
  subUserId,
}) => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const { data: holder, isLoading: holderIsLoading, isSuccess: holdreIsSuccess } =
    useHolderV2ControllerQuery();

  const { financialAccounts } = useFinancialAccounts();
  const { isSubUser, subUserRights } = useCurrentUser();
  const { setSnackBar } = useSnackBar();
  const { unclosedFinancialAccounts } = useFinancialAccounts();
  const paymentCards = usePaymentCards();
  const { data: subUserInfo, isLoading: subUserInfoIsLoading } =
    useSubUserMEControllerQuery({}, { skip: !isSubUser });
  const { accountId } = useParams();

  const { subUsersAvailablePaymentCardsRefetch } =
    useSubUsersAvailablePaymentCards();
  const [createPhysicalCardMutation] = useOpenPhysicalCardControllerMutation();

  const [selectAccount, setSelectAccount] = useState<FinancialAccount>(
    (accountId &&
      financialAccounts?.find((f) => f.financialAccountId === accountId)) ||
      financialAccounts[0]
  );
  const { refetch: refetchPaymentCards } = usePaymentCardsControllerQuery();

  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
  const [address, setAddress] = useState<Adress | undefined>();

  const [person, setPerson] = useState<Person>({ firstName: '', lastName: '' });
  const [deliveryChoise, setDeliveryChoise] = useState<string>('');



  const createPhysicalCardLoadingBool = useBoolean();

  const subUserNotHavePermissionFinAcc =
    isSubUser &&
    subUserRights({
      viewAllCardsAndFinancialAccounts: false,
      mainAccess: false,
    }) &&
    !unclosedFinancialAccounts?.length;

  const physicalCardAddress =
    !address?.streetAddress ||
    !address?.postalCode ||
    !address?.region ||
    !address?.locality;

  const physicalCardChoise = physicalCardAddress || !deliveryChoise;

  const disabledPhysicalCard =
    physicalCardChoise || createPhysicalCardLoadingBool.value;

  useEffect(() => {
    if(!holderIsLoading && !subUserInfoIsLoading){
      const firstName = isSubUser ? subUserInfo?.givenName : holder?.businessAccountHolder?.givenName;
      const lastName = isSubUser ? subUserInfo?.familyName : holder?.businessAccountHolder?.familyName;

      setPerson({
        firstName,
        lastName,
      });
    }

  }, [isSubUser, holderIsLoading, subUserInfoIsLoading]);

  const handlerChoiseDelevery = (nameDelivery) => {
    setDeliveryChoise(nameDelivery);
  };

  useEffect(() => {
    if (subUserNotHavePermissionFinAcc) {
      setSnackBar({
        type: 'info',
        message:
          'Your account is not currently linked to any financial accounts.',
        isShow: true,
        width: '400px',
      });
    }
  }, [subUserNotHavePermissionFinAcc]);

  const orderPhysicalCard = async () => {
    createPhysicalCardLoadingBool.setTrue();
    onStart && onStart();
    try {

      if (address && person && holder) {
        const holderLegalBusinessName = holder?.businessAccountHolder?.legalBusinessName;
        const line1 = `${person.firstName} ${person.lastName}`;
        const expirationDate = moment().add(24, 'months').format('YYYY-MM-DD');
        const deliveryDetails = {
          name: {
            givenName: person.firstName,
            familyName: person.lastName,
          },
          companyName: holderLegalBusinessName,
          address: {
            streetAddress: address.streetAddress,
            extendedAddress: '',
            postalCode: address.postalCode,
            locality: address.locality,
            region: address.region,
            countryCodeAlpha3: CountryCodeAlpha3.USA,
          },
        };
        const resPhysicalCard = await createPhysicalCardMutation({
          subProfileId: subUserId,
          financialAccountId: selectAccount.financialAccountId,
          expirationDate: expirationDate,
          activateNow: false,
          deliveryDetails: deliveryDetails,
          cardPersonalization: {
            Line1: line1,
            Line2: holderLegalBusinessName,
          },
          courier: {
            method: deliveryChoise,
          },
        }).unwrap();

        await helper.sleep(OPEN_NEW_CARD_SLEEP_MS);
        await refetchPaymentCards().unwrap();
        paymentCards.paymentCardsRefetch();

        setSnackBar({
          type: 'success',
          message: 'Success! Your order for a physical card is complete',
          isShow: true,
        });

        const newCardId =
          resPhysicalCard?.orderPhysicalPaymentCard?.physicalPaymentCardId;
        if (newCardId) {
          if (onCardOpened) {
            onCardOpened(newCardId)
          } else {
            navigate(appRoutes.cardTransactions(newCardId));
          }
        } else {
          setChooseCardsMode && setChooseCardsMode('manage-card');
        }
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
      createPhysicalCardLoadingBool.setFalse();
    } finally {
      onFinish && onFinish();
    }
  };

  const handleChangeIsShowConfirm = () => {
    setIsShowConfirm(true);
  };

  const handleSelectAccount = (account: FinancialAccount) => {
    setSelectAccount({ ...account });
  };

  const handleNavigate = () =>
    backRoute
      ? setChooseCardsMode && setChooseCardsMode('manage-card')
      : navigate('/home/open-new-product');

  return holderIsLoading || subUserInfoIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <AnimateWrapper className='fade'>
      {!isAuthorizedUsers && (
        <NavigationTitle
          handleNavigate={handleNavigate}
          title={t('NewProduct.Open physical card', {
            defaultValue: 'Open Physical Card',
          })}
        />
      )}
      {isShowConfirm ? (
        <PhysicalCardSummary
          account={selectAccount}
          person={person}
          holder={holder}
          adress={address}
          deliveryChoise={deliveryChoise}
          onSubmit={orderPhysicalCard}
          isLoading={createPhysicalCardLoadingBool.value}
        />
      ) : (
        <>
          <p className={styles.container_title}>
            {t('common.Choose an account to connect', {
              defaultValue: 'Choose an account to connect',
            })}
          </p>

          <FinancialAccountsSelect
            selectAccount={selectAccount}
            setSelectAccount={handleSelectAccount}
            isPartnersAccountsHidden
            isDisabled={createPhysicalCardLoadingBool.value}
          />

          <OpenPhysicalCard
            setAddress={setAddress}
            setPerson={setPerson}
            handlerChoiseDelevery={handlerChoiseDelevery}
            deliveryChoise={deliveryChoise}
          />

          <div className={styles.buttonBox}>
            <Button
              onClick={handleChangeIsShowConfirm}
              disabled={disabledPhysicalCard}
              variant='contained'
            >
              {t('common.Confirm', {
                defaultValue: 'Confirm',
              })}
            </Button>
          </div>
        </>
      )}
    </AnimateWrapper>
  );
};

export default NewPhisicalCard;
