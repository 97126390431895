import { FilterByName } from '../filterTransactions/componentns/filterByName';
import { Button } from 'shared/ui/buttons';
import { FilterIcon } from 'shared/ui/icons';
import FilterListIcon from '@mui/icons-material/FilterList';
import styles from './style.module.scss';
import { useAccount } from 'pages/accountComponent/provider';
import { useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { AccountMode } from 'pages/accountComponent/types';

export const FilterTransactionsControls = () => {
  const {
    isFetchingaccoutnTransactions,
    searchTransactionByName,
    setSearchTransactionByName,
  } = useAccount();
  
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [, setSearchParams] = useSearchParams();

  return (
    <div className={styles.wrapper}>
      <FilterByName
        searchTransactionByName={searchTransactionByName}
        setSearchTransactionByName={setSearchTransactionByName}
      />
      <Button
        onClick={() =>
          setSearchParams({ mode: AccountMode.filtersTransactions })
        }
        variant='outlined'
        className={styles.btnFilterCard}
        disabled={isFetchingaccoutnTransactions}
      >
        {isFetchingaccoutnTransactions ? <FilterListIcon /> : <FilterIcon />}
        {!isMobile && 'filter'}
      </Button>
    </div>
  );
};
