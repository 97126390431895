import { useState } from "react";
import styles from "./styles.module.scss";
import helper from "services/helper";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { removeWhitespaceAndCommas } from "shared/utils/removeWhitespaceAndCommas";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import { useExchangeRewardPointsControllerMutation } from "api/endpoints/rewards";
import { useRewards } from "pages/reward/provider/useRewards";
import { ChangeEventHandler } from "react";
import CurrencyInputRewards from "../currencyInputRewards";
import SelectRewards from "../selectRewards";
import SelectRewardsList from "../selectRewardsList";
import LoadingButton from "@mui/lab/LoadingButton";
import Poinstnput from "../../../pointsInput";
import { useSnackBar } from "providers/snackBar.provider";
import { useSearchParams } from "react-router-dom";
import { useConfirmDialog } from "providers/confirm.provider";
import { useConvertAmountInDollarControllerMutation } from "api/endpoints/rewards";
import { useDebounce } from "shared/hooks/useDebounce";

const GroupingInputRedeemPoints = () => {
  const { t } = useTranslationProvider();
  const [
    convertPointsToDollarMutataion,
    { isLoading: convertPointsToDollarIsLoading },
  ] = useConvertAmountInDollarControllerMutation();
  const { totalPoints, refetchRewardsHistory, refetchRecentTransactions } =
    useRewards();
  const { unclosedFinancialAccounts } = useFinancialAccounts();
  const [exchangeMutation, { isLoading }] =
    useExchangeRewardPointsControllerMutation();
  const { setSnackBar } = useSnackBar();
  const confirm = useConfirmDialog();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "";

  const [error, setError] = useState<string>("");
  const [amountToConvert, setAmountToConvert] = useState<string>("");
  const [amountInUSD, setAmountInUSD] = useState<string | number>("");
  const [accountToConvertId, setAccountToConvertId] = useState<string>("");
  const [showSelectList, setShowSelectList] = useState<boolean>(false);

  const convertPointsToDollar = useDebounce(async (points: string) => {
    try {
      if (points) {
        const res = await convertPointsToDollarMutataion({
          points: points,
        }).unwrap();
        setAmountInUSD(res);
      } else {
        setAmountInUSD("0");
      }
    } catch (e: any) {
      console.log("error", e);
      const notValidValue = e?.data?.points[0];
      if (notValidValue) {
        setError(notValidValue);
      } else {
        setSnackBar({
          type: "error",
          message: helper.formatErrors(e.data),
          isShow: true,
        });
      }
    }
  }, 500);

  const handleSetAmountToConvert: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const { value } = event.target;
    const temptValue = value === "0" ? "" : value;

    if (Number(temptValue) > totalPoints) {
      setError(
        t("Rewards.Not enough points", {
          defaultValue: "Not enough points",
        })
      );
    } else {
      setError("");
    }

    convertPointsToDollar(temptValue);
    setAmountToConvert(temptValue);
  };

  const handleSetAccountId = (id: string) => {
    setAccountToConvertId(id);
    setShowSelectList(false);
  };

  const handleConvert = async () => {
    const convertedPoints = removeWhitespaceAndCommas(amountToConvert);
    confirm.show({
      applyButtonText: t("Rewards.Convert", {
        defaultValue: "Convert",
      }),
      cancelButtonText: t("common.Cancel", {
        defaultValue: "Cancel",
      }),
      cancelButtonType: "text",
      cancelError: true,
      dialogTitle: t("Rewards.Convert points", {
        defaultValue: "Convert points",
      }),
      dialogText: `${"Do you want to convert"} ${amountToConvert} ${t(
        "Rewards.points to dollars",
        {
          defaultValue: "points to dollars",
        }
      )}?`,
      typeNew: true,
      async onApply() {
        try {
          await exchangeMutation({
            toFinancialAccountId: accountToConvertId,
            points: convertedPoints,
          }).unwrap();
          refetchRecentTransactions();
          refetchRewardsHistory();
          setSearchParams(page);
          setSnackBar({
            type: "success",
            message: "Your points have been successfully redeemed.",
            isShow: true,
          });
        } catch (e: any) {
          console.log("error", e);
          setSnackBar({
            type: "error",
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    });
  };
  return (
    <div className={styles.wrapper}>
      <div>
        <Poinstnput
          label={t("Rewards.Points to convert", {
            defaultValue: "Points to convert",
          })}
          value={amountToConvert}
          handleOnChange={handleSetAmountToConvert}
          error={!!error}
        />
        {error && <p className={styles.error}>{error}</p>}
      </div>
      <CurrencyInputRewards
        value={amountInUSD.toString()}
        isLoading={convertPointsToDollarIsLoading}
      />
      <SelectRewards
        id={accountToConvertId}
        toggleArrow={showSelectList}
        setToggleArrow={setShowSelectList}
      />
      {showSelectList && (
        <SelectRewardsList
          list={unclosedFinancialAccounts}
          handleChooseAcc={handleSetAccountId}
        />
      )}

      <LoadingButton
        variant="contained"
        loading={isLoading}
        disabled={
          !amountToConvert || !amountInUSD || !accountToConvertId || !!error
        }
        onClick={handleConvert}
        sx={{
          width: "fit-content",
        }}
      >
        redeem points
      </LoadingButton>
    </div>
  );
};
export default GroupingInputRedeemPoints;
