import { Typography } from 'shared/ui/typography';
import { Select } from 'shared/ui/inputs';
import { Divider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { InfoIcon } from 'shared/ui/icons';
import TooltipChildren from 'shared/components/toolTip/toolTipChildren';
import { FormikProps } from 'formik';
import { SelectItem } from 'shared/ui/inputs';
import { Payee } from 'api/hooksApi/useExternalPayees';
import styles from './styles.module.scss';

interface FormValues {
  from: string;
  to: string;
  amount: string;
}

interface AutomationsFormProps {
  formik: FormikProps<FormValues>;
  optionsToAcc: SelectItem[];
  payees: Payee[];
  warningAmount: string;
  slotBtn: React.ReactNode;
}

const tolltipText =
  'When your Netevia account balance falls below the minimum balance for replenishment, automatic replenishment initiates to restore it. Funds are then transferred from your connected account to maintain the specified balance.';

export const AutomationsForm = (props: AutomationsFormProps) => {
  const { formik, payees, optionsToAcc, warningAmount, slotBtn } = props;

  return (
    <div className={styles.container}>
      <div className={styles.boxText}>
        <Typography>Automate Netevia account top-up</Typography>
        <Typography>
          Funds will be transferred from your connected account when your
          balance falls below a set amount.
        </Typography>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className={styles.wrapperForm}
      >
        <Select
          label='From'
          {...formik.getFieldProps('from')}
        >
          {payees?.map((payee) => (
            <MenuItem
              key={payee.id}
              value={payee.id}
            >
              <Typography>
                {payee.name} ({payee.last4})
              </Typography>
            </MenuItem>
          ))}
        </Select>
        <Select
          label='To'
          options={optionsToAcc}
          {...formik.getFieldProps('to')}
        />
        <Divider />
        <div className={styles.boxWarning}>
          <Typography>{warningAmount}</Typography>
          <TooltipChildren description={tolltipText}>
            <div>
              <InfoIcon />
            </div>
          </TooltipChildren>
        </div>

        <CurrencyInput2
          fullWidth
          variant='outlined'
          label='Amount'
          placeholder='$0.00'
          name='amount'
          value={formik.values.amount}
          onChange={(value) => {
            formik.setFieldTouched('amount', true);
            formik.setFieldValue('amount', value);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.amount && formik.errors.amount}
          getCaretBoundary={() => [false]}
        />
        {slotBtn}
      </form>
    </div>
  );
};
