import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';
import { ApiTags } from '../api-tags';
import { SecureOperationType, CountryCodeAlpha3 } from 'enums';
import { GiftCardStatus } from './giftCards';
export interface RewardsLastPeriodApi {
  amount: number;
  apr: number;
  aprSpend: number;
  cashBack: number;
  cashBackExtr: number;
  cashBackExtrCredit: number;
  comment: string | null;
  dba: string | null;
  extraSpendCashBack: number;
  highNoteProfileId: number;
  manual: number;
  payment: number;
  percent: number;
  period: string;
  points: number;
  profileId: string | null;
  templateId: number;
  totalCashBack: number;
  transactionId: string | null;
  type: number;
  typeName: string;
  welcome: number;
  welcomeSpend: number;
}

export interface RewardsPublishedReportApi {
  item1: string; // date
  item2: number;
}
export interface RewardslastPeriodGroupedApi {
  amount: number;
  points: number;
  type: number;
  typeName: string;
}

export interface RewardsDataApi {
  totalPoints: number;
  lastPeriodPublished: string;
  lastPeriodRewards: RewardsLastPeriodApi[];
  publishedReports: RewardsPublishedReportApi[];
  lastPeriodPoints: number;
  lastPeriodGrouped: RewardslastPeriodGroupedApi[];
}

export interface RewardsHistory {
  createdDate: string;
  dateTransactions: string | null;
  period: string;
  points: number;
  subjectId: string;
  type: number;
  amount: number;
  typeName: string;
  subjectName: string | null;
  publishDate: string;
}

export interface RewardsHistoryApi {
  totalPoints: number;
  publishedReports: RewardsPublishedReportApi[];
  histories: RewardsHistory[];
}

export interface RewardsReportApi {
  content: string;
  contentType: string;
  name: string;
}

interface RewardsHistoryArg {
  dateFrom: string;
  dateTo: string;
  types?: number[];
}

interface GetRewardsReportArg {
  reportPeriodId: number;
}

interface GetRewardPointsArg {
  toFinancialAccountId: string;
  points: string;
}
interface ConvertAmountInDollarArg {
  points: string;
}
interface RewardTransfersArg {
  oneTimeCode: string;
  secureOperationType: string;
  toProfileId: number;
  points: number;
}
interface CreateGiftCardPointsArg {
  brandId: string;
  faceAmount: number;
  oneTimeCode: string;
  secureOperationType: SecureOperationType;
}
interface CreateGiftCardPointsApi {
  id: string;
  brandId: string;
  brandName: string;
  logoUrl: string;
  url: string;
  currency: CountryCodeAlpha3;
  faceAmount: number;
  amount: number;
  discount: number;
  expirationDate: string;
  createdDate: string;
  cancelDate: string;
  issueTransactionId: string;
  cancelTransactionId: string;
  status: GiftCardStatus;
  userName: string;
}

export const rewardsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'rewards',
  tagTypes: [ApiTags.GetRewardsPoints, ApiTags.GetRewardsHistory],
  endpoints: (builder) => ({
    rewardsController: builder.query<RewardsDataApi, any>({
      providesTags: [ApiTags.GetRewardsPoints],
      query: () => ({
        url: 'api/usersReward/rewards',
        method: 'GET',
      }),
    }),
    rewardsHistoryController: builder.query<
      RewardsHistoryApi,
      RewardsHistoryArg
    >({
      query: ({ dateFrom, dateTo, types }) => ({
        providesTags: [ApiTags.GetRewardsHistory],
        url: 'api/UsersReward/v2/history',
        method: 'GET',
        params: {
          dateFrom,
          dateTo,
          types,
        },
      }),
    }),
    downloadRewardsReportController: builder.mutation<
      RewardsReportApi,
      GetRewardsReportArg
    >({
      query: (arg) => ({
        url: 'api/UsersReward/report',
        method: 'GET',
        params: {
          reportPeriodId: arg.reportPeriodId,
        },
      }),
    }),
    exchangeRewardPointsController: builder.mutation<any, GetRewardPointsArg>({
      invalidatesTags: [ApiTags.GetRewardsPoints, ApiTags.GetRewardsHistory],
      query: (arg) => ({
        url: 'api/usersReward/exchange',
        method: 'POST',
        body: arg,
      }),
    }),

    convertAmountInDollarController: builder.mutation<
      string,
      ConvertAmountInDollarArg
    >({
      query: (arg) => ({
        url: 'api/UsersReward/amountInDollar',
        method: 'GET',
        params: {
          points: arg.points,
        },
      }),
    }),

    rewardTransfersController: builder.mutation<any, RewardTransfersArg>({
      invalidatesTags: [ApiTags.GetRewardsHistory, ApiTags.GetRewardsPoints],
      query: (arg) => ({
        url: 'api/UsersReward/Transfer',
        method: 'POST',
        body: arg,
      }),
    }),
    createGiftCardPoints: builder.mutation<
      CreateGiftCardPointsApi,
      CreateGiftCardPointsArg
    >({
      query: (arg) => ({
        url: 'api/UsersReward/exchange/giftcard',
        method: 'POST',
        body: arg,
      }),
    }),
  }),
});

export const {
  useRewardsControllerQuery,
  useExchangeRewardPointsControllerMutation,
  useRewardsHistoryControllerQuery,
  useDownloadRewardsReportControllerMutation,
  useConvertAmountInDollarControllerMutation,
  useRewardTransfersControllerMutation,
  useCreateGiftCardPointsMutation,
} = rewardsApi;
