import * as yup from 'yup';

export const AutomationsSchema = yup.object().shape({
  from: yup.string().required(),
  to: yup.string().required(),
  amount: yup
    .string()
    .required()
    .test({
      name: 'is-non-zero',
      message: 'Float value must not be zero',
      test: (value) => Number(value) !== 0,
    }),
});
