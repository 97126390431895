export enum EditType {
  Email = 'Email',
  Phone = 'Phone',
  Address = 'Address',
}

export enum SectionPerson {
  AuthorizedPerson = 'Authorized Person',
  Business = 'Business',
  FirstOwner = '1',
  SecondOwner = '2',
  ThirdOwner = '3',
  FourthOwner = '4',
}

