import {
  LoadingButton as LoadingButtonMUI,
  LoadingButtonProps as LoadingButtonPropsMUI,
} from '@mui/lab';

import cn from 'classnames';
import styles from './styles.module.scss';
import { forwardRef } from 'react';

interface ButtonProps
  extends Pick<
    LoadingButtonPropsMUI,
    | 'loading'
    | 'disabled'
    | 'loadingIndicator'
    | 'loadingPosition'
    | 'className'
    | 'href'
    | 'startIcon'
    | 'endIcon'
    | 'children'
    | 'fullWidth'
    | 'onClick'
    | 'size'
    | 'key'
    | 'color'
  > {
  variant?: 'contained' | 'text' | 'outlined';
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props: ButtonProps, ref) {
    const { children, variant = 'contained', size = 'medium', ...rest } = props;

    const isContained = variant === 'contained';
    const isText = variant === 'text';
    const isOtlined = variant === 'outlined';

    const classes: LoadingButtonPropsMUI['classes'] = {
      root: cn('rootBtn'),
      contained: styles.contained,
      text: rest.color === 'error' ? styles.textError : styles.text,
      outlined: rest.color === 'error' ? styles.outlinedError : styles.outlined,
      sizeSmall: cn({
        [styles.textSmall]: isText,
        [styles.containedSmall]: isContained,
        [styles.outlinedSmall]: isOtlined,
      }),
      sizeMedium: cn({
        [styles.textMedium]: isText,
        [styles.containedMedium]: isContained,
        [styles.outlinedMedium]: isOtlined,
      }),
      sizeLarge: cn({
        [styles.textLarge]: isText,
        [styles.containedLarge]: isContained,
        [styles.outlinedLarge]: isOtlined,
      }),
    };

    return (
      <LoadingButtonMUI
        classes={classes}
        variant={variant}
        size={size}
        {...rest}
        ref={ref}
      >
        {children}
      </LoadingButtonMUI>
    );
  }
);
