import { Typography } from 'shared/ui/typography';
import { EnumTransactionStatuses, Amount } from 'api/endpoints/transaction';
import { Badge } from 'shared/ui/badge';
import helper from 'services/helper';
import cn from 'classnames';
import styles from './style.module.scss';
import { UncommingCircleArrowIcon } from 'shared/ui/icons';
import { Transaction } from 'api/endpoints/transaction';
import { ListItemButton } from '@mui/material';
interface TransfersHistoryItemProps {
  item: Transaction;
  handleNavigate: (value: Transaction) => void;
}

export const TransfersHistoryItem = (props: TransfersHistoryItemProps) => {
  const { item, handleNavigate } = props;

  const isPositiveTransfer = item.amount.symbol === '+';

  return (
    <ListItemButton
      className={styles.wrapper}
      onClick={() => handleNavigate(item)}
    >
      <div className={styles.box}>
        <UncommingCircleArrowIcon
          className={
            isPositiveTransfer ? cn(styles.img, styles.imgPositive) : styles.img
          }
        />
        <div className={styles.boxName}>
          <Typography>
            {item.name} {item.last4.value ? `(${item.last4.value})` : ''}
          </Typography>
          <Typography>{item.type}</Typography>
        </div>
      </div>
      <div className={styles.boxAmount}>
        <Typography
          className={
            isPositiveTransfer
              ? cn(styles.amountText, styles.amountPositive)
              : styles.amountText
          }
        >
          {item.amount.symbol}${helper.formatUsdValue(item.amount.value)}
        </Typography>
        <Badge
          label={helper.capitalizeFirstLetter(item.status)}
          styledWrapper={styles.wrapperBadge}
          styledLabel={styles.labelBadge}
        />
      </div>
    </ListItemButton>
  );
};
