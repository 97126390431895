import { Typography } from 'shared/ui/typography';
import { DotIcon, WarningAmberOutlinedIcon } from 'shared/ui/icons';
import { GetNotificationsApi } from 'api/endpoints/notifications';
import { NotificationsLevel } from 'enums';
import moment from 'moment';
import styles from './style.module.scss';
import { ButtonTab } from 'shared/ui/buttons';

interface ContentItemProps {
  item: GetNotificationsApi;
  handleReadMessage: (id:number) => Promise<void>;
  disabled: boolean
}

export const ContentItem = ({ item,handleReadMessage ,disabled}: ContentItemProps) => {
  return (
    <ButtonTab 
        onClick={() => handleReadMessage(item.id)}
        disabled={disabled} 
    >
    <div className={styles.wrapper}>
      <div className={styles.boxTitle}>
        {!item.dateRead && <DotIcon className={styles.boxTitleDots} />}
        {item.level === NotificationsLevel.Warning && (
          <WarningAmberOutlinedIcon />
        )}
        <Typography>{item.subject}</Typography>
      </div>
      <Typography className={styles.subTitle}>{item.body}</Typography>
      <Typography className={styles.createdDate}>
        {moment(item.createdDate).format('DD MMM YYYY hh:mm A')}
      </Typography>
    </div>
    </ButtonTab>
  );
};
