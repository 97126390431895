import { ButtonTab } from 'shared/ui/buttons';
import { FinancialAccount } from 'entities/transfers';
import { Typography } from 'shared/ui/typography';
import { ChevronRightIcon } from 'shared/ui/icons';
import helper from 'services/helper';
import styles from './styles.module.scss';

interface AutomationsItemProps {
  id: number;
  fromAccount: FinancialAccount;
  toAccount: FinancialAccount;
  amount: number;
  handleNavigate: (
    id: number,
    fromAccount: FinancialAccount,
    toAccount: FinancialAccount,
    amount: number
  ) => void;
}

export const AutomationsItem = (props: AutomationsItemProps) => {
  const { id, fromAccount, toAccount, amount, handleNavigate } = props;

  return (
    <ButtonTab
      endIcon={<ChevronRightIcon />}
      className={styles.btnTab}
      onClick={() => handleNavigate(id, fromAccount, toAccount, amount)}
    >
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <Typography>From</Typography>
          <Typography>
            {fromAccount.name}{' '}
            <span className={styles.last4}>({fromAccount.last4Number})</span>
          </Typography>
        </div>
        <div className={styles.box}>
          <Typography>To</Typography>
          <Typography>
            {toAccount.name}{' '}
            <span className={styles.last4}>({toAccount.last4Number})</span>
          </Typography>
        </div>
        <div className={styles.box}>
          <Typography>Minimum balance for replenishment:</Typography>
          <Typography>${helper.moneyFormat(amount)}</Typography>
        </div>
      </div>
    </ButtonTab>
  );
};
