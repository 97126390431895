import { CardViewProps } from '../model';
import styles from './style.module.scss';
import { useMemo } from 'react';
import BgCardFallback from "assets/svg/bg-card.svg";
import CardNumberLineComponent from '../components/cardNumberLineComponent';
import { ViewPaymentCardInfo } from '../../../../api/endpoints/paymentCards';
import CardFooter from '../components/cardFooter';
import { useBoolean } from '../../../hooks/useBoolean';
import cx from 'classnames';
import { ButtonActivateCard } from '../components/buttonActivateCard';
import CardBadge from '../components/cardBadge';

interface Props {
  originalProps: CardViewProps;
  isDataShowing: boolean;
  cardInfo?: ViewPaymentCardInfo;
  handleCopyCardNumber: (cardNumber: string) => void;
  handleShowData: (event: any) => void;
  aceessActivateCard: boolean;
  isActivate: boolean;
}


export const PartnerCard = ({
  originalProps: { partnerIcons, last4, cardId, getCardInfo, isClosed, isAuthorizedUser, isLocked },
  handleCopyCardNumber, isDataShowing, cardInfo, handleShowData, aceessActivateCard, isActivate
}: Props) => {
  const imageLoadedBool = useBoolean();

  const imageUrl = useMemo(() => {
    if (partnerIcons?.length && partnerIcons?.length > 1) {
      return partnerIcons[1];
    }

    return BgCardFallback;
  }, [partnerIcons]);

  return (
    <div className={styles.container}>
      <CardBadge
        isClosed={isClosed}
        isAuthorizedUser={isAuthorizedUser}
        isLocked={isLocked}
        isActivate={isActivate}
      />
      <img
        src={imageUrl}
        onLoad={() => imageLoadedBool.setTrue()}
        className={cx(styles.cardImage, imageLoadedBool.value && styles.cardImageLoaded)}
      />
      {isActivate && aceessActivateCard && (
        <ButtonActivateCard cardId={cardId} getCardInfo={getCardInfo} />
      )}
      <div className={styles.numberWrapper}>
        <CardNumberLineComponent
          last4={last4}
          isShow={isDataShowing}
          copy={handleCopyCardNumber}
          cardNumber={cardInfo?.restrictedDetails?.number!}
          cardCvv={cardInfo?.restrictedDetails?.cvv!}
          cardExpirationDate={cardInfo?.expirationDate!}
          isPrimaryColorMode
        />
        <CardFooter
          handleShowData={handleShowData}
          isDataShowing={isDataShowing}
          hideMasterCardIcon
          textColor="#187AC9"
          buttonBg="rgba(24, 122, 201, 0.08)"
        />
      </div>
    </div>
  )
};
