import { Link } from "react-router-dom";
import { useTranslationProvider } from "providers/translation/translation.provider";
import styles from "./style.module.scss";
const SIGN_UP_LINK =
  "https://hq.netevia.com/MerchantApplication/Index/63d2ce60-ecd9-4fcc-9d75-ed22bc9fd400";

const SingUpNavigate = () => {
  const { t } = useTranslationProvider();
  return (
    <span className={styles.text}>
      {t("Login.Don’t have an account yet", {
        defaultValue: "Don’t have an account yet",
      })}
      ?{" "}
      <Link to={SIGN_UP_LINK} className={styles.link}>
        {t("Login.Sign up", {
          defaultValue: "Sign up",
        })}
      </Link>
    </span>
  );
};
export default SingUpNavigate;
