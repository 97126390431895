import {
  useRewardIntegrationsControllerQuery,
  RewardIntegrationsDTO,
} from 'api/endpoints/rewardIntegrations';
import { useMemo } from 'react';

export interface RewardIntegrations {
  featured: RewardIntegrationsDTO[];
  promo: RewardIntegrationsDTO[];
}
interface RewardIntegrationsReturnType {
  rewardIntegrations: RewardIntegrations;
  rewardIntegrationsIsLoading: boolean;
}

export const useRewardIntegrations = (): RewardIntegrationsReturnType => {
  const { data, isLoading } = useRewardIntegrationsControllerQuery();

  const rewardIntegrations = useMemo(() => {
    const emptyRewardIntegrations: RewardIntegrations = {
      featured: [],
      promo: [],
    };

    if (data) {
      return data.reduce<RewardIntegrations>((acc, item) => {
        switch (item.type) {
          case 1:
            acc.promo.push(item);
            break;
          case 4:
          case 3:
          case 2:
          case 0:
            acc.featured.push(item);
            break;
        }

        return acc;
      }, emptyRewardIntegrations);
    }

    return emptyRewardIntegrations;
  }, [data]);

  return {
    rewardIntegrations,
    rewardIntegrationsIsLoading: isLoading,
  };
};
