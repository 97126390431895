import { FC } from "react";
import { Popup } from "components/popup";
import styles from "./style.module.scss";
import FilterByDate from "../filterByDate";
import FilterByType from "../filterByType";
import { Button } from "@mui/material";
import { useSupportHistory } from "api/hooksApi/useSupportHistory";
import { Ticket } from "../../types";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useMediaQuery, useTheme } from "@mui/material";
const COLOR_BUTTON = "#187AC9";
interface FilterProps {
  setIsOpen: (boolean) => void;
  startDate: Date;
  setStartDate: (date: Date | undefined) => void;
  endDate: Date;
  setEndDate: (date: Date | undefined) => void;
  findNew: boolean;
  setFindNew: (boolean) => void;
  findInProgress: boolean;
  setFindInProgress: (boolean) => void;
  findResolved: boolean;
  setFindResolve: (boolean) => void;
  filtered: () => void;
  setTickets: (tickets: Ticket[]) => void;
}
const Filter: FC<FilterProps> = ({
  setIsOpen,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  findNew,
  setFindNew,
  findInProgress,
  setFindInProgress,
  findResolved,
  setFindResolve,
  filtered,
  setTickets,
}) => {
  const { history } = useSupportHistory();
  const { t } = useTranslationProvider();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const resetFilters = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setFindNew(false);
    setFindInProgress(false);
    setFindResolve(false);
    setTickets(history);
    setIsOpen(false);
  };

  const CANCEL_COLOR = matches ? COLOR_BUTTON : "rgba(0, 0, 0, 0.38)";
  return (
    <Popup onClose={() => setIsOpen(false)} stylesWrapper={styles.popup}>
      <div className={styles.container}>
        <div className={styles.container_boxTitle}>
          <span className={styles.container_boxTitle_text}>
            {t("common.Filter requests", {
              defaultValue: "Filter requests",
            })}
          </span>
          <Button
            onClick={resetFilters}
            sx={{
              color: CANCEL_COLOR,
            }}
          >
            {t("common.Clear all", {
              defaultValue: "Clear all",
            })}
          </Button>
        </div>

        <FilterByDate
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <FilterByType
          findNew={findNew}
          setFindNew={setFindNew}
          findInProgress={findInProgress}
          setFindInProgress={setFindInProgress}
          findResolved={findResolved}
          setFindResolve={setFindResolve}
        />
        <div className={styles.container_boxButtons}>
          <Button
            onClick={() => setIsOpen(false)}
            sx={{
              color: COLOR_BUTTON,
            }}
          >
            {t("common.Cancel", {
              defaultValue: "Cancel",
            })}
          </Button>
          <Button
            onClick={() => filtered()}
            variant="contained"
            sx={{
              backgroundColor: COLOR_BUTTON,
            }}
          >
            {t("common.Apply filter", {
              defaultValue: "Apply filter",
            })}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default Filter;
