import { useTranslationProvider } from 'providers/translation/translation.provider';
import GreenDolar from '../../../assets/svg/greend.svg';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import helper from '../../../services/helper';
import CasesOutlined from 'assets/svg/CasesOutlined.svg';
import styles from './style.module.scss';
import { FinancialAccount } from 'api/endpoints/financialAccounts';

type TConnectAccounts = {
  white?: any;
  edit?: any;
  financialAccountsArr: FinancialAccount[];
};

const AccList = ({ white, edit, financialAccountsArr }: TConnectAccounts) => {
  const { t } = useTranslationProvider();
  const renderItems = () => {
    if (!financialAccountsArr?.length) {
      return (
        <p className={styles.warningText}>
          {t('common.No connected accounts yet', {
            defaultValue: 'No connected accounts yet',
          })}
          .
        </p>
      );
    } else {
      return financialAccountsArr?.map((i, index) => {
        return (
          <AnimateWrapper delay={index * 100} key={i.financialAccountId}>
            <div
              key={i.financialAccountId}
              className={`${styles.item} ${
                white
                  ? styles.item_backGourndWhite
                  : styles.item_backGourndDefault
              }`}
            >
              <div className={styles.blockName}>
                <div className={styles.blockName_img}>
                  <img src={CasesOutlined}/>
                </div>
                <div>
                  <div className={styles.blockName_text}>{i.name}</div>
                  <p className={styles.blockNumb_numb}>
                    ****{i.accountNumber?.slice(-4)}
                  </p>
                </div>
              </div>

              <div className={styles.blockNumb}>
                $ {helper.moneyFormat(i.availableCash?.value)}
              </div>
            </div>
          </AnimateWrapper>
        );
      });
    }
  };

  return <div className={styles.wrapper}>{renderItems()}</div>;
};

export default AccList;
