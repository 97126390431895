import styled, { css } from "styled-components";
import { Typography } from "@mui/material";
import CurrencyDollar from "assets/svg/dollar-blue.svg";
import ItemBalance from "./components/itemBalance";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import helper from "services/helper";
import lineImg from "assets/svg/vertical-line-gray.svg";
import { Skeleton } from "components/skeleton";

const availableBalance =
  "The amount of money in your account that you can use right now.";
const presentBalance =
  "The total amount of money recorded in your account, including funds not yet available for you to use. This includes pending transactions, authorization holds that are not yet posted or deposits that have not yet been made available.";

const AccountBalance = () => {
  const { total, financialAccountsIsLoading } = useFinancialAccounts();

  const renderItems = () => {
    if (total.availableCash === total.cash) {
      return (
        <ItemBalance
          balance={helper.moneyFormat(total.availableCash)}
          description={"Available Balance"}
          tooltip={availableBalance}
        />
      );
    } else {
      return (
        <>
          <ItemBalance
            balance={helper.moneyFormat(total.availableCash)}
            description={"Available Balance"}
            tooltip={availableBalance}
          />
          <ItemBalance
            balance={helper.moneyFormat(total.cash)}
            description={"Present Balance"}
            tooltip={presentBalance}
          />
        </>
      );
    }
  };

  return (
    <Wrapper>
      {financialAccountsIsLoading ? (
        <Skeleton width="100%" height="100%" />
      ) : (
        <Container>
          <Header>
            <img src={CurrencyDollar} />
            <Text>All accounts</Text>
          </Header>
          <Hint>
            {`Here's a summary`} of your financial accounts<br />and&nbsp;balances.
          </Hint>
          <Box>{renderItems()}</Box>
        </Container>
      )}
    </Wrapper>
  );
};

export default AccountBalance;

const Hint = styled('div')`
  font-size: 14px;
`

const Wrapper = styled("div")`
  width: 100%;
  
  @media (min-width: 1170px) {
    max-width: 550px;
  }
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 24px;
  gap: 12px;
  width: 100%;
  height: 100%;
`;
const Header = styled("div")`
  display: flex;
  gap: 16px;
  align-items: center;
`;
const Box = styled("div")`
  display: flex;
  gap: 24px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
`;
const Text = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;
