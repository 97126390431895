import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { useSetPinCardControllerMutation } from "api/endpoints/paymentCards";
import { Popup } from "components/popup";
import { ForwardRefRenderFunction, ReactNode, useRef, useState } from 'react';
import styled, { css } from "styled-components";
import { useSnackBar } from '../../../../providers/snackBar.provider';

interface IСonfirmationInput {
  onChange: (number: string, index: number) => void;
  value: string;
  index: number;
  inputRef: React.Ref<HTMLInputElement>;
}

const СonfirmationInput: ForwardRefRenderFunction<
  HTMLInputElement,
  IСonfirmationInput
> = ({ onChange, index, value, inputRef }) => {
  const handleChange = (event) => {
    if (event.code !== "Backspace" && event.target.value)
      onChange(event.target.value, index);
  };
  const handleKeyUp = (event) => {
    if (event.code === "Backspace") onChange("", index);
  };

  return (
    <Input
      ref={inputRef}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      maxLength={1}
      type="password"
      autoComplete="off"
    />
  );
};

export const ChangePin = ({
  cardId,
  closeModal,
}: {
  cardId: string;
  closeModal: () => void;
}) => {
  const { setSnackBar } = useSnackBar();
  const [isValid, setIsvalid] = useState(false);
  const [firstPassword, setFirstPassword] = useState("");
  const inputRefs = useRef<Array<HTMLInputElement>>(
    Array(4).fill(HTMLInputElement)
  );
  const getInputsValue = () =>
    inputRefs.current.map((inputRef) => (inputRef ? inputRef.value : ""));
  const handleChangeCode = (value: string, index: number) => {
    if (Number(value) || value === "0") {
      inputRefs.current[index].value = value;
      handleInputFocus(index, 1);
    } else if (!value && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].value = "";
      handleInputFocus(index, -1);
    } else {
      inputRefs.current[index].value = "";
    }
    if (getInputsValue().every((item) => Number(item) || item === "0")) {
      setIsvalid(true);
    } else {
      setIsvalid(false);
    }
  };
  const handleInputFocus = (index: number, step: number) => {
    if (inputRefs.current[index + step]) {
      inputRefs.current[index + step]?.focus();
    }
  };
  const [setPinMutation, { isLoading: isPinSubmitting, isSuccess }] =
    useSetPinCardControllerMutation();
  const handleSubmit = async () => {
    if (getInputsValue().join("") !== firstPassword) {
      setSnackBar({
        isShow: true,
        type: 'error',
        message: 'PINs do not match.',
      })
      handleClearInputs();
      setFirstPassword("");
      return;
    }
    try {
      await setPinMutation({ cardId, newPin: getInputsValue().join("") })
        .unwrap()
        .catch();
      closeModal();
      setSnackBar({
        isShow: true,
        type: 'success',
        message: 'You\'ve successfully set up your PIN.',
      })
    } catch (error) {
      console.log(`Error: `, error);
    }
    handleClearInputs();
  };
  const handleCheckFirstPassword = () => {
    setFirstPassword(getInputsValue().join(""));
    handleClearInputs();
  };
  const handleClearInputs = () => {
    inputRefs.current.forEach((element) => (element.value = ""));
  };
  return (
    <Popup>
      <Title>{!firstPassword ? "Set up PIN" : "Сonfirm your PIN"}</Title>
      <ValuesContainer>
        {getInputsValue().map((codeItem, index) => (
          <СonfirmationInput
            inputRef={(el: HTMLInputElement) => (inputRefs.current[index] = el)}
            key={index}
            index={index}
            value={codeItem}
            onChange={handleChangeCode}
          />
        ))}
      </ValuesContainer>
      <ButtonsContainer>
        <ButtonsCancel onClick={closeModal} variant="text">
          Cancel
        </ButtonsCancel>
        <LoadingButton
          loading={isPinSubmitting}
          onClick={!!firstPassword ? handleSubmit : handleCheckFirstPassword}
          disabled={!isValid}
        >
          <div>Confirm</div>
        </LoadingButton>
      </ButtonsContainer>
    </Popup>
  );
};
const Title = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin-left: 10px;
`;
const ValuesContainer = styled("div")`
  display: flex;

  justify-content: space-between;
`;
const ButtonsContainer = styled("div")`
  display: flex;
  gap: 40px;
  justify-content: end;
`;

const ButtonsCancel = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.red};
  `}
`;

const Input = styled("input")`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.borderLightGrey};
    border-radius: 4px;
    width: 60px;
    height: 60px;
    margin: 10px;
    outline: none;
    font-family: DM Sans;
    font-size: 36px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
    @media (max-width: 800px) {
      width: 40px;
    }
  `}
`;
