import { Button } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Popup } from "../../../../../components/popup";

const RemoveSubUserModal = ({
  stateModal,
  label,
  handleCanceled,
  handleSubmit,
  buttonSubmitLabel = "Remove",
  buttonCanceledLabel = "Cancel",
}: any) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  return (
    <>
      {stateModal && (
        <Popup>
          <div className="flex flex-col items-center justify-center ">
            <p className="font-medium text-lg xs:font-normal xs:text-sm">
              {" "}
              {label}
            </p>
            <Button
              variant="outlined"
              onClick={() => handleSubmit()}
              color="error"
              sx={{
                borderRadius: 6,
                textTransform: "none",
                width: 150,
                height: 36,
                mt: 2,
              }}
            >
              {buttonSubmitLabel}
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleCanceled()}
              sx={{
                borderRadius: 6,
                textTransform: "none",
                width: 150,
                height: 36,
                borderColor: "#51B9E5",
                color: "#51B9E5",
                mt: 2,
              }}
            >
              {buttonCanceledLabel}
            </Button>
          </div>
        </Popup>
      )}
    </>
  );
};

export default RemoveSubUserModal;
