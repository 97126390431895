import React, { PropsWithChildren, useMemo, useState, useEffect } from 'react';
import {
  useGetGiftCardsQuery,
  GetGiftCardsBrandsApi,
  GetGiftCardApi,
  useGetGiftCardsBrandsQuery,
} from 'api/endpoints/giftCards';
import { useBoolean, ReturnType } from 'shared/hooks/useBoolean';
import {
  GiftCardMethod,
  BrandsSort,
} from 'pages/reward/components/giftCard/types';
import { useRewardsControllerQuery } from 'api/endpoints/rewards';
import { useSearchParams } from 'react-router-dom';
import { initialDataCategory } from 'pages/reward/components/giftCard/config/constants';
import { DataCategory } from 'pages/reward/components/giftCard/types';
interface ContextProps {
  giftCardsData: GetGiftCardApi[];
  isLoadingGiftCards: boolean;
  refetchGiftCardsData: () => void;
  giftCardsArchiveData: GetGiftCardApi[];
  showDrawerGiftCardBool: ReturnType;
  selectBrand: GetGiftCardsBrandsApi;
  setSelectBrand: (item: GetGiftCardsBrandsApi) => void;
  amount: string;
  setAmount: (value: string) => void;
  paymnetMethod: GiftCardMethod;
  setPaymentMethod: (paymnetMethod: GiftCardMethod) => void;
  currentPoint: number;
  financialAccountId: string;
  setFinacialAccountId: (financailAccountId: string) => void;
  clearData: () => void;
  closeDrawerStepper: () => void;
  refetchRewards: () => void;
  point: number;
  setPoint: (point: number) => void;
  brands: GetGiftCardsBrandsApi[];
  arrBrands: GetGiftCardsBrandsApi[];
  isLoadingBrands: boolean;
  filterBySort: BrandsSort;
  setFilterBySort: (value: BrandsSort) => void;
  filterByCategory: DataCategory[];
  setFilterByCategory: (value: DataCategory[]) => void;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const GiftCardsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [, setSearchParams] = useSearchParams();
  const {
    data: giftCardsData,
    isLoading: isLoadingGiftCardsData,
    refetch: refetchGiftCardsData,
  } = useGetGiftCardsQuery({ archive: false });

  const {
    data: giftCardsArchiveData,
    isLoading: isLoadingGiftCardsArchiveData,
  } = useGetGiftCardsQuery({ archive: true });

  const { data: brands, isLoading: isLoadingBrands } =
    useGetGiftCardsBrandsQuery();

  const {
    data: rewards,
    isLoading: isLoadingRewards,
    refetch: refetchRewards,
  } = useRewardsControllerQuery({});

  const showDrawerGiftCardBool = useBoolean();

  const [selectBrand, setSelectBrand] = useState<GetGiftCardsBrandsApi | null>(
    null
  );
  const [amount, setAmount] = useState<string>('');
  const [paymnetMethod, setPaymentMethod] = useState<GiftCardMethod>(
    GiftCardMethod.PayUsingYourFinancialAccount
  );
  const [financialAccountId, setFinacialAccountId] = useState<string>('');
  const [point, setPoint] = useState<number>();

  const [filterBySort, setFilterBySort] = useState<BrandsSort>(BrandsSort.AZ);
  const [filterByCategory, setFilterByCategory] =
    useState<DataCategory[]>(initialDataCategory);
  const [arrBrands, setArrBrands] = useState<GetGiftCardsBrandsApi[]>([]);
  const clearData = () => {
    setSelectBrand(null);
    setAmount('');
    setPaymentMethod(GiftCardMethod.PayUsingYourFinancialAccount);
    setFilterBySort(BrandsSort.AZ);
    setFilterByCategory(initialDataCategory);
    setFinacialAccountId('');
  };

  const closeDrawerStepper = () => {
    clearData();
    setSearchParams({});
    showDrawerGiftCardBool.setFalse();
  };

  const filterBrands = (
    brands: GetGiftCardsBrandsApi[],
    sortBy: BrandsSort,
    categoryBy: DataCategory[]
  ) => {
    const sortedBrands = brands
      .slice()
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      );

    const filteredByCategory = sortedBrands.filter((brand) =>
      brand.categories.some((category) =>
        categoryBy.some((item) => item.label === category && item.value)
      )
    );
    switch (sortBy) {
      case BrandsSort.AZ:
        return filteredByCategory;
      case BrandsSort.ZA:
        return filteredByCategory.reverse();
      case BrandsSort.DescendingDiscount:
        return filteredByCategory.sort(
          (a, b) => b.clientDiscount - a.clientDiscount
        );
      case BrandsSort.AscendingDiscount:
        return filteredByCategory.sort(
          (a, b) => a.clientDiscount - b.clientDiscount
        );
    }
  };

  useEffect(() => {
    if (!isLoadingBrands && brands) {
      setArrBrands(filterBrands(brands, filterBySort, filterByCategory));
    }
  }, [filterBySort, filterByCategory, isLoadingBrands]);

  const value = useMemo((): ContextProps => {
    return {
      giftCardsData,
      giftCardsArchiveData,
      isLoadingGiftCards:
        isLoadingGiftCardsData ||
        isLoadingGiftCardsArchiveData ||
        isLoadingRewards,
      showDrawerGiftCardBool,
      selectBrand,
      setSelectBrand,
      amount,
      setAmount,
      paymnetMethod,
      setPaymentMethod,
      currentPoint: rewards?.totalPoints || 0,
      financialAccountId,
      setFinacialAccountId,
      refetchGiftCardsData,
      clearData,
      closeDrawerStepper,
      refetchRewards,
      point,
      setPoint,
      arrBrands,
      isLoadingBrands,
      filterBySort,
      setFilterBySort,
      filterByCategory,
      setFilterByCategory,
    } as unknown as ContextProps;
  }, [
    giftCardsData,
    giftCardsArchiveData,
    isLoadingGiftCardsData,
    isLoadingGiftCardsArchiveData,
    isLoadingRewards,
    showDrawerGiftCardBool,
    selectBrand,
    amount,
    paymnetMethod,
    rewards,
    arrBrands,
    isLoadingBrands,
    filterBySort,
    filterByCategory,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useGiftCards = () => React.useContext(Context) as ContextProps;
