import { FC } from "react";
import { Button } from "@mui/material";
import filterIcon from "assets/svg/filter-white.svg";
import styles from "./style.module.scss";
import cx from "classnames";
import moment from "moment";
import cancelIcon from "assets/svg/cancel-gray.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";
import Status from "../status/Status";
import { TicketStatusEnum } from "pages/support/types/enums";

interface OpenFilterProps {
  setIsOpenFilter: (boolean) => void;
  startDate: Date;
  setStartDate: (date: Date | undefined) => void;
  endDate: Date;
  setEndDate: (date: Date | undefined) => void;
  findNew: boolean;
  setFindNew: (boolean) => void;
  findInProgress: boolean;
  setFindInProgress: (boolean) => void;
  findResolved: boolean;
  setFindResolve: (boolean) => void;
}

const OpenFilter: FC<OpenFilterProps> = ({
  setIsOpenFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  findNew,
  setFindNew,
  findInProgress,
  setFindInProgress,
  findResolved,
  setFindResolve,
}) => {
  const { t } = useTranslationProvider();
  const periodBoolean = startDate && endDate;
  const start = moment(startDate).format("MM/DD/YYYY");
  const end = moment(endDate).format("MM/DD/YYYY");
  const filterBoolean = [
    periodBoolean,
    findNew,
    findInProgress,
    findResolved,
  ].some(Boolean);
  const styleWrapper = filterBoolean
    ? cx(styles.wrapper, styles.haveFilter)
    : styles.wrapper;

  const handleDeletePeriod = () => {
    setStartDate(undefined);
    setEndDate(undefined);
  };
  const handleFindNew = () => {
    setFindNew(false);
  };
  const handleFindfInProgress = () => {
    setFindInProgress(false);
  };
  const handleFindResolve = () => {
    setFindResolve(false);
  };
  return (
    <div className={styleWrapper}>
      <div className={styles.boxFilter}>
        {periodBoolean && (
          <div className={styles.wrapper_date}>
            <span className={styles.wrapper_date_text}>
              {start} - {end}
            </span>
            <img src={cancelIcon} onClick={handleDeletePeriod} />
          </div>
        )}
        {findNew && (
          <Status status={TicketStatusEnum.NEW} handleDelete={handleFindNew} />
        )}
        {findInProgress && (
          <Status
            status={TicketStatusEnum.WORKING_IN_PROGRESS}
            handleDelete={handleFindfInProgress}
          />
        )}
        {findResolved && (
          <Status
            status={TicketStatusEnum.RESOLVED}
            handleDelete={handleFindResolve}
          />
        )}
      </div>
      <div className={styles.boxButton}>
        <Button
          onClick={() => setIsOpenFilter(true)}
          variant="contained"
          sx={{
            backgroundColor: "#187AC9",
          }}
        >
          <img src={filterIcon} />
          {t("common.Filter", {
            defaultValue: "Filter",
          })}
        </Button>
      </div>
    </div>
  );
};

export default OpenFilter;
