import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";
import { ApiTags } from "../api-tags";

interface UserApi {
  accessRights: {
    id: number;
    mainAccess: boolean;
    makeTransfers: boolean;
    openBankingCard: boolean;
    openFinancialAccount: boolean;
    viewAllCardsAndFinancialAccounts: boolean;
  };
  email: string;
  familyName: string;
  givenName: string;
  id: number;
  nickName: string;
  twoFactorAuthenticationEnabled: boolean;
  type: number;
  isPasswordChangeRequired?: boolean;
}


export const userApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "user",
  tagTypes: [ApiTags.GetUser],
  endpoints: (builder) => ({
    currentUserController: builder.query<UserApi, any>({
      providesTags: [ApiTags.GetUser],
      query: () => ({
        url: "api/users",
        method: "GET",
      }),
    }),
    //использую этот метод на странице login переписал на мутацию
    forLoginPageCurrentUserController: builder.mutation<UserApi, any>({
      query: ({ token }) => ({
        url: "api/users",
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }),
    }),
    loginHistory: builder.query<any, any>({
      query: ({ count }) => ({
        url: "api/users/logins",
        method: "GET",
        params: {
          count,
        },
      }),
    }),
    aprilURL: builder.mutation<string, void>({
      query: () => ({
        url: "api/users/aprilUrl",
        method: "GET",
        responseHandler: "text",
      }),
    }),
  }),
});

export const {
  useCurrentUserControllerQuery,
  useForLoginPageCurrentUserControllerMutation,
  useLoginHistoryQuery,
  useAprilURLMutation,
} = userApi;
