import { useRewardIntegrationsControllerQuery } from '../endpoints/rewardIntegrations';
import {
  getLocalStorageObject,
  LocalStorageKeys,
} from '../../shared/utils/local-storage';

interface ReturnType {
  getProductId(productName: string): Promise<string | null>;
}

export const usePartnerProductIdByName = (): ReturnType => {
  const { data: rewardIntegrations } = useRewardIntegrationsControllerQuery();

  return {
    async getProductId(productName) {
      const integration = rewardIntegrations?.find(
        (r) => r.name.toLowerCase() === productName.toLowerCase()
      );
      if (integration) {
        return fetch(integration.url, {
          headers: {
            Authorization: `Bearer ${getLocalStorageObject(
              LocalStorageKeys.AuthToken
            )}`,
          },
        }).then((r) => r.text());
      }

      return null;
    },
  };
};
