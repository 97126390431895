import { AnimateWrapper } from "components/animate-wrapper";
import styles from "./style.module.scss";
import { triggerBase64Download } from "common-base64-downloader-react";
import moment from "moment";
import { useAccount } from "pages/accountComponent/provider";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useBankingRequisitesControllerMutation } from "api/endpoints/account";
import LoadingButton from "@mui/lab/LoadingButton";
import Download from "@mui/icons-material/Download";
import { useHolderV2ControllerQuery } from "api/endpoints/account";

interface AccountDetailsProps {}

const AccountDetails = ({}: AccountDetailsProps) => {
  const { t } = useTranslationProvider();
  const { data: holder } = useHolderV2ControllerQuery();
  const { financialAccount } = useAccount();

  const [bankingRequisitesMutation, { isLoading: bankingRequisitesIsLoading }] =
    useBankingRequisitesControllerMutation();

  const items = [
    {
      label: t("AccountRoutingNumber.Account number", {
        defaultValue: "Account number",
      }),
      value: financialAccount?.accountNumber,
    },
    {
      label: t("common.Routing number", {
        defaultValue: "Routing number",
      }),
      value: financialAccount?.routingNumber,
    },
    {
      label: t("AccountRoutingNumber.Account holder", {
        defaultValue: "Account holder",
      }),
      value: holder?.businessAccountHolder?.legalBusinessName,
    },
    {
      label: t("common.Currency", {
        defaultValue: "Currency",
      }),
      value: financialAccount?.availableCash?.currencyCode,
    },
    {
      label: t("common.Name", {
        defaultValue: "Name",
      }),
      value: financialAccount?.name,
    },
    {
      label: t("AccountInfo.Opened on", {
        defaultValue: "Opened on",
      }),
      value: moment(financialAccount?.created).format("MM/DD/YYYY"),
    },
  ];

  const sendingLetter = async () => {
    const resBanking = await bankingRequisitesMutation({
      financialAccountId: financialAccount.financialAccountId,
    }).unwrap();
    if (resBanking) {
      const { content } = resBanking;
      const base64Str = content;
      const base64PDF = `data:application/pdf;base64, ${base64Str}`;
      triggerBase64Download(base64PDF, "Bank_Letter");
    }
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div>
          {items.map((item) => (
            <div key={item.value} className={styles.detailsItem}>
              <div className={styles.detailsItem_label}>{item.label}</div>
              <div className={styles.detailsItem_value}>{item.value}</div>
            </div>
          ))}
        </div>
        <div>
          <LoadingButton
            onClick={sendingLetter}
            loading={bankingRequisitesIsLoading}
            variant="contained"
            startIcon={<Download />}
          >
            Account details
          </LoadingButton>
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default AccountDetails;
