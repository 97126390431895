import { AccountsFromSelect } from "shared/components/accountsSelect";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useAccount } from "pages/accountComponent/provider";
import { useState } from "react";
import {
  Button,
  Collapse,
  FormControlLabel,
  SelectChangeEvent,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ArrowForwardIosOutlined from "assets/svg/ArrowForwardIosOutlined.svg";
import MonthCalendar from "shared/components/calendar/monthCalendar";
import { useSearchParams } from "react-router-dom";
import { Filters } from "api/endpoints/transaction";
import RangeCalendar from "shared/components/calendar/rangeCalendar";
import { RangeDatePicker } from "shared/components/calendar/RangeDatePicker";

interface SelectAccountProps {
  filter: Filters;
  handleDateChange: (any) => void;
  date: any;
  onSubmit: (any) => void;
  isRange?: boolean;
}
const SELECTED_ALL = "Select all";
const filterArrayToObject = (filter) => {
  return filter?.reduce((acc, curr) => {
    const key = curr.key;
    return (acc[key] = curr), acc;
  }, {});
};
const filterObjectToArray = (filter) => {
  return filter
    ? Object.keys(filter).map((key) => {
        return {
          key,
          ...filter[key],
        };
      })
    : [];
};

const Filter = ({
  filter,
  handleDateChange,
  date,
  onSubmit,
  isRange,
}: SelectAccountProps) => {
  const { t } = useTranslationProvider();

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterArray, setFilterArray] = useState(filterObjectToArray(filter));

  const handleSubmit = () => {
    onSubmit(filterArrayToObject(filterArray));
    setSearchParams({});
  };
  const onSelectFilter = (selectedItem) => {
    setFilterArray(
      filterArray.map((i) => {
        let value = i.value;
        if (selectedItem.displayName === SELECTED_ALL) {
          value = !selectedItem.value;
          i.items = i?.items?.map((subI) => {
            return {
              ...subI,
              value,
            };
          });
        } else if (selectedItem.displayName === i.displayName) {
          value = !selectedItem.value;
        } else if (i.displayName === SELECTED_ALL) {
          value = false;
        }

        return {
          ...i,
          value,
        };
      })
    );
  };
  const onSelectFilterMerchantCategory = (selectedItem, subItem) => {
    setFilterArray(
      filterArray.map((i) => {
        if (i.displayName === selectedItem.displayName) {
          i.items = i.items.map((subI) => {
            console.log(subI.displayName === subItem.displayName, subI);
            return {
              ...subI,
              value:
                subI.displayName === subItem.displayName
                  ? !subItem.value
                  : subI.value,
            };
          });
          const dontHasSubSelected = !i?.items?.find((i) => i.value);
          if (dontHasSubSelected) {
            i.value = false;
          }
        }
        if (i.displayName === SELECTED_ALL) {
          i.value = false;
        }
        return i;
      })
    );
  };

  const renderItem = (item, index) =>
    item.displayName && (
      <div key={index}>
        <div>
          <div>
            {!item?.items && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => onSelectFilter(item)}
                    checked={item.value}
                  />
                }
                label={item.displayName}
              />
            )}
            {item?.items && (
              <div className={styles.subitemsLabel}>
                <div className={styles.subitemsLabel_text}>
                  {item.displayName}
                </div>
                <Button onClick={() => onSelectFilter(item)}>
                  <img
                    style={item.value ? { transform: "rotate(180deg)" } : {}}
                    src={ArrowForwardIosOutlined}
                  />
                </Button>
              </div>
            )}
          </div>
        </div>
        <Collapse in={item.value}>
          <div>
            {item?.items?.map((subItem) => (
              <div key={subItem.displayName}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() =>
                        onSelectFilterMerchantCategory(item, subItem)
                      }
                      checked={subItem.value}
                    />
                  }
                  label={subItem.displayName}
                />
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    );

  return (
    <div className={styles.container}>
      {!isRange ? (
        <MonthCalendar
          disableFuture
          currentDate={date}
          onChange={handleDateChange}
          isDatapiker
        />
      ) : (
        <RangeDatePicker
          disableFuture
          currentDate={date}
          onChange={handleDateChange}
        />
      )}

      <div className={styles.itemsContainer}>
        <div className={styles.subitemsLabel}>
          <div className={styles.subitemsLabel_text}>
            {"Filter by transaction category"}
          </div>
        </div>
        {filterArray.map((item, index) => renderItem(item, index))}
      </div>
      <Button onClick={handleSubmit} variant="contained">
        <div>Apply filter</div>
      </Button>
    </div>
  );
};

export default Filter;
