import { FC } from "react";
import { useTranslationProvider } from "providers/translation/translation.provider";
import styles from "./style.module.scss";
interface ForgotNavigateProps {
  setPageMode: (boolean) => void;
}
const ForgotNavigate: FC<ForgotNavigateProps> = ({ setPageMode }) => {
  const { t } = useTranslationProvider();
  return (
    <span className={styles.forgot} onClick={() => setPageMode(true)}>
      {t("Login.Forgot", {
        defaultValue: "Forgot",
      })}{" "}
      {t("Login.Username", {
        defaultValue: "Username",
      })?.toLowerCase()}{" "}
      {t("common.or", {
        defaultValue: "or",
      })}{" "}
      {t("Login.password", {
        defaultValue: "password",
      })}
      ?
    </span>
  );
};

export default ForgotNavigate;
