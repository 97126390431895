import { Container, useMediaQuery, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
// import Footer from "../../components/footer/Footer";
import Navbar from "../../components/adaptiveNavbar/components/navbar";
import { useLocation } from "react-router-dom";
import { AnimateWrapper } from "components/animate-wrapper";
// import styles from "./style.module.scss";
import Footer from "shared/components/footer";
import styles from "./style.module.scss";
import StartMainSection from "layouts/LoginLayout/components/startMainSection";
const RegistrationLayout = () => {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  // const location = useLocation().pathname;

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div className={styles.content}>
          <StartMainSection />
          <Outlet />
        </div>
        <Footer stylesWrapper={styles.stylesWrapperFooter} />
      </div>
    </AnimateWrapper>
  );
};

export default RegistrationLayout;
