import React from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { TextInput } from 'shared/ui/inputs';
import { IconButton, Badge, Divider, List } from '@mui/material';
import { SearchIcon } from 'shared/ui/icons';
import { FilterIcon } from 'shared/ui/icons';
import styles from './style.module.scss';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { Skeleton } from 'components/skeleton';
import { Typography } from 'shared/ui/typography';
import { Transaction } from 'api/endpoints/transaction';
import { TransfersHistoryItem } from '../transfersHistoryItem';
import moment from 'moment';

interface MainProps {
  loadingPage: boolean;
  loadingList: boolean;
  transfers: Transaction[];
  searchQuery: string;
  setSearchQuery: (vlaue: string) => void;
  filtersLength: number;
  setSelectTransfer: (value: Transaction | null) => void;
}

export const Main = (props: MainProps) => {
  const {
    loadingPage,
    loadingList,
    transfers,
    searchQuery,
    setSearchQuery,
    filtersLength,
    setSelectTransfer,
  } = props;

  const { goNext } = useDrawerStepperContext();

  const filteredData = transfers?.filter((transfer) =>
    transfer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderDate = (formattedDate: string, shouldRenderDate: boolean) => {
    if (shouldRenderDate) {
      return <Typography className={styles.date}>{formattedDate}</Typography>;
    }
    return null;
  };

  const handleNavigateToDetail = (transfer: Transaction) => {
    setSelectTransfer(transfer);
    goNext('2');
  };
  const renderList = () => {
    let renderedDates: string[] = [];
    return (
      <List className={styles.list}>
        {!!filteredData.length ? (
          filteredData.map((transfer, index) => {
            const formattedDate = moment(transfer.date).format('MMMM DD, YYYY');
            const shouldRenderDate = !renderedDates.includes(formattedDate);
            const isLastItem = index !== filteredData.length - 1;

            if (shouldRenderDate) {
              renderedDates.push(formattedDate);
            }

            return (
              <React.Fragment key={transfer.id}>
                {renderDate(formattedDate, shouldRenderDate)}

                <TransfersHistoryItem
                  item={transfer}
                  key={transfer.id}
                  handleNavigate={handleNavigateToDetail}
                />
                {isLastItem && <Divider className={styles.divider} />}
              </React.Fragment>
            );
          })
        ) : (
          <>
            <Typography className={styles.date}>Recent Transfers</Typography>
            <Typography className={styles.empty}>No Transfers</Typography>
          </>
        )}
      </List>
    );
  };

  return loadingPage ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.box}>
          <TextInput
            fullWidth
            placeholder='Search'
            autoComplete='off'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <div className={styles.boxStarAdornment}>
                  <SearchIcon />
                </div>
              ),
            }}
          />

          <IconButton
            aria-label='open filter'
            onClick={() => goNext('1')}
            disabled={loadingList}
          >
            <Badge
              overlap='circular'
              badgeContent={filtersLength}
              classes={{ badge: styles.badge }}
            >
              <div className={styles.wrapIcon}>
                <FilterIcon />
              </div>
            </Badge>
          </IconButton>
        </div>
        <div>
          {loadingList ? (
            <Skeleton
              width='100%'
              height='110px'
            />
          ) : (
            renderList()
          )}
        </div>
      </div>
    </TransitionAnimation>
  );
};
