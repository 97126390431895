import * as React from 'react';
import { useMemo } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { colors } from '../../../../../theme';
import styles from '../style.module.scss';
import { DiagramTopPanel } from '../shared/diagramTopPanel';
import { SvgIcon } from '../../../../../shared/components/svgIcon';
import { CashFlowProvider, useCashFlow } from './provider';
import { PeriodInfo } from './periodInfo';
import { LinearProgress } from '@mui/material';
import cx from 'classnames';
import { centsToDollars } from '../../../../../shared/utils/price';
import { CashFlowPeriodEnum } from '../../../../../api/endpoints/financialAccounts';
import moment from 'moment';
import { categories, formatYLabel, divideSegment } from '../shared/common';
import { useDiagrams } from '../provider';


const CashFlowComponent = () => {
  const cashFlow = useCashFlow();
  const diagrams = useDiagrams();

  const dataset = useMemo(() => {
    if (!cashFlow.cashFlowApi?.data) {
      return [];
    }

    return cashFlow.cashFlowApi.data.map(ch => {
      return {
        in: ch.cashIn,
        out: ch.cashOut,
        xAxisLabel: `${ch.dateFrom}/${ch.dateTo}`,
      };
    });
  }, [cashFlow.cashFlowApi]);

  const yAxisConfig: any = useMemo(() => {
    if (!cashFlow.cashFlowApi?.data) {
      return undefined;
    }

    const maxValue = cashFlow.maxCashValue * 1.1;

    return [{
      tickInterval: divideSegment(0, maxValue),
      max: maxValue,
      valueFormatter: (value: number, context) => {
        if (context.location === 'tick' && typeof value === 'number' && value > 0) {
          if (100 >= value) {
            return formatYLabel(centsToDollars(value));
          }
          return formatYLabel(Math.round(centsToDollars(value)));
        }
        return `$${value}`;
      }
    }];
  }, [cashFlow.cashFlowApi, cashFlow.maxCashValue]);

  const xAxisConfig = useMemo(() => {
    const period = cashFlow.selectedPeriod;
    return [
      {
        scaleType: 'band',
        dataKey: 'xAxisLabel',
        categoryGapRatio: 0.18,
        barGapRatio: 0,
        valueFormatter: (value: string, context) => {
          if (cashFlow.isDataLoading) {
            return '';
          }

          const [dateFrom, dateTo] = value.split('/');
          const mdf = moment(dateFrom);
          const mdt = moment(dateTo);

          if (context.location === 'tick') {
            if (period === CashFlowPeriodEnum.D7) {
              return mdf.format('ddd');
            }

            if (period === CashFlowPeriodEnum.M1) {
              return `${mdf.format('DD')}-${mdt.format('DD')}`;
            }

            if ([
              CashFlowPeriodEnum.M6,
              CashFlowPeriodEnum.Y1,
              CashFlowPeriodEnum.QTD,
              CashFlowPeriodEnum.YTD,
            ].includes(period)) {
              return mdf.format('MMM');
            }
          }

          if (context.location === 'tooltip') {
            if (period === CashFlowPeriodEnum.D7) {
              return mdf.format('DD ddd');
            }

            if (period === CashFlowPeriodEnum.M1) {
              return `${mdf.format('MMM')} ${mdf.format('DD')}-${mdt.format('DD')}`;
            }

            if ([
              CashFlowPeriodEnum.M6,
              CashFlowPeriodEnum.Y1,
              CashFlowPeriodEnum.QTD,
              CashFlowPeriodEnum.YTD,
            ].includes(period)) {
              return mdf.format('MMM');
            }
          }

          return dateFrom;
        }
      } as any
    ];
  }, [cashFlow.cashFlowApi, cashFlow.selectedPeriod, cashFlow.isDataLoading]);

  return (
    <div className={styles.wrapper}>
      <DiagramTopPanel
        categories={categories}
        selectedCategory="Cashflow"
        isDisabled={cashFlow.isDataLoading}
        onChangePeriod={(newPeriod) => cashFlow.setPeriod(newPeriod as any)}
        selectedPeriod={cashFlow.selectedPeriod}
        periods={[
          { label: '7D', value: CashFlowPeriodEnum.D7 },
          { label: 'MTD', value: CashFlowPeriodEnum.M1 },
          { label: '6M', value: CashFlowPeriodEnum.M6 },
          { label: 'QTD', value: CashFlowPeriodEnum.QTD },
          { label: '1Y', value: CashFlowPeriodEnum.Y1 },
          { label: 'YTD', value: CashFlowPeriodEnum.YTD },
        ]}
        onChangeCategory={(newCategory) => diagrams.changeMode(newCategory)}
      />
      <div className={styles.chartWrapper}>
        <div className={cx(styles.loader, cashFlow.isDataLoading && styles.loaderActive)}>
          <LinearProgress/>
        </div>
        <PeriodInfo/>
        {cashFlow.cashFlowApi?.data?.length && cashFlow.maxCashValue ? (
          <>
            <BarChart
              sx={{
                '.MuiChartsAxis-line': {
                  display: 'none',
                },
                '.MuiChartsAxis-tick': {
                  'display': 'none'
                },
              }}
              dataset={dataset}
              series={[
                {
                  type: 'bar',
                  dataKey: 'in',
                  label: 'Cash In',
                  color: colors.green,
                  valueFormatter: (v) => `$${centsToDollars(v ?? 0)}`
                },
                {
                  type: 'bar',
                  dataKey: 'out',
                  label: 'Cash Out',
                  color: colors.lightGrey,
                  valueFormatter: (v) => `$${centsToDollars(v ?? 0)}`
                }
              ]}
              height={110}
              xAxis={xAxisConfig}
              yAxis={yAxisConfig}
              slotProps={{ legend: { hidden: true } }}
              grid={{ horizontal: true }}
              borderRadius={4}
              margin={{ top: 10, bottom: 25, left: 40, right: 5 }}
            />
            <div className={styles.hint}>
              <SvgIcon name="InfoOutlined" sizePx={19}/>
              <div>
                Data doesn’t&nbsp;include last&nbsp;2&nbsp;days.
                Authorizations and&nbsp;partially cleared transactions are&nbsp;not&nbsp;included
              </div>
            </div>
          </>
        ) : (
          <div style={{ height: '40px' }}/>
        )}
        {cashFlow.maxCashValue === 0 && !cashFlow.isDataLoading && (
          <div className={styles.emptyFallback}>no data to display</div>
        )}
      </div>
    </div>
  );
};

export const CashFlow = () => {
  return (
    <CashFlowProvider>
      <CashFlowComponent/>
    </CashFlowProvider>
  );
};
