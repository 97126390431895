import { useSearchParams } from "react-router-dom";
import CardsList from "shared/components/cardList";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Button, Skeleton } from "@mui/material";
import AccList from "shared/components/accList";
import { useSubUsersAttachedFinancialAccounts } from "api/hooksApi/useSubUsersAttachedFinancialAccounts";
interface RenderDetails {
  item: any;
  isConnectedCardsLoading?: boolean;
}

export const RenderDetails = ({ item, isConnectedCardsLoading }: RenderDetails) => {
  const { t } = useTranslationProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  const subUserId = searchParams.get("id") || "";
  const {
    subUserAttachedFinancialAccount,
    subUserAttachedFinancialAccountIsLoading,
  } = useSubUsersAttachedFinancialAccounts(subUserId);

  const handleEditInfo = () => {
    setSearchParams({
      page,
      mode: "editUser",
      id: item.id,
      title: `Edit${item.givenName}${item.familyName}`,
    });
  };

  const handleManageCards = () => {
    setSearchParams({
      page,
      mode: "manageCards",
      id: item.id,
      title: `ManageConnectedCards`,
    });
  };
  const handleManageAccounts = () => {
    setSearchParams({
      page,
      mode: "manageAccounts",
      id: item.id,
      title: `ManageConnectedAccounts`,
    });
  };

  return (
    <div className={styles.wrapperDetails}>
      <div className={styles.infoBlock}>
        <div className={styles.title}>
          <span>User information</span>
          <Button variant="text" onClick={handleEditInfo}>
            EDIT
          </Button>
        </div>
        {item.email && (
          <div className={styles.boxDetailsItems}>
            <p className={styles.detailsTitle}>
              {t("Settings.Email", {
                defaultValue: "Email",
              })}
            </p>
            <p className={styles.detailsDesription}>{item.email}</p>
          </div>
        )}
        {item.department && (
          <div className={styles.boxDetailsItems}>
            <p className={styles.detailsTitle}>
              {" "}
              {t("Settings.Department", {
                defaultValue: "Department",
              })}
            </p>
            <p className={styles.detailsDesription}>{item.department}</p>
          </div>
        )}
      </div>
      {
        <div className={styles.infoBlock}>
          <p className={styles.title}>
            <span>
              {t("Settings.Connected cards", {
                defaultValue: "Connected cards",
              })}
              {` (${item?.attachedPaymentCards?.length || 0})`}
            </span>
            <Button onClick={handleManageCards} variant="text">
              MANAGE
            </Button>
          </p>
          {isConnectedCardsLoading ? <Skeleton height="100px" /> : <CardsList cardArr={item.attachedPaymentCards} />}
        </div>
      }
      {subUserAttachedFinancialAccountIsLoading ? (
        <Skeleton height="100px" />
      ) : (
        <div className={styles.infoBlock}>
          <p className={styles.title}>
            <span>
              {t("common.Connected accounts", {
                defaultValue: "Connected accounts",
              })}
              {` (${subUserAttachedFinancialAccount?.length || 0})`}
            </span>
            <Button onClick={handleManageAccounts} variant="text">
              MANAGE
            </Button>
          </p>
          <AccList
            financialAccountsArr={subUserAttachedFinancialAccount}
            white
          />
        </div>
      )}
    </div>
  );

  return <></>;
};
