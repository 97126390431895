import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  Amount,
  FinancialAccount,
  NewExternalPayee,
  SelectScheduleTransfer,
  FrequencyTitle
} from 'entities/transfers'

type TransferAch = {
  fromFinancialAccount: FinancialAccount | null
  toPayee: FinancialAccount | null
  amount: Amount
  frequency: string
  dateTransfer: string
  selectScheduleTransfer: SelectScheduleTransfer | null
  selectExternalPayeeId: string
  newExternalPayee: NewExternalPayee
}

const initialState: TransferAch = {
  fromFinancialAccount: null,
  toPayee: null,
  amount: {
    floatValue: undefined,
    formattedValue: undefined,
    value: undefined,
  },
  frequency: FrequencyTitle.Once,
  dateTransfer: moment().toISOString(),
  selectScheduleTransfer: null,
  selectExternalPayeeId: '',
  newExternalPayee: {
    name: '',
    type: '',
    accountNumber: '',
    routingNumber: '',
  },
}

export const slice = createSlice({
  name: 'achTransfer',
  initialState,
  reducers: {
    clearDataAch: () => {
      return initialState
    },

    clearDataNewExternalPayee: (state) => {
      state.newExternalPayee = initialState.newExternalPayee
    },

    setFromFinancialAccount: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.fromFinancialAccount = payload.value
    },

    setToPayee: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.toPayee = payload.value
    },

    setAmount: (state, { payload }: PayloadAction<{ value: Amount }>) => {
      state.amount = payload.value
    },

    setSelectFrequency: (
      state,
      { payload }: PayloadAction<{ value: string }>
    ) => {
      state.frequency = payload.value
    },

    setSelectDateTransfer: (
      state,
      { payload }: PayloadAction<{ value: string }>
    ) => {
      state.dateTransfer = payload.value
    },

    setSelectScheduleTransfer: (
      state,
      { payload }: PayloadAction<{ value: SelectScheduleTransfer }>
    ) => {
      state.selectScheduleTransfer = payload.value
    },

    setSelectPayeeId: (
      state,
      { payload }: PayloadAction<{ value: string }>
    ) => {
      state.selectExternalPayeeId = payload.value
    },

    setNewExternalPayee: (
      state,
      { payload }: PayloadAction<{ value: NewExternalPayee }>
    ) => {
      state.newExternalPayee = payload.value
    },
  },
})

export const selectFromFinancialAccount = (state: RootState) =>
  state.achTransfer.fromFinancialAccount

export const selectToPayee = (state: RootState) => state.achTransfer.toPayee

export const selectAmount = (state: RootState) => state.achTransfer.amount

export const selectFrequency = (state: RootState) => state.achTransfer.frequency

export const selectDateTransfer = (state: RootState) =>
  state.achTransfer.dateTransfer

export const selectScheduleTransfer = (state: RootState) =>
  state.achTransfer.selectScheduleTransfer

export const selectExternalPayeeId = (state: RootState) =>
  state.achTransfer.selectExternalPayeeId

export const selectNewExternalPayee = (state: RootState) =>
  state.achTransfer.newExternalPayee

export const {
  clearDataAch,
  clearDataNewExternalPayee,
  setFromFinancialAccount,
  setToPayee,
  setAmount,
  setSelectFrequency,
  setSelectDateTransfer,
  setSelectScheduleTransfer,
  setSelectPayeeId,
  setNewExternalPayee,
} = slice.actions
