import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";

export interface EnumsItemApi {
  name: string;
  description: string;
  title: string;
  isSelected: boolean;
}
export interface RewardsTypesApi {
  name: string;
  id: number;
  description: string;
  groupName: null | string;
}
export const enumsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "enums",
  endpoints: (builder) => ({
    merchantCategoriesController: builder.query<EnumsItemApi[], void>({
      query: () => ({
        url: "api/enums/merchantCategory",
        method: "GET",
      }),
    }),
    countriesController: builder.query<EnumsItemApi[], void>({
      query: () => ({
        url: "api/enums/ISO3166Alpha3Country",
        method: "GET",
      }),
    }),
    deliveryCompanyController: builder.mutation<any, any>({
      query: () => ({
        url: "api/enums/paymentCardShippingMethod",
        method: "GET",
      }),
    }),
    rewardsTypesController: builder.query<RewardsTypesApi[], void>({
      query: () => ({
        url: "api/enums/rewardHistoryTypes",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useMerchantCategoriesControllerQuery,
  useCountriesControllerQuery,
  useDeliveryCompanyControllerMutation,
  useRewardsTypesControllerQuery,
} = enumsApi;
