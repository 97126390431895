import styles from "./styles.module.scss";
import RewardFilterType from "./components/rewardFilterType";
import RewardsFilterMonth from "./components/rewardsFilterMonth";
import { useSearchParams } from "react-router-dom";
import DrawerModal from "shared/components/drawerModal";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { RewardMode } from "pages/reward/enums";

const RewardsFilter = () => {
  const { t } = useTranslationProvider();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.up("sm"));
  const [searchParams, setSearchParams] = useSearchParams();
 
  const mode = searchParams.get("mode");

  const closeDrawer = () => {
     setSearchParams({ });
  };

  const renderFilters = () => (
    <div className={styles.wrapper}>
      <div>
        <RewardFilterType />
      </div>
      <div>
        <RewardsFilterMonth />
      </div>
      {!isMobile && (
        <Button
          variant="contained"
          className={styles.button}
          onClick={closeDrawer}
        >
          {t("common.Apply", {
            defaultValue: "Apply",
          })}
        </Button>
      )}
    </div>
  );

  return (
    <>
      {isMobile ? (
        renderFilters()
      ) : (
        <DrawerModal
          isShow={mode === RewardMode.Filters}
          onClose={closeDrawer}
          titleText={t("common.Filters", {
            defaultValue: "Filters",
          })}
        >
          {renderFilters()}
        </DrawerModal>
      )}
    </>
  );
};

export default RewardsFilter;
