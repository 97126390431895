import FrameLoans from "pages/loans/components/frameLoans";
import chartBannerIcon from "assets/svg/chart-horizontal.svg";
import succsessIcon from "assets/svg/lending-succses.svg";
import clockIcon from "assets/svg/clock-blue.svg";
import helper from "services/helper";

interface UseViewLoansFrameProps {
  amount?: number;
  terms?: string;
  loansRequest?: () => Promise<void>;
  loanRequestIsLoading?: boolean;
  navigateToMarketingLink?: () => Promise<void>;
  navigateToEmbededLink?: () => void;
}
export const useViewLoansFrame = ({
  amount,
  terms,
  loansRequest,
  loanRequestIsLoading,
  navigateToMarketingLink,
  navigateToEmbededLink,
}: UseViewLoansFrameProps) => {
  const imgStylesChart = {
    bottom: "-3px",
    right: "7px",
    width: "160px",
    height: "140px",
  };
  const imgStylesSuccsess = {
    bottom: "0px",
    right: "0px",
    width: "109px",
    height: "109px",
  };

  const wrapperStyles = {
    marginTop: "21px",
    marginLeft: "0px",
  };
  const requestALoan = //1
    (
      <FrameLoans
        title={"Request a loan"}
        img={chartBannerIcon}
        imgStyles={imgStylesChart}
        subTitle={
          "Your loan offers will be displayed here once they become available. In the meantime, you can request a loan by clicking on the button below."
        }
        buttonLabel={"Request a loan"}
        onSubmit={loansRequest}
        isLoading={loanRequestIsLoading}
        buttonArrow
      />
    );
  const specialLoan = //1
    (
      <FrameLoans
        title={"Special loan offer is coming!"}
        img={succsessIcon}
        imgStyles={imgStylesSuccsess}
        subTitle={
          "Get ready for tailored loan offer coming your way soon! Your loan offer will be displayed here once it`s ready, and you`ll have the option to submit your application."
        }
      />
    );
  const submitloanApplication = //2
    (
      <FrameLoans
        title={"Submit loan application"}
        img={chartBannerIcon}
        imgStyles={imgStylesChart}
        subTitle={`Congratulations! You’re pre-selected for an exclusive $${
          amount && helper.moneyFormat(amount * 100)
        } opportunity. Explore a competitive interest rate of 9.5%-18% for a ${terms}-month term.`}
        buttonLabel={"Apply now"}
        onSubmit={navigateToMarketingLink}
        buttonArrow
      />
    );
  const loanInProgress = //2
    (
      <FrameLoans
        title={"Loan application in progress"}
        img={chartBannerIcon}
        imgStyles={imgStylesChart}
        subTitle={
          "You`ve initiated your loan application but haven`t completed it yet. To continue and finalize your application, please click the button below."
        }
        buttonLabel={"Continue application"}
        onSubmit={navigateToMarketingLink}
        buttonArrow
      />
    );
  const loanInReview = //2
    (
      <FrameLoans
        title={"Loan application in review"}
        img={clockIcon}
        imgStyles={imgStylesSuccsess}
        subTitle={
          "Your loan application has been successfully submitted and is currently under review. We appreciate your patience, and you will be notified of the decision shortly."
        }
        // buttonLabel={"Cancel application"}
      />
    );
  const loanInformation = (
    <FrameLoans
      title={"Your loan information"}
      img={succsessIcon}
      imgStyles={imgStylesSuccsess}
      wrapperStyles={wrapperStyles}
      subTitle={
        "Congratulations! Your loan has been approved. Click the button below to view the details of your loan."
      }
      buttonLabel={"View loan"}
      onSubmit={navigateToEmbededLink}
      buttonStyles={{ marginTop: "20px" }}
      buttonArrow
    />
  );
  return {
    requestALoan,
    specialLoan,
    submitloanApplication,
    loanInProgress,
    loanInReview,
    loanInformation,
  };
};
