import { Outlet } from "react-router-dom";
import Footer from "shared/components/footer";
import styles from "./style.module.scss";
import StartMainSection from "./components/startMainSection";
import { AnimateWrapper } from "components/animate-wrapper";
import { PropsWithChildren } from 'react';

const LoginLayout = () => {
  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div className={styles.content}>
          <StartMainSection />
          <Outlet />
        </div>
        <Footer stylesWrapper={styles.stylesWrapperFooter} />
      </div>
    </AnimateWrapper>
  );
};

export const LoginLayoutRender = ({ children }: PropsWithChildren) => {
  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div className={styles.content}>
          <StartMainSection />
          {children}
        </div>
        <Footer stylesWrapper={styles.stylesWrapperFooter} />
      </div>
    </AnimateWrapper>
  );
};

export default LoginLayout;
