import { useState } from 'react';
import { Button } from '@mui/material';
import styles from './styles.module.scss';
import socialdialogIcon from 'assets/svg/social-dialog-blue.svg';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import DrawerModal from 'shared/components/drawerModal';
import { useSearchParams } from 'react-router-dom';
import { RewardMode } from 'pages/reward/enums';
import { useBoolean } from 'shared/hooks/useBoolean';
import { RewardTransfer } from './components/rewardTransfer';

const TransferPoints = () => {
  const { t } = useTranslationProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get('mode') || '';
  const showAuntificatorBool = useBoolean();

  const [pointTo, setPointsTo] = useState<string>('');

  const handleClickButton = (mode: string) => {
    setSearchParams({ mode });
  };

  const onClose = () => {
    setPointsTo('');
    showAuntificatorBool.setFalse();
    setSearchParams({});
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <img
          className={styles.title_img}
          src={socialdialogIcon}
          alt='Dialog'
        />
        <span className={styles.title_text}>
          {t('Rewards.Transfer points', {
            defaultValue: 'Transfer points',
          })}
        </span>
      </div>
      <p className={styles.subTitle}>
        {t('Rewards.Send your points ', {
          defaultValue:
            'Send your points to other Netevia’s customers with ease',
        })}
      </p>
      <div className={styles.boxButton}>
        <Button onClick={() => handleClickButton(RewardMode.TransferPoints)}>
          {t('Transfers.Transfer', {
            defaultValue: 'Transfer',
          })}
        </Button>
      </div>
      <DrawerModal
        isShow={mode === RewardMode.TransferPoints}
        onClose={() => onClose()}
        titleText={t('Rewards.Transfer points', {
          defaultValue: 'Transfer points',
        })}
        goBack={
          showAuntificatorBool.value
            ? () => showAuntificatorBool.setFalse()
            : undefined
        }
      >
        <RewardTransfer
          showAuntificatorBool={showAuntificatorBool}
          pointTo={pointTo}
          setPointsTo={setPointsTo}
        />
      </DrawerModal>
    </div>
  );
};

export default TransferPoints;
