import { useState, useEffect } from 'react'
import styles from './style.module.scss'
import { ListButton } from 'shared/ui/lists'
import { ChevronRightIcon } from 'shared/ui/icons'
import { Button } from 'shared/ui/buttons'
import { ToMyOwnAccount } from 'features/transfers/transfer-to-my-own-account'
import { useAppDispatch } from 'shared/models'
import { clearDataToMyOwnAccount } from 'features/transfers/transfer-to-my-own-account/model'
import { ToNeteviaCustomer } from 'features/transfers/transfer-to-netevia-customer'
import { clearDataToNeteviaCustomer } from 'features/transfers/transfer-to-netevia-customer/model'
import { getNextKey } from 'entities/transfers'
import { AchTransfer } from 'features/transfers/transfer-ach'
import { clearDataAch } from 'features/transfers/transfer-ach/model'
import { TransitionAnimation } from 'shared/ui/animations'
import {
  GroupPersonIcon,
  BankIcon,
  ProfileInCircleIcon,
  RefreshIcon,
  SwapIcon,
} from 'shared/ui/icons'
import { FromConnectedAccount } from 'features/transfers/transfer-from-connected-account'
import { clearDataFromConnectedAccount } from 'features/transfers/transfer-from-connected-account/model'
import { useNavigate,useSearchParams} from 'react-router-dom'
import { appRoutes, TransferRoute,TransferQs } from 'routes'
import { clearDataBatch } from 'features/transfers/transfer-batch/model'
import { ClockRestoreIcon } from 'shared/ui/icons'
import { TransferHistory } from './components/transferHistory'
import { useBoolean } from 'shared/hooks/useBoolean'

const Transfer = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams();
  const currentMode = searchParams.get("mode") || "";
  const step = searchParams.get("step") || "";
  const [showToMyOwnAccount, setShowToMyOwnAccount] = useState<boolean>(false)
  const [keyToMyOwnAccount, setKeyToMyOwnAccount] = useState('toMyOwnAccount_1')

  const [showToNeteviaCustomer, setShowToNeteviaCustomer] =
    useState<boolean>(false)
  const [keyToNeteviaCustomer, setKeyToNeteviaCustomer] = useState(
    'toNeteviaCustomer_1'
  )

  const [showAchTransfer, setShowAchTransfer] = useState<boolean>(false)
  const [keyAchTransfer, setKeyAchTransfer] = useState('achTransfer_1')

  const [showFromConnectedAccount, setShowFromConnectedAccount] =
    useState<boolean>(false ||currentMode === TransferQs.fromConnectedAccount)
  const [keyFromConnectedAccount, setKeyFromConnectedAccount] = useState(
    'fromConnectedAccount_1'
  )

const showTransferHistoryBool = useBoolean()

  const handleCloseToMyOwnAccount = () => {
    dispatch(clearDataToMyOwnAccount())
    setKeyToMyOwnAccount((prevKey) => getNextKey(prevKey))
    setShowToMyOwnAccount(false)
  }

  const handleCloseToNeteviaCustomer = () => {
    dispatch(clearDataToNeteviaCustomer())
    setKeyToNeteviaCustomer((prevKey) => getNextKey(prevKey))
    setShowToNeteviaCustomer(false)
  }
  const handleCloseAchTransfer = () => {
    dispatch(clearDataAch())
    setKeyAchTransfer((prevKey) => getNextKey(prevKey))
    setShowAchTransfer(false)
  }
  const handleCloseFromConnectedAccount = () => {
    dispatch(clearDataFromConnectedAccount())
    setKeyFromConnectedAccount((prevKey) => getNextKey(prevKey))
    setShowFromConnectedAccount(false)
    setSearchParams({})
  }
  const handleNavigateToBatchTransfer = () => {
    dispatch(clearDataBatch())
    navigate(appRoutes.transfer(TransferRoute.batchTransfer))
  }
  return (
    <TransitionAnimation>
      <ToMyOwnAccount
        key={keyToMyOwnAccount}
        isShow={showToMyOwnAccount}
        onClose={handleCloseToMyOwnAccount}
      />
      <ToNeteviaCustomer
        key={keyToNeteviaCustomer}
        isShow={showToNeteviaCustomer}
        onClose={handleCloseToNeteviaCustomer}
      />
      <AchTransfer
        key={keyAchTransfer}
        isShow={showAchTransfer}
        onClose={handleCloseAchTransfer}
      />

      <FromConnectedAccount
        key={keyFromConnectedAccount}
        isShow={showFromConnectedAccount}
        onClose={handleCloseFromConnectedAccount}
        startStep={step}
      />
      <TransferHistory
      isShow={showTransferHistoryBool.value}
      onClose={() => showTransferHistoryBool.setFalse()}
      />
      <div className={styles.container}>
        <span>Transfer</span>
        <div>
          <span>Within Netevia</span>
          <ListButton>
            <Button
              startIcon={<RefreshIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => setShowToMyOwnAccount(true)}
            >
              Between own accounts
            </Button>
            <Button
              startIcon={<ProfileInCircleIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => setShowToNeteviaCustomer(true)}
            >
              To Netevia’s customer
            </Button>
          </ListButton>
        </div>
        <div>
          <span>Outside Netevia</span>
          <ListButton>
            <Button
              startIcon={<BankIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => setShowFromConnectedAccount(true)}
            >
              From connected account
            </Button>
            <Button
              startIcon={<SwapIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => setShowAchTransfer(true)}
            >
              ACH transfer
            </Button>
          </ListButton>
        </div>
        <div>
          <span>Other</span>
          <ListButton>
            <Button
              startIcon={<GroupPersonIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={handleNavigateToBatchTransfer}
            >
              Batch transfer
            </Button>
          </ListButton>
        </div>
        <div>
          <span>History</span>
          <ListButton>
            <Button
              startIcon={<ClockRestoreIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => showTransferHistoryBool.setTrue()}
            >
              Transfer history
            </Button>
          </ListButton>
        </div>
      </div>
    </TransitionAnimation>
  )
}

export default Transfer
