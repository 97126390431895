import { FC } from "react";
import { useEffect, useState } from "react";
import {
  useViewPaymentCardControllerMutation,
  ViewPaymentCardInfo,
} from "../../../api/endpoints/paymentCards";
import { useCurrentUser } from "../../../providers/user.provider";
import { ButtonActivateCard } from "./components/buttonActivateCard";
import cx from "classnames";
import styles from "./style.module.scss";
import CardHeader from "./components/cardHeader";
import CardNumberLineComponent from "./components/cardNumberLineComponent";
import { useSnackBar } from "providers/snackBar.provider";
import CardFooter from "./components/cardFooter";
import { AnimateWrapper } from "components/animate-wrapper";
import CardBadge from "./components/cardBadge";
import { CardViewProps } from './model';
import { PartnerCard } from './partnerCard';

const Card: FC<CardViewProps> = (props) => {
  const {
    last4,
    cardholder,
    formFactor,
    cardType,
    cardId,
    isClosed,
    isAuthorizedUser,
    isLocked,
    isActivate,
    getCardInfo,
    hideActivateBage,
    isCardLoading,
  } = props;
  const { isSubUser, user, subUserRights } = useCurrentUser();
  const { setSnackBar } = useSnackBar();
  const paymentCardId = cardId;

  const [cardInfo, setCardInfo] = useState<ViewPaymentCardInfo | undefined>();
  const [isDataShowing, setDataShowing] = useState(false);
  const [cardRequisitesMutation, { isLoading: cardInfoIsLoading }] =
    useViewPaymentCardControllerMutation();
  const subUserCardHolder = `${isAuthorizedUser?.givenName} ${isAuthorizedUser?.familyName}`;
  const cardHolder = !!isAuthorizedUser ? subUserCardHolder : cardholder;

  const isSubUserCard =
    isSubUser && isAuthorizedUser?.subProfileId === user?.id;
  const aceessActivateCard =
    !isSubUser || subUserRights({ mainAccess: true }) || isSubUserCard;

  useEffect(() => {
    if (isDataShowing) {
      getCardInformation();
    }
  }, [isDataShowing]);

  const getCardInformation = async () => {
    try {
      if (!cardInfo) {
        const res = await cardRequisitesMutation({ paymentCardId }).unwrap();
        setCardInfo(res.node);
      }
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const handleShowData = (event) => {
    event.stopPropagation();
    setDataShowing(!isDataShowing);
  };

  const handleCopyCardNumber = (cardNumber: string) => {
    navigator.clipboard.writeText(cardNumber);
    setSnackBar({
      type: "success",
      message: "Data copied!",
      isShow: true,
    });
  };

  const shortenedCardHolder = (cardHolder) =>
    cardHolder?.length > 23 ? cardHolder.slice(0, 20) : cardHolder;

  const checkBurnerCard = cardType === 2;
  const cardWrapper = cx(
    styles.container,
    checkBurnerCard ? styles.bgBurnerCard : styles.bgCard,
    isClosed ? styles.opacity70 : styles.opacity100
    // isActivate && aceessActivateCard ? styles.blur : undefined
  );

  if (cardInfoIsLoading || isCardLoading) {
    return <div className={styles.loadingCard} />
  }

  if (props.partnerName && props.partnerIcons?.length) {
    return (
      <PartnerCard
        handleCopyCardNumber={handleCopyCardNumber}
        isDataShowing={isDataShowing}
        originalProps={props}
        cardInfo={cardInfo}
        handleShowData={handleShowData}
        aceessActivateCard={aceessActivateCard}
        isActivate={!!isActivate}
      />
    );
  }

  return (
    <AnimateWrapper className="fade">
      <div className={cardWrapper}>
        <CardBadge
          isClosed={isClosed}
          isAuthorizedUser={isAuthorizedUser}
          isLocked={isLocked}
          isActivate={isActivate}
        />
        {isActivate && aceessActivateCard && (
          <ButtonActivateCard cardId={cardId} getCardInfo={getCardInfo} />
        )}
        <CardHeader
          isBurner={checkBurnerCard}
          formFactor={formFactor}
          cardHolder={shortenedCardHolder(cardHolder)}
        />
        <CardNumberLineComponent
          last4={last4}
          isShow={isDataShowing}
          copy={handleCopyCardNumber}
          cardNumber={cardInfo?.restrictedDetails?.number!}
          cardCvv={cardInfo?.restrictedDetails?.cvv!}
          cardExpirationDate={cardInfo?.expirationDate!}
        />
        <CardFooter
          handleShowData={handleShowData}
          isDataShowing={isDataShowing}
        />
      </div>
    </AnimateWrapper>
  );
};

export default Card;
