import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Amount, FinancialAccount } from 'entities/transfers'

export type FormValues = {
  fromFinancialAccount: FinancialAccount | null
  toFinancialAccount: FinancialAccount | null
  amount: Amount
}

const initialState: FormValues = {
  fromFinancialAccount: null,
  toFinancialAccount: null,
  amount: {
    floatValue: undefined,
    formattedValue: undefined,
    value: undefined,
  },
}

export const slice = createSlice({
  name: 'toMyOwnAccount',
  initialState,
  reducers: {
    clearDataToMyOwnAccount: () => {
      return initialState
    },
    setFromFinancialAccount: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.fromFinancialAccount = payload.value
    },
    setToFinancialAccount: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.toFinancialAccount = payload.value
    },
    setAmount: (state, { payload }: PayloadAction<{ value: Amount }>) => {
      state.amount = payload.value
    },
  },
})

export const selectFromFinancialAccount = (state: RootState) =>
  state.toMyOwnAccount.fromFinancialAccount

export const selectToFinancialAccount = (state: RootState) =>
  state.toMyOwnAccount.toFinancialAccount

export const selectAmount = (state: RootState) => state.toMyOwnAccount.amount

export const {
  clearDataToMyOwnAccount,
  setFromFinancialAccount,
  setToFinancialAccount,
  setAmount,
} = slice.actions
