import { FilterCards } from 'pages/accountComponent/types'
import { PaymentCard, EnumCardStatuses } from 'api/endpoints/paymentCards'

export const filteredCards = (cards: PaymentCard[], filter: FilterCards): PaymentCard[] => {
    switch (filter) {
      case FilterCards.MyCards:
        return cards.filter(card =>
          card.status !== EnumCardStatuses.CLOSED && card.subProfile === null
        );
  
      case FilterCards.AllAvaibleCards:
        return cards.filter(card =>
          card.status !== EnumCardStatuses.CLOSED
        );
  
      case FilterCards.ClosedCards:
        return cards.filter(card =>
          card.status === EnumCardStatuses.CLOSED
        );
  
      default:
        return cards;
    }
  };