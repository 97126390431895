import { z } from 'zod'

export const ScheduleTransferSchema = z
  .object({
    fromFinancialAccount: z.string().min(1),
    toAccount: z.string().min(1),
    amount: z
      .object({
        value: z.string(),
        formattedValue: z.string(),
        floatValue: z.number(),
      })
      .required(),
    frequency: z.string(),
    dateTransfer: z.string(),
  })
  .refine((data) => data.amount?.floatValue !== 0, {
    message: 'Float value must not be zero',
    path: ['amount'],
  })

export type ScheduleTransfer = z.infer<typeof ScheduleTransferSchema>
