import { useNavigate, useSearchParams } from 'react-router-dom';
import { AnimateWrapper } from 'components/animate-wrapper';
import SubUserListItem from './components/SubUserListItem';
import styles from './style.module.scss';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import AddButton from '../components/AddButton';
import DrawerModal from 'shared/components/drawerModal';
import EditAuthorizedSubUser from '../editAuthorizedSubUser';
import ChooseCardsToConnectSubUser from 'components/chooseCardsToConnectSubUser';
import { useSubUsersControllerQuery } from 'api/endpoints/subProfiles';
import ChooseFinAccToConnectSubUser from 'components/chooseFinAccToConnectSubUser';
import CreateNewAutorizedSubUser from '../createNewAutorizedSubUser';
import SnackBarSuccess from 'shared/components/snackBar/snackBarSuccess';
import SnackBarWarning from 'shared/components/snackBar/snackBarWarning';
import { useState } from 'react';

const AuthorizedUser = () => {
  const { t } = useTranslationProvider();
  const { data: subUsers, isLoading: subUsersIsLoading } =
    useSubUsersControllerQuery();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '';
  const subUserId = searchParams.get('id') || '';
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const subUser = subUsers?.find((user) => user.id === Number(subUserId));
  const navigate = useNavigate();
  const handleNvigate = () => {
    navigate('/settings/subuser/create-new-authorized-user');
  };

  const handleCreateUser = () => {
    setSearchParams({
      page,
      mode: 'createUser',
      title: `CreateNewAuthorizedUser`,
    });
  };

  const handleShowInfo = () => {
    setSearchParams({
      page,
      mode: 'details',
      id: subUserId,
      title: `AuthorizedUser${subUser?.givenName}${subUser?.familyName}`,
    });
  };

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.wrapper}>
        <AddButton
          label='+ Add new authorized user'
          onClick={handleCreateUser}
        />

        <SubUserListItem />
        <DrawerModal
          isShow={searchParams.get('mode') === 'createUser'}
          onClose={() => setSearchParams({ page })}
        >
          <CreateNewAutorizedSubUser setSuccessMessage={setSuccessMessage} />
        </DrawerModal>
        <DrawerModal
          isShow={searchParams.get('mode') === 'editUser'}
          onClose={() => setSearchParams({ page })}
          goBack={handleShowInfo}
        >
          <EditAuthorizedSubUser setSuccessMessage={setSuccessMessage} />
        </DrawerModal>
        <DrawerModal
          isShow={searchParams.get('mode') === 'manageCards'}
          onClose={() => setSearchParams({ page })}
          goBack={handleShowInfo}
        >
          <ChooseCardsToConnectSubUser
            setPageStatus={handleShowInfo}
            backRoute={'EditSubUserCard'}
            setNewCardId={() => {}}
          />
        </DrawerModal>
        <DrawerModal
          isShow={searchParams.get('mode') === 'manageAccounts'}
          onClose={() => setSearchParams({ page })}
          goBack={handleShowInfo}
        >
          <ChooseFinAccToConnectSubUser
            setPageStatus={handleShowInfo}
            backRoute='EditSubUserAcc'
          />
        </DrawerModal>
        <SnackBarSuccess
          isOpen={!!successMessage}
          message={successMessage}
          onClose={() => setSuccessMessage('')}
        />
        <SnackBarWarning
          isOpen={!!errorMessage}
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      </div>
    </AnimateWrapper>
  );
};

export default AuthorizedUser;
