import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";
import { ApiTags } from "../api-tags";
import { Ticket } from "../../pages/support/history/types";

interface UploadTicketAttachments {
  ticketId: number;
  fileName: string;
  contentType: string;
  fileBase64: unknown;
}
interface UploadTicketMessage {
  ticketId: number;
  text: string;
}
export interface ActiveUpdateFieldRequestsApi {
  Email: number;
  Phone: number;
  Address: number;
  PrimAuthEmail: number;
  PrimAuthPhone: number;
  PrimAuthAddress: number;
  Owner1Email: number;
  Owner1Phone: number;
  Owner1Address: number;
  Owner2Email: number;
  Owner2Phone: number;
  Owner2Address: number;
  Owner3Email: number;
  Owner3Phone: number;
  Owner3Address: number;
  Owner4Email: number;
  Owner4Phone: number;
  Owner4Address: number;
}

export const ticketsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "tickets",
  tagTypes: [ApiTags.GetTickets, ApiTags.GetTicketById],
  endpoints: (builder) => ({
    uploadTicketAttachmentController: builder.mutation<
      any,
      UploadTicketAttachments
    >({
      invalidatesTags: [ApiTags.GetTickets],
      query: (data) => ({
        url: "api/tickets/attachment",
        method: "POST",
        body: data,
      }),
    }),
    getAllTicketsController: builder.query<Ticket[], any>({
      providesTags: [ApiTags.GetTickets],
      query: () => ({
        url: "api/tickets/all",
        method: "GET",
      }),
    }),
    getTicketController: builder.query<any, any>({
      providesTags: [ApiTags.GetTicketById],
      query: ({ ticketId }) => ({
        url: `api/tickets`,
        method: "GET",
        params: {
          ticketId,
        },
      }),
    }),
    uploadTicketMessageController: builder.mutation<any, UploadTicketMessage>({
      invalidatesTags: [ApiTags.GetTickets, ApiTags.GetTicketById],
      query: (data) => ({
        url: "api/tickets/message",
        method: "POST",
        body: data,
      }),
    }),
    getActiveUpdateFieldRequests: builder.query<
      ActiveUpdateFieldRequestsApi,
      void
    >({
      query: () => ({
        url: `api/tickets/activeUpdateFieldRequests`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useUploadTicketAttachmentControllerMutation,
  useGetAllTicketsControllerQuery,
  useGetTicketControllerQuery,
  useUploadTicketMessageControllerMutation,
  useGetActiveUpdateFieldRequestsQuery,
} = ticketsApi;
