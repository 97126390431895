import { Button } from 'shared/ui/buttons'
import { ChevronRightIcon } from 'shared/ui/icons'
import { PersonSettingIcon } from 'shared/ui/icons'
import { ButtonGroup } from 'shared/ui/button-groups'
import styles from './styles.module.scss'
import { TransitionAnimation } from 'shared/ui/animations'
import { ListButton } from 'shared/ui/lists'
import { Typography } from 'shared/ui/typography'
import { useGetScheduledTransferQuery } from 'api/endpoints/scheduledTransfer'
import { useExternalPayees } from 'api/hooksApi/useExternalPayees'
import { findObjById, findFrequencyByValue } from 'entities/transfers'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import { ScheduledTransfer } from 'entities/transfers'
import { EmptyScheduledTransfer } from 'entities/transfers'
import { Skeleton } from 'components/skeleton'
import { ClockIcon } from 'shared/ui/icons'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { useAppDispatch } from 'shared/models'
import { setSelectScheduleTransfer } from '../../model'
import { createFormatAmountObj } from 'entities/transfers'
import { FinancialAccount } from 'entities/transfers'
import { ScheduleTransferType } from 'entities/transfers'
import { filteredScheduleTransferByType } from 'entities/transfers'

export const Manage = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()

  const { data: scheduledTransfers, isLoading: isLoadingScheduledTransfers } =
    useGetScheduledTransferQuery()

  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts()

  const { payees, isLoadingPayees } = useExternalPayees()

  const labeleForList = (
    <div className={styles.titleForList}>
      <ClockIcon />
      <Typography>Scheduled transfers</Typography>
    </div>
  )

  const handleNavigateToTransferDetail = (
    id: number,
    fromAccount: FinancialAccount,
    toAccount: FinancialAccount,
    amount: number,
    frequency: string,
    transferDate: string
  ) => {
    dispatch(
      setSelectScheduleTransfer({
        value: {
          id,
          fromAccount,
          toAccount,
          amount: createFormatAmountObj(amount),
          frequency,
          dateTransfer: transferDate,
        },
      })
    )

    goNext('4')
  }

  const renderScheduledTransferList = () => {
    const outcommingAchTrasfers = 
      filteredScheduleTransferByType(scheduledTransfers ?? [], ScheduleTransferType.OutcommingAchTrasfer)
      
    return !!outcommingAchTrasfers?.length ? (
      <ListButton
        label={labeleForList}
        className={styles.list}
      >
        {outcommingAchTrasfers.map((transfer) => {
          const from = findObjById(
            transfer.fromFinancialAccountId,
            unclosedFinancialAccounts
          )
          const to = findObjById(transfer.toFinancialAccountId, payees)

          return (
            <ScheduledTransfer
              key={transfer.id}
              id={transfer.id}
              fromAccount={from}
              toAccount={to}
              transferDate={transfer.nextDate || ''}
              amount={transfer.amount}
              frequency={findFrequencyByValue(transfer.frequency) || ''}
              handleNavigate={handleNavigateToTransferDetail}
            />
          )
        })}
      </ListButton>
    ) : (
      <EmptyScheduledTransfer />
    )
  }
  const isLoading =
    isLoadingScheduledTransfers || isLoadingPayees || financialAccountsIsLoading
  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <ButtonGroup>
          <Button
            startIcon={<PersonSettingIcon />}
            endIcon={<ChevronRightIcon />}
            className={styles.btnPayees}
            onClick={() => goNext('8')}
          >
            Manage payees
          </Button>
        </ButtonGroup>
        {isLoading ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          renderScheduledTransferList()
        )}
      </div>
    </TransitionAnimation>
  )
}
