import emtyIcon from "assets/svg/bank-gray.svg";
import styled, { css } from "styled-components";

const EmtyItem = () => {
  return (
    <Wrapper>
      <img src={emtyIcon} />
      <p>You don`t have any financial accounts</p>
      <p>Your financial accounts will be displayed here once added.</p>
    </Wrapper>
  );
};
export default EmtyItem;

const Wrapper = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    gap: 8px;
    margin-top: 24px;
    margin-left: 24px;

    & > *:nth-child(1) {
      width: 24px;
      height: 24px;
    }
    & > *:nth-child(2) {
      font-family: "Noto Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.15px;
    }
    & > *:nth-child(3) {
      color: rgba(0, 0, 0, 0.6);
      font-family: "Noto Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 143%;
      letter-spacing: 0.17px;
    }
    ${theme.breakpoints.down("sm")} {
      padding: 0px 16px;
      margin-top: 0px;
      margin-left: 0px;
    }
  `}
`;
