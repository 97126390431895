import { FC } from "react";
import styles from "./style.module.scss";
import deviceIcon from "assets/svg/device-grayColor.svg";
import Button from "@mui/material/Button";
import { useTranslationProvider } from "providers/translation/translation.provider";

interface DevicesItemProps {
  id: string;
  name: string;
  date: string;
  handleDelete?: (id: string) => void;
}

export const DevicesItem: FC<DevicesItemProps> = ({
  id,
  name,
  date,
  handleDelete,
}) => {
  const { t } = useTranslationProvider();
  return (
    <div className={styles.wrapper}>
      <img src={deviceIcon} />
      <div className={styles.box}>
        <div className={styles.deviceInfo}>
          <span className={styles.deviceInfo_name}>{name}</span>
          <span className={styles.deviceInfo_date}>{date}</span>
        </div>
        {handleDelete && (
          <Button
            variant="outlined"
            className={styles.button}
            onClick={() => handleDelete(id)}
          >
            <span className={styles.button_label}>
              {t("Settings.Delete device", {
                defaultValue: "Delete device",
              })}
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};
