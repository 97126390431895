import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import { useTranslationProvider } from "providers/translation/translation.provider";
import HistoryListItem from "./components/historyListItem";
import { useSupportHistory } from "api/hooksApi/useSupportHistory";
import { Skeleton } from "components/skeleton";
import Filter from "./components/filter";
import OpenFilter from "./components/openFilter";

const History = () => {
  const { t } = useTranslationProvider();
  const { history, historyIsLoading } = useSupportHistory();
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [tickets, setTickets] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [findNew, setFindNew] = useState<boolean>(false);
  const [findInProgress, setFindInProgress] = useState<boolean>(false);
  const [findResolved, setFindResolve] = useState<boolean>(false);

  useEffect(() => {
    if (history) {
      setTickets(history);
    }
  }, [history]);

  useEffect(() => {
    if (!isOpenFilter) {
      filtered();
    }
  }, [startDate, endDate, findNew, findInProgress, findResolved]);

  const filtered = () => {
    let filteredData = history;
    if (startDate && endDate) {
      filteredData = filteredByDate();
    }
    if (findNew || findInProgress || findResolved) {
      filteredData = filteredByType(filteredData);
    }
    setTickets(filteredData);
    setIsOpenFilter(false);
  };
  const filteredByDate = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return history.filter((item) => {
      const itemDate = new Date(item.createdDate);
      return itemDate >= start && itemDate <= end;
    });
  };

  const filteredByType = (data) => {
    return data.filter((item) => {
      return (
        (findNew && item.status === "New") ||
        (findInProgress &&
          (item.status === "Pending" ||
            item.status === "Working in progress")) ||
        (findResolved && item.status === "Resolved")
      );
    });
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <OpenFilter
          setIsOpenFilter={setIsOpenFilter}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          findNew={findNew}
          setFindNew={setFindNew}
          findInProgress={findInProgress}
          setFindInProgress={setFindInProgress}
          findResolved={findResolved}
          setFindResolve={setFindResolve}
        />
        {historyIsLoading ? (
          <Skeleton width="100%" height="110px" />
        ) : (
          <HistoryListItem historyArr={tickets} />
        )}

        {isOpenFilter && (
          <Filter
            setIsOpen={setIsOpenFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            findNew={findNew}
            setFindNew={setFindNew}
            findInProgress={findInProgress}
            setFindInProgress={setFindInProgress}
            findResolved={findResolved}
            setFindResolve={setFindResolve}
            filtered={filtered}
            setTickets={setTickets}
          />
        )}
      </div>
    </AnimateWrapper>
  );
};

export default History;
