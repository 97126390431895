import {
  useFindPaymentCardWithInfoControllerMutation,
  useAttachedWithInfoPaymentCardsControllerMutation,
} from "../endpoints/paymentCards";
import helper from "../../services/helper";

interface PaymentCardByIdReturnType {
  paymentCardById(paymentCardId: string): Promise<PaymentCardDetails>;
  paymentCardByIdIsLoading: boolean;
  paymentCardByIdIsSuccess: boolean;
}

interface FinancialAccountFeature {
  __typename: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  id: string;
}

interface LedgerBalance {
  id: string;
  name: string;
  creditBalance: {
    currencyCode: string;
    value: number;
  };
  debitBalance: {
    currencyCode: string;
    value: number;
  };
  normalBalance: string;
}

interface PhysicalPaymentCardOrder {
  __typename: string;
  id: string;
  orderState: {
    status: string;
  };
  cardPersonalization: {
    textLines: {
      line1: string;
      line2: string;
    };
  };
  paymentCardShipment: {
    courier: {
      method: string;
      signatureRequiredOnDelivery: boolean;
      tracking: string | null;
    };
    requestedShipDate: string;
    senderDetails: {
      name: {
        givenName: string;
        middleName: string;
        familyName: string;
      };
      companyName: string;
      address: {
        streetAddress: string;
        extendedAddress: string;
        postalCode: string;
        region: string;
        locality: string;
        countryCodeAlpha3: string;
      };
    };
    deliveryDetails: {
      name: {
        givenName: string;
        middleName: string;
        familyName: string;
      };
      companyName: string;
      address: {
        streetAddress: string;
        extendedAddress: string;
        postalCode: string;
        region: string;
        locality: string;
        countryCodeAlpha3: string;
      };
    };
  };
  stateHistory: {
    previousStatus: string | null;
    newStatus: string;
    createdAt: string;
  }[];
  paymentCard: {
    id: string;
    bin: string;
    last4: string;
  };
  createdAt: string;
  updatedAt: string;
}

interface BusinessAccountHolderSnapshot {
  __typename: string;
  primaryAuthorizedPerson: {
    name: {
      familyName: string;
      givenName: string;
      middleName: string;
      title: string;
      suffix: string;
    };
    homeAddress: {
      streetAddress: string;
      extendedAddress: string;
      locality: string;
      postalCode: string;
      region: string;
      countryCodeAlpha3: string;
    };
  };
  businessProfile: {
    name: {
      doingBusinessAsName: string;
      legalBusinessName: string;
    };
  };
  accountHolderCurrent: {
    id: string;
    businessProfile: {
      billingAddress: {
        streetAddress: string;
        extendedAddress: string;
        locality: string;
        postalCode: string;
        region: string;
        countryCodeAlpha3: string;
      };
    };
    primaryAuthorizedPerson: {
      homeAddress: {
        streetAddress: string;
        extendedAddress: string;
        locality: string;
        postalCode: string;
        region: string;
        countryCodeAlpha3: string;
      };
    };
  };
}

interface AuthorizationAndClearEvent {
  __typename: string;
  id: string;
  createdAt: string;
  responseCode: string;
  approvedAmount: {
    value: number;
    currencyCode: string;
  };
  transaction: {
    __typename: string;
    id: string;
  };
  merchantDetails: {
    category: string;
    categoryCode: string;
    name: string;
    description: string;
  };
}

interface ClearingEvent {
  __typename: string;
  id: string;
  createdAt: string;
  responseCode: string;
  approvedAmount: {
    value: number;
    currencyCode: string;
  };
  transaction: {
    __typename: string;
    id: string;
  };
  merchantDetails: {
    category: string;
    categoryCode: string;
    name: string;
    description: string;
  };
}

interface AuthorizationEvent {
  __typename: string;
  id: string;
  createdAt: string;
  responseCode: string;
  approvedAmount: {
    value: number;
    currencyCode: string;
  };
  transaction: {
    __typename: string;
    id: string;
  };
  requestedAmount: {
    value: number;
    currencyCode: string;
  };
  merchantDetails: {
    category: string;
    categoryCode: string;
    name: string;
    description: string;
  };
}

interface CardDigitalWalletToken {
  __typename: string;
  id: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  requesterName: string;
}

interface AvailableCash {
  id: string;
  name: string;
  creditBalance: {
    currencyCode: string;
    value: number;
  };
  debitBalance: {
    currencyCode: string;
    value: number;
  };
  normalBalance: string;
}

interface AttachedToSubProfile {
  paymentCardId: string;
  subProfileId: number;
  nickName: string;
  isMainCard: boolean;
  givenName: string;
  familyName: string;
}

interface SubUserAddress {
  streetAddress: string;
  postalCode: string;
  region: string;
  locality: string;
}

export interface PaymentCardDetails {
  __typename: string;
  id: string;
  bin: string;
  last4: string;
  expirationDate: string;
  expirationMonth: string;
  expirationYear: string;
  network: string;
  status: string;
  formFactor: string;
  financialAccounts: FinancialAccountFeature[];
  ledgers: LedgerBalance[];
  cardProduct: {
    id: string;
    name: string;
    usage: string;
    vertical: string;
  };
  physicalPaymentCardOrders: PhysicalPaymentCardOrder[];
  application: {
    accountHolderSnapshot: BusinessAccountHolderSnapshot;
  };
  transactionEvents: {
    pageInfo: {
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: {
      cursor: string;
      node: AuthorizationAndClearEvent | ClearingEvent | AuthorizationEvent;
    }[];
  };
  cardDigitalWalletTokens: {
    __typename: string;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor: string;
      startCursor: string;
    };
    edges: {
      cursor: string;
      node: CardDigitalWalletToken;
    }[];
  };
  availableCash: AvailableCash;
  address: string;
  cardType: number;
  cardBurnerInfo: boolean;
  attachedToSubProfile: AttachedToSubProfile;
  billingAddress: string;
  dba: string;
  subUserAddress: SubUserAddress;
  subUserFormatAddress: string;
}

export const usePaymentCardByid = (): PaymentCardByIdReturnType => {
  const [
    findPaymentCardWithInfoMutation,
    {
      isLoading: findPaymentCardWithInfoIsLoading,
      isSuccess: findPaymentCardWithInfoIsSuccess,
    },
  ] = useFindPaymentCardWithInfoControllerMutation();

  const [
    attachedWithInfoPaymentCardsMutation,
    {
      isLoading: attachedWithInfoPaymentCardsIsLoading,
      isSuccess: attachedWithInfoPaymentCardsIsSuccess,
    },
  ] = useAttachedWithInfoPaymentCardsControllerMutation();

  return {
    async paymentCardById(paymentCardId: string): Promise<PaymentCardDetails> {
      const paymentCard = await findPaymentCardWithInfoMutation({
        paymentCardId,
      }).unwrap();
      const attachedPaymentCards = await attachedWithInfoPaymentCardsMutation(
        {}
      ).unwrap();

      const availableCash =
        paymentCard?.Card?.node?.financialAccounts &&
        paymentCard.Card.node.financialAccounts[0].ledgers.find(
          (l) => l.name === "AVAILABLE_CASH"
        );
      const billingAddress = helper.formatAddress(
        paymentCard?.Card?.node?.application?.accountHolderSnapshot
          ?.accountHolderCurrent?.businessProfile?.billingAddress
      );
      const dba =
        paymentCard?.Card?.node?.application?.accountHolderSnapshot
          ?.businessProfile?.name?.legalBusinessName;

      const address = helper.formatAddress(
        paymentCard?.Card?.node?.application?.accountHolderSnapshot
          ?.accountHolderCurrent?.businessProfile?.billingAddress
      );
      const cardType = paymentCard?.CardType;
      const attachedToSubProfile = attachedPaymentCards?.find(
        (a) => a.paymentCardId === paymentCard.Card.node.id
      );

      const subUserAddress = {
        streetAddress: paymentCard?.Address,
        postalCode: paymentCard?.PostalCode,
        region: paymentCard?.State,
        locality: paymentCard?.City,
      };
      const subUserFormatAddress = helper.formatAddress(subUserAddress);

      const cardBurnerInfo = cardType == 2 && paymentCard?.BurnerCardInfo;
      const temptPaymentCard: PaymentCardDetails = {
        ...paymentCard.Card.node,
        availableCash,
        address: address || subUserFormatAddress,
        cardType,
        cardBurnerInfo,
        attachedToSubProfile,
        billingAddress,
        dba,
        subUserAddress,
        subUserFormatAddress,
      };
      return temptPaymentCard;
    },

    paymentCardByIdIsLoading:
      findPaymentCardWithInfoIsLoading || attachedWithInfoPaymentCardsIsLoading,
    paymentCardByIdIsSuccess:
      findPaymentCardWithInfoIsSuccess && attachedWithInfoPaymentCardsIsSuccess,
  };
};
