import { FC, ReactNode } from 'react';
import styled, { css } from "styled-components";
import { Button, Typography } from '@mui/material';
import plusIcon from "assets/svg/plus-blue.svg";

interface AccountListHeaderProps {
  handleAddAcc: (mode: string) => void;
  rights: boolean;
  title: string;
  openLink: string;
  buttonLabel: string;
  ExtraAction?: ReactNode;
}

const AccountListHeader: FC<AccountListHeaderProps> = ({
  handleAddAcc,
  rights,
  title,
  openLink,
  buttonLabel,
  ExtraAction
}) => {
  return (
    <Box>
      <Title>{title}</Title>
      <div style={{ display: 'flex', gap: '8px' }}>
        {ExtraAction && ExtraAction}
        {rights && (
          <ButtonOpenAcc variant="text" onClick={() => handleAddAcc(openLink)}>
            <img src={plusIcon} />
            <Typography>{buttonLabel}</Typography>
          </ButtonOpenAcc>
        )}
      </div>
    </Box>
  );
};
export default AccountListHeader;

const Title = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
    ${theme.breakpoints.down("sm")} {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }
  `}
`;

const ButtonOpenAcc = styled(Button)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    color: ${theme.colors.primaryBlue};
    gap: 8px;
  `}
`;
const Box = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
