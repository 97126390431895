import { AnimateWrapper } from 'components/animate-wrapper';
import styles from './style.module.scss';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import transferIn from 'assets/svg/transferIn.svg';
import MailTransaction from 'assets/svg/mail-transaction.svg';
import helper from 'services/helper';
import { useEffect, useMemo, useState } from 'react';
import StepperComponent from './Stepper';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import Memo from 'shared/components/memo';
import { EnumTransactionStatuses, Transaction, useSetTransactionMemoEventsControllerMutation, useTransactionByIdQuery } from 'api/endpoints/transaction';
import TransactionShowDetail from './transactionShowDetail';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { UploadFile } from './UploadFile';
import { useACHTransferDetailsControllerMutation } from 'api/endpoints/fundsMovement';
import { PaymentCard } from 'api/endpoints/paymentCards';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { InfoTooltip } from '../InfiTooltip';
import { CardView } from '../CardView';
import { useMerchantCategoriesControllerQuery } from 'api/endpoints/enums';
import successIcon from 'assets/svg/success-dark-blue.svg';
import { safeJsonParse } from '../../utils/require-not-null';
import { CurrencyCodeSign } from '../../../enums';
import { appRoutes, SettingsRouteSubRoute } from '../../../routes';
import { NEW_REQUEST_PARAMS } from '../../../pages/support/types/enums';
import { Typography } from 'shared/ui/typography';

interface TransactionDetailsProps {
  allTransactions: Transaction[];
  paymentCards: PaymentCard[];
  currentCartId?: string;
}

const COMPLETED = "COMPLETED";
const TRANSFER_STATUS_COMPLETED =
  "The transfer has been successfuly processed by the bank and funds are available";

const TransactionDetails = ({
  allTransactions,
  paymentCards,
  currentCartId,
}: TransactionDetailsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslationProvider();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { accountId } = useParams();
  const currentAccount = unclosedFinancialAccounts.find(
    (account) => account.financialAccountId === accountId
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTransactionId = searchParams.get("transaction") || "";

  const { data: merchantCategory } = useMerchantCategoriesControllerQuery();

  const [setMemoMutation, { isLoading: setMemoIsLoading }] =
    useSetTransactionMemoEventsControllerMutation();
  const [transferDetails, setTransferDetails] = useState<any>();
  const [memo, setMemo] = useState<string>("");

  const transaction = allTransactions.find(
    (item) => item.id === currentTransactionId
  )!;

  const isPaymentTransaction = [
    'payment',
    'atm withdrawal',
    'authorization'
  ].includes(transaction.type?.toLowerCase());

  const { data: transactionEventsData, isLoading: cardTransactionEventsIsLoading } = useTransactionByIdQuery(
    { transactionId: currentTransactionId },
    { skip: !isPaymentTransaction }
  )

  const transactionEvents = useMemo(() => {
    return transactionEventsData?.node?.financialAccounts?.edges?.find(
      (e: any) => Boolean(e?.node?.transactionEvents)
    )?.node?.transactionEvents?.edges?.flatMap((item: any) => item.node)
  }, [transactionEventsData]);

  const lastEvent = transactionEvents ? transactionEvents[0] : undefined;

  const cardId = lastEvent?.paymentCard?.id || currentCartId;
  const card = cardId
    ? paymentCards.find((item) => item.id === cardId)
    : undefined;

  const statusInfoCard =
    transaction.transactionSource?.transactionEvents?.[0].responseDescription ||
    "";

  const checkStatusCompleted = transaction.status === COMPLETED;
  const statusInfoTransfer = checkStatusCompleted
    ? TRANSFER_STATUS_COMPLETED
    : "";

  const statusInfo = card ? statusInfoCard : statusInfoTransfer;

  useEffect(() => {
    const memo = transactionEventsData?.node?.Memo;
    if (memo) {
      setMemo(memo);
    }
  }, [transactionEventsData]);

  const [
    ACHTransferDetailsMutation,
    { isLoading: ACHTransferIsLoading, isSuccess: ACHTransferIsSuccess },
  ] = useACHTransferDetailsControllerMutation();

  useEffect(() => {
    if (!isPaymentTransaction && currentTransactionId) {
      getACHTransferDetails(currentTransactionId);
    }
  }, [currentTransactionId, isPaymentTransaction]);

  const handleGoToSupport = () => {
    navigate(appRoutes.settings(SettingsRouteSubRoute.support, NEW_REQUEST_PARAMS))
  }

  const getACHTransferDetails = async (transferId) => {
    try {
      const res = await ACHTransferDetailsMutation({ transferId }).unwrap();
      const data = safeJsonParse<any>(res, {});

      const formatDate = (inputDate) => {
        const parsedDate = moment(inputDate);
        const formattedDate = parsedDate.format("h:mm A, ddd, DD MMM YYYY");
        return formattedDate;
      };
      const transformData = [
        data.node.createdAt && { name: "Created on", date: formatDate(data.node.createdAt) },
        data.node.updatedAt && { name: "Last updated", date: formatDate(data.node.updatedAt) },
        data.node.settlementDate && { name: "Settlement date", date: formatDate(data.node.settlementDate) },
        data.node.fundsAvailableDate && {
          name: "Funds available date",
          date: formatDate(data.node.fundsAvailableDate),
        },
      ].filter(Boolean);
      setTransferDetails({ ...data.node, stepperData: transformData });
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const handleSumbitMemo = async () => {
    try {
      await setMemoMutation({
        paymentCardId: card?.id!,
        transactionId: transaction.id!,
        memo,
      }).unwrap();
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const approvedAmountString = (() => {
    if (cardTransactionEventsIsLoading) {
      return '';
    }

    let result = `$${helper.formatUsdValue(lastEvent?.approvedAmount?.value)}`;
    // if (transaction?.status === EnumTransactionStatuses.COMPLETED) {
      const competedEventTypes = ['AuthorizationEvent', 'AuthorizationAndClearEvent'];
      if (transactionEvents && transactionEvents.length > 0) {
        const completedEvent = transactionEvents.find((e: any) => competedEventTypes.includes(e?.__typename));
        if (completedEvent) {
          result = `$ ${helper.formatUsdValue(completedEvent.approvedAmount.value)}`;
        }
      }
    // }

    if (transaction?.status === EnumTransactionStatuses.PENDING && transaction?.clearAmount?.value) {
      result = `$ ${helper.formatUsdValue(transaction.clearAmount.value)}`;
    }

    return result;
  })();

  const requestedAmountString = (() => {
    if (lastEvent?.requestedAmount?.value) {
      const raCurCode = lastEvent?.requestedAmount?.currencyCode;
      const cur = `${CurrencyCodeSign[raCurCode] || raCurCode}`
      return `${cur} ${helper.formatUsdValue(lastEvent?.requestedAmount.value)}`
    }
  })();

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div>
          <div className={styles.infoBlock}>
            <div className={styles.amount}>
              <div>
                {transaction.amount.symbol}$
                {helper.formatUsdValue(transaction.amount.value)}
                {transaction.balance && (
                  <div className={styles.runningBalance}>
                    ${helper.formatUsdValue(transaction.balance.value)}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.stringWrap}>
                <div className={styles.infoBlock_title}>
                  {isPaymentTransaction
                    ? 'Merchant name'
                    : transaction.amount.symbol === "-"
                      ? "Transfer To"
                      : "Transfer from"}
                </div>
                <div className={styles.infoBlock_name}>
                  <span>{transaction.name}</span>
                  {!card && <span>{transaction.last4?.value ? `(${transaction.last4.value})` : ''}</span>}
                </div>
                <div className={styles.infoBlock_title}>
                  {moment(transaction?.date).format("MM.DD.YYYY, hh:mm A")}
                </div>
              </div>
              <img src={transferIn} />
            </div>
          </div>

          <div className={styles.infoBlock}>
            {!!card && (
              <div className={styles.cardView_container}>
                <div>
                  <CardView card={card} isSmallSize />
                </div>

                <div className={styles.stringWrap}>
                  <div className={styles.infoBlock_content}>
                    {card?.cardName}
                  </div>
                  <div className={styles.infoBlock_title}>
                    {`${card?.network} ****${card?.last4 ? card?.last4 : ''}`}
                  </div>
                </div>
              </div>
            )}

            {transaction.amount.symbol === "-" && !card && (
              <div className={styles.flexContainer}>
                <div className={styles.stringWrap}>
                  <div className={styles.infoBlock_title}>Transfer from</div>
                  <div className={styles.infoBlock_name}>
                    <span>{currentAccount?.name}</span>
                    <span>({currentAccount?.accountNumber.slice(-4)})</span>
                  </div>
                </div>
              </div>
            )}

            <div className={styles.stringWrap}>
              <div className={styles.infoBlock_title}>Transaction Type</div>
              <div className={styles.infoBlock_content}>{transaction.type}</div>
            </div>
            <div className={styles.stringWrap}>
              <div className={styles.infoBlock_title}>Status</div>
              <div className={styles.infoBlock_status}>
                {checkStatusCompleted && <img src={successIcon} width={20} />}
                <span>{helper.capitalizeFirstLetter(transaction.status)}</span>
                {statusInfo && <InfoTooltip text={statusInfo} />}
              </div>
            </div>
          </div>
          {isPaymentTransaction && (
            <div className={styles.infoBlock}>
              {requestedAmountString && (
                <div className={styles.stringWrap}>
                  <div className={styles.infoBlock_title}>Requested Amount</div>
                  <div className={styles.infoBlock_content}>
                    {requestedAmountString}
                  </div>
                </div>
              )}
              {approvedAmountString && (
                <div className={styles.stringWrap}>
                  <div className={styles.infoBlock_title}>Approved Amount</div>
                  <div className={styles.infoBlock_content}>
                    {approvedAmountString}
                  </div>
                </div>
              )}
            </div>
          )}
          {!card && transferDetails && (
            <div className={styles.infoBlock}>
              <StepperComponent
                data={transferDetails.stepperData}
                isLoading={ACHTransferIsLoading}
              />
            </div>
          )}
            {!card && !!transferDetails?.memo?.trim() && (
              <div className={styles.boxTransferMemo}>
                <Typography>Memo</Typography>
                <Typography>{ transferDetails.memo}</Typography> 
              </div>
        )}
          {isPaymentTransaction && (
            <div className={styles.infoBlock}>
              <div className={styles.stringWrap}>
                <div className={styles.infoBlock_title}>Merchant Category</div>
                <div className={styles.infoBlock_content}>
                  {
                    merchantCategory?.find(
                      (item) => item.name === transaction.merchantCategory
                    )?.description
                  }
                </div>
              </div>
              {/* <div className={styles.stringWrap}>
                <div className={styles.infoBlock_title}>Description</div>
                <div className={styles.infoBlock_content}>
                  {transaction.description}
                </div>
              </div> */}
              {transactionEvents && (
                <TransactionShowDetail
                  events={transactionEvents}
                  detailsTransaction={true}
                  remainingClearAmount={
                    transaction?.pendingAmount && transaction?.status === EnumTransactionStatuses.PENDING
                      ? transaction.pendingAmount.value
                      : undefined
                  }
                />
              )}
            </div>
          )}
        </div>
        {isPaymentTransaction && (
          <>
            {cardTransactionEventsIsLoading ? (
              <Skeleton height={100} />
            ) : (
              <>
                <Memo
                  memo={memo}
                  setMemo={setMemo}
                  onSumbit={handleSumbitMemo}
                  isLoading={setMemoIsLoading || cardTransactionEventsIsLoading}
                />

                <UploadFile />
              </>
            )}
          </>
        )}
        <div className={styles.report}>
          <p className={styles.report_textQuestion}>
            {t("Transactions.Having a problem with this transaction", {
              defaultValue: "Having a problem with this transaction?",
            })}
          </p>
          <div className={styles.report_boxText} onClick={handleGoToSupport}>
            <img src={MailTransaction} />
            <div className={styles.report_text}>
              {t("Transactions.Report a problem", {
                defaultValue: "Report a problem",
              })}
            </div>
          </div>
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default TransactionDetails;
