import { TransitionAnimation } from 'shared/ui/animations';
import { Button } from 'shared/ui/buttons';
import { ChevronRightIcon } from 'shared/ui/icons';
import { Typography } from 'shared/ui/typography';
import styles from './style.module.scss';
import { appRoutes,TransferQs } from 'routes';
import { useNavigate } from 'react-router-dom';
export const Automations = () => {
  const navigate = useNavigate()
 
  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Button
          className={styles.btn}
          onClick={() =>    navigate(appRoutes.transfer(undefined,{mode: TransferQs.fromConnectedAccount, step: '10'}))}
        >
          <div className={styles.btnContent}>
            <Typography>Automations for connected accounts</Typography>
            <ChevronRightIcon />
          </div>
        </Button>
        {/*  TODO in the futured
        <Button className={styles.btn}>
          <div className={styles.btnContent}>
            <Typography>Automations for own Netevia accounts</Typography>
            <ChevronRightIcon />
          </div>
        </Button> */}
      </div>
    </TransitionAnimation>
  );
};
