import { useEffect } from 'react';
import helper from 'services/helper';
import { useSnackBar } from 'providers/snackBar.provider';
import { TransitionAnimation } from 'shared/ui/animations';
import {
  useACHTransferManyMutation,
  usePayeeTransferManyControllerMutation,
  TransferRequests,
} from 'api/endpoints/fundsMovement';
import { useAppSelector } from 'shared/models';
import {
  selectTransfers,
  selectTransferType,
  TransferType,
  StepContent,
} from 'features/transfers/transfer-batch/model';
import { CurrencyCode, SecureOperationType, ShortMessageType } from 'enums';
import { useMfa } from '../../../../../providers/mfa/mfa';
import { Skeleton } from '../../../../../components/skeleton';

interface AuntificatorTransferProps {
  setStepContent: (step: StepContent) => void;
}

export const AuntificatorTransfer = ({
  setStepContent,
}: AuntificatorTransferProps) => {
  const { setSnackBar } = useSnackBar();
  const mfa = useMfa();
  const [achTransferMany] = useACHTransferManyMutation();
  const [payeeTransferMany] = usePayeeTransferManyControllerMutation();

  const transfers = useAppSelector(selectTransfers);
  const transferType = useAppSelector(selectTransferType);

  const achTransfer = async (
    mappedTransfers: TransferRequests[],
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      await achTransferMany({
        transferRequests: mappedTransfers,
        oneTimeCode: code,
        secureOperationType: secureOperationType,
      }).unwrap();
      setSnackBar({
        type: 'success',
        message: 'Transfer successfully completed',
        isShow: true,
      });
      mfa.close();
      setStepContent(StepContent.success);
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const neteviaTransfer = async (
    mappedTransfers: TransferRequests[],
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      await payeeTransferMany({
        transferRequests: mappedTransfers,
        oneTimeCode: code,
        secureOperationType: secureOperationType,
      }).unwrap();
      setSnackBar({
        type: 'success',
        message: 'Transfer successfully completed',
        isShow: true,
      });
      mfa.close();
      setStepContent(StepContent.success);
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handlerSubmit = async (secureOperationType: SecureOperationType, code: string) => {
    if (!transfers?.length) return;
    const mappedTransfers = transfers.map((item) => ({
      fromFinancialAccountId: item.fromFinancialAccount,
      toFinancialAccountId: item.toPayee,
      amount: parseFloat(item.amount),
      currencyCode: CurrencyCode.USD,
      memo: item?.memo
    }));

    if (transferType === TransferType.toAch) {
      await achTransfer(mappedTransfers, secureOperationType, code);
    } else {
      await neteviaTransfer(mappedTransfers, secureOperationType, code);
    }
  };

  useEffect(() => {
    mfa.show({
      title: 'Confirm Transfer',
      shortMessageType: ShortMessageType.ApproveTransaction,
      onDrawerClose() {
        setStepContent(StepContent.form);
      },
      async onCodeSubmit(secureOperationType: SecureOperationType, code: string) {
        await handlerSubmit(secureOperationType, code)
      }
    })
  }, []);

  return (
    <TransitionAnimation>
      <Skeleton width="100%" height={100} />
    </TransitionAnimation>
  );
};
