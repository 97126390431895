import { RewardIntegrationsDTO } from 'api/endpoints/rewardIntegrations';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import { Typography } from 'shared/ui/typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import cn from 'classnames';
import { CircularProgress } from '@mui/material';

interface SliderItemProps {
  item: RewardIntegrationsDTO;
  onClick: (item: RewardIntegrationsDTO) => void;
  aprilURLIsLoading?: boolean;
  styleItem?: {
    wrapper?: string;
    item?: string;
  };
}

export const SliderItem = ({
  item,
  onClick,
  styleItem,
  aprilURLIsLoading = false,
}: SliderItemProps) => {
  const btnLabel = () => {
    const labels = {
      0: 'learn more',
      2: 'get started',
      3: 'learn more',
      4: 'get a card',
    };

    return labels[item.type] || '';
  };

  return (
    <div className={cn(styleItem?.wrapper, styles.container)}>
      <div className={cn(styleItem?.item, styles.box)}>
        <Typography className={styles.textName}>{item.name}</Typography>
        <Typography className={styles.textDescription}>
          {item.description}
        </Typography>
        <Button
          variant='text'
          className={styles.btn}
          onClick={() => onClick(item)}
        >
          {btnLabel()} <ArrowForwardIosIcon />
        </Button>
      </div>
      {item?.imageUrls[1]?.filePath && (
        <img
          src={item.imageUrls[1].filePath}
          alt={item.name}
          className={styles.img}
        />
      )}
      {item.type === 2 && aprilURLIsLoading && (
        <CircularProgress className={styles.progress} />
      )}
    </div>
  );
};
