import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';
import moment from 'moment';
import { ApiTags } from '../api-tags';
import type {
  openPartnerCardPhysicalArg,
  openPartnerCardPhysicalResponse,
} from './paymentCards.types';
import { CountryCodeAlpha3 } from 'enums';
interface FindPaymentCardWithInfoArg {
  paymentCardId: string;
}

interface ViewPaymentCardInfoArg {
  paymentCardId: any;
}

export interface ViewPaymentCardInfoApi {
  node: ViewPaymentCardInfo;
}

export interface ViewPaymentCardInfo {
  __typename: string;
  id: string;
  status: string;
  bin: string;
  last4: string;
  expirationDate: string;
  financialAccounts: null;
  restrictedDetails: RestrictedDetails;
}
interface RestrictedDetails {
  number: string;
  cvv: string;
  __typename: string;
}

interface OpenPaymentCardArg {
  financialAccountId: string;
  expirationDate: any;
  activateNow: boolean;
}

export interface DeliveryDetails {
  name: {
    givenName: string;
    familyName: string;
  };
  companyName: string;
  address: {
    streetAddress: string;
    extendedAddress: string;
    postalCode: string;
    locality: string;
    region: string;
    countryCodeAlpha3: string | CountryCodeAlpha3;
  };
}

interface CardPersonalization {
  Line1: string;
  Line2: string;
}

interface Courier {
  method: string;
}

interface CreatePhysicalCardArgs {
  paymentCardId?: string;
  deliveryDetails: DeliveryDetails;
  cardPersonalization: CardPersonalization;
  courier: Courier;
  financialAccountId?: string;
  expirationDate?: string;
  activateNow?: boolean;
  subProfileId?: string | number;
}

interface SetPinCardArg {
  newPin: string;
  cardId: string;
}

interface CardIdArg {
  cardId: string;
}

interface CancelPhysicalPaymentCardOrderArg {
  paymentCardId: string;
  orderId: string;
}

// interface FindPaymentCardWithInfoArg {
//     paymentCardId: string
// }

interface PenamePaymentCardNameArg {
  paymentCardId: string;
  nickName: string;
}

export enum EnumCardStatuses {
  'ACTIVATION_REQUIRED' = 'ACTIVATION_REQUIRED',
  'ACTIVE' = 'ACTIVE',
  'LOCKED' = 'LOCKED',
  'CLOSED' = 'CLOSED',
  'IN_REVIEW' = 'IN_REVIEW',
  'SUSPENDED' = 'SUSPENDED',
}

export enum EnumCardTypes {
  'PHYSICAL' = 'PHYSICAL',
  'VIRTUAL' = 'VIRTUAL',
  'BURNER' = 'BURNER',
}

export enum CardNumberType {
  stub,
  regular,
  burner,
}

export interface PaymentCard {
  id: string;
  bin: string;
  last4: string;
  status: EnumCardStatuses;
  formFactor: EnumCardTypes;
  expirationDate: string;
  expirationMonth: string;
  expirationYear: string;
  network: string;
  cardType: number; // 0, 1 = regular, 2 = burner
  cardholder: string;
  cardName: string;
  subProfile: string;
  department: string;
  financialAccounts: {
    id: string;
    name?: string | null;
  }[];
  attachedToSubProfile?: AttachedPaymentCard | null;
  partnerName: string | null;
  partnerCardIcons: string[];
}

interface AttachedPaymentCard {
  paymentCardId: string;
  subProfileId: number;
  nickName: string;
  isMainCard: boolean;
  givenName: string;
  familyName: string;
}

interface NickNames {
  highnotePaymentCardId: string;
  userProfileId: number;
  paymentCardName: string | null;
  maxTotalSpending: number | null;
  maxTransactionsCount: number | null;
  totalSpending: number | null;
  totalTransactionsCount: number | null;
  type: number;
  expirationDate: string | null;
  active: boolean;
  last4: string | null;
}

interface openPartnerCardVirtualArg {
  cardProductId: string;
}

export enum ReissueReason {
  Fraud = 'Fraud',
  LostStolen = 'LostOrStolen',
  Damaged = 'Damaged',
  IneedANewCard = 'NeedNewCard',
}

export enum DeliveryMethod {
  USPS_GROUND = 'USPS_GROUND',
  USPS_PRIORITY = 'USPS_PRIORITY',
  USPS_EXPRESS = 'USPS_EXPRESS',
  UPS_GROUND = 'UPS_GROUND',
  UPS_SECOND_DAY = 'UPS_SECOND_DAY',
  UPS_NEXT_DAY = 'UPS_NEXT_DAY',
}

interface ReissueCardArg {
  paymentCardId: string;
  reason: ReissueReason;
  orderPhysicalPaymentCard?: {
    deliveryDetails: {
      name: {
        givenName: string;
        familyName: string;
      };
      companyName: string;
      address: {
        streetAddress: string;
        extendedAddress: string;
        postalCode: string;
        region: string;
        locality: string;
        countryCodeAlpha3: CountryCodeAlpha3;
      };
    };
    courier: {
      method: DeliveryMethod;
    };
  };
}
interface ReissueCardApi {
  reissuePaymentCard: {
    id: string;
    last4: string;
    status: EnumCardStatuses;
    formFactor: EnumCardTypes;
    expirationDate: string;
    errors: null;
  };
}

export const paymentCardsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'paymentCards',
  tagTypes: [
    ApiTags.GetPaymentCards,
    ApiTags.GetSubUserCard,
    ApiTags.GetPaymentCardInformation,
  ],
  endpoints: (builder) => ({
    paymentCardsController: builder.query<PaymentCard[], void>({
      providesTags: [ApiTags.GetPaymentCards],
      query: () => ({
        url: '/api/paymentCards/v3',
        method: 'GET',
      }),
    }),
    attachedPaymentCardsController: builder.query<AttachedPaymentCard[], any>({
      // providesTags: [ApiTags.GetPaymentCards],
      query: () => ({
        url: 'api/paymentCards/viewAttachedCards',
        method: 'GET',
      }),
    }),
    attachedWithInfoPaymentCardsController: builder.mutation<any, any>({
      query: () => ({
        url: 'api/paymentCards/viewAttachedCards',
        method: 'GET',
      }),
    }),
    findPaymentCardWithInfoController: builder.mutation<
      any,
      FindPaymentCardWithInfoArg
    >({
      query: ({ paymentCardId }) => ({
        url: `api/paymentCards/findPaymentCardWithInfo`,
        method: 'GET',
        params: {
          paymentCardId,
          from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          to: moment().subtract(-1, 'days').format('YYYY-MM-DD'),
        },
      }),
    }),
    viewPaymentCardController: builder.mutation<
      ViewPaymentCardInfoApi,
      ViewPaymentCardInfoArg
    >({
      query: ({ paymentCardId }) => ({
        url: `api/paymentCards/viewPaymentCard`,
        method: 'GET',
        params: {
          paymentCardId,
        },
      }),
    }),
    openPaymentCardController: builder.mutation<any, OpenPaymentCardArg>({
      invalidatesTags: [ApiTags.GetPaymentCards, ApiTags.GetSubUserCard],
      query: (queryArg) => ({
        url: `/api/paymentCards/v2/Issue`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    openBurnerCardController: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetPaymentCards, ApiTags.GetSubUserCard],
      query: (queryArg) => ({
        url: `/api/paymentCards/orderBurnerPaymentCard`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    openPhysicalCardByIdController: builder.mutation<
      any,
      CreatePhysicalCardArgs
    >({
      invalidatesTags: [
        ApiTags.GetPaymentCards,
        ApiTags.GetPaymentCardInformation,
      ],
      query: (queryArg) => ({
        url: `/api/paymentCards/orderPhysicalPaymentCard`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    openPhysicalCardController: builder.mutation<any, CreatePhysicalCardArgs>({
      invalidatesTags: [
        ApiTags.GetPaymentCards,
        ApiTags.GetPaymentCardInformation,
      ],
      query: (queryArg) => ({
        url: `/api/paymentCards/v2/orderPhysicalPaymentCard`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    setPinCardController: builder.mutation<void, SetPinCardArg>({
      query: ({ newPin, cardId }) => ({
        url: `api/paymentCards/setPinForPaymentCard`,
        method: 'POST',
        body: {
          NewPin: newPin,
          PinConfirm: newPin,
          PaymentCardId: cardId,
        },
      }),
    }),
    suspendCardController: builder.mutation<void, CardIdArg>({
      invalidatesTags: [
        ApiTags.GetPaymentCards,
        ApiTags.GetPaymentCardInformation,
      ],
      query: ({ cardId }) => ({
        url: `api/paymentCards/suspendPaymentCard`,
        method: 'POST',
        body: {
          PaymentCardId: cardId,
        },
      }),
    }),
    closeCardController: builder.mutation<void, CardIdArg>({
      invalidatesTags: [ApiTags.GetPaymentCards],
      query: ({ cardId }) => ({
        url: `api/paymentCards/closePaymentCard`,
        method: 'POST',
        body: {
          PaymentCardId: cardId,
        },
      }),
    }),
    activateCardController: builder.mutation<void, CardIdArg>({
      invalidatesTags: [
        ApiTags.GetPaymentCards,
        ApiTags.GetPaymentCardInformation,
      ],
      query: ({ cardId }) => ({
        url: `api/paymentCards/activatePaymentCard`,
        method: 'POST',
        body: {
          PaymentCardId: cardId,
        },
      }),
    }),
    viewNickNamesController: builder.query<any, any>({
      query: ({}) => ({
        url: `api/paymentCards/viewNickNames`,
        method: 'GET',
      }),
    }),
    findPhysicalPaymentCardOrderController: builder.mutation<any, any>({
      query: ({ paymentCardId, orderId }) => ({
        url: `api/paymentCards/findPhysicalPaymentCardOrder`,
        method: 'GET',
        params: {
          paymentCardId,
          orderId,
        },
      }),
    }),
    cancelPhysicalPaymentCardOrderController: builder.mutation<
      any,
      CancelPhysicalPaymentCardOrderArg
    >({
      query: (queryArg) => ({
        url: `api/paymentCards/cancelPhysicalPaymentCardOrder`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    atmLocationController: builder.mutation<any, any>({
      query: (queryArg) => ({
        url: `api/paymentCards/atmPaymentCard`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    paymentCardInformationController: builder.query<
      any,
      FindPaymentCardWithInfoArg
    >({
      providesTags: [ApiTags.GetPaymentCardInformation],
      query: (queryArg) => ({
        url: `api/paymentCards/findPaymentCardWithInfo`,
        method: 'GET',
        params: {
          paymentCardId: queryArg,
          from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          to: moment().subtract(-1, 'days').format('YYYY-MM-DD'),
        },
      }),
    }),
    paymentCardNameController: builder.query<NickNames[], any>({
      providesTags: [ApiTags.GetPaymentCards],
      query: () => ({
        url: `api/paymentCards/viewNickNames`,
        method: 'GET',
      }),
    }),
    renamePaymentCardNameController: builder.mutation<
      any,
      PenamePaymentCardNameArg
    >({
      invalidatesTags: [ApiTags.GetPaymentCards],
      query: (queryArg) => ({
        url: `api/paymentCards/nickName`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    //
    spendingReportController: builder.query<any, any>({
      query: (queryArg) => ({
        url: `api/paymentCards/spendingReport`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getCardSpendingReportEXEL: builder.mutation<any, any>({
      query: (queryArg) => ({
        url: `api/paymentCards/spendingReportXL`,
        method: 'POST',
        body: queryArg,
      }),
    }),

    openPartnerCardVirtual: builder.mutation<null, openPartnerCardVirtualArg>({
      query: (queryArg) => ({
        url: `api/paymentCards/v2/openPartnerCardVirtual`,
        method: 'POST',
        body: queryArg,
      }),
    }),

    openPartnerCardPhysical: builder.mutation<
      openPartnerCardPhysicalResponse,
      openPartnerCardPhysicalArg
    >({
      query: (queryArg) => ({
        url: `api/paymentCards/v2/openPartnerCardPhysical`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    reissueCard: builder.mutation<ReissueCardApi, ReissueCardArg>({
      query: (queryArg) => ({
        url: 'api/paymentCards/v2/reissuePaymentCard',
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
});

export const {
  usePaymentCardsControllerQuery,
  useAttachedPaymentCardsControllerQuery,
  useAttachedWithInfoPaymentCardsControllerMutation,
  useFindPaymentCardWithInfoControllerMutation,
  useViewPaymentCardControllerMutation,
  useOpenPaymentCardControllerMutation,
  useOpenBurnerCardControllerMutation,
  useOpenPhysicalCardControllerMutation,
  useOpenPhysicalCardByIdControllerMutation,
  useSetPinCardControllerMutation,
  useSuspendCardControllerMutation,
  useActivateCardControllerMutation,
  useCloseCardControllerMutation,
  useViewNickNamesControllerQuery,
  useFindPhysicalPaymentCardOrderControllerMutation,
  useCancelPhysicalPaymentCardOrderControllerMutation,
  useAtmLocationControllerMutation,
  usePaymentCardInformationControllerQuery,
  usePaymentCardNameControllerQuery,
  useRenamePaymentCardNameControllerMutation,
  useSpendingReportControllerQuery,
  useGetCardSpendingReportEXELMutation,
  useOpenPartnerCardVirtualMutation,
  useOpenPartnerCardPhysicalMutation,
  useReissueCardMutation,
} = paymentCardsApi;
