import moment from "moment";
import { GetScheduledTransfer } from "api/endpoints/scheduledTransfer";
import { ScheduleTransferType } from "../config/types";


  export const filteredScheduleTransferByType = (
    transfers: GetScheduledTransfer[] , 
    transferType: ScheduleTransferType
  ): GetScheduledTransfer[] => {
  
    return transfers
      .filter(transfer => transfer.transferType === transferType)
      .sort((a, b) => moment(a.nextDate).diff(moment(b.nextDate)));
  }