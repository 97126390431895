import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNotifications } from "../../../../../api/hooksApi/useNotifications";
import {
  useSaveNotificationsControllerMutation,
  useDevicesControllerQuery,
  useDeleteDeviceControllerMutation,
} from "../../../../../api/endpoints/settings";
import { AnimateWrapper } from "../../../../../components/animate-wrapper";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import EmailNotifications from "./components/emailNotifications";
import DeviceList from "./components/devicesList";
import ArrowLeft from "assets/svg/arrow-left-gray.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import { usePermission } from "shared/hooks/usePermission";

const Notifications = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslationProvider();
  const { notificationDevicesPermission } = usePermission();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const { notifications, notificationsIsLoading, notificationsIsSuccess } =
    useNotifications();
  const [notificationsMutation] = useSaveNotificationsControllerMutation();
  const { data, isFetching: devicesIsFetching } = useDevicesControllerQuery({});
  const [deleteDeviceMutation, { isLoading: deleteDeviceIsLoading }] =
    useDeleteDeviceControllerMutation();

  const [toogleEmail, setToogleEmail] = useState<boolean | undefined>(
    undefined
  );
  const devices = data?.Devices;
  const isLoadingDevice = [devicesIsFetching, deleteDeviceIsLoading].some(
    Boolean
  );
  useEffect(() => {
    if (!notificationsIsLoading) {
      setToogleEmail(notifications.emailEnabled);
    }
  }, [notifications]);

  useEffect(() => {
    if (
      toogleEmail !== undefined &&
      toogleEmail !== notifications?.emailEnabled
    ) {
      sumbitNotifications(toogleEmail);
    }
  }, [toogleEmail]);

  const sumbitNotifications = (toogleEmail) => {
    notificationsMutation({
      emailEnabled: toogleEmail,
      pushEnabled: true,
    }).unwrap();
  };

  const removeDevice = async (id) => {
    try {
      await deleteDeviceMutation({ deviceId: id }).unwrap();
    } catch (e: any) {
      console.log("error", e);
    }
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        {!matches && (
          <div className={styles.container_nav}>
            <img src={ArrowLeft} onClick={() => setSearchParams({})} />
            <span className={styles.container_nav_title}>
              {t("Settings.Notifications", {
                defaultValue: "Notifications",
              })}
            </span>
          </div>
        )}
        <EmailNotifications
          isLoading={notificationsIsLoading}
          toogleEmail={toogleEmail}
          setToogleEmail={setToogleEmail}
        />
        {!!devices?.length && notificationDevicesPermission && (
          <DeviceList
            isLoading={isLoadingDevice}
            devicesArr={devices}
            removeDevice={removeDevice}
          />
        )}
      </div>
    </AnimateWrapper>
  );
};

export default Notifications;
