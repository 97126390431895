import { FormControl, Select, SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { FC, ReactNode } from "react";
import { useTopicsControllerQuery } from "api/endpoints/support";
import BeatLoader from "react-spinners/BeatLoader";
import styles from "../../style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { TopicApi } from "api/endpoints/support";
interface Props {
  topic: TopicApi | undefined;
  setTopic: (topic: TopicApi | undefined) => void;
}

const SelectTopic: FC<Props> = ({ topic, setTopic }) => {
  const { t } = useTranslationProvider();
  const { data, isLoading } = useTopicsControllerQuery({});

  const handleChangeTopic = (e: SelectChangeEvent | any) => {
    const topic = data?.find((t) => t.name === e.target.value);
    setTopic(topic);
  };

  const renderValue = (selected: string[]): ReactNode => {
    if (selected.length === 0) {
      return (
        <div className={styles.selectTopic_placeholder}>
          {t("Support.Enter topic", {
            defaultValue: "Enter topic",
          })}
        </div>
      );
    }
    return selected.join(", ");
  };

  return (
    <FormControl fullWidth>
      <Select
        fullWidth
        value={topic ? [topic.name] : []}
        displayEmpty
        onChange={handleChangeTopic}
        placeholder={t("Support.Enter topic", {
          defaultValue: "Enter topic",
        })}
        renderValue={renderValue}
      >
        {isLoading ? (
          <BeatLoader size="10" className={styles.loader} />
        ) : (
          data?.map((topic: TopicApi) => (
            <MenuItem key={topic.id} value={topic.name}>
              {topic.name}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default SelectTopic;
