import  { FC } from "react";
import styles from "./style.module.scss";
import Status from "../status/Status";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { SupportModeEnum } from "pages/support/types/enums";
import { TicketStatusEnum } from "pages/support/types/enums";

export interface HistoryItemProps {
  createdDate: string;
  status: TicketStatusEnum;
  text: string;
  ticketId: number;
  topic: string;
  topicId: number;
}

const HistoryItem: FC<HistoryItemProps> = ({
  topic,
  status,
  text,
  createdDate,
  ticketId,
}) => {
  const formattedDate = moment(createdDate).format(" hh:mm A, MM/DD/YYYY");

  const [, setSearchParams] = useSearchParams();

  const handleNavigate = (ticketId, topic) => {
    setSearchParams({
      pageMode: SupportModeEnum.ChatSupport,
      title: topic,
      ticket: ticketId,
    });
  };

  return (
    <div
      className={styles.wrapper}
      onClick={() => handleNavigate(ticketId, topic)}
    >
      <div className={styles.boxName}>
        <span className={styles.boxName_text}>{topic}</span>
        <p className={styles.boxName_date}>{formattedDate}</p>
      </div>
      <div className={styles.boxStatus}>
        {status && <Status status={status} />}
        <p className={styles.boxStatus_ticketId}>#{ticketId}</p>
      </div>
      <footer className={styles.historyItem_footer}>
        <div />
        <p className={styles.historyItem_description}>{text}</p>
      </footer>
    </div>
  );
};

export default HistoryItem;
