import { z } from 'zod'

export const NeteviaTransferSchema = z
  .object({
    fromFinancialAccount: z.string().min(1),
    toCustomer: z.string().min(1),
    amount: z
      .object({
        value: z.string(),
        formattedValue: z.string(),
        floatValue: z.number(),
      })

      .required(),
    frequency: z.string().optional(),
    dateTransfer: z.string(),
  })
  .refine((data) => data.amount.floatValue !== 0, {
    message: 'Float value must not be zero',
    path: ['amount'],
  })

export type NeteviaTransfer = z.infer<typeof NeteviaTransferSchema>
