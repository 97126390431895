import { useEffect, useState } from 'react';
import helper from 'services/helper';
import { useSnackBar } from 'providers/snackBar.provider';
import { useSaveOpsConfirmationControllerMutation } from 'api/endpoints/settings';
import { TransitionAnimation } from 'shared/ui/animations';
import { Skeleton } from 'components/skeleton';
import { SecureOperationType, ShortMessageType } from 'enums';
import { AuntificateWithOtp } from './components/auntificate-with-otp';
import { AuntificateWithSms } from './components/auntificate-with-sms';
import { useOpsConfirmationQuery } from 'api/endpoints/settings';
import { useBoolean } from 'shared/hooks/useBoolean';
import { useMfa } from '../mfa';
import { useIsMounted } from '../../../shared/hooks/useIsMounted';

interface AuntificatorProps {
  isLoadingSubmit: boolean;
  onSubmit: (secureOperationType: SecureOperationType, code: string) => Promise<void> | void;
  shortMessageType: ShortMessageType;
  sumbitButtonLabel?: string | React.ReactNode;
  isOnlyOtpFlow?: boolean;
}

export const AuntificatorComponent = (props: AuntificatorProps) => {
  const [code, setCode] = useState('');
  const isMounted = useIsMounted();
  const {
    onSubmit,
    isLoadingSubmit,
    shortMessageType,
    sumbitButtonLabel,
    isOnlyOtpFlow,
  } = props;

  const handleSubmit = (op: SecureOperationType) => {
    onSubmit(op, code);
    if (isMounted()) {
      setCode('');
    }
  };

  const mfa = useMfa();
  const { setSnackBar } = useSnackBar();

  const { data: opsConfirmation, isFetching: isFetchingOpsConfirmation } =
    useOpsConfirmationQuery();

  const [saveOpsConfirmationMutation] =
    useSaveOpsConfirmationControllerMutation({});

  const isSmsEnabledBool = useBoolean();

  useEffect(() => {
    if (!isFetchingOpsConfirmation && opsConfirmation) {
      isSmsEnabledBool.setValue(opsConfirmation?.shortMessageCodeEnabled);
    }
  }, [isFetchingOpsConfirmation]);


  const changeAuthenticationerType = async () => {
    try {
      const withOneTimePassword = opsConfirmation?.shortMessageCodeEnabled;
      if (mfa.isTimeBasedSetUp) {
        await saveOpsConfirmationMutation({
          withOneTimePassword: withOneTimePassword!,
        }).unwrap();
      }else{
        isSmsEnabledBool.toggle()
      }

      if (withOneTimePassword) {
        mfa.reload();
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const loading = isFetchingOpsConfirmation || mfa.isLoading;

  const isSmsEnabled = isOnlyOtpFlow ? false : isSmsEnabledBool.value;

  return (
    <TransitionAnimation>
      {loading ? (
        <Skeleton
          width='100%'
          height='110px'
        />
      ) : (
        <>
          {isSmsEnabled ? (
            <AuntificateWithSms
              toggleType={changeAuthenticationerType}
              code={code}
              setCode={setCode}
              onSubmit={handleSubmit}
              isLoadingSubmit={isLoadingSubmit}
              shortMessageType={shortMessageType}
              sumbitButtonLabel={sumbitButtonLabel}
            />
          ) : (
            <AuntificateWithOtp
              toggleType={changeAuthenticationerType}
              code={code}
              setCode={setCode}
              onSubmit={handleSubmit}
              isLoadingSubmit={isLoadingSubmit}
              sumbitButtonLabel={sumbitButtonLabel}
            />
          )}
        </>
      )}
    </TransitionAnimation>
  );
};
