import React, { FC } from "react";
import { FormControl, TextField } from "@mui/material";
import styles from "../style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Button } from "shared/ui/buttons";
interface EditPhoneNumberProps {
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setErrors: React.Dispatch<React.SetStateAction<string>>;
  phoneError: boolean;
  validateFormEmailOrPhone: any;
  handleSubmit: () => Promise<void>;
  isLoading: boolean;
}

const EditPhoneNumber: FC<EditPhoneNumberProps> = ({
  phone,
  setPhone,
  setErrors,
  phoneError,
  validateFormEmailOrPhone,
  handleSubmit,
  isLoading,
}) => {
  const { t } = useTranslationProvider();
  return (
    <div className={styles.wrapper}>
      <FormControl fullWidth>
        <TextField
          id="outlined-required"
          value={`${phone}`}
          label="Phone number"
          inputProps={{ maxLength: 10 }}
          onChange={(e: any) => {
            setPhone(e.target.value);
            setErrors("");
          }}
          onBlur={() => validateFormEmailOrPhone("Phone")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              validateFormEmailOrPhone("Phone");
            }
          }}
          error={phoneError}
        />
        {phoneError && (
          <p className="text-red-500 ">
            Error: Please enter a valid phone number
          </p>
        )}
      </FormControl>
      <Button
        onClick={handleSubmit}
        loading={isLoading}
        variant="contained"
        className={styles.button}
        disabled={phoneError}
      >
        Save Changes
      </Button>
    </div>
  );
};

export default EditPhoneNumber;
