import { Typography } from 'shared/ui/typography'
import { Button } from 'shared/ui/buttons'
import { ExternalPayeeAddForm } from './external-payee-add-form'
import { TransitionAnimation } from 'shared/ui/animations'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  ExternalPayeeAddSchema,
  ExternalPayeeAdd,
} from '../../model/externalPayeeAddModel'
import styles from './styles.module.scss'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { useAppSelector, useAppDispatch } from 'shared/models'
import { setNewExternalPayee, selectNewExternalPayee } from '../../model'

export const ManagePayeeAdd = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()

  const newPayee = useAppSelector(selectNewExternalPayee)

  const { control, formState, getValues } = useForm<ExternalPayeeAdd>({
    resolver: zodResolver(ExternalPayeeAddSchema),
    mode: 'all',
    defaultValues: {
      payeeName: newPayee.name,
      payeeType: newPayee.type,
      accountNumber: newPayee.accountNumber,
      routingNumber: newPayee.routingNumber,
    },
  })

  const handleGoToAuntificator = () => {
    const value = getValues()
    dispatch(
      setNewExternalPayee({
        value: {
          name: value.payeeName,
          type: value.payeeType,
          accountNumber: value.accountNumber,
          routingNumber: value.routingNumber,
        },
      })
    )
    goNext('11')
  }

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography>External account payee details</Typography>
        <ExternalPayeeAddForm control={control} />
        <Button
          className={styles.button}
          variant='contained'
          onClick={handleGoToAuntificator}
          disabled={!formState.isValid}
        >
          add payee
        </Button>
      </div>
    </TransitionAnimation>
  )
}
