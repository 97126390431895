import { FC } from "react";
import PrimaryAccItem from "../primaryAccItem";
import styles from "./style.module.scss";
import { Skeleton } from "components/skeleton";
import { FinancialAccount } from "api/endpoints/financialAccounts";

interface ChooseAccListProps {
  arr: FinancialAccount[];
  handleSumbit: (string) => void;
  isLoading: boolean;
  sumbitIsLoading: boolean;
}

const ChooseAccList: FC<ChooseAccListProps> = ({
  arr,
  handleSumbit,
  isLoading,
  sumbitIsLoading,
}) => {
  return (
    <div className={styles.container}>
      {isLoading ? (
        <Skeleton width="100%" height="63px" />
      ) : (
        arr?.map((item) => (
          <PrimaryAccItem
            item={item}
            key={item.financialAccountId}
            handleSumbit={handleSumbit}
            sumbitIsLoading={sumbitIsLoading}
          />
        ))
      )}
    </div>
  );
};

export default ChooseAccList;
