import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PropsWithChildren } from "react";
import styles from "./style.module.scss";
import cx from "classnames";

interface Props {
  buttonLable: string;
  img: string;
  expanded?: boolean;
  setExpanded: (value: boolean) => void;
  activeSummary: boolean;
}

const AccordionSideBar = ({
  children,
  buttonLable,
  img,
  expanded,
  setExpanded,
  activeSummary,
}: PropsWithChildren<Props>) => {
  const activeStyleItem = (activeSummary) =>
    cx(styles.container_summary, { [styles.active]: activeSummary });

  const renderTitleOrImgTitle = () => (
    <div className={styles.container_summary_box}>
      <img
        src={img}
        className={styles.container_summary_img}
        alt="Title Image"
      />
      <span className={styles.container_summary_label}>{buttonLable}</span>
    </div>
  );
  return (
    <Accordion
      className={styles.container}
      expanded={expanded}
      onClick={() => setExpanded(!expanded)}
      sx={{
        "&::before": {
          content: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={activeStyleItem(activeSummary)}
        sx={{
          ".MuiAccordionSummary-content": {
            margin: 0,
          },
          ".MuiAccordionSummary-content.Mui-expanded": {
            margin: 0,
          },
          ".MuiSvgIcon-root": {
            fill: " #FFFFFF",
          },
        }}
      >
        {renderTitleOrImgTitle()}
      </AccordionSummary>
      <AccordionDetails className={styles.container_details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSideBar;
