import { useCallback, useState } from 'react'
import { TransitionAnimation } from 'shared/ui/animations'
import styles from './styles.module.scss'
import { ChevronRightIcon } from 'shared/ui/icons'
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link'
import {
  useCreatePlaidLinkTokenControllerQuery,
  useCreatePlaidExchangePublicTokenControllerMutation,
  useCreateFinicityLinkQuery,
} from 'api/endpoints/account'
import { Skeleton } from 'components/skeleton'
import { useSnackBar } from 'providers/snackBar.provider'
import helper from 'services/helper'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { Divider } from '@mui/material'
import { useExternalPayees } from 'api/hooksApi/useExternalPayees'
import { ConnectedPayeeList } from 'entities/transfers'
import { useDeleteExternalPayeeMutation } from 'api/endpoints/fundsMovement'
import { ButtonTab } from 'shared/ui/buttons'
import {
  FinicityConnect,
  ConnectEventHandlers,
  ConnectOptions,
  ConnectDoneEvent,
  ConnectCancelEvent,
  ConnectErrorEvent,
  ConnectRouteEvent,
} from '@finicity/connect-web-sdk'
import { InfoIcon } from 'shared/ui/icons'
import { Typography } from 'shared/ui/typography'
import { useDialog } from 'providers/dialogController.provider'
import { Button } from 'shared/ui/buttons'
import { useGetScheduledTransferQuery } from 'api/endpoints/scheduledTransfer'

const WARNING_TEXT =
  'You have reached maximum number of the connected accounts. Please contact support to increase allowed number of connected accounts.'
const DIALOG_TEXT = 'You have scheduled transfers for this payee. Do you want to remove the payee and all associated scheduled and recurring transfers?'


export const ManageConnectedAccount = () => {
  const dialog = useDialog()
  const { goNext } = useDrawerStepperContext()
  const { setSnackBar } = useSnackBar()

  const { data: scheduledTransfers, isLoading: isLoadingScheduledTransfers } =
  useGetScheduledTransferQuery()

  const { plaidPayees, isFetchingPayees } = useExternalPayees()

  const { data: plaidToken, isLoading: isLoadingCreatePlaidLinkToken } =
    useCreatePlaidLinkTokenControllerQuery({ platform: 'WEB' })
  const [
    createPlaidExchangePublicTokenMutation,
    { isLoading: isLoadingCreatePlaidExchangePublicToke },
  ] = useCreatePlaidExchangePublicTokenControllerMutation()

  const { data: finicityLink, isLoading: isLoadingFinicityLink } =
    useCreateFinicityLinkQuery()

  const [deleteExternalPayee, { isLoading: isLoadingDeleteExternalPayee }] =
    useDeleteExternalPayeeMutation()

  const [deletingPayeeId, setDeletingPayeeId] = useState<string | null>(null)

  const plaidOnSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    try {
      if (metadata.institution) {
        await createPlaidExchangePublicTokenMutation({
          publicToken,
          accounts: metadata.accounts,
          institution: {
            name: metadata.institution.name,
            id: metadata.institution.institution_id,
          },
        }).unwrap()
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      })
    }
  }
  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    plaidOnSuccess(publicToken, metadata)
    goNext('9')
  }, [])

  const { open: openPlaid, ready: readyPlaid } = usePlaidLink({
    token: plaidToken ? plaidToken : null,
    onSuccess,
  })

  const isLoadingPlaid =
    isLoadingCreatePlaidLinkToken || isLoadingCreatePlaidExchangePublicToke

  const connectEventHandlers: ConnectEventHandlers = {
    onDone: (event: ConnectDoneEvent) => {
      goNext('9')
    },
    onCancel: (event: ConnectCancelEvent) => {},
    onError: (event: ConnectErrorEvent) => {},
    onRoute: (event: ConnectRouteEvent) => {},
    onUser: (event: any) => {},
    onLoad: () => {},
  }

  const connectOptions: ConnectOptions = {
    overlay: 'rgba(17, 17, 18, 0.16)',
  }

  const openFinicity = () => {
    if (finicityLink) {
      FinicityConnect.launch(finicityLink, connectEventHandlers, connectOptions)
    }
  }

  const deletePayee = async (id:string) => {
    
    try {
      setDeletingPayeeId(id)
      await deleteExternalPayee({
        financialAccountId: id,
        force: true,
      }).unwrap()

      setSnackBar({
        type: 'success',
        message: 'Payee deleted successfully.',
        isShow: true,
      })
      setDeletingPayeeId(null)
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      })
    }
  }

 
  const renderSlotApplyButton = (
    <Button
      variant='text'
      className={styles.btnApplyDialog}
    >
      Delete payee
    </Button>
  )
  const renderSlotCancelButton = <Button variant='text'>Close</Button>

  const handeleDeletePayee = async (id: string) => {
    const scheduledTransferBool = scheduledTransfers?.some(transfer => transfer.fromFinancialAccountId === id)

    if(scheduledTransferBool) {
      dialog.show({
        dialogText: DIALOG_TEXT,
        slotApplyButton: renderSlotApplyButton,
        slotCancelButton: renderSlotCancelButton,
        async onApply() {
          deletePayee(id)
        },
      })
    }else{
      deletePayee(id)
    }
  }

  const disabledExtrnalPayee = !finicityLink || !readyPlaid
  const isLoadingExternalPayee = isLoadingFinicityLink || isLoadingPlaid

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        {isLoadingFinicityLink ? (
          <Skeleton
            width='100%'
            height='72px'
          />
        ) : (
          <ButtonTab
            endIcon={<ChevronRightIcon />}
            onClick={openFinicity}
            disabled={!finicityLink}
          >
            Connect external account
          </ButtonTab>
        )}

        {isLoadingPlaid ? (
          <Skeleton
            width='100%'
            height='100px'
          />
        ) : (
          <ButtonTab
            endIcon={<ChevronRightIcon />}
            onClick={() => openPlaid()}
            disabled={!readyPlaid}
          >
            Connect your financial institution or app if you face issues with
            the first option
          </ButtonTab>
        )}
        {!isLoadingExternalPayee && disabledExtrnalPayee && (
          <div className={styles.boxWarning}>
            <InfoIcon />
            <Typography>{WARNING_TEXT}</Typography>
          </div>
        )}
        <Divider />

        <ConnectedPayeeList
          payees={plaidPayees}
          isLoading={isFetchingPayees || isLoadingScheduledTransfers}
          handleDeletePayee={handeleDeletePayee} 
          isLoadingDelete={isLoadingDeleteExternalPayee}
          deletingPayeeId={deletingPayeeId}
        />
      </div>
    </TransitionAnimation>
  )
}
