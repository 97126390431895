import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import FormNewAutorizedSubUser from 'components/formNewAutorizedUser';
import ManageAccess from 'components/manageAccess';
import styles from './style.module.scss';
import {
  useUpdateSubUserControllerMutation,
  useAttachToPaymentCardControllerMutation,
  useAttachToFinAccControllerMutation,
  useDeleteSubUserControllerMutation,
} from 'api/endpoints/subProfiles';
import {
  useSubUserAccessRightsControllerQuery,
  useEditSubUserAccessRightsControllerMutation,
} from 'api/endpoints/usersManagement';
import { useSubUsersAttachedFinancialAccounts } from 'api/hooksApi/useSubUsersAttachedFinancialAccounts';
import MoonLoader from 'react-spinners/MoonLoader';
import { AnimateWrapper } from 'components/animate-wrapper';
import ChooseCardsToConnectSubUser from 'components/chooseCardsToConnectSubUser';
import ChooseFinAccToConnectSubUser from 'components/chooseFinAccToConnectSubUser';
import * as yup from 'yup';
import RemoveSubUserModal from './components/removeSubUserModal';
import { useSubUser } from 'api/hooksApi/useSubUser';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { Button } from '@mui/material';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email address'
    ),
  name: yup.string().required('Name is required'),
  lastName: yup.string().required('Last name is required'),
});

type EditSubUserPageMode = 'manage' | 'manage-card' | 'manage-account';

interface EditAuthorizedSubUserProps {
  setSuccessMessage?: (value: string) => void;
}

const EditAuthorizedSubUser = ({
  setSuccessMessage,
}: EditAuthorizedSubUserProps) => {
  const { t } = useTranslationProvider();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '';
  const subUserId = searchParams.get('id') || '';
  const navigate = useNavigate();
  const { subUser, subUserIsLoading } = useSubUser(subUserId);
  const {
    data: subUserAccess,
    isLoading: subUserAccessIsLoading,
    isSuccess: subUserAccessIsSuccess,
  } = useSubUserAccessRightsControllerQuery({ subUserId });

  const { subUserAttachedFinAccRefetch } =
    useSubUsersAttachedFinancialAccounts(subUserId);
  const [name, setName] = useState<string>(subUser.givenName);
  const [lastName, setLastName] = useState<string>(subUser.familyName);
  const [email, setEmail] = useState<string>(subUser.email);
  const [department, setDepartment] = useState<string>(subUser.department);
  const [manageVisibleAccess, setManageVisibleAccess] =
    useState<boolean>(false);
  const [bankingCardAccess, setBankingCardAccess] = useState<boolean>(true);
  const [financialAccounAccess, setFinancialAccounAccess] =
    useState<boolean>(true);
  const [allViewAccess, setAllViewAccess] = useState<boolean>(true);
  const [makeTransferAccess, setMakeTransferAccess] = useState<boolean>(true);
  const [fullAccess, setFullAccess] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>({});
  const [updateSubUserMutation, { isLoading: updateSubUserIsLoading }] =
    useUpdateSubUserControllerMutation();
  const [
    editSubUserAccessRightsMutation,
    { isLoading: editSubUserAccessRightsIsLoading },
  ] = useEditSubUserAccessRightsControllerMutation();
  const [deleteSubUserMutation] = useDeleteSubUserControllerMutation();
  const [attachToPaymentCardMutation] =
    useAttachToPaymentCardControllerMutation();
  const [attachToFinAccMutation] = useAttachToFinAccControllerMutation();
  const isLoading = updateSubUserIsLoading || editSubUserAccessRightsIsLoading;
  const [editSubUserPageMode, setEditSubUserPageMode] =
    useState<EditSubUserPageMode>('manage');
  const [newCardId, setNewCardId] = useState<string>('');
  const [newAccountId, setNewAccountId] = useState<string>('');
  const [removeSubUserModal, setRemoveSubUserModal] = useState<boolean>(false);
  const [closeLinkedCardsModal, setCloseLinkedCardsModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (subUserAccessIsSuccess) {
      setBankingCardAccess(subUserAccess.openBankingCard);
      setFinancialAccounAccess(subUserAccess.openFinancialAccount);
      setAllViewAccess(subUserAccess.viewAllCardsAndFinancialAccounts);
      setMakeTransferAccess(subUserAccess.makeTransfers);
      setFullAccess(subUserAccess.mainAccess);
    }
  }, [subUserAccessIsSuccess]);

  useEffect(() => {
    subUserAccess &&
      setManageVisibleAccess(Object.values(subUserAccess).some((e) => e));
  }, [subUserAccess]);

  useEffect(() => {
    if (newCardId) {
      connectedNewCard(newCardId);
    }
  }, [newCardId]);

  useEffect(() => {
    if (newAccountId) {
      connectedNewAccount(newAccountId);
    }
  }, [newAccountId]);

  const connectedNewCard = async (newCardId) => {
    await attachToPaymentCardMutation({
      PaymentCardId: newCardId,
      SubProfileId: subUserId,
    });
  };

  const connectedNewAccount = async (newAccountId) => {
    await attachToFinAccMutation({
      financialAccountId: newAccountId,
      SubProfileId: subUserId,
    });
    subUserAttachedFinAccRefetch();
  };

  const accessProps = {
    subUserAccess,
    manageVisibleAccess,
    setManageVisibleAccess,
    bankingCardAccess,
    setBankingCardAccess,
    financialAccounAccess,
    setFinancialAccounAccess,
    allViewAccess,
    setAllViewAccess,
    makeTransferAccess,
    setMakeTransferAccess,
    fullAccess,
    setFullAccess,
    isLoading: subUserAccessIsLoading,
  };
  const formProps = {
    name,
    setName,
    lastName,
    setLastName,
    email,
    setEmail,
    department,
    setDepartment,
    errors,
    setErrors,
    createSub: false,
  };

  const validateForm = async () => {
    const values = { email, name, lastName };
    try {
      await schema.validate(values, { abortEarly: false });
      setErrors('');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        const errors = {};
        error.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
        setErrors(errors);
      }
    }
  };
  const editSubUser = async () => {
    try {
      const propertiesSubUserNotEqua = !(
        email === subUser.email &&
        name === subUser.givenName &&
        lastName === subUser.familyName &&
        department === subUser.department
      );

      const subUserAccessNotEqua = !(
        bankingCardAccess === subUserAccess!.openBankingCard &&
        financialAccounAccess === subUserAccess!.openFinancialAccount &&
        allViewAccess === subUserAccess!.viewAllCardsAndFinancialAccounts &&
        makeTransferAccess === subUserAccess!.makeTransfers &&
        fullAccess === subUserAccess!.mainAccess
      );

      await schema.validate({ email, name, lastName }, { abortEarly: false });
      if (propertiesSubUserNotEqua) {
        await updateSubUserMutation({
          email: email,
          givenName: name,
          familyName: lastName,
          department: department,
          subProfileId: subUser.id,
        }).unwrap();
      } else if (subUserAccessNotEqua) {
        const data = {
          openBankingCard: bankingCardAccess,
          openFinancialAccount: financialAccounAccess,
          viewAllCardsAndFinancialAccounts: allViewAccess,
          makeTransfers: makeTransferAccess,
          mainAccess: fullAccess,
        };
        await editSubUserAccessRightsMutation({
          data,
          subUserId: subUser.id,
        }).unwrap();
      } else if (!subUserAccessNotEqua && !propertiesSubUserNotEqua) {
        return;
      }
      setSearchParams({ page });
      setSuccessMessage &&
        setSuccessMessage('Authorized user information updated successfully');
    } catch (error: any) {
      validateForm();
      console.log('e', error);
    }
  };

  const onConfirmDelete = async (deleteCards = true) => {
    try {
      await deleteSubUserMutation({
        id: subUserId!,
        deleteCards,
      });
      setSearchParams({ page });
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleRemoveSubUser = () => {
    setRemoveSubUserModal(false);
    setCloseLinkedCardsModal(true);
  };

  const handleCloseLinkedCardsTrue = () => {
    setCloseLinkedCardsModal(false);
    onConfirmDelete(true);
  };
  const handleCloseLinkedCardsFalse = () => {
    setCloseLinkedCardsModal(false);
    onConfirmDelete(false);
  };

  if (editSubUserPageMode === 'manage') {
    return (
      <AnimateWrapper className='fade'>
        <div>
          <div>
            <FormNewAutorizedSubUser {...formProps} />
          </div>

          <div className={styles.boxConnected}>
            <ManageAccess {...accessProps} />
          </div>

          {isLoading ? (
            <div style={{ display: 'flex', paddingLeft: '20%', marginTop: 10 }}>
              <MoonLoader />
            </div>
          ) : (
            <div className={styles.boxButton}>
              <Button
                onClick={editSubUser}
                variant='contained'
              >
                {t('common.Save Changes', {
                  defaultValue: 'Save Changes',
                })}
              </Button>

              <Button
                onClick={() => setRemoveSubUserModal(true)}
                variant='outlined'
              >
                {t('common.Delete', {
                  defaultValue: 'Delete',
                })}
              </Button>
            </div>
          )}
        </div>
        <RemoveSubUserModal
          handleSubmit={handleRemoveSubUser}
          handleCanceled={() => setRemoveSubUserModal(false)}
          stateModal={removeSubUserModal}
          label={t('Settings.Are you sure you want to remove Authorized User', {
            defaultValue: 'Are you sure you want to remove Authorized User?',
          })}
        />

        <RemoveSubUserModal
          stateModal={closeLinkedCardsModal}
          handleSubmit={handleCloseLinkedCardsTrue}
          handleCanceled={handleCloseLinkedCardsFalse}
          buttonSubmitLabel={'Yes'}
          buttonCanceledLabel={'No'}
          label={t(
            'Settings.Do you want to close cards linked to the authorized user',
            {
              defaultValue:
                'Do you want to close cards linked to the authorized user?',
            }
          )}
        />
      </AnimateWrapper>
    );
  }

  if (editSubUserPageMode === 'manage-card') {
    return (
      <ChooseCardsToConnectSubUser
        setPageStatus={setEditSubUserPageMode}
        backRoute={'EditSubUserCard'}
        setNewCardId={setNewCardId}
      />
    );
  }

  if (editSubUserPageMode === 'manage-account') {
    return (
      <ChooseFinAccToConnectSubUser
        setPageStatus={setEditSubUserPageMode}
        backRoute={'EditSubUserAcc'}
        setNewAccountId={setNewAccountId}
      />
    );
  }

  return null;
};

export default EditAuthorizedSubUser;
