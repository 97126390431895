import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';

interface ImageUrls {
  contentType: string;
  filePath: string;
  id: number;
}

export interface RewardIntegrationsDTO {
  name: string;
  description: string;
  url: string;
  imageUrls: ImageUrls[];
  type: number;
  id: number;
}

type RewardIntegrationsResponse = RewardIntegrationsDTO[];

export const rewardsIntegrationsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'rewardsIntegrations',
  endpoints: (builder) => ({
    rewardIntegrationsController: builder.query<
      RewardIntegrationsResponse,
      void
    >({
      query: () => ({
        url: 'api/RewardIntegrations',
        method: 'GET',
      }),
    }),
  }),
});

export const { useRewardIntegrationsControllerQuery } = rewardsIntegrationsApi;
