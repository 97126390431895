import { Button } from "@mui/material";
import { FC, ReactNode } from "react";
import styles from "./style.module.scss";

interface AddButtonProps {
  onClick: () => void;
  label: string;
}
const AddButton: FC<AddButtonProps> = ({ onClick, label }) => {
  return (
    <div className={styles.newAccountContainer}>
      <Button
        onClick={onClick}
        className={styles.buttonOpenAcc}
        variant="contained"
      >
        {label}
      </Button>
    </div>
  );
};
export default AddButton;
