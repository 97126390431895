import { useMemo } from 'react';
import { useSubUsersAvailablePaymentCardsControllerQuery } from '../endpoints/subProfiles';
import { useSubUsersControllerQuery } from '../endpoints/subProfiles';

export const useSubUsersAvailablePaymentCards = (subUserId?: string) => {
  const {
    data: subUsersAvailablePaymentCards,
    isFetching: subUsersAvailablePaymentCardsIsLoading,
    isSuccess: subUsersAvailablePaymentCardsIsSuccess,
    refetch: subUsersAvailablePaymentCardsRefetch,
  } = useSubUsersAvailablePaymentCardsControllerQuery({});

  const { data: subUsers, isLoading: subUsersIsLoading, refetch: refetchSubUsers } =
    useSubUsersControllerQuery();

  const value = useMemo<any>(() => {
    if (!subUsersAvailablePaymentCards) {
      return [];
    }

    if (subUsersAvailablePaymentCards && subUserId && subUsers) {
      const subUser = subUsers.find((item) => item.id.toString() === subUserId);
      let cards = subUsersAvailablePaymentCards
        .filter((i) => i.status !== 'CLOSED')
        .map((i) => {
          return {
            ...i,
            selected: Boolean(
              subUser?.attachedPaymentCards?.find((a) => a.id === i.id)
            ),
          };
        });
      cards = [
        ...(subUser?.attachedPaymentCards
          ? subUser?.attachedPaymentCards.map((i) => ({
              ...i,
              selected: true,
              attached: true,
            }))
          : []),

        ...cards,
      ];

      cards = cards.filter((card, index, self) => {
        return self.findIndex((c) => c.id === card.id) === index;
      });
      return cards;
    } else {
      let cards = subUsersAvailablePaymentCards.filter(
        (i) => i.status !== 'CLOSED'
      );

      return cards;
    }
  }, [subUsersAvailablePaymentCards, subUserId, subUsers]);

  return {
    subUsersAvailablePaymentCards: value,
    subUsersAvailablePaymentCardsIsLoading:
      subUsersAvailablePaymentCardsIsLoading || subUsersIsLoading,
    subUsersAvailablePaymentCardsIsSuccess,
    subUsersAvailablePaymentCardsRefetch,
    refetchSubUsers,
  };
};
