import { useMediaQuery, useTheme } from "@mui/material";
import {
  PaymentCard,
  useRenamePaymentCardNameControllerMutation,
} from "api/endpoints/paymentCards";
import {
  EnumTransactionStatuses,
  Filters,
  Transaction,
  useAvailableToSpendCardControllerQuery,
} from 'api/endpoints/transaction';
import {
  useCardApprovedTransactionsControllerMutation,
  useCardPendingTransactionsControllerMutation,
} from "api/endpoints/transactionEvents";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import {
  PaymentCardDetails,
  usePaymentCardByid,
} from "api/hooksApi/usePaymentCardById";
import { usePaymentCards } from "api/hooksApi/usePaymentCards";
import moment from "moment";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { usePaymentCardLimitsControllerQuery } from "api/endpoints/spendRules";
import helper from "services/helper";
import { StatusComponent } from "shared/components/StatusComponent";
export interface AvailableToSpend{
  available: number;
  availableWithLimit: number;
  hasLimit: boolean;
  limit: number ;
  spend: number
}

interface ContextProps {
  cardId: string;
  paymentCards: PaymentCard[];
  currentCard: PaymentCard;
  paymentCardById: (paymentCardId: string) => Promise<PaymentCardDetails>;
  renamePaymentCard: (value: {
    paymentCardId: string;
    nickName: string;
  }) => any;
  setMessage: (value: string) => void;
  getAllTransactions: (filters: Filters) => void;
  handleChangePeriod: (period: Period) => void;
  filter: Filters;
  periodDate: Period;
  message: string;
  paymentCardDetails: PaymentCardDetails;
  availableToSpend: AvailableToSpend;
  pendingTransactions: Transaction[];
  approvedTransactions: Transaction[];
  matches: boolean;
  transactionsIsLoading: boolean;
  cardsIsLoading: boolean;
  renamePaymentCardIsLoading: boolean;
  paymentCardsIsFetching: boolean;
}
interface Period {
  startDate: string;
  endDate: string;
}
const Context = React.createContext<ContextProps | undefined>(undefined);

export const CardInformationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { cardId } = useParams();
  const { data: availableToSpend, isLoading: availableToSpendIsLoading } =
    useAvailableToSpendCardControllerQuery({
      paymentCardId: cardId!,
    });

  
  const { paymentCards, paymentCardsIsLoading, paymentCardsIsFetching } =
    usePaymentCards();
  const { paymentCardById, paymentCardByIdIsLoading } = usePaymentCardByid();
  const [periodDate, setPeriodDate] = useState<any>({
    startDate: moment().subtract(2, "months").toDate(),
    endDate: moment().toDate(),
  });
  const [
    cardPendingTransactionsMutation,
    { isLoading: pendingTransactionsisLoading },
  ] = useCardPendingTransactionsControllerMutation();
  const [
    cardApprovedTransactionsMutation,
    { isLoading: approvedTransactionsisLoading, data: cardApprovedData },
  ] = useCardApprovedTransactionsControllerMutation();
  const { filter } = cardApprovedData || {};

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState<string>("");
  const [paymentCardDetails, setPaymentCardDetails] =
    useState<PaymentCardDetails>();
  const [pendingTransactions, setPendingTransactions] =
    useState<Transaction[]>();
  const [approvedTransactions, setApprovedTransactions] =
    useState<Transaction[]>();
  const [renamePaymentCard, { isLoading: renamePaymentCardIsLoading }] =
    useRenamePaymentCardNameControllerMutation({});
  useEffect(() => {
    getCardInfo(cardId);
  }, [cardId]);
  useEffect(() => {
    getCardInfo(cardId);
    getPendingTransactions(filter);
    getApprovedTransactions(filter);
  }, []);

  const getCardInfo = async (paymentCardId) => {
    try {
      const resCardInfo = await paymentCardById(paymentCardId);

      if (resCardInfo) {
        setPaymentCardDetails(resCardInfo);
      }
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const getPendingTransactions = async (filterValue) => {
    try {
      const res = await cardPendingTransactionsMutation({
        paymentCardId: cardId,
        dateFrom: moment(new Date(periodDate.startDate)).format(
          "YYYY-MM-DDT00:00:ss.SSS\\Z"
        ),
        dateTo: moment(new Date(periodDate.endDate)).format(
          "YYYY-MM-DDT23:59:ss.SSS\\Z"
        ),
        filter: filterValue,
      }).unwrap();

      if (res) {
        setPendingTransactions(res.data?.filter(t => t.status === EnumTransactionStatuses.PENDING));
      }
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const getApprovedTransactions = async (filterValue) => {
    try {
      const res = await cardApprovedTransactionsMutation({
        paymentCardId: cardId,
        dateFrom: moment(new Date(periodDate.startDate)).format(
          "YYYY-MM-DDT00:00:ss.SSS\\Z"
        ),
        dateTo: moment(new Date(periodDate.endDate)).format(
          "YYYY-MM-DDT23:59:ss.SSS\\Z"
        ),
        filter: filterValue,
      }).unwrap();

      if (res?.data) {
        setApprovedTransactions(res.data?.filter(t => t.status !== EnumTransactionStatuses.PENDING));
      }
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const getAllTransactions = (filterValue: Filters) => {
    getApprovedTransactions(filterValue);
    getPendingTransactions(filterValue);
  };
  const handleChangePeriod = (value) => {
    const period = {
      startDate: value.startDate,
      endDate: value.endDate,
    };
    setPeriodDate(period);
  };
  const value = useMemo((): ContextProps => {
    const cardsIsLoading =
      availableToSpendIsLoading ||
      paymentCardsIsLoading ||
      paymentCardByIdIsLoading;

    const transactionsIsLoading =
      pendingTransactionsisLoading || approvedTransactionsisLoading;
    const currentCard = paymentCards.find((card) => card.id === cardId);


    return {
      transactionsIsLoading,
      cardsIsLoading,
      renamePaymentCardIsLoading,
      cardId,
      paymentCards,
      currentCard,
      paymentCardDetails,
      availableToSpend,
      pendingTransactions,
      approvedTransactions,
      matches,
      filter,
      periodDate,
      renamePaymentCard,
      getAllTransactions,
      handleChangePeriod,
      message,
      setMessage,
      paymentCardsIsFetching,
    } as unknown as ContextProps;
  }, [
    cardId,
    paymentCards,
    paymentCardDetails,
    availableToSpend,
    pendingTransactions,
    approvedTransactions,
    matches,
    renamePaymentCardIsLoading,
    message,
    periodDate,
    filter,
    paymentCardsIsLoading,
    paymentCardByIdIsLoading,
    availableToSpendIsLoading,
    paymentCardsIsFetching,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCardInformation = () =>
  React.useContext(Context) as ContextProps;
