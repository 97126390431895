import { useMemo } from "react";
import { useCurrentUser } from "../../providers/user.provider";
import {
  FinancialAccount,
  useFinancialAccountsControllerQuery,
  useFinancialAccountsMyControllerQuery,
  FinancialAccountTotal,
} from "api/endpoints/financialAccounts";
import { Account } from "shared/models/ICards";

interface FinancialAccountsReturnType {
  financialAccounts: FinancialAccount[];
  total: FinancialAccountTotal;
  unclosedFinancialAccounts: FinancialAccount[];
  financialAccountsIsLoading: boolean;
  financialAccountsIsSuccess: boolean;
  finAccountsRefetch: () => void;
}

export const useFinancialAccounts = (): FinancialAccountsReturnType => {
  const { isSubUser } = useCurrentUser();
  const {
    data: financialAccounts,
    isLoading: finAccountsIsLoading,
    isSuccess: financialAccountsIsSuccess,
    refetch: finAccountsRefetch,
  } = useFinancialAccountsControllerQuery({});

  const {
    data: myFinancialAccounts,
    isLoading: myFinancialAccountsIsLoading,
    isSuccess: myFinancialAccountsIsSuccess,
  } = useFinancialAccountsMyControllerQuery({ enable: isSubUser });

  const financialAccountsData = () => {
    if (!financialAccounts) {
      return [];
    }

    if (isSubUser && !myFinancialAccounts) {
      return [];
    }

    let temptFinanncialAccountsArr: FinancialAccount[] =
      financialAccounts.financialAccounts;

    if (isSubUser) {
      temptFinanncialAccountsArr = financialAccounts.financialAccounts.map(
        (i) => {
          return {
            ...i,
            isMine: myFinancialAccounts.includes(i.financialAccountId),
          };
        }
      );
    }

    return [...temptFinanncialAccountsArr].sort(
      (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
    );
  };

  const unclosedFinancialAccounts = () => {
    if (financialAccountsData().length === 0) {
      return [];
    }

    return financialAccountsData().filter((fa) =>
      ["ACTIVE", "UNDER_REVIEW"].includes(fa.status)
    );
  };
  const financialAccountsTotal = () => {
    if (!financialAccounts?.totals) {
      return {
        cash: 0,
        availableCash: 0,
        cashIn: 0,
        cashOut: 0,
      };
    }
    let temtTotal: FinancialAccountTotal = financialAccounts.totals;
    return temtTotal;
  };

  return {
    financialAccounts: financialAccountsData(),
    total: financialAccountsTotal(),
    unclosedFinancialAccounts: unclosedFinancialAccounts(),
    financialAccountsIsLoading:
      finAccountsIsLoading || myFinancialAccountsIsLoading,
    financialAccountsIsSuccess: financialAccountsIsSuccess,
    finAccountsRefetch,
  };
};
