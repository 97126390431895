import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from 'api/baseApi';
import { ApiTags } from "../api-tags";
import { NotificationsGroup, NotificationsLevel} from 'enums';

export interface GetNotificationsApi {
    id: number;
    createdDate: string;
    subject: string; 
    body: string;
    group: NotificationsGroup;
    level: NotificationsLevel; 
    dateRead: string | null;
}

export interface GetNotificationsNewApi {
    group: NotificationsGroup;
    count: number;
}

interface NotificationsReadArg {
    ids?: number[];
    notificationGroup?: NotificationsGroup;
    readAll: boolean; 
}

export const notificationsApi = createApi({
    baseQuery: rtkBaseQuery,
    reducerPath: 'notification',
    tagTypes: [
        ApiTags.GetNotifications,
      ],
    endpoints: (builder) => ({
        getNotifications: builder.query<GetNotificationsApi[],void>({
            providesTags: [ApiTags.GetNotifications],
            query: () => ({
                url: 'api/notifications',
                method: 'GET',
            }),
        }),
        getNotificationsNew: builder.query<GetNotificationsNewApi[],void>({
            providesTags: [ApiTags.GetNotifications],
            query: () => ({
                url: 'api/notifications/new',
                method: 'GET',
            }),
        }),
        notificationsRead: builder.mutation<void,NotificationsReadArg>({
            invalidatesTags: [ApiTags.GetNotifications],
            query: (queryArg) => ({
                url: 'api/notifications/read',
                method: 'PUT',
                body: queryArg,
            }),
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    useGetNotificationsNewQuery,
    useNotificationsReadMutation
} = notificationsApi;