import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AnimateWrapper } from "../animate-wrapper";
import { Skeleton } from "../skeleton";
import styles from "./style.module.scss";
import { useSubUsersAvailableFinancialAccounts } from "api/hooksApi/useSubUsersAvailableFinancialAccounts";
import CasesOutlined from "../../assets/svg/CasesOutlined.svg";
import helper from "../../services/helper";
import WarningModal from "../modals/WarningModal";
import NewAccount from "../../shared/components/newAccount";
import { Button, Radio, RadioGroup } from "@mui/material";

interface PropTypes {
  setPageStatus: (string) => void;
  backRoute: string;
  selectAccounts?: any[];
  setSelectAccounts?: (selectAccounts: any[]) => void;
  setNewAccountId?: (string) => void;
}

type ChoosePrimaryCardAccToConnectSubUser =
  | "manage-primary-account"
  | "open-primary-account";

const ChoosePrimaryCardAccToConnectSubUser = ({
  setPageStatus,
  backRoute,
  setSelectAccounts = () => {},
  selectAccounts = [],
  setNewAccountId = () => {},
}: PropTypes) => {
  const { subUserId } = useParams();

  const {
    subUsersAvailableFinancialAccounts,
    subUsersAvailableFinancialAccountsIsLoading,
  } = useSubUsersAvailableFinancialAccounts(subUserId);
  const [availableAccounts, setAvailableAccounts] = useState<any>([]);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [
    choosePrimaryCardAccToConnectSubUserMode,
    setChoosePrimaryCardAccToConnectSubUserMode,
  ] = useState<ChoosePrimaryCardAccToConnectSubUser>("manage-primary-account");

  useEffect(() => {
    setAvailableAccounts(subUsersAvailableFinancialAccounts);
  }, [subUsersAvailableFinancialAccounts]);

  useEffect(() => {
    if (selectAccounts.length) {
      updateAvailableCardsWithSelection();
    }
  }, []);

  const updateAvailableCardsWithSelection = () => {
    setAvailableAccounts((prevAccounts) => {
      return prevAccounts.map((acc) => {
        if (
          selectAccounts.find(
            (selectCard) => selectCard.id === acc.financialAccountId
          )
        ) {
          return {
            ...acc,
            selected: true,
          };
        }
        return acc;
      });
    });
  };
  const selectedAcc = availableAccounts.filter((acc) => acc.selected === true);
  const sumbit = () => {
    setSelectAccounts(selectedAcc);
    setPageStatus("manage");
  };

  const sortByDate = (a, b) => {
    const dateA = new Date(a.created);
    const dateB = new Date(b.created);
    return Number(dateA) - Number(dateB);
  };

  const renderItems = () => {
    return availableAccounts?.sort(sortByDate).map((acc, index) => (
      <AnimateWrapper delay={index * 100} key={acc.financialAccountId}>
        <div key={acc.financialAccountId} className={styles.listItem}>
          <div className={styles.blockName}>
            <div className={styles.blockName_img}>
              <img src={CasesOutlined} />
            </div>

            <div className={styles.blockMoney}>
              <p className={styles.blockName_text}>{acc.name}</p>
              <p className={styles.blockMoney_text}>
                $ {helper.moneyFormat(acc.availableCash?.value)}
              </p>
            </div>
          </div>
          <div className={styles.mobView}>
            <div className={styles.blockNumb}>
              <p className={styles.blockNumb_text}>
                ****
                {acc?.accountNumber?.slice(-4)}
              </p>
            </div>
          </div>
          <RadioGroup
            value={acc.selected ? "selected" : "notSelected"}
            onChange={() => onSelect(acc)}
          >
            <Radio
              value="selected"
              checked={acc.selected}
              color="primary"
              className={styles.radio}
            />
          </RadioGroup>
        </div>
      </AnimateWrapper>
    ));
  };

  const onSelect = (acc) => {
    setAvailableAccounts((prevAccounts) => {
      return prevAccounts.map((i) => {
        if (acc.financialAccountId === i.financialAccountId) {
          return {
            ...i,
            selected: !i.selected,
          };
        } else {
          return {
            ...i,
            selected: false,
          };
        }
      });
    });
  };

  if (choosePrimaryCardAccToConnectSubUserMode === "manage-primary-account") {
    return (
      <AnimateWrapper className="fade">
        <div>
          <div className={styles.container}>
            <div className="w-[509px]">
              <p className={styles.container_title}>
                Choose an account for primary card
              </p>
              <div className={styles.addButtonContainer}>
                <Button
                  onClick={() =>
                    setChoosePrimaryCardAccToConnectSubUserMode(
                      "open-primary-account"
                    )
                  }
                  variant="text"
                >
                  {"+ open new account"}
                </Button>
              </div>
              <div className={styles.container_list}>
                {subUsersAvailableFinancialAccountsIsLoading ? (
                  <Skeleton height="75px" />
                ) : (
                  renderItems()
                )}
              </div>
            </div>
          </div>
          <div className={styles.blockButton}>
            <Button
              disabled={!selectedAcc.length}
              onClick={sumbit}
              variant="contained"
            >
              Save Changes
            </Button>
          </div>
        </div>

        <WarningModal
          titleMassage={"You must select a financial account"}
          onClose={() => setWarningModal(false)}
          isOpen={warningModal}
        />
      </AnimateWrapper>
    );
  }

  if (choosePrimaryCardAccToConnectSubUserMode === "open-primary-account") {
    return (
      <NewAccount
        setPageStatus={setPageStatus}
        setOpenNewAccountId={setNewAccountId}
        backRoute={backRoute}
        setChooseFinAccMode={setChoosePrimaryCardAccToConnectSubUserMode}
        chooseFinAccToConnectSubUserMode={
          choosePrimaryCardAccToConnectSubUserMode
        }
      />
    );
  }
  return null;
};

export default ChoosePrimaryCardAccToConnectSubUser;
