import { TransitionAnimation } from 'shared/ui/animations';
import { Accordion } from '../accordion';
import { FormControlLabel, Checkbox, FormHelperText } from '@mui/material';
import styles from './style.module.scss';
import { useAccount } from 'pages/accountComponent/provider';
import { FilterProps } from 'pages/accountComponent/types';
import { Skeleton } from 'components/skeleton';

export const FilterByMoneyMovement = (props: FilterProps) => {
  const { filterObj, setFilterObj, loading } = props;
  const { expandFilter, setExpandFilter } = useAccount();
  const { expandByMoneyMovement } = expandFilter;

  const byMoneyMovement = [filterObj.deposit, filterObj.withdraw];

  const handleChangeExpand = (value: boolean) => {
    setExpandFilter({
      ...expandFilter,
      expandByMoneyMovement: value,
    });
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedFilters = {
      ...filterObj,
      all: {
        displayName: 'Select all',
        value: false,
        items: null,
      },
      [name.toLowerCase()]: {
        ...filterObj[name.toLowerCase()],
        value: checked,
      },
    };
    setFilterObj(updatedFilters);
  };

  const renderCheckbox = () => {
    return byMoneyMovement.map((item) => (
      <FormControlLabel
        key={item?.displayName}
        classes={{ root: styles.rootFormControlLabel }}
        control={
          <Checkbox
            classes={{
              checked: styles.checked,
            }}
            checked={item?.value}
            onChange={handleCheckboxChange}
            name={item?.displayName}
          />
        }
        label={item?.displayName}
      />
    ));
  };
  return loading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <Accordion
        expanded={expandByMoneyMovement}
        onChangeExpanded={handleChangeExpand}
        summary='By money movement'
      >
        {renderCheckbox()}
        {!filterObj.deposit.value && !filterObj.withdraw.value && (
          <FormHelperText error={true}>
            Select at least one options
          </FormHelperText>
        )}
      </Accordion>
    </TransitionAnimation>
  );
};
