import { z } from 'zod'

export const ToMyOwnAccountSchema = z
  .object({
    fromFinancialAccount: z.string().min(1),
    toFinancialAccount: z.string().min(1),
    amount: z
      .object({
        value: z.string(),
        formattedValue: z.string(),
        floatValue: z.number(),
      })

      .required(),
  })
  .refine((data) => data.amount.floatValue !== 0, {
    message: 'Float value must not be zero',
    path: ['amount'],
  })

export type ToMyOwnAccount = z.infer<typeof ToMyOwnAccountSchema>
