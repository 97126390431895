import { EnumCardStatuses, PaymentCard } from 'api/endpoints/paymentCards';
import { PaymentCardDetails, usePaymentCardByid, } from 'api/hooksApi/usePaymentCardById';
import { usePaymentCards } from 'api/hooksApi/usePaymentCards';
import React, { createContext, PropsWithChildren, useContext, useEffect, useState, } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTimeout } from '../../../shared/hooks/useTimeout';
import DrawerModal from '../../../shared/components/drawerModal';
import OrderPhysiacalCardDeliveryStatus from '../../cardTransactions/orderPhysiacalCardDeliveryStatus';
import { CARD_ORDER_STATUS_QS_NAME } from '../../../routes';

const CardManageContext = createContext({});

interface ContextProps {
  currentCard: PaymentCard;
  cardsIsLoading: boolean;
  paymentCardDetails: PaymentCardDetails;
  message: string;
  setMessage: (value: string) => void;
  currentCardIsLocked: boolean;
  openOrderStatus(orderId: string): void;

  refetchCard(): Promise<void>;
}

const CardManageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { cardId } = useParams();
  const { paymentCards, paymentCardsIsLoading, paymentCardsRefetch } = usePaymentCards();
  const { paymentCardById, paymentCardByIdIsLoading } = usePaymentCardByid();
  const [paymentCardDetails, setPaymentCardDetails] =
    useState<PaymentCardDetails>();
  const [message, setMessage] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams()

  const orderStatusId = searchParams.get(CARD_ORDER_STATUS_QS_NAME);

  useEffect(() => {
    getCardInfo(cardId);
  }, [cardId]);

  const getCardInfo = async (paymentCardId) => {
    try {
      const resCardInfo = await paymentCardById(paymentCardId);

      if (resCardInfo) {
        setPaymentCardDetails(resCardInfo);
      }
    } catch (e: any) {
      console.log('error', e);
    }
  };
  const currentCard = paymentCards.find((card) => card.id === cardId);
  const cardsIsLoading = paymentCardsIsLoading;

  const refetchCard = async () => {
    paymentCardsRefetch();
    await getCardInfo(cardId);
  }

  // after issued new card around 5 min we have financialAccounts is null value
  // this hinoat bug, this effect fixed this
  useTimeout(() => {
    if (!currentCard?.financialAccounts && paymentCards.length > 0) {
      refetchCard();
    }
  }, 5000);


  const handkeOpenOrderStatus = (orderId: string) => {
    setSearchParams({
      [CARD_ORDER_STATUS_QS_NAME]: orderId,
    })
  }

  const handleCloseOrderStatus = () => {
    setSearchParams({})
  }

  return (
    <CardManageContext.Provider
      value={{
        currentCard,
        cardsIsLoading,
        paymentCardDetails,
        message,
        setMessage,
        currentCardIsLocked: paymentCardDetails?.status === EnumCardStatuses.SUSPENDED,
        refetchCard,
        openOrderStatus: handkeOpenOrderStatus,
      }}
    >
      {children}
      <DrawerModal
        isShow={!!orderStatusId}
        onClose={handleCloseOrderStatus}
        titleText="Order status"
      >
        {!!orderStatusId && <OrderPhysiacalCardDeliveryStatus orderId={orderStatusId} />}
      </DrawerModal>
    </CardManageContext.Provider>
  );
};

const useCardInformation = () => useContext(CardManageContext) as ContextProps;
export { useCardInformation, CardManageProvider };
