import { TransitionAnimation } from 'shared/ui/animations';
import { AutomationsForm } from '../manage-automations/components/automations-form';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'shared/models';
import {
  AutomationsSchema,
  selectAutomations,
  setAutomations,
} from '../../model';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import helper from 'services/helper';
import { Button as ButtonMUI } from '@mui/material';
import { Button } from 'shared/ui/buttons';
import { Skeleton } from 'components/skeleton';
import styles from './styles.module.scss';
import { useSnackBar } from 'providers/snackBar.provider';
import { useDeleteScheduledTransferMutation } from 'api/endpoints/scheduledTransfer';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';

export const ManagaAutomationsEdit = () => {
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { plaidPayees, isLoadingPayees } = useExternalPayees();
  const { setSnackBar } = useSnackBar();
  const { goNext } = useDrawerStepperContext();
  const [
    deleteScheduledTransfer,
    { isLoading: isLoadingDeleteScheduledTransfer },
  ] = useDeleteScheduledTransferMutation();

  const dispatch = useAppDispatch();
  const automations = useAppSelector(selectAutomations);

  const formik = useFormik({
    initialValues: {
      from: automations.from,
      to: automations.to,
      amount: automations.amount,
    },
    validationSchema: AutomationsSchema,
    onSubmit: (values) => {
      dispatch(setAutomations({ 
        value: {
          id: automations?.id,
          from: values.from,
          to: values.to,
          amount: values.amount,
        }
      }));
      goNext('14');
    },
  });

  const deleteAutomations = async () => {
    try {
      if (automations?.id) {
        await deleteScheduledTransfer({
          id: automations.id,
        }).unwrap();
        goNext('10');
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const optionsToAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {account.name} ({account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const disabedBtn =
    !formik.dirty ||
    !formik.isValid ||
    !formik.values.from ||
    !formik.values.to ||
    !formik.values.amount;

  const renderSlotBtn = (
    <div className={styles.btnGroup}>
      <Button
        variant='outlined'
        className={styles.btnGroupCancel}
        onClick={deleteAutomations}
        loading={isLoadingDeleteScheduledTransfer}
      >
        cancel automations
      </Button>
      <ButtonMUI
        variant='contained'
        className={styles.btnGroupSubmit}
        type='submit'
        disabled={disabedBtn || isLoadingDeleteScheduledTransfer}
      >
        save changes
      </ButtonMUI>
    </div>
  );

  return financialAccountsIsLoading || isLoadingPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <AutomationsForm
        formik={formik}
        payees={plaidPayees}
        optionsToAcc={optionsToAcc}
        warningAmount={'Enter autoreplenishment amount'}
        slotBtn={renderSlotBtn}
      />
    </TransitionAnimation>
  );
};
