import Badge from 'pages/accountComponent/components/Badge';
import { CardView } from 'shared/components/CardView';
import CardBadge from '../../card/components/cardBadge';

interface TableCells {
  label: string;
  name: string;
  props: {
    align?: string;
    component?: string;
    scope?: string;
  };
  transform?: (val: string, isPlus?: any, styles?: string) => any;
}

export const CardCells: () => TableCells[] = () => {
  return [
    {
      label: 'Card',
      name: 'cardName',
      props: { component: 'th', scope: 'row' },
      transform: (value, item, styles) => (
        <div className={styles}>
          <div>
            <CardView isSmallSize card={item}/>
          </div>
          <div>
            {value || 'Business Debit'}
            <div style={{ opacity: 0.5, fontSize: '12px' }}>
              ****{item?.last4}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: 'Cardholder name',
      name: 'cardholder',
      props: { align: 'left' },
      transform: (value, item) => {
        const isSubProfile = !!item.attachedToSubProfile;
        return (
          <div>
            {(isSubProfile && item.subProfile) || value}
            {isSubProfile && (
              <Badge item={item} text="Authorized user"/>
            )}
          </div>
        );
      },
    },
    {
      label: 'Card type',
      name: 'formFactor',
      props: { align: 'left' },
      transform: (value, item) => (
        <div style={{ textTransform: 'lowercase', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {value}
          {item.isMainCard && (
            <CardBadge isSmallSize isAuthorizedUser={item.attachedToSubProfile} />
          )}
        </div>
      ),
    },
    {
      label: 'Department',
      name: 'department',
      props: { align: 'left' },
      transform: (value) => <>{value ? value : ''}</>,
    },
    {
      label: 'Status',
      name: 'status',
      props: { align: 'left' },
      transform: (value, item) => (
        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <Badge item={item}/>
        </div>
      ),
    },
    // {
    //   label: "Available balance",
    //   name: "date",
    //   props: { align: "left" },
    // },
  ];
};
