import { TransitionAnimation } from 'shared/ui/animations';
import styles from './style.module.scss';
import { Typography } from 'shared/ui/typography';
import { Select } from 'shared/ui/inputs';
import helper from 'services/helper';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Skeleton } from 'components/skeleton';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useFormik } from 'formik';
import { FilterSchema } from '../validationFilterSchema';
import { LoadingButton } from '@mui/lab';
import { ApiFilterOptions } from '../..';
interface FilterProps {
  loadingFinancialAccounts: boolean;
  financialAccounts: FinancialAccount[];
  apiFilterOptions: ApiFilterOptions;
  setApiFilterOptions: (value: ApiFilterOptions) => void;
  setSearchQuery: (value: string) => void;
  initialApiFilterOptions: ApiFilterOptions;
}

export const Filter = (props: FilterProps) => {
  const {
    loadingFinancialAccounts,
    financialAccounts,
    apiFilterOptions,
    setApiFilterOptions,
    setSearchQuery,
    initialApiFilterOptions,
  } = props;
  const { goNext } = useDrawerStepperContext();

  const optionsAccount = financialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {account.name} ({account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const formik = useFormik({
    initialValues: {
      financialAccount: apiFilterOptions.financialAccountId,
      date: [apiFilterOptions.dateFrom, apiFilterOptions.dateTo] as [
        Dayjs | null,
        Dayjs | null,
      ],
      fromAmount: apiFilterOptions.fromAmount,
      toAmount: apiFilterOptions.toAmount,
    },

    validationSchema: FilterSchema,
    onSubmit: (values) => {
      setApiFilterOptions({
        financialAccountId: values.financialAccount,
        dateFrom: values.date[0] as Dayjs,
        dateTo: values.date[1] as Dayjs,
        fromAmount: values.fromAmount,
        toAmount: values.toAmount,
      });
      goNext('0');
    },
  });

  const onReset = () => {
    formik.setValues({
      financialAccount: initialApiFilterOptions.financialAccountId,
      date: [initialApiFilterOptions.dateFrom, initialApiFilterOptions.dateTo],
      fromAmount: initialApiFilterOptions.fromAmount,
      toAmount: initialApiFilterOptions.toAmount,
    });
    setApiFilterOptions(initialApiFilterOptions);
    setSearchQuery('');
  };

  return loadingFinancialAccounts ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <form
        className={styles.container}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.box}>
          <Typography>Account</Typography>
          <Select
            fullWidth
            label='Select account'
            options={optionsAccount}
            {...formik.getFieldProps('financialAccount')}
          />
        </div>
        <div className={styles.box}>
          <Typography>Date range</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              disableFuture
              localeText={{ start: 'From', end: 'To' }}
              calendars={1}
              value={formik.values.date}
              onChange={(newDateRange) => {
                formik.setFieldValue('date', newDateRange);
              }}
              slotProps={{
                textField: {
                  error: !!formik.errors.date,
                },
                fieldSeparator: {
                  display: 'none',
                },
                fieldRoot: {
                  direction: 'column',
                },
              }}
            />
          </LocalizationProvider>
        </div>
        <div className={styles.box}>
          <Typography>Amount range</Typography>
          <CurrencyInput2
            fullWidth
            variant='outlined'
            label='From'
            placeholder='$0.00'
            getCaretBoundary={() => [false]}
            name='fromAmount'
            value={formik.values.fromAmount}
            onChange={(value) => {
              formik.setFieldValue('fromAmount', value);
            }}
            onBlur={formik.handleBlur}
          />
          <CurrencyInput2
            fullWidth
            variant='outlined'
            label='To'
            placeholder='$0.00'
            getCaretBoundary={() => [false]}
            name='toAmount'
            value={formik.values.toAmount}
            onChange={(value) => {
              formik.setFieldValue('toAmount', value);
            }}
            onBlur={formik.handleBlur}
            error={formik.errors.toAmount?.toString()}
          />
        </div>
        <div className={styles.boxBtn}>
          <LoadingButton
            variant='text'
            onClick={() => onReset()}
          >
            <div>clear all</div>
          </LoadingButton>
          <LoadingButton
            type='submit'
            className={styles.applyBtn}
            variant='contained'
            disabled={Object.values(formik.errors).some(Boolean)}
          >
            <div>apply filter</div>
          </LoadingButton>
        </div>
      </form>
    </TransitionAnimation>
  );
};
