import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TextField } from '@mui/material'
import useGooglePlaceAutoCompleteV3 from './useGooglePlaceAutoCompleteV3'

import { IState, STATES, states } from '../../../constants/data'
import FormControl from '@mui/material/FormControl'
import { SelectChangeEvent } from '@mui/material'
import { useTranslationProvider } from 'providers/translation/translation.provider'

interface MyOldAutocompleteProps {
  address: string
  label: string
  onAddressChange: (address: string) => void
  setAddressFull: (addressFull: {}) => void
}

function MyAutocomplete_V3({
  address,
  label,
  onAddressChange,
  setAddressFull,
}: MyOldAutocompleteProps) {
  const { t } = useTranslationProvider()
  const address1Ref = useRef()
  const googleAutoCompleteSvc = useGooglePlaceAutoCompleteV3()

  const autoComplete = useRef<null | google.maps.places.Autocomplete>()

  useEffect(() => {
    onAddressChange(address)
  }, [address, onAddressChange])

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm({})

  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(
      autoComplete.current
    )

    setAddressFull(addressObj)
  }

  useEffect(() => {
    async function loadGoogleMaps() {
      autoComplete.current = await googleAutoCompleteSvc.initAutoComplete(
        address1Ref.current,
        handleAddressSelect
      )
    }
    loadGoogleMaps()
  }, [])

  return (
    <>
      <div>
        <TextField
          id='outlined-required'
          type='text'
          label={label}
          placeholder='123 W Street Rd'
          //defaultValue={address}
          inputRef={address1Ref}
          fullWidth
          value={address}
          InputLabelProps={{ shrink: true }}
          onChange={(e: any) => {
            onAddressChange(e.target.value)
          }}
        />
        {errors.address1 && (
          <span className='validation-error'>
            {t('common.Error', {
              defaultValue: 'Error',
            })}
            :{' '}
            {t('Settings.Street address is required', {
              defaultValue: 'Street address is required',
            })}
            .
          </span>
        )}
      </div>
    </>
  )
}

export default MyAutocomplete_V3
