import { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import CreditCardOutlined from 'assets/svg/CreditCardOutlined.svg';
import { useSubUsersAvailablePaymentCards } from 'api/hooksApi/useSubUsersAvailablePaymentCards';
import {
  useAttachToPaymentCardControllerMutation,
  useDetachToPaymentCardControllerMutation,
} from 'api/endpoints/subProfiles';
import styles from './style.module.scss';
import WarningModals from '../modals/WarningModal';
import NewVirtualCard from 'shared/components/newVirtualCard';
import NewProductCard from 'shared/components/newProductCard';
import NewPrimaryCard from 'shared/components/newPrimaryCard';
import { Button, Switch } from '@mui/material';
import NewPhisicalCard from 'shared/components/newPhysicalCard';
import NewBurnerCard from 'shared/components/newBurnerCard';
import helper from '../../services/helper';
import { useBoolean } from '../../shared/hooks/useBoolean';
import { LinearProgress } from '@mui/material';
import { requireNotNull } from '../../shared/utils/require-not-null';
import { useSnackBar } from '../../providers/snackBar.provider';
import { useDrawerBehavior } from '../../providers/drawer-behavior';

interface PropTypes {
  backRoute: string;
  setPageStatus: (string) => void;
  setNewCardId?: (string) => void;
}

export type ChooseCardsToConnectSubUserMode = 'manage-card' | 'open-card';

const ChooseCardsToConnectSubUser = ({
  setPageStatus,
  backRoute,
  setNewCardId = () => {
  },
}: PropTypes) => {
  const snackbar = useSnackBar();
  const mutateProcessBool = useBoolean();
  const [searchParams] = useSearchParams();
  const subUserId = searchParams.get('id') || '';

  const {
    subUsersAvailablePaymentCards,
    subUsersAvailablePaymentCardsIsLoading,
    subUsersAvailablePaymentCardsRefetch,
    refetchSubUsers,
  } = useSubUsersAvailablePaymentCards(subUserId);
  const [availableCards, setAvailableCards] = useState<any>([]);
  const [attachToPaymentCardMutation,] = useAttachToPaymentCardControllerMutation();
  const [detachToPaymentCardMutation,] = useDetachToPaymentCardControllerMutation();
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningText, setWarningText] = useState<string>('');
  const [chooseCardsToConnectSubUserMode, setChooseCardsToConnectSubUserMode] =
    useState<ChooseCardsToConnectSubUserMode>('manage-card');

  const drawerBehavior = useDrawerBehavior();

  useEffect(() => {
    if (mutateProcessBool.value) {
      drawerBehavior.lockForClose()
    } else {
      drawerBehavior.unlockForClose()
    }
  }, [mutateProcessBool.value]);

  useEffect(() => {
    setAvailableCards(subUsersAvailablePaymentCards);
  }, [subUsersAvailablePaymentCards]);

  const handleCreateNewCard = () => {
    setChooseCardsToConnectSubUserMode('open-card');
  };

  const toggleCardSelected = async (cardIdOrInstance: string | any) => {
    try {
      const card = typeof cardIdOrInstance === 'string'
        ? requireNotNull(subUsersAvailablePaymentCards.find(c => c.id === cardIdOrInstance))
        : cardIdOrInstance;

      if (card.isMainCard) {
        snackbar.setSnackBar({
          type: 'info',
          isShow: true,
          message: 'The card cannot be unlinked. You can either close or lock the card.'
        });
        return;
      }

      mutateProcessBool.setTrue();
      if (card.selected) {
        await detachToPaymentCardMutation({
          PaymentCardId: card.id,
          SubProfileId: subUserId,
        });
        snackbar.setSnackBar({
          isShow: true,
          type: 'success',
          message: 'Card detached',
        })
      } else {
        await attachToPaymentCardMutation({
          PaymentCardId: card.id,
          SubProfileId: subUserId,
        });
        snackbar.setSnackBar({
          isShow: true,
          type: 'success',
          message: 'Card attached',
        })
      }
      await subUsersAvailablePaymentCardsRefetch();
    } finally {
      mutateProcessBool.setFalse();
    }
  }

  const isLoading = subUsersAvailablePaymentCardsIsLoading || mutateProcessBool.value;

  const handleCardOpened = async (newCardId: string) => {
    mutateProcessBool.setTrue();
    setChooseCardsToConnectSubUserMode('manage-card');
    await helper.sleep(5e3);
    const newCardItem = (await subUsersAvailablePaymentCardsRefetch().unwrap()).find(c => c.id === newCardId)
    if (newCardItem) {
      await toggleCardSelected(newCardItem);
    } else {
      // Если открывается физическая карта и мы передаем subProfileId
      // карта автоматически будет привязана к сабу поэтому нужно просто их перезапросить
      await refetchSubUsers();
    }
    mutateProcessBool.setFalse();
  };

  const handleOpenPrimaryCard = async (id: string) => {
    setNewCardId(id);
  }

  const isSubUserHasPrimaryCard = !!availableCards.find(c => c.isMainCard);

  if (chooseCardsToConnectSubUserMode === 'manage-card') {
    return (
      <>
        <div>
          <div className={styles.container}>
            <div>
              {isLoading && (
                <div className={styles.loaderContainer}>
                  <LinearProgress/>
                </div>
              )}
              <p className={styles.container_title}>
                Choose cards to connect to the authorized user
              </p>
              <div className={styles.addButtonContainer}>
                <Button
                  disabled={mutateProcessBool.value}
                  onClick={handleCreateNewCard}
                  variant="text"
                >
                  {'+ OPEN new card'}
                </Button>
              </div>
              <div className={styles.container_list}>
                {availableCards.map((card) => (
                  <div
                    key={card.id}
                    className={`${styles.item} ${styles.item_backGourndWhite} `}
                  >
                    <div className={styles.blockName}>
                      <div className={styles.blockName_img}>
                        <img src={CreditCardOutlined}/>
                      </div>
                      <div>
                        <div className={styles.blockName_text}>{card.formFactor}</div>
                        <p className={styles.blockNumb_numb}>***{card.last4}</p>
                      </div>
                    </div>
                    <div>
                      <Switch
                        checked={card.selected}
                        disabled={mutateProcessBool.value}
                        onClick={() => toggleCardSelected(card.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <WarningModals
          titleMassage={warningText}
          onClose={() => setWarningModal(false)}
          isOpen={warningModal}
        />
      </>
    );
  }

  if (chooseCardsToConnectSubUserMode === 'open-card') {
    return (
      <NewProductCard
        primaryCardOpenErrorText={
          isSubUserHasPrimaryCard ?
            'Primary card already created. Only one primary is allowed per authorized user.'
            : undefined
        }
        setChooseCardsMode={setChooseCardsToConnectSubUserMode}
        backRoute={backRoute}
        availableCards={availableCards}
      />
    );
  }

  if (chooseCardsToConnectSubUserMode === 'openVirtualcard') {
    return (
      <NewVirtualCard
        isAuthorizedUsers
        onCardOpened={handleCardOpened}
        onBack={() => setChooseCardsToConnectSubUserMode('manage-card')}
        onStart={drawerBehavior.lockForClose}
        onFinish={drawerBehavior.unlockForClose}
      />
    );
  }
  if (chooseCardsToConnectSubUserMode === 'physicalCard') {
    return (
      <NewPhisicalCard
        isAuthorizedUsers
        subUserId={subUserId.length ? subUserId : undefined}
        backRoute={backRoute}
        setChooseCardsMode={setChooseCardsToConnectSubUserMode}
        onCardOpened={handleCardOpened}
        onStart={drawerBehavior.lockForClose}
        onFinish={drawerBehavior.unlockForClose}
      />
    );
  }
  if (chooseCardsToConnectSubUserMode === 'burnerCard') {
    return (
      <NewBurnerCard
        setPageStatus={setPageStatus}
        setChooseCardsMode={setChooseCardsToConnectSubUserMode}
        isAuthorizedUsers
        onCardOpened={handleCardOpened}
        onStart={drawerBehavior.lockForClose}
        onFinish={drawerBehavior.unlockForClose}
      />
    );
  }

  if (chooseCardsToConnectSubUserMode === 'openPrimaryCard') {
    return (
      <NewPrimaryCard
        setPageStatus={setPageStatus}
        setOpenNewCardId={handleOpenPrimaryCard}
        backRoute={backRoute}
        onStart={drawerBehavior.lockForClose}
        onFinish={async () => {
          drawerBehavior.unlockForClose();
          refetchSubUsers();
        }}
      />
    );
  }

  return null;
};

export default ChooseCardsToConnectSubUser;
