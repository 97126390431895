import { TransitionAnimation } from 'shared/ui/animations'
import { Button } from 'shared/ui/buttons'
import { DetailsTransfer } from 'entities/transfers'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { useAppSelector, useAppDispatch } from 'shared/models'
import moment from 'moment'
import styles from './styles.module.scss'
import { selectScheduleTransfer, clearDataToNeteviaCustomer } from '../../model'

export const ManageScheduleTransferSuccess = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()

  const scheduleTransfer = useAppSelector(selectScheduleTransfer)

  const handleNavigate = () => {
    dispatch(clearDataToNeteviaCustomer())
    goNext('0')
  }

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        {scheduleTransfer && (
          <DetailsTransfer
            amount={scheduleTransfer?.amount?.formattedValue || ''}
            accountFrom={scheduleTransfer?.fromAccount}
            accountTo={scheduleTransfer?.toAccount}
            date={moment(scheduleTransfer?.dateTransfer).format('MM/DD/YYYY')}
            frequency={scheduleTransfer?.frequency}
          />
        )}

        <Button
          variant='contained'
          loading={false}
          onClick={handleNavigate}
        >
          done
        </Button>
      </div>
    </TransitionAnimation>
  )
}
