import { z } from 'zod'

export const FromConnectedAccountSchema = z
  .object({
    fromPayee: z.string().min(1),
    toFinancialAccount: z.string().min(1),
    amount: z
      .object({
        value: z.string(),
        formattedValue: z.string(),
        floatValue: z.number(),
      })
      .required(),
    frequency: z.string().optional(),
    dateTransfer: z.string(),
  })

  .refine((data) => data.amount.floatValue !== 0, {
    message: 'Float value must not be zero',
    path: ['amount'],
  })

export type FromConnectedAccount = z.infer<typeof FromConnectedAccountSchema>
