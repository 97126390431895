import React, { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import { Button } from 'shared/ui/buttons'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import { useDebounce } from 'shared/hooks/useDebounce'
import {
  useNeteviaPayeesControllerQuery,
  useSearchClientsMutation,
  SearchClientsApi
} from 'api/endpoints/fundsMovement'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { TransitionAnimation } from 'shared/ui/animations'
import styles from './styles.module.scss'
import { useAppSelector, useAppDispatch } from 'shared/models'
import { findObjById, FREQUENCY_DATA, FrequencyTitle } from 'entities/transfers'
import helper from 'services/helper'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { MakeTransferForm } from './make-transfer-form'
import { Typography } from 'shared/ui/typography'
import { MagnifyingGlassIcon } from 'shared/ui/icons'
import {
  NeteviaTransferSchema,
  NeteviaTransfer,
  setFromFinancialAccount,
  setToCustomer,
  setAmount,
  setSelectFrequency,
  setSelectDateTransfer,
  selectFromFinancialAccount,
  selectToCustomer,
  selectAmount,
  selectFrequency,
  selectDateTransfer,
  setNotRegisteredPayee,
} from 'features/transfers/transfer-to-netevia-customer/model'
import { useBoolean } from 'shared/hooks/useBoolean'
import moment from 'moment'
import { useSnackBar } from 'providers/snackBar.provider'
import { Skeleton } from 'components/skeleton'

export const MakeTransfer = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()
  const { setSnackBar } = useSnackBar()
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts()
  const { data: neteviaPayees, isLoading: isLoadingNeteviaPayees } =
    useNeteviaPayeesControllerQuery()

  const [filteredPayees, setFilteredPayees] = useState<SearchClientsApi[] | null>(null)
  const [searchClients, { data: searchedClients, isSuccess }] =
    useSearchClientsMutation()

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const toCustomer = useAppSelector(selectToCustomer)
  const amount = useAppSelector(selectAmount)
  const frequency = useAppSelector(selectFrequency)
  const dateTransfer = useAppSelector(selectDateTransfer)

  const notRegisteredPayeeBool = useBoolean()

  useEffect(() => {
    if (searchedClients && isSuccess) {
      setFilteredPayees(
        searchedClients.map((item) => ({
          ...item,
          name: item.doingBusinessName,
          id: item.financialAccountId,
          last4: item.accountNumberLast4,
        }))
      )
    }
  }, [searchedClients])

  const TransferSchema = NeteviaTransferSchema.refine(
    (data) => {
      const account = findObjById(
        data.fromFinancialAccount,
        unclosedFinancialAccounts
      )

      if (account.availableCash) {
        return data.amount.floatValue <= account?.availableCash?.value
      } else {
        return true
      }
    },
    {
      message: 'The amount in your account should not exceed the balance',
      path: ['amount'],
    }
  )

  const {
    control,
    formState,
    getValues,
    watch,
    trigger: validateForm,
    setValue,
  } = useForm<NeteviaTransfer>({
    resolver: zodResolver(TransferSchema),
    mode: 'all',
    defaultValues: {
      fromFinancialAccount: fromFinancialAccount?.id || '',
      toCustomer: toCustomer?.id || '',
      amount: amount,
      frequency: frequency,
      dateTransfer: dateTransfer,
    },
  })

  const fromFinAccount = watch('fromFinancialAccount')
  const toAccount = watch('toCustomer')

  const handleDisabledDateAndFrequency = () => {
    const notRegisteredPayee = findObjById(toAccount, filteredPayees)

    if (notRegisteredPayee.id) {
      setValue('frequency', FrequencyTitle.Once)
      setValue('dateTransfer', moment.utc().startOf('day').toISOString())
      notRegisteredPayeeBool.setTrue()
      setSnackBar({
        type: 'info',
        message:
          'Schedule and recurring transfers are only available for registered payees. Please add a payee before scheduling transfers.',
        isShow: true,
        width: '400px',
      })
    } else {
      notRegisteredPayeeBool.setFalse()
    }
  }

  useEffect(() => {
    if (toAccount) {
      handleDisabledDateAndFrequency()
    }
  }, [toAccount])

  useEffect(() => {
    validateForm()
  }, [fromFinAccount])

  const handleSearchPayee = useDebounce(
    async ({
      target: { value },
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!value) {
        setFilteredPayees(null)
        return
      }
      searchClients({ whatsLookingFor: value })
    },
    500
  )

  const onSubmit = () => {
    const value = getValues()

    dispatch(
      setFromFinancialAccount({
        value: findObjById(
          value.fromFinancialAccount,
          unclosedFinancialAccounts
        ),
      })
    )

    const toCustomerFromNetevia = findObjById(
      value.toCustomer,
      neteviaPayees?.payees
    )
    const toCustomerFromFiltered = findObjById(value.toCustomer, filteredPayees)

    if (toCustomerFromNetevia.id) {
      dispatch(
        setToCustomer({
          value: toCustomerFromNetevia,
        })
      )
    } else if (toCustomerFromFiltered.id) {
      dispatch(
        setNotRegisteredPayee({
          value:  filteredPayees?.find(payee => payee.id === toCustomerFromFiltered.id) ,
        })
      )
      dispatch(
        setToCustomer({
          value: toCustomerFromFiltered,
        })
      )
    }

    dispatch(setAmount({ value: value.amount }))

    dispatch(
      setSelectFrequency({ value: value.frequency || FrequencyTitle.Once })
    )

    dispatch(setSelectDateTransfer({ value: value.dateTransfer! }))
    goNext('1')
  }

  const showNotRegisteredPayee = () => {
    if (filteredPayees) {
      return !!filteredPayees?.length ? (
        filteredPayees?.map((payee) => (
          <MenuItem
            key={payee.id}
            value={payee.id}
          >
            {`${payee.name} (${payee.last4})`}
          </MenuItem>
        ))
      ) : (
        <div className={styles.empty}>
          <MagnifyingGlassIcon />
          <Typography>
            The payee with the entered data was not found in our system.
          </Typography>
        </div>
      )
    }
  }
  const showRegisteredPayee = neteviaPayees?.payees?.map((payee) => (
    <MenuItem
      key={payee.id}
      value={payee.id}
    >
      {`${payee.name} (${payee.last4})`}
    </MenuItem>
  ))

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {account.name} ({account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }))

  const optionsFrequency = FREQUENCY_DATA.map((el) => ({
    id: el.title,
    value: el.title,
    content: el.title,
  }))

  return financialAccountsIsLoading || isLoadingNeteviaPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <MakeTransferForm
          control={control}
          optionsFromAcc={optionsFromAcc}
          handleSearchPayee={handleSearchPayee}
          optionsFrequency={optionsFrequency}
          disabledDateAndFrequenc={notRegisteredPayeeBool.value}
          showRegisteredPayee={showRegisteredPayee}
          showNotRegisteredPayee={showNotRegisteredPayee}
        />
        <Button
          variant='contained'
          disabled={!formState.isValid}
          onClick={onSubmit}
        >
          Continue
        </Button>
      </div>
    </TransitionAnimation>
  )
}
