import { Badge, BadgeProps, styled } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button } from "shared/ui/buttons";
interface FilterButtonProps {
  onClick: () => void;
  disabled?: boolean;
  filter?: any;
}


const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  display: "block",
  background: "none",
  "& .MuiBadge-badge": {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    position: "relative",
    left: "-20px",
    top: "9px",
    background: "none",
  },
}));


export const FilterButton = ({
  onClick,
  disabled,
  filter,
}: FilterButtonProps) => {
  const countTrueValues = (obj) =>
    Object.values(obj).reduce(
      (count, value) =>
        count +
        (typeof value === "object" && value !== null
          ? countTrueValues(value)
          : value === true
            ? 1
            : 0),
      0
    );
  const counter = filter && countTrueValues(filter);
  return (
    <Button
      onClick={onClick}
      variant="contained"
      disabled={disabled}
    >
      <>
        {counter ? (
          <StyledBadge badgeContent={counter} />
        ) : (
          <FilterListIcon />
        )}
        <span>filter</span>
      </>
    </Button>
  );
};
