import { Select, SelectItem, TextInput } from 'shared/ui/inputs'
import styles from './styles.module.scss'
import { states } from 'constants/data'
import { Control, useController } from 'react-hook-form'
import { PhysicalCardOrder } from '../../../model'
import {
  mapGooglePlace,
  useGooglePlaceAutoComplete,
} from 'shared/services/google-maps'
import { useCallback, useEffect, useRef } from 'react'

const States = states.map<SelectItem>((item) => {
  return { id: item.label, content: item.label, value: item.label }
})

interface DeliveryFormProps {
  control: Control<PhysicalCardOrder>
}

export const DeliveryForm = (props: DeliveryFormProps) => {
  const { control } = props

  const addressRef = useRef<HTMLInputElement | null>(null)

  const firstName = useController({ name: 'firstName', control })
  const lastName = useController({ name: 'lastName', control })
  const address = useController({ name: 'address', control })
  const { ref, ...restAddress } = address.field
  const additionalAddress = useController({
    name: 'additionalAddress',
    control,
  })
  const city = useController({ name: 'city', control })
  const state = useController({ name: 'state', control })
  const zipCode = useController({ name: 'postCode', control })

  useEffect(() => {
    address.field.ref(addressRef.current)
  }, [addressRef.current])

  const handleAutoComplete = useCallback(
    (autoComplete: google.maps.places.Autocomplete) => {
      const place = autoComplete.getPlace()

      const mapPlace = mapGooglePlace(place)

      if (!mapPlace) return

      mapPlace.fullAddress && address.field.onChange(mapPlace.fullAddress)
      mapPlace.localityLong && city.field.onChange(mapPlace.localityLong)
      mapPlace.adminArea1Short && state.field.onChange(mapPlace.adminArea1Short)
      mapPlace.postalCode && zipCode.field.onChange(mapPlace.postalCode)
    },
    []
  )

  useGooglePlaceAutoComplete(addressRef.current!, handleAutoComplete)

  return (
    <div className={styles.root}>
      <TextInput
        label='Fist name'
        {...firstName.field}
        // error={firstName.fieldState.error?.message}
      />
      <TextInput
        label='Last name'
        {...lastName.field}
        // error={lastName.fieldState.error?.message}
      />
      <TextInput
        label='Address line 1'
        {...restAddress}
        inputRef={addressRef}
        // error={address.fieldState.error?.message}
      />
      <TextInput
        label='Address line 2 (optional)'
        {...additionalAddress.field}
        // error={additionalAddress.fieldState.error?.message}
      />
      <TextInput
        label='City'
        {...city.field}
        // error={city.fieldState.error?.message}
      />
      <Select
        options={States}
        label='State'
        {...state.field}
        // error={state.fieldState.error?.message}
      />
      <TextInput
        label='ZIP code'
        {...zipCode.field}
        // error={zipCode.fieldState.error?.message}
      />
    </div>
  )
}
