import { useInitiateTransferBetweenFinancialAccControllerMutation } from 'api/endpoints/fundsMovement'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { useAppSelector } from 'shared/models'
import { useSnackBar } from 'providers/snackBar.provider'
import helper from 'services/helper'
import { DetailsTransfer } from 'entities/transfers'
import { Button } from 'shared/ui/buttons'
import { Typography } from 'shared/ui/typography'
import styles from './styles.module.scss'
import { TransitionAnimation } from 'shared/ui/animations'
import {
  selectFromFinancialAccount,
  selectToFinancialAccount,
  selectAmount,
} from 'features/transfers/transfer-to-my-own-account/model'

export const ToMyOwnAccountStep2 = () => {
  const { setSnackBar } = useSnackBar()
  const { goNext } = useDrawerStepperContext()

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const toFinancialAccount = useAppSelector(selectToFinancialAccount)
  const amount = useAppSelector(selectAmount)

  const [payeeTransferMutation, { isLoading: isLoadingPayeeTransfer }] =
    useInitiateTransferBetweenFinancialAccControllerMutation()

  const handleTransfer = async () => {
    try {
      if (fromFinancialAccount && toFinancialAccount && amount?.floatValue) {
        await payeeTransferMutation({
          fromFinancialAccountId: fromFinancialAccount.id,
          toFinancialAccountId: toFinancialAccount.id,
          amount: amount?.floatValue,
          currencyCode: 840,
          memo: '',
        }).unwrap()
        goNext('2')
        setSnackBar({
          type: 'success',
          message: 'Transfer successfully completed',
          isShow: true,
        })
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      })
    }
  }

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography>Review & confirm</Typography>
        <DetailsTransfer
          amount={amount?.formattedValue || ''}
          accountFrom={fromFinancialAccount}
          accountTo={toFinancialAccount}
        />
        <Button
          variant='contained'
          loading={isLoadingPayeeTransfer}
          onClick={handleTransfer}
        >
          confirm transfer
        </Button>
      </div>
    </TransitionAnimation>
  )
}
