import { Button } from 'shared/ui/buttons'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import {
  setFromPayee,
  setToFinancialAccount,
  setAmount,
  selectFromPayee,
  selectToFinancialAccount,
  selectAmount,
  selectFrequency,
  selectDateTransfer,
  setSelectFrequency,
  setSelectDateTransfer,
} from '../../model'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { TransitionAnimation } from 'shared/ui/animations'
import styles from './styles.module.scss'
import { useAppSelector, useAppDispatch } from 'shared/models'
import { findObjById, FREQUENCY_DATA, FrequencyTitle } from 'entities/transfers'
import helper from 'services/helper'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { MakeTransferForm } from './make-transfer-form'
import { FromConnectedAccountSchema, FromConnectedAccount } from '../../model'
import { useExternalPayees } from 'api/hooksApi/useExternalPayees'
import { Skeleton } from 'components/skeleton'

export const MakeTransfer = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts()
  const { plaidPayees, isLoadingPayees } = useExternalPayees()

  const fromPayee = useAppSelector(selectFromPayee)
  const toFinancialAccount = useAppSelector(selectToFinancialAccount)
  const amount = useAppSelector(selectAmount)
  const frequency = useAppSelector(selectFrequency)
  const dateTransfer = useAppSelector(selectDateTransfer)

  const { control, formState, getValues } = useForm<FromConnectedAccount>({
    resolver: zodResolver(FromConnectedAccountSchema),
    mode: 'all',
    defaultValues: {
      fromPayee: fromPayee?.id || '',
      toFinancialAccount: toFinancialAccount?.id || '',
      amount: amount,
      frequency: frequency,
      dateTransfer: dateTransfer,
    },
  })

  const onSubmit = () => {
    const value = getValues()

    dispatch(
      setFromPayee({
        value: findObjById(value.fromPayee, plaidPayees),
      })
    )
    dispatch(
      setToFinancialAccount({
        value: findObjById(value.toFinancialAccount, unclosedFinancialAccounts),
      })
    )

    dispatch(setAmount({ value: value.amount }))

    dispatch(setSelectFrequency({ value: value.frequency || FrequencyTitle.Once })) 

    dispatch(setSelectDateTransfer({ value: value.dateTransfer! }))

    goNext('1')
  }

  const optionsToAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {account.name} ({account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }))

  const optionsFrequency = FREQUENCY_DATA.map((el) => ({
    id: el.title,
    value: el.title,
    content: el.title,
  }))

  return financialAccountsIsLoading || isLoadingPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <MakeTransferForm
          control={control}
          optionsToAcc={optionsToAcc}
          payees={plaidPayees}
          optionsFrequency={optionsFrequency}
        />

        <Button
          variant='contained'
          disabled={!formState.isValid}
          onClick={onSubmit}
        >
          Continue
        </Button>
      </div>
    </TransitionAnimation>
  )
}
