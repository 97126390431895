import { useMemo } from "react";
import { useMerchantCategoriesControllerQuery } from "api/endpoints/enums";
import {
  useCardProductSettingsControllerQuery,
  useUsersRewardControllerQuery,
} from "api/endpoints/settings";
import { useTranslationProvider } from "providers/translation/translation.provider";

export interface LimitsItems {
  label: string;
  name: string;
  infoItems: string[];
}

interface LimitsAndRewardsConditionsReturnType {
  conditions: {
    transactionLimitsItems: LimitsItems[];
    rewardsLimitsItems: LimitsItems[];
  };
  conditionsIsLoading: boolean;
}

export const useLimitsAndRewardsConditions =
  (): LimitsAndRewardsConditionsReturnType => {
    const { t } = useTranslationProvider();
    const { data: categories, isLoading: categoriesLoading } =
      useMerchantCategoriesControllerQuery();
    const { data: transactionLimits, isLoading: transactionLimitsLoading } =
      useCardProductSettingsControllerQuery({});
    const { data: usersRewards, isLoading: usersRewardsLoading } =
      useUsersRewardControllerQuery({});

    const value = useMemo<any>(() => {
      const formatAmount = (value, text = "none") => {
        if (!value || !value.toLocaleString) {
          return text;
        }

        return `$${value.toLocaleString()}`
      };
      const getDayString = (day: number) => (day === 1 ? "day" : "days");

      const getTransactionsLimits = () => {
        if (!transactionLimits) {
          return [];
        } else {
          return [
            {
              label: t("Limits.Maximum account balance", {
                defaultValue: "Maximum account balance",
              }),
              name: "maximumAccountBalance",
              infoItems: [
                `${formatAmount(transactionLimits?.balanceLimit)} (${t(
                  "Limits.cap includes all FA",
                  {
                    defaultValue: "cap includes all FA",
                  }
                )})`,
              ],
            },
            {
              label: t("Limits.ACH limits", {
                defaultValue: "ACH limits",
              }),
              name: "ACHLimits",
              infoItems: [
                `${t("Limits.Max ACH daily incoming transfer limit", {
                  defaultValue: "Max ACH daily incoming transfer limit",
                })} ${formatAmount(transactionLimits?.balanceLimit)} (${t(
                  "Limits.includes card + FA balance",
                  {
                    defaultValue: "includes card + FA balance",
                  }
                )})`,
                `${t("Limits.Outgoing ACH limit", {
                  defaultValue: "Outgoing ACH limit",
                })} - ${formatAmount(transactionLimits?.achLimitTxn)}`,
              ],
            },
            {
              label: t("Limits.ATM limits", {
                defaultValue: "ATM limits",
              }),
              name: "ATMLimits",
              infoItems: [
                `${t("Limits.ATM daily limit", {
                  defaultValue: "ATM daily limit",
                })} ${formatAmount(transactionLimits?.atmLimitDaily)}`,
                `${t("Limits.ATM monthly", {
                  defaultValue: "ATM monthly",
                })} ${formatAmount(transactionLimits?.atmLimitMonthly)}`,
              ],
            },
            {
              label: t("Limits.POS spending limit", {
                defaultValue: "POS spending limits",
              }),
              name: "POSSpendingLimit",
              infoItems: [
                `${t("Limits.Max POS spend limit per transaction", {
                  defaultValue: "Max POS spend limit per transaction",
                })} ${formatAmount(transactionLimits?.posLimitTxn)}`,
                `${t("Limits.Max POS spend limit per day", {
                  defaultValue: "Max POS spend limit per day",
                })} ${formatAmount(transactionLimits?.posLimitDaily)}`,
                `${t("Limits.Max POS spend limit per month", {
                  defaultValue: "Max POS spend limit per month",
                })} ${formatAmount(transactionLimits?.posLimitMonthly)}`,
              ],
            },
            {
              label: t("Limits.A2A outgoing transaction limits", {
                defaultValue: "A2A outgoing transaction limits",
              }),
              name: "A2AOutgoingTransactionLimits",
              infoItems: [
                `${formatAmount(transactionLimits?.a2aLimitTxn)} ${t(
                  "Limits.per transaction",
                  {
                    defaultValue: "per transaction",
                  }
                )}`,
                `${formatAmount(transactionLimits?.a2aLimitDaily)} ${t(
                  "Limits.per day",
                  {
                    defaultValue: "per day",
                  }
                )}`,
                `${formatAmount(transactionLimits?.a2aLimitWeekly)} ${t(
                  "Limits.per week",
                  {
                    defaultValue: "per week",
                  }
                )}`,
                `${formatAmount(transactionLimits?.a2aLimitMonthly)} ${t(
                  "Limits.per month",
                  {
                    defaultValue: "per month",
                  }
                )}`,
              ],
            },

            {
              label: t("Limits.A2A incoming transfers", {
                defaultValue: "A2A incoming transfers",
              }),
              name: "A2AIncomingTransfers",
              infoItems: [
                `${formatAmount(
                  transactionLimits?.a2aLimitIncoming,
                  "No limits"
                )}`,
              ],
            },
          ];
        }
      };

      const getRewardLimitsAndConditions = () => {
        if (!categories || !usersRewards) {
          return [];
        } else {
          const getCategories = (num) => {
            const merchantCategories = usersRewards?.extraSpendRewards[
              num
            ]?.merchantCategories?.map((element) => element?.merchantCategory);

            return categories
              ?.filter(
                (category) => merchantCategories?.includes(category.name)
              )
              .map((category) => category.description)
              .join(", ");
          };
          const spendingCreditData = usersRewards?.extraSpendRewards.find(
            (item) => item.type === 1
          );
          const specialSpendingRewardsData =
            usersRewards?.extraSpendRewards.find((item) => item.type === 0);

          return [
            {
              label: t("Limits.Spending rewards", {
                defaultValue: "Spending rewards",
              }),
              name: "spendingRewards",
              infoItems: [
                `${t("Limits.Debit spend cash back", {
                  defaultValue: "Debit spend cash back",
                })} - ${usersRewards?.debitSpendPercent}%`,
                `${t("Limits.Extra cash back limit", {
                  defaultValue: "Extra cash back limit",
                })} - ${formatAmount(usersRewards?.extraSpendAmount)}`,
                `${t("Limits.Extra cash back", {
                  defaultValue: "Extra cash back",
                })} - ${usersRewards?.extraDebitSpendPercent}%`,
              ],
              tooltipText:
                "Every dollar you spend earns you points! For every dollar spent, you'll receive 1 point. These points are then multiplied by the cashback percentage. So, the more you spend, the more points you earn, boosting your cashback rewards",
            },
            specialSpendingRewardsData && {
              label: t("Limits.Special spending rewards categories", {
                defaultValue: "Special spending rewards categories",
              }),
              name: "specialSpendingRewards",
              infoItems: [
                `${t("Limits.Categories", {
                  defaultValue: "Categories",
                })} - ${getCategories(0)}`,
                `${t("Limits.Cash back", {
                  defaultValue: "Cash back",
                })} - ${specialSpendingRewardsData.percent}%`,
                `${t("Limits.Limit", {
                  defaultValue: "Limit",
                })} - ${formatAmount(
                  specialSpendingRewardsData.debitSpendMin
                )}`,
                `${t("Limits.Limit period", {
                  defaultValue: "Limit period",
                })} - ${specialSpendingRewardsData.period} Month`,
              ],
              tooltipText:
                "With every dollar spent, you earn 1 point. Later, these points are multiplied by the cashback percentage (multiplied by 100) applicable to specific transaction MCC/SIC codes. However, there's a spending limit in place to keep things in check!",
            },
            spendingCreditData && {
              label: t("Limits.Spending credit", {
                defaultValue: "Spending credit",
              }),
              name: "spendingCredit",
              infoItems: [
                `${t("Limits.Categories", {
                  defaultValue: "Categories",
                })} - ${getCategories(1)}`,
                `${t("Limits.Amount", {
                  defaultValue: "Amount",
                })} - ${formatAmount(spendingCreditData.debitSpendMin)}`,
                `${t("Limits.Period", {
                  defaultValue: "Period",
                })} - ${spendingCreditData.period} Month`,
              ],
              tooltipText:
                "It's our way of giving back! When you spend in specific categories, we've got a treat for you. You'll earn 100 points for every dollar spent within these categories, up to a certain limit. Enjoy earning rewards as you shop!",
            },
            {
              label: t("Limits.Earned credit rewards", {
                defaultValue: "Earned credit rewards",
              }),
              name: "earnedCreditRewards",
              infoItems: [
                `${t("Limits.Earned credit interest", {
                  defaultValue: "Earned credit interest",
                })} ${usersRewards?.aprPercent}%`,
                `${t("Limits.Extra earned credit interest", {
                  defaultValue: "Extra earned credit interest",
                })} ${usersRewards?.extraAPRPercent}%`,
                `${t("Limits.Extra earned credit spending requirement", {
                  defaultValue: "Extra earned credit spending requirement",
                })} ${formatAmount(usersRewards?.extraAPRAmount)}`,
                `${t("Limits.Extra earned credit balance cap", {
                  defaultValue: "Extra earned credit balance cap",
                })} ${formatAmount(usersRewards?.aprSpendMin)}`,
              ],
              tooltipText:
                "We calculate your APR and transform it into valuable credit points for you to enjoy. Start earning rewards simply by using our banking services.",
            },
            {
              label: t("Limits.Welcome bonus", {
                defaultValue: "Welcome bonus",
              }),
              name: "welcomeBonus",
              infoItems: [
                `${t("Limits.Welcome bonus", {
                  defaultValue: "Welcome bonus",
                })} - ${usersRewards?.welcomePercent}%`,
                `${t("Limits.Welcome bonus cap", {
                  defaultValue: "Welcome bonus cap",
                })} - ${formatAmount(usersRewards?.welcomeMin)}`,
                `${t("Limits.Deposit time frame", {
                  defaultValue: "Deposit time frame",
                })} - ${usersRewards?.welcomeTimeDays} ${getDayString(
                  usersRewards?.welcomeTimeDays
                )}`,
              ],
              tooltipText:
                "We add up all your deposits during a specific period, subtract the total amount you've transferred out via ACH, and then multiply that by a certain percentage. The resulting amount is converted into points, with $1 equaling 100 points. It's our way of giving back for your activity!",
            },
            {
              label: t("Limits.Welcome spending bonus", {
                defaultValue: "Welcome spending bonus",
              }),
              name: "welcomeSpendingBonus",
              infoItems: [
                `${t("Limits.Minimal spending", {
                  defaultValue: "Minimal spending",
                })} - ${formatAmount(usersRewards?.welcomeSpendMin)}`,
                `${t("Limits.Time frame", {
                  defaultValue: "Time frame",
                })} - ${usersRewards?.welcomeSpendTimeDays} ${getDayString(
                  usersRewards?.welcomeSpendTimeDays
                )}`,
                `${t("Limits.Amount", {
                  defaultValue: "Amount",
                })} - ${formatAmount(usersRewards?.welcomeSpendAmount)}`,
              ],
              tooltipText:
                "This feature is all about rewarding you. Here's how it works: For every dollar you spend, you'll earn 100 points. Once you've spent a specific amount within the given period, we'll convert those amounts into points just for you! It's our way of saying thank you for choosing us.",
            },
          ].filter((element) => element);
        }
      };

      return {
        transactionLimitsItems: getTransactionsLimits(),
        rewardsLimitsItems: getRewardLimitsAndConditions(),
      };
    }, [categories, transactionLimits, usersRewards]);

    const isLoading = [
      categoriesLoading,
      transactionLimitsLoading,
      usersRewardsLoading,
    ].some(Boolean);
    return {
      conditions: value,
      conditionsIsLoading: isLoading,
    };
  };
