import React, { FC } from "react";
import { List, ListItemButton, Divider } from "@mui/material";
import styles from "./style.module.scss";
import moment from "moment";
import { DevicesItem } from "pages/settings/security/components/notifications/components/devicesItem";
import { Skeleton } from "components/skeleton";
import { useTranslationProvider } from "providers/translation/translation.provider";
interface Item {
  createdDate: string;
  deviceId: string;
  deviceName: string;
  ipAddress: string;
  userProfileId: number;
}
interface LoginHistoryListProps {
  loginHistory: Item[];
  isLoading: boolean;
}
const LoginHistoryList: FC<LoginHistoryListProps> = ({
  loginHistory,
  isLoading,
}) => {
  const { t } = useTranslationProvider();

  const renderItem = () => {
    return loginHistory?.map((i, index) => (
      <div key={index}>
        <DevicesItem
          date={moment(i.createdDate).format("MM/DD/YYYY, hh:mm ")}
          id={i.deviceId}
          name={i.deviceName}
        />
        {index < loginHistory.length - 1 && <Divider />}
      </div>
    ));
  };
  return (
    <>
      <span className={styles.subTitle}>
        {t("common.Your devices", {
          defaultValue: "Your devices",
        })}
      </span>
      {isLoading ? (
        <Skeleton width="100%" height="70px" />
      ) : (
        <List disablePadding className={styles.listWrapper}>
          {renderItem()}
        </List>
      )}
    </>
  );
};
export default LoginHistoryList;
