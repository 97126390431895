import { z } from 'zod'

export const PayeeAddSchema = z
  .object({
    accountName: z.string().min(1),
    accountNumber: z.string().max(20),
  })
  .refine((data) => /^\d+$/.test(data.accountNumber), {
    message: 'Enter numbers only',
    path: ['accountNumber'],
  })
export type TPayeeAdd = z.infer<typeof PayeeAddSchema>
