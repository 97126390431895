import { AnimateWrapper } from '../../../components/animate-wrapper';
import ButtonWithChevron from '../../../components/button/buttonWithChevron';
import cardImg from '../../../assets/svg/CreditCardOutlined.svg';
import burnerCard from '../../../assets/svg/burnerCard.svg';
import creditScoreOutlined from '../../../assets/svg/CreditScoreOutlined.svg';
import burnercCard from '../../../assets/svg/burnerCard.svg';
import ArrowLeft from '../../../assets/svg/arrow-left.svg';
import styles from './style.module.scss';
import WarningModal from '../../../components/modals/WarningModal';
import { useState } from 'react';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import SnackBarWarning from '../snackBar/snackBarWarning';

interface PropTypes {
  backRoute?: string;
  setChooseCardsMode?: (string) => void;
  availableCards: any[];
  primaryCardOpenErrorText?: string;
}

const NewProductCard = ({
  backRoute = '',
  setChooseCardsMode = () => {
  },
  availableCards,
  primaryCardOpenErrorText
}: PropTypes) => {
  const { t } = useTranslationProvider();
  const [warningModal, setWarningModal] = useState<string>('');

  const navigatetoOpenPrimaryCard = () => {
    const findPrimaryCard = !!availableCards.find(
      (obj) => obj.isMainCard === true
    );
    findPrimaryCard
      ? setWarningModal(
        t('Card.You have a primary card', {
          defaultValue: 'You have a primary card',
        })
      )
      : setChooseCardsMode('openPrimaryCard');
  };

  return (
    <AnimateWrapper className="fade">
      <div>
        <div className={styles.container}>
          <p className={styles.container_title}>
            {t('NewProduct.Choose one of the options', {
              defaultValue: 'Choose one of the options',
            })}
          </p>
          <div className={styles.container_boxButtons}>
            {(!primaryCardOpenErrorText && backRoute === 'EditSubUserCard' || backRoute === 'Home') && (
              <div
                onClick={() => navigatetoOpenPrimaryCard()}
              >
                <ButtonWithChevron
                  text={t('NewProduct.Primary card', {
                    defaultValue: 'Primary card',
                  })}
                  img={cardImg}
                />
              </div>
            )}
            <div onClick={() => setChooseCardsMode('physicalCard')}>
              <ButtonWithChevron
                text={t('Home.Physical card', {
                  defaultValue: 'Physical card',
                })}
                img={cardImg}
              />
            </div>
            <div onClick={() => setChooseCardsMode('openVirtualcard')}>
              <ButtonWithChevron
                text={t('Home.Virtual card', {
                  defaultValue: 'Virtual card',
                })}
                img={creditScoreOutlined}
              />
            </div>
            <div onClick={() => setChooseCardsMode('burnerCard')}>
              <ButtonWithChevron
                text={t('Home.Burner card', {
                  defaultValue: 'Burner card',
                })}
                img={burnercCard}
              />
            </div>
          </div>
          {primaryCardOpenErrorText && (
            <div className={styles.primaryDisabled}>
              <div style={{ opacity: 0.5 }}>
                <img style={{ width: '24px', height: '24px' }} src={cardImg}/>
              </div>
              <div className={styles.primaryDisabledText}>
                <div>Primary card</div>
                <div className={styles.primaryDisabledSubtitle}>{primaryCardOpenErrorText}</div>
              </div>
            </div>
          )}
        </div>

        <SnackBarWarning
          isOpen={!!warningModal}
          message={warningModal}
          onClose={() => setWarningModal('')}
        />
      </div>
    </AnimateWrapper>
  );
};

export default NewProductCard;
