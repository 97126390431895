import React, { FC } from "react";
import Status from "pages/support/history/components/status/Status";
import Checkbox from "@mui/material/Checkbox";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { TicketStatusEnum } from "pages/support/types/enums";

interface FilterByTypeProps {
  findNew: boolean;
  setFindNew: (findNew: boolean) => void;
  findInProgress: boolean;
  setFindInProgress: (findInProgress:boolean) => void;
  findResolved: boolean;
  setFindResolve: (findResolved: boolean) => void;
}

const FilterByType: FC<FilterByTypeProps> = ({
  findNew,
  setFindNew,
  findInProgress,
  setFindInProgress,
  findResolved,
  setFindResolve,
}) => {
  const { t } = useTranslationProvider();
  const handleChangeNew = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFindNew(event.target.checked);
  };
  const handleChangeInProgress = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFindInProgress(event.target.checked);
  };
  const handleChangeResolved = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFindResolve(event.target.checked);
  };
  return (
    <div className={styles.wrapper}>
      <span className={styles.wrapper_title}>
        {t("Support.Filter by ticket type", {
          defaultValue: "Filter by ticket type",
        })}
      </span>
      <div className={styles.box}>
        <Checkbox
          checked={findNew}
          onChange={handleChangeNew}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            "&.Mui-checked": {
              color: "#187AC9",
            },
          }}
        />
        <Status status={TicketStatusEnum.NEW} />
      </div>

      <div className={styles.box}>
        <Checkbox
          checked={findInProgress}
          onChange={handleChangeInProgress}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            "&.Mui-checked": {
              color: "#187AC9",
            },
          }}
        />
        <Status status={TicketStatusEnum.WORKING_IN_PROGRESS} />
      </div>
      <div className={styles.box}>
        <Checkbox
          checked={findResolved}
          onChange={handleChangeResolved}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            "&.Mui-checked": {
              color: "#187AC9",
            },
          }}
        />
        <Status status={TicketStatusEnum.RESOLVED} />
      </div>
    </div>
  );
};

export default FilterByType;
