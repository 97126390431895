import { useTranslationProvider } from "providers/translation/translation.provider";
import CreditCardOutlined from "assets/svg/CreditCardOutlined.svg";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import styles from "./style.module.scss";

type TConnectCard = {
  white?: any;
  edit?: any;
  cardArr: any[];
};

const CardsList = ({ white, edit, cardArr }: TConnectCard) => {
  const { t } = useTranslationProvider();
  const renderBadge = (i) => {
    const statusClass = getStatusStyle(i.status);
    const statusText = i.status.charAt(0) + i.status.slice(1).toLowerCase();

    return (
      <div className={`${styles.statusBadge}  ${statusClass} `}>
        <p className={styles.statusBadge_text}>
          {i.status === "SUSPENDED" ? "Locked" : statusText}
        </p>
      </div>
    );
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "SUSPENDED":
        return styles.statusBadge_locked;
      case "ACTIVE":
        return styles.statusBadge_active;
      case "CLOSED":
        return styles.statusBadge_closed;
      default:
        return "";
    }
  };

  const renderItems = () => {
    if (!cardArr?.length) {
      return (
        <p className={styles.warningText}>
          {t("common.No connected cards yet", {
            defaultValue: "No connected cards yet",
          })}
          .
        </p>
      );
    } else {
      return cardArr?.map((i, index) => (
        <AnimateWrapper delay={index * 100} key={i.id}>
          <div
            key={i.id}
            className={`${styles.item} ${
              white
                ? styles.item_backGourndWhite
                : styles.item_backGourndDefault
            } `}
          >
            <div className={styles.blockName}>
              <div className={styles.blockName_img}>
                <img src={CreditCardOutlined} />
              </div>
              <div>
                <div className={styles.blockName_text}>{i.formFactor}</div>
                <p className={styles.blockNumb_numb}>***{i.last4}</p>
              </div>
            </div>

            <div className={styles.blockNumb}>{renderBadge(i)}</div>
          </div>
        </AnimateWrapper>
      ));
    }
  };

  return <div className={styles.wrapper}>{renderItems()}</div>;
};

export default CardsList;
