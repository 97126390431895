import React, { FC } from "react";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Skeleton } from "components/skeleton";
import Switch from "@mui/material/Switch";

interface EmailNotificationsProps {
  isLoading: boolean;
  toogleEmail: boolean | undefined;
  setToogleEmail: (boolean) => void;
}
const EmailNotifications: FC<EmailNotificationsProps> = ({
  isLoading,
  toogleEmail,
  setToogleEmail,
}) => {
  const { t } = useTranslationProvider();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToogleEmail(event.target.checked);
  };
  return (
    <>
      {isLoading ? (
        <Skeleton width={500} height={100} />
      ) : (
        <div className={styles.notifications}>
          <div className={styles.notifications_box}>
            <span className={styles.title}>
              {t("Settings.Email notifications", {
                defaultValue: "Email notifications",
              })}
            </span>

            <p className={styles.subTitle}>
              {t(
                "Settings.Turn on email notifications to receive updates about",
                {
                  defaultValue:
                    "Turn on email notifications to receive updates about account activity, transactions, and other important events. Stay informed and in control of your finances. Avoid unexpected overdraft fees.",
                }
              )}
            </p>
          </div>
          {toogleEmail !== undefined && (
            <Switch
              checked={toogleEmail}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                "& .Mui-checked": {
                  color: "#187AC9",
                },
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
export default EmailNotifications;
