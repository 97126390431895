import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";
import { ApiTags } from "../api-tags";

export enum TicketType {
  banking = 21,
  bankingRisk = 26,
}

interface CreateTicket {
  topicId: number;
  text: string;
  updateFieldRequest?: string;
  ticketType?: TicketType;
}
interface CreateTicketResponse {
  ticketId: number;
}
export interface TopicApi {
  id: number;
  isDeleted: boolean;
  name: string;
  responseTime: string;
};

export interface FAQListApi {
  answer: string;
  question: string;
  topic: string;
}

export const supportApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "support",
  tagTypes: [
    ApiTags.GetTopics,
    ApiTags.GetTickets,
    ApiTags.GetFianancialAccounts,
    ApiTags.GetdefaultTransfersFinAccount,
  ],
  endpoints: (builder) => ({
    topicsController: builder.query<TopicApi[], any>({
      //providesTags: [ApiTags.GetTopics],
      query: () => ({
        url: "api/support/topics",
        method: "GET",
      }),
    }),
    createTicketController: builder.mutation<
      CreateTicketResponse,
      CreateTicket
    >({
      invalidatesTags: [
        // ApiTags.GetTickets,
        // ApiTags.GetFianancialAccounts,
        // ApiTags.GetdefaultTransfersFinAccount,
      ],
      query: (data) => ({
        url: "api/support/createTicket",
        method: "POST",
        body: { ticketType: TicketType.banking, ...data },
      }),
    }),
    getFAQController: builder.query<FAQListApi[], any>({
      //  providesTags: [ApiTags.GetTopics],
      query: () => ({
        url: "api/support/faq",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useTopicsControllerQuery,
  useCreateTicketControllerMutation,
  useGetFAQControllerQuery,
} = supportApi;
