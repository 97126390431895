import { css, styled, Typography, Stack, Link } from "@mui/material";
import phoneIcon from "assets/svg/phone-blue.svg";
import mailIcon from "assets/svg/mail-blue.svg";

const ContactInformation = () => {
  return (
    <Container>
      <Title>Feel free to contact us</Title>
      <Stack direction="row" alignItems="center" spacing={1}>
        <img src={phoneIcon} alt="Phone" />
        <Text>855-479-4411</Text>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <img src={mailIcon} alt="Mail" />
        <Link href="mailto:card@netevia.com" color="#187ac9" underline="none">
          card@netevia.com
        </Link>
      </Stack>
    </Container>
  );
};
export default ContactInformation;

const Container = styled("div")`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 4px;
    background: rgba(24, 122, 201, 0.04);
    gap: 16px;
  `}
`;
const Title = styled(Typography)`
  ${({ theme }) => css`
    color: rgba(0, 0, 0, 0.6);
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
  `}
`;
const Text = styled(Typography)`
  ${({ theme }) => css`
    color: #187ac9;
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
  `}
`;
