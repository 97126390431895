import { z } from 'zod'

export const PhysicalCardOrderSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  address: z.string().min(1),
  additionalAddress: z.string().optional(),
  city: z.string().min(1),
  state: z.string().min(1),
  postCode: z.string().min(1),
})

export type PhysicalCardOrder = z.infer<typeof PhysicalCardOrderSchema>
