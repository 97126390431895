import { useState } from 'react'
import { Control, useController, UseFormRegisterReturn } from 'react-hook-form'
import { Select, SelectItem } from 'shared/ui/inputs'
import { Button } from 'shared/ui/buttons'
import { Typography } from 'shared/ui/typography'
import { CloseOutlineGrayIcon } from 'shared/ui/icons'
import { CurrencyInput2 } from 'shared/ui/inputs'
import { AchTransfer } from 'features/transfers/transfer-batch/model'
import { Divider, IconButton, useMediaQuery } from '@mui/material'
import helper from 'services/helper'
import styles from './styles.module.scss'
import { TextInput } from 'shared/ui/inputs'

type RegisterAmount = Omit<
  UseFormRegisterReturn,
  'onChange' | 'value' | 'onBlur'
> & {}

interface BatchTransferFormItemProps {
  control: Control<AchTransfer>
  optionsFromAcc: SelectItem[]
  optionsToPayee: SelectItem[]
  formIndex: number
  handleDeleteTransfer: (index: number) => void
  isDeleteDisabled: boolean
  applyAllAmount: (amount: string) => void
  registerFromFinancialAccount: UseFormRegisterReturn
  registerPayee: UseFormRegisterReturn
  registerAmount: RegisterAmount
  onAccountFromChange: () => void

  updateForm: (
    formIndex: number,
    formData: {
      fromFinancialAccount: string
      amount: string
      toPayee: string
      memo:string
    }
  ) => void
  isTransferToNetevia: boolean
  applyAllMemo: (memo:string) => void
}

export const BatchTransferFormItem = (props: BatchTransferFormItemProps) => {
  const {
    control,
    optionsFromAcc,
    optionsToPayee,
    formIndex,
    handleDeleteTransfer,
    isDeleteDisabled,
    applyAllAmount,
    registerFromFinancialAccount,
    registerPayee,
    registerAmount,
    updateForm,
    onAccountFromChange,
    isTransferToNetevia,
    applyAllMemo
  } = props

  const isMobile = useMediaQuery('(max-width: 600px)')

  const fromFinancialAccount = useController({
    name: `forms.${formIndex}.fromFinancialAccount`,
    control,
  })
  const toPayee = useController({ name: `forms.${formIndex}.toPayee`, control })
  const amount = useController({ name: `forms.${formIndex}.amount`, control })
  const memo = useController({ name: `forms.${formIndex}.memo`, control })

  const [cashValueAmount, setCasheValueAmount] = useState<string>(
    amount.field.value
  )

 
  const updateAmount = async (value: string) => {
    await helper.sleep(100)
    updateForm(formIndex, {
      fromFinancialAccount: fromFinancialAccount.field.value,
      amount: value,
      toPayee: toPayee.field.value,
      memo:memo.field.value as string
    })
  }

  const renderButtonDelete = () => (
    <IconButton
      onClick={() => handleDeleteTransfer(formIndex)}
      disabled={isDeleteDisabled}
    >
      <CloseOutlineGrayIcon />
    </IconButton>
  )

  return (
    <div className={styles.container}>
      <div className={styles.wrapperForm}>
        {!isMobile && (
          <Typography className={styles.title}>#{formIndex + 1}</Typography>
        )}
        {isMobile && (
          <div className={styles.isMobileTitle}>
            <Typography className={styles.title}>
              Transfer #{formIndex + 1}
            </Typography>
            {renderButtonDelete()}
          </div>
        )}
        <div className={styles.box}>
          <Select
            className={styles.boxInput}
            label='From'
            options={optionsFromAcc}
            {...fromFinancialAccount.field}
            {...registerFromFinancialAccount}
            onChange={(...rest) => {
              registerFromFinancialAccount.onChange(...rest)
              onAccountFromChange()
            }}
          />
          <Select
            className={styles.boxInput}
            label='To'
            options={optionsToPayee}
            {...toPayee.field}
            {...registerPayee}
          />

          <CurrencyInput2
            className={styles.boxInput}
            variant='outlined'
            label='Amount'
            placeholder='$0.00'
            error={!!amount.fieldState.error?.message}
            getCaretBoundary={() => [false]}
            InputProps={{
              endAdornment: (
                <Button
                  className={styles.btn}
                  variant='text'
                  onClick={() => {
                    applyAllAmount(cashValueAmount)
                  }}
                >
                  Apply to all
                </Button>
              ),
            }}
            {...amount.field}
            {...registerAmount}
            onChange={(value: any) => setCasheValueAmount(value)}
            onBlur={(value: string) => updateAmount(value)}
          />
        </div>
        
        {!isMobile && renderButtonDelete()}
      </div>
     {isTransferToNetevia &&
     ( <TextInput  
        label='Memo'
        className={styles.memo}    
        {...memo.field}
         autoComplete="off"
         inputProps={{ maxLength: 140 }} 
        InputProps={{
              endAdornment: (
                <Button
                  className={styles.btn}
                  variant='text'
                  onClick={() => {
                    applyAllMemo(memo.field.value as string)
                  }}
                >
                  Apply to all
                </Button>
              ),
            }}/>)}
      <Divider />
    </div>
  )
}
