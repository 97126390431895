import { useMemo } from "react";
import { useGetAllTicketsControllerQuery } from "api/endpoints/tickets";
enum TicketStatus {
  NEW = "New",
  PENDING = "Pending",
  WORKING_IN_PROGRESS = "Working in progress",
  RESOLVED = "Resolved",
}
interface Ticket {
  createdDate: string;
  parentTicketId: null;
  status: TicketStatus;
  text: string;
  ticketId: number;
  topic: string;
  topicId: number;
  userProfileId: number;
}
interface HistoryReturnType {
  history: Ticket[];
  historyIsLoading: boolean;
  historyIsSuccess: boolean;
  historyRefetch: () => void;
}

export const useSupportHistory = (): HistoryReturnType => {
  const { data, isLoading, isFetching, isSuccess, refetch } =
    useGetAllTicketsControllerQuery({});

  const value = useMemo<any>(() => {
    if (!data) {
      return [];
    }

    const temptArr = [...data];

    return temptArr.reverse();
  }, [data]);

  return {
    history: value,
    historyIsLoading: isFetching,
    historyIsSuccess: isSuccess,
    historyRefetch: refetch,
  };
};
