import { FC } from "react";
import { useRewards } from "pages/reward/provider/useRewards";
import { replaceNumberWithComma } from "shared/utils/price";
import styles from "./styles.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import cx from "classnames";
interface EarnderCategoriesItemProps {
  type: number;
  points: number;
  stylesWrapper?: string;
}
const EarnderCategoriesItem: FC<EarnderCategoriesItemProps> = ({
  type,
  points,
  stylesWrapper,
}) => {
  const { t } = useTranslationProvider();
  const { findRewardCategoryByType } = useRewards();

  const item = findRewardCategoryByType(type);

  return (
    <div className={cx(styles.wrapper, stylesWrapper)}>
      <div className={styles.title}>
        <img className={styles.title_img} src={item?.img} />
        <span className={styles.title_text}>
          {t(item?.title!, {
            defaultValue: item?.defaultTitle!,
          })}
        </span>
      </div>

      <span className={styles.title_text}>
        {replaceNumberWithComma(points)}
      </span>
    </div>
  );
};
export default EarnderCategoriesItem;
