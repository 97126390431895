import * as yup from 'yup';

export const FilterSchema = yup.object().shape({
  financialAccount: yup.string().required(),
  date: yup
    .array()
    .of(yup.date().nullable())
    .test('both-dates-not-null', function (value) {
      if (Array.isArray(value) && value.length === 2) {
        if (!value[0] || !value[1]) {
          return false;
        }
      }

      return true;
    }),
  fromAmount: yup.string(),
  toAmount: yup
    .string()
    .test(
      'is-greater',
      `The 'From' value must be less than or equal to the 'To' value.`,
      function (value) {
        const { fromAmount } = this.parent;
        if (fromAmount && value) {
          return Number(value) >= Number(fromAmount);
        }
        return true;
      }
    ),
});
