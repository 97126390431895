import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import i18n from "i18next";
import { useTranslationControllerQuery } from "api/endpoints/settings";
import LOCAL_LANGUAGES from "constants/translations/translations.json";

const TranslationContext = createContext<TranslationContextProps | undefined>(
  undefined
);

interface TranslationContextProps {
  t: (key: string, obj?: { defaultValue: string }) => string;
  changeLanguage: (newLang: string) => void;
  currentLanguage: string;
}

export const TranslationProvider = ({ children }: { children: any }) => {
  const [loadedLanguages, setLoadedLanguages] = useState(false);
  const savedLanguage = localStorage.getItem("language");
  const [currentLanguage, setCurrentLanguage] = useState(
    savedLanguage ? savedLanguage : "en"
  );
  const { data, isLoading } = useTranslationControllerQuery({});
  const LANGUAGES = LOCAL_LANGUAGES.data; // data?.data?.en ? data.data : LOCAL_LANGUAGES.data;
  useEffect(() => {
    const resources = {
      en: { translation: LANGUAGES.en },
      es: { translation: LANGUAGES.es },
    };

    i18n.init({
      resources,
      lng: currentLanguage,
      fallbackLng: "en",
      debug: false,
      interpolation: {
        escapeValue: false,
      },
    });

    setLoadedLanguages(true);
  }, [data, currentLanguage, LANGUAGES]);

  const changeLanguage = (newLang: string) => {
    localStorage.setItem("language", newLang);
    setCurrentLanguage(newLang);
  };

  const value: any = useMemo(() => {
    return {
      t: (key, obj) => i18n.t(key, obj),
      changeLanguage,
      currentLanguage,
    };
  }, [LANGUAGES, loadedLanguages, currentLanguage]);

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationProvider = () => useContext(TranslationContext) as TranslationContextProps;
