import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import mapIcon from "assets/svg/map-outlined.svg";
import mapIconW from "assets/svg/map-white.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { AnimateWrapper } from "components/animate-wrapper";
import SnackBarSuccess from "shared/components/snackBar/snackBarSuccess";

const HeaderButton = () => {
  const { t, changeLanguage, currentLanguage } = useTranslationProvider();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const { pathname } = useLocation();
  const [isShowSuccess, setIsShowSuccess] = useState<boolean>(false);
  const hideHeaderButton =
    pathname === "/login/forgot-password" ||
    pathname === "/login/forgot-userName";

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "es" : "en";
    changeLanguage(newLanguage);
    setIsShowSuccess(true);
  };

  const capitalize = () => {
    const langugage = currentLanguage === "en" ? "es" : "en";
    return langugage.charAt(0).toUpperCase() + langugage.slice(1);
  };

  const renderComponent = () => (
    <AnimateWrapper className="fade">
      <div className={styles.wrapper}>
        <IconButton
          aria-label="change language"
          onClick={() => toggleLanguage()}
        >
          <span className={styles.changeLanguage}>{capitalize()}</span>
        </IconButton>
        <IconButton
          aria-label="navigate to moneypass"
          onClick={() => window.open("https://www.moneypass.com/")}
        >
          <img src={matches ? mapIcon : mapIconW} alt="Map Icon" />
        </IconButton>
      </div>
      <SnackBarSuccess
        message={t("Settings.The language has been successfully changed", {
          defaultValue: "The language has been successfully changed",
        })}
        isOpen={isShowSuccess}
        onClose={() => setIsShowSuccess(false)}
      />
    </AnimateWrapper>
  );

  return hideHeaderButton ? <></> : renderComponent();
};

export default HeaderButton;
