import { useEffect, useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import styles from './style.module.scss';
import { Transaction } from 'api/endpoints/transaction';
import { Typography } from 'shared/ui/typography';
import helper from 'services/helper';
import moment from 'moment';
import StepperComponent from 'shared/components/TransactionDetails/Stepper';
import { useACHTransferDetailsControllerMutation } from 'api/endpoints/fundsMovement';
import { safeJsonParse } from 'shared/utils/require-not-null';
import { Skeleton } from 'components/skeleton';
import { Divider } from '@mui/material';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { InfoTooltip } from 'shared/components/InfiTooltip';
import { CercleSuccessGreenIcon } from 'shared/ui/icons';
import { UncommingCircleArrowIcon } from 'shared/ui/icons';
import cn from 'classnames'

interface TransferDetailProps {
  selectTransfer: Transaction | null;
  selectAccount: string;
}

const transferStatusCompleted =
  'The transfer has been successfuly processed by the bank and funds are available';

export const TransferDetail = ({
  selectTransfer,
  selectAccount,
}: TransferDetailProps) => {
  const [ACHTransferDetailsMutation, { isLoading: ACHTransferIsLoading }] =
    useACHTransferDetailsControllerMutation();

  const [transferDetails, setTransferDetails] = useState<any>();

  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const fromFinAccount = unclosedFinancialAccounts.find(
    (account) => account.financialAccountId === selectAccount
  );

  useEffect(() => {
    if (selectTransfer) {
      getACHTransferDetails(selectTransfer.id);
    }
  }, [selectTransfer]);

  const getACHTransferDetails = async (transferId: string) => {
    try {
      const res = await ACHTransferDetailsMutation({ transferId }).unwrap();
      const data = safeJsonParse<any>(res, {});

      const formatDate = (inputDate) => {
        const parsedDate = moment(inputDate);
        const formattedDate = parsedDate.format('h:mm A, ddd, DD MMM YYYY');
        return formattedDate;
      };
      const transformData = [
        data.node.createdAt && {
          name: 'Created on',
          date: formatDate(data.node.createdAt),
        },
        data.node.updatedAt && {
          name: 'Last updated',
          date: formatDate(data.node.updatedAt),
        },
        data.node.settlementDate && {
          name: 'Settlement date',
          date: formatDate(data.node.settlementDate),
        },
        data.node.fundsAvailableDate && {
          name: 'Funds available date',
          date: formatDate(data.node.fundsAvailableDate),
        },
      ].filter(Boolean);
      setTransferDetails({ ...data.node, stepperData: transformData });
    } catch (e: any) {
      console.log('e', e);
    }
  };

  const amountCurrencyCode = helper.currencyCode(
    selectTransfer?.amount.currencyCode
  );
  const balanceCurrencyCode = helper.currencyCode(
    selectTransfer?.balance?.currencyCode
  );
  const status = helper.capitalizeFirstLetter(selectTransfer?.status || '');

  const isPositiveTransfer = selectTransfer?.amount.symbol === '+';

  return ACHTransferIsLoading || financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.boxBalance}>
          <Typography>
            {amountCurrencyCode}
            {helper.formatUsdValue(selectTransfer?.amount.value)}
          </Typography>
          <Typography>
            {balanceCurrencyCode || amountCurrencyCode}
            {helper.formatUsdValue(selectTransfer?.balance?.value)}
          </Typography>
        </div>
        <div className={styles.boxTransferTo}>
          <div>
            <Typography className={styles.labelText}>Transfer To</Typography>
            <Typography>
              {selectTransfer?.name}{' '}
              <span className={styles.last4}>
                {selectTransfer?.last4.value
                  ? `(${selectTransfer?.last4.value})`
                  : ''}
              </span>
            </Typography>
            <Typography className={styles.labelText}>
              {moment(selectTransfer?.date).format('MM.DD.YYYY, hh:mm A')}
            </Typography>
          </div>
          <UncommingCircleArrowIcon
            className={
              isPositiveTransfer ? cn(styles.img, styles.imgPositive) : styles.img
            }
          />
        </div>
        <Divider />
        <div>
          <Typography className={styles.labelText}>Transfer From</Typography>
          <Typography>
            {fromFinAccount?.name}{' '}
            <span className={styles.last4}>
              ({fromFinAccount?.accountNumber.slice(-4)})
            </span>
          </Typography>
        </div>
        <div>
          <Typography className={styles.labelText}>Transaction Type</Typography>
          <Typography>{selectTransfer?.type}</Typography>
        </div>
        <div>
          <Typography className={styles.labelText}>Status</Typography>
          <div className={styles.status}>
            <CercleSuccessGreenIcon className={styles.statusIcon} />
            <Typography className={styles.statusText}>{status}</Typography>
            <InfoTooltip text={transferStatusCompleted} />
          </div>
        </div>
        {transferDetails?.stepperData && <Divider />}
        <div>
          {transferDetails?.stepperData && (
            <StepperComponent
              data={transferDetails.stepperData}
              isLoading={ACHTransferIsLoading}
            />
          )}
        </div>
        {!!transferDetails?.memo?.trim() && (
            <>
              <Divider />
              <div>
                <Typography className={styles.labelText}>Memo</Typography>
                <Typography>{ transferDetails.memo}</Typography> 
              </div>
          </>
        )}
      </div>
    </TransitionAnimation>
  );
};
