import Sidebar from "shared/components/sidebar";
import AdaptivenNavbar from "components/adaptiveNavbar";
import { PropsWithChildren } from "react";
import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import Footer from "../../shared/components/footer/index";
import { useMediaQuery } from "@mui/material";
import { BellNotificationWidget } from "widget/bellNotificationWidget";

export const HomeLayout = ({ children }: PropsWithChildren) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const location = useLocation();
  const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);

  useEffect(() => {
    setIsShowDrawer(false);
  }, [location.pathname]);

  return (
    <div className={styles.container}>
      <div className={styles.sideBarWrapper}>
        <Sidebar />
      </div>

      <div className={styles.container_box}>
        <AdaptivenNavbar
          isShowDrawer={isShowDrawer}
          setIsShowDrawer={setIsShowDrawer}
        />
        <div className={styles.wrapper}>
       
          <div
            className={cx(styles.contentWrapper, {
              [styles.hideConten]: isShowDrawer,
            })}
          >
          {!isMobile && (
            <div className={styles.desctop}>
              <BellNotificationWidget/>
            </div>
          )}
            {children}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
