import { useState, useEffect } from "react";
import NewAccessRequestForNavbar from "pages/settings/security/components/loginOptions/components/newAccessRequestForNavbar";
import NewLoansOffer from "pages/loans/components/newLoansOffer";
import { useSnackBar } from "providers/snackBar.provider";
import { useLoans } from "shared/hooks/useLoans";

const message =
  "We'll check back with you later. If you decide, your offer will be waiting. In the meantime, feel free to explore or reach out whenever you're ready.";

const PopupGroup = () => {
  const { loanSettings, loanSettingIsLoading, isShowGotLoanOffer } = useLoans();
  const { setSnackBar } = useSnackBar();
  const [showLoansOffer, setShowLoansOffer] = useState(false);
  const [showAccessRequest, setShowAccessRequest] = useState(false);

  useEffect(() => {
    if (loanSettingIsLoading) {
      return;
    }
    if (isShowGotLoanOffer) {
      setShowLoansOffer(isShowGotLoanOffer);
    } else {
      setShowAccessRequest(true);
    }
  }, [isShowGotLoanOffer, loanSettingIsLoading]);

  const handleLoansOfferClose = () => {
    setShowLoansOffer(false);
    setSnackBar({
      type: "success",
      message: message,
      isShow: true,
      width: "400px",
    });
    setShowAccessRequest(true);
  };

  return (
    <>
      {showLoansOffer && loanSettings?.marketingLink && (
        <NewLoansOffer onClose={handleLoansOfferClose} />
      )}
      {showAccessRequest && (
        <NewAccessRequestForNavbar
          onClose={() => setShowAccessRequest(false)}
        />
      )}
    </>
  );
};
export default PopupGroup;
