//page
const PERSONAL_INFORMATION = 'PersonalInformation';
const COMPANY_INFORMATION = 'CompanyInformation';
const PRIMARY_ACCOUNT = 'PrimaryAccount';
const AUTHORIZED_USERS = 'AuthorizedUsers';
const SUPPORT = 'Support';
const LANGUAGE = 'Language';
const CONNECTED_ACCOUNT = 'ConnectedAccount';
//pageMode
const FAQ = 'FAQ';
const CREATE_NEW_REWUEST = 'CreateNewRequest';
const HISTORY = 'History';
const CHAT_SUPPORT = 'ChatSupport';
const LIMITS_CONDITIONS = 'LimitsConditions';
const CONNECTE_ACCOUNT = 'ConnectAccount';

//mode
const EDIT_EMAIL_ADDRESS = 'EditEmailAddress';
const EDIT_PHONE_NUMBER = 'EditPhoneNumber';
const EDIT_ADDRESS = 'EditAddress';
const REQUEST_ACCESS = 'RequestAccess';

///
export enum SECTION {
  AUTHORIZED = 'Authorized Person',
  BUSINESS = 'Business',
  FIRST_OWNER = '1',
  SECOND_OWNER = '2',
  THIRD_OWNER = '3',
  FOURTH_OWNER = '4',
}

export enum FIELD_TYPE {
  ADDRESS = 'Address',
  EMAIL = 'Email',
  PHONE = 'Phone',
}

export {
  PERSONAL_INFORMATION,
  COMPANY_INFORMATION,
  PRIMARY_ACCOUNT,
  AUTHORIZED_USERS,
  SUPPORT,
  LANGUAGE,
  LIMITS_CONDITIONS,
  CONNECTED_ACCOUNT,
  CONNECTE_ACCOUNT,
  REQUEST_ACCESS,
};
