import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper'
import { ToMyOwnAccountStep1 } from '../to-my-own-account-step-1'
import { ToMyOwnAccountStep2 } from '../to-my-own-account-step-2'
import { ToMyOwnAccountStep3 } from '../to-my-own-account-step-3'

interface Props {
  isShow: boolean
  onClose: () => void
}

const steps: Array<[string, TStepOfDrawerStepper]> = [
  [
    '0',
    {
      id: '0',
      isCanGoBack: false,
      title: 'Between own accounts',
      prevId: null,
      Element: <ToMyOwnAccountStep1 />,
    },
  ],
  [
    '1',
    {
      id: '1',
      isCanGoBack: true,
      title: 'Between own accounts',
      prevId: '0',
      Element: <ToMyOwnAccountStep2 />,
    },
  ],
  [
    '2',
    {
      id: '2',
      isCanGoBack: false,
      title: 'Between own accounts',
      prevId: '0',
      Element: <ToMyOwnAccountStep3 />,
    },
  ],
]

const MapSteps = new Map(steps)

export const ToMyOwnAccount = (props: Props) => {
  const { isShow, onClose } = props

  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep='0'
      steps={MapSteps}
      onClose={onClose}
    />
  )
}
