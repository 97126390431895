import React, { FC } from "react";
import styles from "./style.module.scss";
import logoIcon from "assets/svg/logo-netevia-new.svg";
import phoneIcon from "assets/svg/phone-gray.svg";
import mailIcon from "assets/svg/mail-gray.svg";
import xIcon from "assets/svg/X-logo.svg";
import instaIcon from "assets/svg/instagram.svg";
import fbIcon from "assets/svg/facebook.svg";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import { useTranslationProvider } from "providers/translation/translation.provider";
import cx from "classnames";

const COLOR_BUTTON = "#023047";
interface FooterProps {
  stylesWrapper?: any;
}
const Footer: FC<FooterProps> = ({ stylesWrapper }) => {
  const { t } = useTranslationProvider();
  const hanldeNavigate = (link) => {
    window.open(link);
  };
  const styledButton = {
    color: COLOR_BUTTON,
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };
  return (
    <div className={cx(styles.container, stylesWrapper)}>
      <img src={logoIcon} width={80} />
      <div className={styles.info}>
        <div className={styles.info_contacts}>
          <div className={styles.info_contacts_box}>
            <span className={styles.info_contacts_box_title}>
              {t("Settings.Address", {
                defaultValue: "Address",
              })}
            </span>
            <p className={styles.info_contacts_box_text}>
              © 2024 Netevia Group LLC 3363 NE 163rd St, Suite 606 North Miami
              Beach, FL 33160
            </p>
          </div>

          <div className={styles.info_contacts_box}>
            <span className={styles.info_contacts_box_title}>
              {t("common.Contact information", {
                defaultValue: "Contact information",
              })}
            </span>
            <div className={styles.info_contacts_box_img}>
              <img src={phoneIcon} width={16} />
              <span className={styles.info_contacts_box_text}>
                855-479-4411
              </span>
            </div>
            <div className={styles.info_contacts_box_img}>
              <img src={mailIcon} width={16} />
              <span className={styles.info_contacts_box_text}>
                <a href="mailto:card@netevia.com">card@netevia.com</a>
              </span>
            </div>
          </div>
        </div>

        <div className={styles.info_description}>
          <p className={styles.info_description_text}>
            {t("common.Netevia is not a bank.", {
              defaultValue:
                "Netevia is not a bank. Banking services provided by Sutton Bank, Member FDIC. ",
            })}
            <br />
            {t("common.Should the member", {
              defaultValue:
                "Should the member bank holding your balance fail, you are insured for up to $250,000 of your money in the account.",
            })}
          </p>
        </div>
      </div>

      <div className={styles.buttonGroup}>
        <div className={styles.buttonGroup_box}>
          <Button
            sx={styledButton}
            onClick={() => hanldeNavigate("https://netevia.com/privacy/")}
          >
            <span className={styles.buttonGroup_label}>
              {t("common.Privacy Policy", {
                defaultValue: "Privacy Policy",
              })}
            </span>
          </Button>
          <Button
            sx={styledButton}
            onClick={() =>
              hanldeNavigate("https://netevia.com/terms-conditions/")
            }
          >
            <span className={styles.buttonGroup_label}>
              {t("common.Terms of service", {
                defaultValue: "Terms of service",
              })}
            </span>
          </Button>
          {/* <Button sx={styledButton}>
            <span className={styles.buttonGroup_label}>
              {" "}
              {t("common.Feedback", {
                defaultValue: "Feedback",
              })}
            </span>
          </Button> */}
        </div>
        <div className={styles.buttonGroup_box}>
          <IconButton
            aria-label="facebook"
            onClick={() => hanldeNavigate("https://www.facebook.com/netevia")}
          >
            <img src={fbIcon} width={24} />
          </IconButton>

          <IconButton
            aria-label="instagram"
            onClick={() =>
              hanldeNavigate("https://www.instagram.com/neteviapayments/")
            }
          >
            <img src={instaIcon} width={24} />
          </IconButton>
          <IconButton
            aria-label="X"
            onClick={() =>
              hanldeNavigate("https://twitter.com/NeteviaPayments")
            }
          >
            <img src={xIcon} width={24} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Footer;
