import { useRewards } from "pages/reward/provider/useRewards";
import { Skeleton } from "components/skeleton";
import styles from "./styles.module.scss";
import { replaceNumberWithComma } from "shared/utils/price";
import EarnderCategoriesItem from "pages/reward/components/dashboard/components/earnedMonthDashboard/components/earnedCategoriesItem";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslationProvider } from "providers/translation/translation.provider";
const HistoryLastEarnedPoints = () => {
  const { t } = useTranslationProvider();
  const {
    isDataLoading,
    lastPeriodPoints,
    lastPeriodGrouped,
    findRewardCategoryByType,
  } = useRewards();

  const calculatePercentageAmount = (amount) => {
    if (lastPeriodPoints === 0) {
      return 0;
    }
    return (amount / lastPeriodPoints) * 100;
  };
  const renderLinner = () => {
    return lastPeriodGrouped?.map((el, index) => {
      const itemProccent = calculatePercentageAmount(el.amount);
      const item = findRewardCategoryByType(el.type);
      return (
        <LinearProgress
          key={index}
          variant="determinate"
          value={100}
          sx={{
            height: "100%",
            width: `${itemProccent}%`,
            "& .MuiLinearProgress-bar": {
              backgroundColor: item?.color,
            },
          }}
        />
      );
    });
  };
  return isDataLoading ? (
    <Skeleton width="27%" height="194px" />
  ) : (
    <div className={styles.container}>
      <div className={styles.box}>
        <span className={styles.box_titleText}>
          {t("Rewards.Last earned points", {
            defaultValue: "Last earned points",
          })}
        </span>
        <span className={styles.box_pointsText}>
          {replaceNumberWithComma(lastPeriodPoints)}{" "}
          {t("Rewards.points", {
            defaultValue: "points",
          })}
        </span>
        <div className={styles.box_linner}>{renderLinner()}</div>
      </div>
      {lastPeriodGrouped?.map((el, index) => (
        <EarnderCategoriesItem
          key={index}
          points={el.points}
          type={el.type}
          stylesWrapper={styles.wrapperItem}
        />
      ))}
    </div>
  );
};

export default HistoryLastEarnedPoints;
