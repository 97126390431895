import { useBoolean } from 'shared/hooks/useBoolean'
import { Typography } from 'shared/ui/typography'
import cn from 'classnames'
import styles from './styles.module.scss'

interface ReadMoreProps {
  text: string
  maxLength: number
  styledText?: string
  styledReadMore?: string
  showLabel?: string
  hideLabel?: string
}

export const ReadMore = ({
  text,
  maxLength,
  styledText,
  styledReadMore,
  showLabel = 'Read less',
  hideLabel = 'Read more',
}: ReadMoreProps) => {
  const expandedBool = useBoolean()
  const isTextLong = text.length > maxLength
  return (
    <Typography className={cn(styledText, styles.text)}>
      {expandedBool.value ? text : `${text.substring(0, maxLength)}`}{' '}
      {isTextLong && (
        <span
          className={cn(styledReadMore, styles.readMore)}
          onClick={() => expandedBool.toggle()}
        >
          {expandedBool.value ? showLabel : hideLabel}
        </span>
      )}
    </Typography>
  )
}
