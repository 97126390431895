import { TransitionAnimation } from 'shared/ui/animations'
import { Typography } from 'shared/ui/typography'
import styles from './styles.module.scss'
import {
  selectTotalAmount,
  selectTransferType,
  selectTransfers,
  clearDataBatch,
  TransferType,
} from '../../model'
import { useAppSelector } from 'shared/models'
import SimpleContainer from 'shared/components/containers/simpleContainer'
import helper from 'services/helper'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import { useExternalPayees } from 'api/hooksApi/useExternalPayees'
import { findObjById } from 'entities/transfers'
import { BatchTransferItem } from 'entities/transfers'
import { Button } from 'shared/ui/buttons'
import { appRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'shared/models'
import { CercleSuccessGreenIcon } from 'shared/ui/icons'
import { useNeteviaPayeesControllerQuery } from 'api/endpoints/fundsMovement'
import cn from 'classnames'

export const BatchTransferSuccess = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { unclosedFinancialAccounts } = useFinancialAccounts()
  const { externalPayees } = useExternalPayees()
  const { data: neteviaPayees } = useNeteviaPayeesControllerQuery()

  const transferType = useAppSelector(selectTransferType)
  const total = useAppSelector(selectTotalAmount)
  const transfers = useAppSelector(selectTransfers)
  const isNeteviaTransfer = transferType === TransferType.toNetevia

  const renderTransferItem = () => {
    return transfers?.map((item, index) => {
      const fromAcc = findObjById(
        item.fromFinancialAccount,
        unclosedFinancialAccounts
      )
      const toPayee = isNeteviaTransfer
        ? findObjById(item.toPayee, neteviaPayees?.payees)
        : findObjById(item.toPayee, externalPayees)

      return (
        <BatchTransferItem
          key={index}
          transferIndex={index}
          fromAccName={fromAcc.name}
          fromAccLast4={fromAcc.last4Number}
          toPayeeName={toPayee.name}
          toPayeeLast4={toPayee.last4Number}
          amount={`$${helper.moneyFormat(item.amount)}`}
          isNeteviaTransfer={isNeteviaTransfer}
          memo={item?.memo}
        />
      )
    })
  }

  const onClose = () => {
    navigate(appRoutes.transfer())
    dispatch(clearDataBatch())
  }

  return (
    <TransitionAnimation>
      <SimpleContainer title={`Batch transfer - ${transferType}`}>
        <div className={styles.container}>
          <Typography>Transfer details</Typography>
          <div className={isNeteviaTransfer ? 
            cn(styles.isNeteviaWrapper, styles.wrapper) 
            : styles.wrapper}>
            <div className={styles.boxTitle}>
              <CercleSuccessGreenIcon />
              <Typography>Transfers successfully completed</Typography>
            </div>
            <div className={styles.boxTotal}>
              <Typography>${helper.moneyFormat(total)}</Typography>
              <Typography>Total batch amount</Typography>
            </div>

            {renderTransferItem()}
          </div>
          <Button
            variant='contained'
            className={styles.btn}
            onClick={onClose}
          >
            Done
          </Button>
        </div>
      </SimpleContainer>
    </TransitionAnimation>
  )
}
