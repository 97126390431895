import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangeCalendar, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";

interface RangeDatePickerProps {
  currentDate: any;
  onChange: (date: { startDate: string; endDate: string }) => void;
  disableFuture?: boolean;
}

export const RangeDatePicker = ({
  currentDate,
  onChange,
  disableFuture,
}: RangeDatePickerProps) => {
  const value: [Dayjs | null, Dayjs | null] = useMemo(
    () => [dayjs(currentDate?.startDate), dayjs(currentDate?.endDate)],
    [currentDate]
  );
  const handleChangeDate = (value, selectionState) => {
    if (selectionState === "partial") return;
    const formattedDateRange = {
      startDate: value[0].format("MM/DD/YYYY"),
      endDate: value[1].format("MM/DD/YYYY"),
    };
    onChange(formattedDateRange);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        localeText={{ start: "From", end: "To" }}
        calendars={1}
        defaultValue={value}
        onChange={handleChangeDate}
        format={"MMMM DD, YYYY"}
        disableFuture={disableFuture}
      />
    </LocalizationProvider>
  );
};
