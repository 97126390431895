export const createFormatAmountObj = (amount: number) => {
  const floatValue = amount
  
  const formatedFloatVlaue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  }).format(floatValue / 100)

  const formattedValue = formatedFloatVlaue
  const value = floatValue.toString()

  return {
    floatValue,
    formattedValue,
    value,
  }
}
