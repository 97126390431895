import { FC, ReactNode } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepContent,
} from "@mui/material";

interface StepsProps {
  activeStep: number;
  steps: string[];
  stepContent?: ReactNode[];
  orientation: "vertical" | "horizontal";
  alternativeLabel?: boolean;
}

const Steps: FC<StepsProps> = ({
  alternativeLabel,
  orientation,
  activeStep,
  steps,
  stepContent,
}) => {
  const renderStepContent = (
    index: number,
    activeStep: number,
    label: string,
    stepContent: ReactNode[]
  ) => (
    <>
      <StepLabel>{index === activeStep ? null : label}</StepLabel>
      <StepContent>{stepContent[activeStep]}</StepContent>
    </>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        alternativeLabel={alternativeLabel}
        activeStep={activeStep}
        orientation={orientation}
        connector={<ColorlibConnector />}
        sx={{
          ".MuiSvgIcon-root.Mui-completed": {
            color: "#187AC9",
          },
          ".MuiSvgIcon-root.Mui-active": {
            color: "#187AC9",
          },
          ".MuiStepLabel-labelContainer span": {
            color: "rgba(0, 0, 0, 0.60)",
            fontFamily: "Noto Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "157%",
            letterSpacing: "0.1px",
          },
        }}
      >
        {steps.map((label, index) => (
          <Step key={index}>
            {!!stepContent ? (
              renderStepContent(index, activeStep, label, stepContent)
            ) : (
              <StepLabel>{label}</StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default Steps;

const ColorlibConnector = (props) => (
  <StepConnector
    {...props}
    sx={{
      height: "auto",
      display: "flex",
    }}
  />
);
