import CustomContainer from 'shared/components/containers/customContainer';
import notificationsIcon from 'assets/svg/bell-gray.svg';
import loginManagementIcon from 'assets/svg/device-success-gray.svg';
import changePasswordIcon from 'assets/svg/lock-gray.svg';
import Notifications from './components/notifications';
import { useSearchParams } from 'react-router-dom';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import {
  NOTIFICATIONS,
  LOGIN_OPTIONS,
  CHANGE_PASSWORD,
} from './securitySearchParams';
import LoginOptions from './components/loginOptions';
import {ChangePassword} from './components/changePassword';

const Security = () => {
  const { t } = useTranslationProvider();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  const notifications = {
    id: NOTIFICATIONS,
    label: t('Settings.Notifications', {
      defaultValue: 'Notifications',
    }),
    img: notificationsIcon,
    isShow: true,
  };
  const loginOptions = {
    id: LOGIN_OPTIONS,
    label: t('Settings.Login Options', {
      defaultValue: 'Login Options',
    }),
    img: loginManagementIcon,
    isShow: true,
  };
  const changePassword = {
    id: CHANGE_PASSWORD,
    label: t('Login.Change Password', {
      defaultValue: 'Change Password',
    }),
    img: changePasswordIcon,
    isShow: true,
  };
  const searchParamsArr = [notifications, loginOptions, changePassword].filter(
    (item) => item.isShow
  );

  return (
    <CustomContainer
      title={t('Settings.Security Settings', {
        defaultValue: 'Security Settings',
      })}
      tabsArr={searchParamsArr}
    >
      {page === NOTIFICATIONS && <Notifications />}
      {page === LOGIN_OPTIONS && <LoginOptions />}
      {page === CHANGE_PASSWORD && <ChangePassword />}
    </CustomContainer>
  );
};
export default Security;
