import { useState, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useRewardIntegrations } from 'api/hooksApi/useRewardsIntegrations';
import { useAprilURLMutation } from 'api/endpoints/user';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import {
  RewardIntegrationsDTO,
} from 'api/endpoints/rewardIntegrations';
import { OpenOrderOutCard } from 'features/open-orderout-card';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { appRoutes, RewardsRouteSubRoute } from 'routes';
import { useNavigate } from 'react-router-dom';
import { SliderRewards } from './componetns/sliderRewards';
import { RewardMode } from 'pages/reward/enums';

const DESCTOP_LENGTH = 4;
const MOB_LENGTH = 1.5;

const FeaturedRewards = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const { setSnackBar } = useSnackBar();

  const { rewardIntegrations, rewardIntegrationsIsLoading } =
    useRewardIntegrations();
  const [aprilURLMutation, { isLoading: aprilURLIsLoading }] =
    useAprilURLMutation();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [showOrderOut, setShowOrderOut] = useState(false);

  const handleCloseOrderOut = useCallback(() => {
    setShowOrderOut(false);
  }, []);

  const settingsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: matches ? DESCTOP_LENGTH : MOB_LENGTH,
    slidesToScroll: 1,
    arrows: false,
  };

  const createSliderItemHandler = (item: RewardIntegrationsDTO) => {
    if (item.type === 2) {
      getAprilLink();
      return;
    }

    if (item.type === 3 && item.name === 'OrderOut') {
      setShowOrderOut(true);
      return;
    }
    if (item.type === 4 && item.name === 'Gift card deals') {
      navigate(
        appRoutes.rewards(RewardsRouteSubRoute.myGiftCard, {
          mode: RewardMode.Brands,
        })
      );
      return;
    }
    window.open(item.url);
  };

  const getAprilLink = async () => {
    try {
      const res = await aprilURLMutation().unwrap();
      window.open(res);
    } catch (e: any) {
      console.log('error', e);
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const BANNER_LENGTH = rewardIntegrations?.featured?.length;

  const disabledPrevSlide = currentSlideIndex === 0;

  const disabledNextSlideDesctop =
    currentSlideIndex === BANNER_LENGTH - DESCTOP_LENGTH;

  const disabledNextSlideMob = currentSlideIndex === BANNER_LENGTH - MOB_LENGTH;

  const disabledNextSlide = matches
    ? disabledNextSlideDesctop
    : disabledNextSlideMob;

  return (
    <>
      <OpenOrderOutCard
        isShow={showOrderOut}
        onClose={handleCloseOrderOut}
      />
      <SliderRewards
        title={t('Rewards.Featured rewards for you', {
          defaultValue: 'Featured rewards for you',
        })}
        data={rewardIntegrations?.featured}
        loadingData={rewardIntegrationsIsLoading}
        disabledPrevSlide={disabledPrevSlide}
        disabledNextSlide={disabledNextSlide}
        setCurrentSlideIndex={setCurrentSlideIndex}
        aprilURLIsLoading={aprilURLIsLoading}
        settingsSlider={settingsSlider}
        sliderHandler={createSliderItemHandler}
      />
    </>
  );
};

export default FeaturedRewards;
