import { FC } from "react";
import { Popup } from "components/popup";
import styles from "./style.module.scss";
import bannerIcon from "assets/svg/lending-banner.svg";
import helper from "services/helper";
import {
  css,
  styled,
  Button,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLoans } from "shared/hooks/useLoans";

interface NewLoansOfferProps {
  onClose: () => void;
}
const NewLoansOffer: FC<NewLoansOfferProps> = ({ onClose }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const { navigateToMarketingLink, dontShowMeLoan, loanSettings } = useLoans();

  return (
    <Popup onClose={onClose} stylesWrapper={styles.popup}>
      <Stack direction={matches ? "row" : "column"} spacing={3}>
        <Image src={bannerIcon} alt={"banner"} />
        <Stack direction={"column"} spacing={1}>
          <Title>Unlock possibilities with an exclusive offer!</Title>
          <SubTitle>
            Congratulations! You`re pre-selected for an exclusive{" "}
            <DedicatedText>
              $
              {loanSettings?.amount &&
                helper.moneyFormat(loanSettings?.amount * 100)}
            </DedicatedText>{" "}
            opportunity. Explore a competitive interest rate of{" "}
            <DedicatedText>9.5%-18.00%</DedicatedText> for a{" "}
            <DedicatedText>{loanSettings?.terms}-months</DedicatedText> term.
          </SubTitle>
        </Stack>
      </Stack>
      <ButtonGroup>
        <Button
          variant="text"
          onClick={dontShowMeLoan}
          sx={{ color: "#187ac9" }}
        >
          NOT INTERESTED
        </Button>
        <div>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ color: "#187ac9", width: matches ? "fit-contet" : "100%" }}
          >
            Ask me later
          </Button>
          <Button
            variant="contained"
            onClick={navigateToMarketingLink}
            sx={{
              background: "#187ac9",
              width: matches ? "fit-contet" : "100%",
            }}
          >
            APPLY NOW
          </Button>
        </div>
      </ButtonGroup>
    </Popup>
  );
};

export default NewLoansOffer;

const Title = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  `}
`;
const SubTitle = styled(Typography)`
  ${({ theme }) => css`
    width: 400px;
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;
const DedicatedText = styled("span")`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.15px;
  `}
`;

const ButtonGroup = styled("span")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    width: 100%;
    gap: 16px;
    & > div {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      & > div {
        width: 100%;
        justify-content: space-between;
      }
    }
  `}
`;
const Image = styled("img")`
  ${({ theme }) => css`
    width: 220px;
    height: 150px;
    ${theme.breakpoints.down("sm")} {
      width: 100%;
      height: auto;
    }
  `}
`;
