import * as yup from "yup";
import dayjs, { Dayjs } from "dayjs";

const today = dayjs();
const subtractDate = today.subtract(18, "years").startOf("day"); /// user not 18 old

export const schema = yup.object().shape({
  name: yup
    .string()
    .test("is-required", "Name is required", function (value) {
      return !!value || this.createError({ message: "Name is required" });
    })
    .test("no-digits", "Name should not contain digits", function (value) {
      if (!value) return true;
      return (
        /^[^0-9]*$/.test(value) ||
        this.createError({ message: "Name should not contain digits" })
      );
    })
    .test(
      "no-special-chars",
      "Name should not contain special characters",
      function (value) {
        if (!value) return true;
        return (
          /^[a-zA-Z0-9\s]*$/.test(value) ||
          this.createError({
            message: "Last name should not contain special characters",
          })
        );
      }
    ),

  lastName: yup
    .string()
    .test("is-required", "Last name is required", function (value) {
      return !!value || this.createError({ message: "Last name  is required" });
    })
    .test(
      "no-digits",
      "Last name  should not contain digits",
      function (value) {
        if (!value) return true;
        return (
          /^[^0-9]*$/.test(value) ||
          this.createError({ message: "Last name  should not contain digits" })
        );
      }
    )
    .test(
      "no-special-chars",
      "Last name should not contain special characters",
      function (value) {
        if (!value) return true;
        return (
          /^[a-zA-Z0-9\s]*$/.test(value) ||
          this.createError({
            message: "Last name should not contain special characters",
          })
        );
      }
    ),

  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address"
    ),
  phone: yup
    .string()
    .test("is-required", "Phone number is required", function (value) {
      return (
        !!value || this.createError({ message: "Phone number is required" })
      );
    })
    .test(
      "is-valid-format",
      "Please enter a valid 10-digit phone number",
      function (value) {
        if (!value) return true;
        return (
          /^\d{10}$/.test(value) ||
          this.createError({
            message: "Please enter a valid 10-digit phone number",
          })
        );
      }
    )
    .test(
      "no-letters",
      "Phone number should not contain letters",
      function (value) {
        if (!value) return true;
        return (
          /^\d+$/.test(value) ||
          this.createError({
            message: "Phone number should not contain letters",
          })
        );
      }
    )
    .test(
      "no-spaces",
      "Phone number should not contain spaces",
      function (value) {
        if (!value) return true;
        return (
          /^\S*$/.test(value) ||
          this.createError({
            message: "Phone number should not contain spaces",
          })
        );
      }
    ),
  dateOfBirth: yup
    .date()
    .required("Please enter a valid Date Of Birth")
    .max(
      subtractDate.toDate(),
      "Authorized  user should be at least 18 years old to proceed"
    )
    .min(
      dayjs("1925-01-01").toDate(),
      "The date of birth must be no older than 01.01.1925"
    ),
});
