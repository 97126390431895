import React from "react";
import AcoountItem from "./components/accountItem";
import { Divider } from "@mui/material";
import styled, { css } from "styled-components";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import helper from "services/helper";
import { Skeleton } from "components/skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import DrawerModal from "shared/components/drawerModal";
import AddNewAccount from "./components/addNewAccount";
import { useMediaQuery, useTheme } from "@mui/material";
import AccountListHeader from "./components/accountListHeader";
import AccountListFooter from "./components/accountListFooter";
import { useSnackBar } from "providers/snackBar.provider";
import { useCurrentUser } from "providers/user.provider";
import EmtyItem from "./components/emtyItem";

export const ADD_NEW_ACCOUNT = "Add new account";

const AccountList = () => {
  const navigate = useNavigate();
  const { isSubUser, subUserRights } = useCurrentUser();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const [searchParams, setSearchParams] = useSearchParams();

  const { financialAccountsIsLoading, unclosedFinancialAccounts, total } =
    useFinancialAccounts();

  const userOrSubUserRigthOpenNewFinancialAccount =
    !isSubUser ||
    subUserRights({
      openFinancialAccount: true,
      mainAccess: true,
    });

  const renderItem = () => {
    return unclosedFinancialAccounts?.map((account, index) => (
      <React.Fragment key={index}>
        <AcoountItem
          key={account.financialAccountId}
          id={account.financialAccountId}
          name={account.name}
          last4={`(**** ${account.accountNumber?.slice(-4)})`}
          avaibleBalance={`$${helper.moneyFormat(account.availableCash?.value) || ''}`}
          accountType={account.accountType}
          handleNavigate={navigateToAccount}
        />
        {!matches && index < unclosedFinancialAccounts.length - 1 && (
          <Divider />
        )}
      </React.Fragment>
    ));
  };

  const renderListAcc = () => {
    return !!unclosedFinancialAccounts?.length ? (
      <>
        <List>
          {renderItem()}
          <Divider />
        </List>
        <AccountListFooter balance={helper.moneyFormat(total.availableCash)} />
      </>
    ) : (
      <EmtyItem />
    );
  };

  const navigateToAccount = (id: string) => {
    navigate(`/account/${id}`);
  };

  const openNewFinAcc = (mode: string) => {
    setSearchParams({ mode });
  };

  return financialAccountsIsLoading ? (
    <Skeleton width="100%" height="210px" />
  ) : (
    <Container>
      <AccountListHeader
        handleAddAcc={openNewFinAcc}
        rights={userOrSubUserRigthOpenNewFinancialAccount}
        title="Your accounts"
        openLink={ADD_NEW_ACCOUNT}
        buttonLabel="add account"
      />
      {renderListAcc()}
      <DrawerModal
        isShow={searchParams.get("mode") === ADD_NEW_ACCOUNT}
        onClose={() => setSearchParams({})}
        titleText={ADD_NEW_ACCOUNT}
      >
        <AddNewAccount />
      </DrawerModal>
    </Container>
  );
};

export default AccountList;

const Container = styled("div")`
  ${({ theme }) => css`
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    border: 1px solid ${theme.colors.borderLightGrey};
    & > *:nth-child(1) {
      border-bottom: 1px solid ${theme.colors.borderLightGrey};
      padding: 16px 24px;
    }
    & > *:nth-child(3) {
      padding: 16px 24px;
    }
    ${theme.breakpoints.down("sm")} {
      & > *:nth-child(1) {
        border-bottom: none;
        padding: 16px;
      }
      & > *:nth-child(3) {
        padding: 0 16px 16px 16px;
      }
    }
  `}
`;

const List = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    ${theme.breakpoints.down("sm")} {
      padding: 16px;
    }
  `};
`;
