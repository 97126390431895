import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowLeft from 'assets/svg/arrow-left-gray.svg';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import CompanyInfoButton from '../components/companyInfoButton/indexs';
import styles from './style.module.scss';
import { useCurrentUser } from '../../../providers/user.provider';
import { useSubUserMEControllerQuery } from '../../../api/endpoints/subProfiles';
import helper from '../../../services/helper';
import { states } from '../../../constants/data';
import AvatarIcon from '../../../components/avatar/AvatarIcon';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { List, Divider } from '@mui/material';
import Mail from 'assets/svg/mail-gray.svg';
import Home from 'assets/svg/home-gray.svg';
import Phone from 'assets/svg/phone-gray.svg';
import { useMediaQuery, useTheme } from '@mui/material';
import { useAccount } from 'pages/settings/accountSettings/provider';
import { PERSONAL_INFORMATION } from '../accountSettings/searchParamsTypes';
import { SectionPerson, EditType } from '../accountSettings/types';

const PersonalInformation = () => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up('sm'));
  const [searchParams, setSearchParams] = useSearchParams();
  const { setIsShowDrawer } = useAccount();
  const { data: subUserInfo, isLoading: subUserInfoIsLoading } =
    useSubUserMEControllerQuery({});
  const region = states.find((state) => state.value === subUserInfo?.state);
  const subUserFullName = `${subUserInfo?.givenName} ${subUserInfo?.familyName}`;

  const handleNavigate = (mode: string, type: EditType, titleForm) => {
    const page = PERSONAL_INFORMATION;
    const pageMode = 'ChangePersonalInfo';

    setIsShowDrawer(true);
    setSearchParams({
      page,
      pageMode,
      mode,
      title: titleForm || 'Edit data',
      type: type,
    });
  };
  const titleEmail = `EditPersonalEmailAddress`;
  const titlePhone = `EditPersonalPhoneNumber`;
  const titleAddress = `EditPersonalAddress`;
  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        {!matches && (
          <div className={styles.container_nav}>
            <img
              src={ArrowLeft}
              onClick={() => setSearchParams({})}
            />
            <span className={styles.container_nav_title}>
              {t('Settings.Personal Information', {
                defaultValue: 'Personal Information',
              })}
            </span>
          </div>
        )}
        <span className={styles.container_holderName}>
          {subUserInfoIsLoading ? '' : subUserFullName}
        </span>

        <List
          disablePadding
          className={styles.listWrapper}
        >
          <CompanyInfoButton
            title={t('Settings.Email', {
              defaultValue: 'Email',
            })}
            img={Mail}
            description={subUserInfo?.email}
            handleNavigate={handleNavigate}
            mode={EditType.Email}
            titleForm={titleEmail}
            type={EditType.Email}
          />
          <Divider />
          <CompanyInfoButton
            title={t('Settings.Phone', {
              defaultValue: 'Phone',
            })}
            img={Phone}
            description={`${
              subUserInfo?.countryCodeAlpha3 === 'USA' ? '+1' : '+'
            } ${helper.formatPhone(subUserInfo?.phoneNumber)}`}
            handleNavigate={handleNavigate}
            mode={EditType.Phone}
            titleForm={titlePhone}
            type={EditType.Phone}
          />
          <Divider />
          <CompanyInfoButton
            title={t('Settings.Address', {
              defaultValue: 'Address',
            })}
            img={Home}
            description={helper.formatAddress({
              countryCodeAlpha3: subUserInfo?.countryCodeAlpha3,
              extendedAddress: '',
              locality: subUserInfo?.city,
              postalCode: subUserInfo?.postalCode,
              region: region?.label,
              streetAddress: subUserInfo?.streetAddress,
            })}
            handleNavigate={handleNavigate}
            mode={EditType.Address}
            titleForm={titleAddress}
            type={EditType.Address}
          />
        </List>
      </div>
    </AnimateWrapper>
  );
};

export default PersonalInformation;
