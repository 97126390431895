import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AnimateWrapper } from "../animate-wrapper";
import { Skeleton } from "../skeleton";
import styles from "./style.module.scss";
import {
  useAttachToFinAccControllerMutation,
  useDetachToFinAccControllerMutation,
} from "../../api/endpoints/subProfiles";
import CasesOutlined from "../../assets/svg/CasesOutlined.svg";
import helper from "../../services/helper";
import WarningModal from "../modals/WarningModal";
import NewAccount from "../../shared/components/newAccount";
import { Button, Switch } from "@mui/material";
import MoonLoader from "react-spinners/MoonLoader";
import { useSubUsersAvailableFinancialAccounts } from "api/hooksApi/useSubUsersAvailableFinancialAccounts";

interface PropTypes {
  setPageStatus: (string) => void;
  backRoute: string;
  selectAccounts?: any[];
  setSelectAccounts?: (selectAccounts: any[]) => void;
  setNewAccountId?: (string) => void;
}

type ChooseFinAccToConnectSubUserMode = "manage-account" | "open-account";

const ChooseFinAccToConnectSubUser = ({
  setPageStatus,
  backRoute,
  setSelectAccounts = () => {},
  selectAccounts = [],
  setNewAccountId = () => {},
}: PropTypes) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const subUserId = searchParams.get("id") || "";

  const {
    subUsersAvailableFinancialAccounts,
    subUsersAvailableFinancialAccountsIsLoading,
    subUsersAvailableFinancialAccountsRefetch,
  } = useSubUsersAvailableFinancialAccounts(subUserId);

  const [availableAccounts, setAvailableAccounts] = useState<any>([]);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [attachToFinAccMutation, { isLoading: attachToFinAccLoading }] =
    useAttachToFinAccControllerMutation();
  const [detachToFinAccMutation, { isLoading: detachToFinAccLoading }] =
    useDetachToFinAccControllerMutation();
  const [
    chooseFinAccToConnectSubUserMode,
    setChooseFinAccToConnectSubUserMode,
  ] = useState<ChooseFinAccToConnectSubUserMode>("manage-account");
  useEffect(() => {
    setAvailableAccounts(subUsersAvailableFinancialAccounts);
  }, [subUsersAvailableFinancialAccounts]);

  useEffect(() => {
    if (selectAccounts.length) {
      updateAvailableCardsWithSelection();
    }
  }, []);

  const updateAvailableCardsWithSelection = () => {
    setAvailableAccounts((prevAccounts) => {
      return prevAccounts.map((acc) => {
        if (selectAccounts.find((selectCard) => selectCard.id === acc.id)) {
          return {
            ...acc,
            selected: true,
          };
        }
        return acc;
      });
    });
  };
  const sortByDate = (a, b) => {
    const dateA = new Date(a.created);
    const dateB = new Date(b.created);
    return Number(dateA) - Number(dateB);
  };
  const renderItems = () => {
    return availableAccounts?.sort(sortByDate).map((acc, index) => (
      <AnimateWrapper key={acc.financialAccountId} delay={index * 100}>
        <div key={acc.financialAccountId} className={styles.listItem}>
          <div className={styles.blockName}>
            <img className={styles.blockName_img} src={CasesOutlined} />
            <div>
              <p className={styles.blockName_text}>{acc.name}</p>
              <p className={styles.blockMoney_text}>
                $ {helper.moneyFormat(acc.availableCash?.value)}
              </p>
            </div>
          </div>
          <div className={styles.mobView}>
            <div className={styles.blockNumb}>
              <p className={styles.blockNumb_text}>
                ****
                {acc?.accountNumber?.slice(-4)}
              </p>
            </div>
          </div>
          <div>
            <Switch onChange={() => onSelect(acc)} checked={!!acc.selected} />
          </div>
        </div>
      </AnimateWrapper>
    ));
  };

  const onSelect = (acc) => {
    setAvailableAccounts(
      availableAccounts.map((i) => {
        return {
          ...i,
          selected:
            acc.financialAccountId === i.financialAccountId
              ? !i.selected
              : i.selected,
        };
      })
    );
  };

  const editAccount = async () => {
    try {
      const filterSelectAccounts = availableAccounts.filter((i) => i.selected);
      setSelectAccounts(filterSelectAccounts);
      if (backRoute === "CreateSubUserAcc" && filterSelectAccounts.length) {
        setSuccessModal(true);
        setPageStatus(undefined);
        return;
      } else if (
        backRoute === "CreateSubUserAcc" &&
        !filterSelectAccounts.length
      ) {
        setWarningModal(true);
        return;
      }
      await Promise.all(
        availableAccounts.map(async (account) => {
          if (!account.attached && account.selected) {
            await attachToFinAccMutation({
              financialAccountId: account.financialAccountId,
              SubProfileId: subUserId,
            });
          } else if (account.attached && !account.selected) {
            await detachToFinAccMutation({
              FinancialAccoutId: account.financialAccountId,
              SubProfileId: subUserId,
            });
          }
        })
      );
      setPageStatus(undefined);
      subUsersAvailableFinancialAccountsRefetch();
      setSuccessModal(true);
    } catch (e) {
      console.log("e", e);
    }
  };

  if (chooseFinAccToConnectSubUserMode === "manage-account") {
    return (
      <AnimateWrapper className="fade">
        <div>
          <div className={styles.container}>
            <div>
              <p className={styles.container_title}>
                Choose accounts to connect to the authorized user
              </p>
              <div className={styles.addButtonContainer}>
                <Button
                  onClick={() =>
                    setChooseFinAccToConnectSubUserMode("open-account")
                  }
                  variant="text"
                >
                  {"+ open new account"}
                </Button>
              </div>
              <div className={styles.container_list}>
                {subUsersAvailableFinancialAccountsIsLoading ? (
                  <Skeleton height="75px" />
                ) : (
                  renderItems()
                )}
              </div>
            </div>
          </div>
          <div className={styles.blockButton}>
            <Button variant="contained" onClick={editAccount}>
              {detachToFinAccLoading || attachToFinAccLoading ? (
                <MoonLoader color={"#fff"} size={15} loading={true} />
              ) : (
                "Save Changes"
              )}
            </Button>
          </div>
        </div>
        <WarningModal
          titleMassage={"You must select a financial account"}
          onClose={() => setWarningModal(false)}
          isOpen={warningModal}
        />
      </AnimateWrapper>
    );
  }

  if (chooseFinAccToConnectSubUserMode === "open-account") {
    return (
      <NewAccount
        setPageStatus={setPageStatus}
        setOpenNewAccountId={setNewAccountId}
        backRoute={backRoute}
        setChooseFinAccMode={setChooseFinAccToConnectSubUserMode}
        chooseFinAccToConnectSubUserMode={chooseFinAccToConnectSubUserMode}
      />
    );
  }
  return null;
};

export default ChooseFinAccToConnectSubUser;
