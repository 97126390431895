import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FormNewAutorizedSubUser from '../../../components/formNewAutorizedUser';
import ManageAccess from '../../../components/manageAccess';
import styles from './style.module.scss';
import {
  useAddSubUserControllerMutation,
  useAttachToFinAccControllerMutation,
  useAttachToPaymentCardControllerMutation,
  useSubUsersControllerQuery,
} from 'api/endpoints/subProfiles';
import { useEditSubUserAccessRightsControllerMutation } from '../../../api/endpoints/usersManagement';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import { usePaymentCardByid } from '../../../api/hooksApi/usePaymentCardById';
import { useSubUsersAvailablePaymentCards } from '../../../api/hooksApi/useSubUsersAvailablePaymentCards';
import ConnectedPrimaryCardAccountList from '../../../shared/components/connectedPrimaryCardAccountList';
import ChoosePrimaryCardAccToConnectSubUser from '../../../components/choosePrimaryCardAccToConnectSubUser';
import WarningModal from '../../../components/modals/WarningModal';
import BeatLoader from 'react-spinners/BeatLoader';
import { schema } from './schemaValidation';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import DrawerModal from 'shared/components/drawerModal';
import { Button } from '@mui/material';
import { useSubUsersAvailableFinancialAccounts } from 'api/hooksApi/useSubUsersAvailableFinancialAccounts';

type CreateSubUserPageMode =
  | 'manage'
  | 'manage-primary-account';

interface CreateNewAutorizedUserProps {
  setSuccessMessage?: (value: string) => void;
}

const CreateNewAutorizedUser = ({
  setSuccessMessage,
}: CreateNewAutorizedUserProps) => {
  const { t } = useTranslationProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '';
  const { refetch } = useSubUsersControllerQuery();
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs>();

  const [department, setDepartment] = useState<string>('');
  const [manageVisibleAccess, setManageVisibleAccess] =
    useState<boolean>(false);
  const [bankingCardAccess, setBankingCardAccess] = useState<boolean>(false);
  const [financialAccounAccess, setFinancialAccounAccess] =
    useState<boolean>(false);
  const [allViewAccess, setAllViewAccess] = useState<boolean>(false);
  const [makeTransferAccess, setMakeTransferAccess] = useState<boolean>(false);
  const [fullAccess, setFullAccess] = useState<boolean>(false);
  const [selectCards, setSelectCards] = useState<any[]>([]);
  const [newCardId, setNewCardId] = useState<string>('');
  const [newAccountId, setNewAccountId] = useState<string>('');
  const [selectAccounts, setSelectAccounts] = useState<any[]>([]);
  const [selectPrimaryAccount, setSelectPrimaryAccount] = useState<any[]>([]);
  const [newPrimaryAccountId, setNewPrimaryAccountId] = useState<string>('');
  const [errors, setErrors] = useState<any>({});
  const [createSubUserPageMode, setCreateSubUserPageMode] =
    useState<CreateSubUserPageMode>('manage');
  const [warningModal, setWarningModal] = useState<boolean>(false);

  const [addSubUserMutation, { isLoading: addSubUserIsLoading }] =
    useAddSubUserControllerMutation();
  const [
    editSubUserAccessRightsMutation,
    { isLoading: editSubUserAccessRightsIsLoading },
  ] = useEditSubUserAccessRightsControllerMutation();
  const [
    attachToPaymentCardMutation,
    { isLoading: attachToPaymentCardIsLoading },
  ] = useAttachToPaymentCardControllerMutation();
  const [attachToFinAccMutation] = useAttachToFinAccControllerMutation();
  const { subUsersAvailablePaymentCardsIsLoading } =
    useSubUsersAvailablePaymentCards();

  const { subUsersAvailableFinancialAccounts } =
    useSubUsersAvailableFinancialAccounts();

  const { paymentCardById } = usePaymentCardByid();

  const isLoading = [
    addSubUserIsLoading,
    editSubUserAccessRightsIsLoading,
    attachToPaymentCardIsLoading,
  ].some(Boolean);

  useEffect(() => {
    if (newCardId && !subUsersAvailablePaymentCardsIsLoading) {
      addOpenNewCard(newCardId);
    }
  }, [newCardId, subUsersAvailablePaymentCardsIsLoading]);

  useEffect(() => {
    if (newAccountId) {
      addOpenNewFinAccoutn(newAccountId);
    }
    if (newPrimaryAccountId) {
      addOpenNewPrimaryFinAccoutn(newPrimaryAccountId);
    }
  }, [newAccountId, newPrimaryAccountId, subUsersAvailableFinancialAccounts]);

  const addOpenNewFinAccoutn = async (newAccountId) => {
    const findItem = subUsersAvailableFinancialAccounts?.find(
      (acc) => acc.financialAccountId === newAccountId
    );
    if (findItem) {
      setSelectAccounts([...selectAccounts, findItem]);
    }
  };

  const addOpenNewPrimaryFinAccoutn = async (newPrimaryAccountId) => {
    const findItem = subUsersAvailableFinancialAccounts?.find(
      (acc) => acc.financialAccountId === newPrimaryAccountId
    );
    if (findItem) {
      setSelectPrimaryAccount([findItem]);
    }
  };

  const addOpenNewCard = async (newCardId) => {
    // массив subUsersAvailablePaymentCards не обновляеться соотвественно новой карты там нет
    // const find = subUsersAvailablePaymentCards.find((card) => card.id === openCardId)
    const findItem = await paymentCardById(newCardId);
    setSelectCards([...selectCards, findItem]);
  };

  const validateForm = async () => {
    const values = { email, name, lastName, phone, dateOfBirth };
    try {
      await schema.validate(values, { abortEarly: false });
      setErrors('');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        const errors = {};
        error.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
        setErrors(errors);
      }
    }
  };
  const createNewSubUser = async () => {
    try {
      const values = { email, name, lastName, phone, dateOfBirth };
      await schema.validate(values, { abortEarly: false });
      let res;
      const date = dateOfBirth?.toDate();
      if (!!selectPrimaryAccount.length && dateOfBirth) {
        res = await addSubUserMutation({
          email: email,
          givenName: name,
          familyName: lastName,
          department: department,
          dateOfBirth: date
            ? new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
              ).toISOString()
            : '',
          phoneNumber: phone,
          financialAccountId: selectPrimaryAccount[0]?.financialAccountId,
        }).unwrap();
        const subUserId = res?.id;

        if (subUserId) {
          const data = {
            openBankingCard: bankingCardAccess,
            openFinancialAccount: financialAccounAccess,
            viewAllCardsAndFinancialAccounts: allViewAccess,
            makeTransfers: makeTransferAccess,
            mainAccess: fullAccess,
          };

          await editSubUserAccessRightsMutation({
            data,
            subUserId,
          }).unwrap();
        }

        if (subUserId && selectCards?.length) {
          await Promise.all(
            selectCards.map(async (card) => {
              await attachToPaymentCardMutation({
                PaymentCardId: card.id,
                SubProfileId: subUserId,
              });
            })
          );
        }

        if (subUserId && selectAccounts?.length) {
          await Promise.all(
            selectAccounts.map(async (account) => {
              await attachToFinAccMutation({
                financialAccountId: account.financialAccountId,
                SubProfileId: subUserId,
              });
            })
          );
        }
        refetch();
        setSearchParams({ page });
        setSuccessMessage &&
          setSuccessMessage('New authorized user added successfully');
      } else {
        setWarningModal(true);
      }
    } catch (error: any) {
      validateForm();
      console.log('e', error);
    }
  };

  const accessProps = {
    manageVisibleAccess,
    setManageVisibleAccess,
    bankingCardAccess,
    setBankingCardAccess,
    financialAccounAccess,
    setFinancialAccounAccess,
    allViewAccess,
    setAllViewAccess,
    makeTransferAccess,
    setMakeTransferAccess,
    fullAccess,
    setFullAccess,
  };
  const formProps = {
    name,
    setName,
    lastName,
    setLastName,
    email,
    setEmail,
    department,
    setDepartment,
    errors,
    setErrors,
    phone,
    setPhone,
    dateOfBirth,
    setDateOfBirth,
    createSub: true,
  };
  if (true) {
    return (
      <AnimateWrapper className='fade'>
        <div>
          <div style={{ height: 'auto' }}>
            <FormNewAutorizedSubUser {...formProps} />
          </div>

          <div className={styles.boxConnected}>
            <ManageAccess {...accessProps} />
            <ConnectedPrimaryCardAccountList
              setCreateSubUserPageMode={setCreateSubUserPageMode}
              selectAccount={selectPrimaryAccount}
            />
          </div>

          <div className={styles.boxButton}>
            <Button
              onClick={createNewSubUser}
              disabled={isLoading}
              variant='contained'
            >
              {isLoading ? (
                <BeatLoader
                  size={10}
                  color='#939598'
                />
              ) : (
                t('Settings.Add Authorized User', {
                  defaultValue: 'Add Authorized User',
                })
              )}
            </Button>
          </div>
          <WarningModal
            titleMassage={t(
              'Settings.Primary card account is a mandatory field',
              {
                defaultValue: 'Primary card account is a mandatory field!',
              }
            )}
            onClose={() => setWarningModal(false)}
            isOpen={warningModal}
          />
        </div>
        <DrawerModal
          isShow={createSubUserPageMode === 'manage-primary-account'}
          onClose={() => setSearchParams({ page })}
          goBack={() => setCreateSubUserPageMode('manage')}
          titleText='Set primary card account'
        >
          <ChoosePrimaryCardAccToConnectSubUser
            setPageStatus={setCreateSubUserPageMode}
            backRoute={'CreateSubUserAcc'}
            selectAccounts={selectPrimaryAccount}
            setSelectAccounts={setSelectPrimaryAccount}
            setNewAccountId={setNewPrimaryAccountId}
          />
        </DrawerModal>
      </AnimateWrapper>
    );
  }
};

export default CreateNewAutorizedUser;
