import styles from './style.module.scss';
import cx from 'classnames';
import React from 'react';
import { MenuItem, Menu } from '@mui/material';
import { SvgIcon } from '../../../../../../shared/components/svgIcon';

interface Option {
  label: string;
  value: string;
}

interface Props {
  selectedCategory: string;
  categories: string[];

  onChangeCategory(newCategory: string): void;

  selectedPeriod: string;
  periods: Option[];

  onChangePeriod(newPeriod: string): void;

  isDisabled?: boolean;
}

export const DiagramTopPanel = ({
   selectedCategory, categories, periods, selectedPeriod, onChangePeriod, onChangeCategory, isDisabled
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isCategoryDropOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryOptionSelect = (value: string) => {
    onChangeCategory(value);
    handleClose();
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.controlPanel}>
        <div
          aria-haspopup="true"
          id="select-category=button"
          tabIndex={0}
          onClick={handleClick}
          className={cx(styles.categoryButton, isCategoryDropOpen && styles.categoryButtonActive)}
        >
          {selectedCategory}
          <SvgIcon name="ArrowDropDownFilter" sizePx={20} />
        </div>
        <Menu
          id="select-category=button"
          anchorEl={anchorEl}
          open={isCategoryDropOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'select-category=button',
          }}
        >
          {categories.map(cat => (
            <MenuItem key={cat} onClick={() => handleCategoryOptionSelect(cat)}>{cat}</MenuItem>
          ))}
        </Menu>
        <div className={styles.periodFilterWrapper}>
          {periods.map(({ label, value }) => (
            <div
              tabIndex={0}
              key={value}
              onClick={() => onChangePeriod(value)}
              className={
                cx(
                  styles.periodFilterItem,
                  value === selectedPeriod && styles.periodFilterItemActive,
                  isDisabled && styles.periodFilterItemDisabled,
                )
              }
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
