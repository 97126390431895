import { z } from 'zod'

export const NeteviaPayeeAddSchema = z
  .object({
    payeeName: z.string().min(1),
    accountNumber: z.string().max(20),
  })
  .refine((data) => /^\d+$/.test(data.accountNumber), {
    message: 'Enter numbers only',
    path: ['accountNumber'],
  })
export type NeteviaPayeeAdd = z.infer<typeof NeteviaPayeeAddSchema>
