import { useState, FC } from "react";
import { Popup } from "components/popup";
import { useSendBusinessInfoMutation } from "api/endpoints/lendingFront";
import {
  TextField,
  Button,
  Stack,
  css,
  styled,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./style.module.scss";
import { useLoans } from "shared/hooks/useLoans";

interface PopupBusinessInfoProps {
  onClose: (isShow: boolean) => void;
}

const PopupBusinessInfo: FC<PopupBusinessInfoProps> = ({ onClose }) => {
  const [sendBusinessInfoMutation, { isLoading: sendBusinessInfoIsLoading }] =
    useSendBusinessInfoMutation();

  const [businessStartDate, setBusinessStartDate] = useState<Date | null>(null);
  const [annualBusinessRevenue, setAnnualBusinessRevenue] =
    useState<string>("");

  const sendBusinessInfo = async () => {
    try {
      if (businessStartDate) {
        await sendBusinessInfoMutation({
          businessStartDate: new Date(businessStartDate).toISOString(),
          annualBusinessRevenue,
        }).unwrap();
        onClose(false);
      }
    } catch (e: any) {
      console.log("error", e);
    }
  };
  return (
    <Popup onClose={() => onClose(false)} stylesWrapper={styles.popup}>
      <Stack direction="column" spacing={2}>
        <Title>
          Provide additional business information to apply for a loan
        </Title>
        <TextField
          fullWidth
          placeholder="Annual business revenue"
          variant="outlined"
          value={annualBusinessRevenue}
          onChange={(e) =>
            setAnnualBusinessRevenue(e.target.value.replace(/\D/g, ""))
          }
          inputProps={{
            autoComplete: "off",
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              value={businessStartDate ? dayjs(businessStartDate) : null}
              onChange={(newValue: dayjs.Dayjs | null) =>
                newValue && setBusinessStartDate(newValue.toDate())
              }
              slotProps={{
                textField: {
                  placeholder: "Business start date",
                },
              }}
              sx={{
                width: "100%",
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
        <Stack spacing={2}>
          <LoadingButton
            variant="contained"
            onClick={sendBusinessInfo}
            loading={sendBusinessInfoIsLoading}
            disabled={!businessStartDate || !annualBusinessRevenue}
          >
            confirm
          </LoadingButton>
          <CancelButton variant="text" onClick={() => onClose(false)}>
            Cancel
          </CancelButton>
        </Stack>
      </Stack>
    </Popup>
  );
};
export default PopupBusinessInfo;
const Title = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  `}
`;
const CancelButton = styled(Button)`
  ${({ theme }) => css`
    color: #d32f2f;
    width: fit-content;
    margin-left: auto !important;
  `}
`;
