import { useState } from 'react'
import {
  BankIcon,
  ProfileInCircleIcon,
  RefreshIcon,
  SwapIcon,
} from 'shared/ui/icons'
import { ListButton } from 'shared/ui/lists'
import { ChevronRightIcon } from 'shared/ui/icons'
import styles from './style.module.scss'
import { Button } from 'shared/ui/buttons'
import { ToMyOwnAccount } from 'features/transfers/transfer-to-my-own-account'
import { useAppDispatch } from 'shared/models'
import { clearDataToMyOwnAccount } from 'features/transfers/transfer-to-my-own-account/model'
import { ToNeteviaCustomer } from 'features/transfers/transfer-to-netevia-customer'
import { clearDataToNeteviaCustomer } from 'features/transfers/transfer-to-netevia-customer/model'
import { AchTransfer } from 'features/transfers/transfer-ach'
import { clearDataAch } from 'features/transfers/transfer-ach/model'
import { TransitionAnimation } from 'shared/ui/animations'
import { getNextKey } from 'entities/transfers'
import { FromConnectedAccount } from 'features/transfers/transfer-from-connected-account'
import { clearDataFromConnectedAccount } from 'features/transfers/transfer-from-connected-account/model'

export const Transfers = () => {
  const dispatch = useAppDispatch()

  const [showToMyOwnAccount, setShowToMyOwnAccount] = useState<boolean>(false)
  const [keyToMyOwnAccount, setKeyToMyOwnAccount] = useState('toMyOwnAccount_1')

  const [showToNeteviaCustomer, setShowToNeteviaCustomer] =
    useState<boolean>(false)
  const [keyToNeteviaCustomer, setKeyToNeteviaCustomer] = useState(
    'toNeteviaCustomer_1'
  )

  const [showAchTransfer, setShowAchTransfer] = useState<boolean>(false)
  const [keyAchTransfer, setKeyAchTransfer] = useState('achTransfer_1')

  const [showFromConnectedAccount, setShowFromConnectedAccount] =
    useState<boolean>(false)
  const [keyFromConnectedAccount, setKeyFromConnectedAccount] = useState(
    'fromConnectedAccount_1'
  )
  const handleCloseToMyOwnAccount = () => {
    dispatch(clearDataToMyOwnAccount())
    setKeyToMyOwnAccount((prevKey) => getNextKey(prevKey))
    setShowToMyOwnAccount(false)
  }

  const handleCloseToNeteviaCustomer = () => {
    dispatch(clearDataToNeteviaCustomer())
    setKeyToNeteviaCustomer((prevKey) => getNextKey(prevKey))
    setShowToNeteviaCustomer(false)
  }
  const handleCloseAchTransfer = () => {
    dispatch(clearDataAch())
    setKeyAchTransfer((prevKey) => getNextKey(prevKey))
    setShowAchTransfer(false)
  }
  const handleFromConnectedAccount = () => {
    dispatch(clearDataFromConnectedAccount())
    setKeyFromConnectedAccount((prevKey) => getNextKey(prevKey))
    setShowFromConnectedAccount(false)
  }

  return (
    <TransitionAnimation>
      <ToMyOwnAccount
        key={keyToMyOwnAccount}
        isShow={showToMyOwnAccount}
        onClose={handleCloseToMyOwnAccount}
      />
      <ToNeteviaCustomer
        key={keyToNeteviaCustomer}
        isShow={showToNeteviaCustomer}
        onClose={handleCloseToNeteviaCustomer}
      />
      <AchTransfer
        key={keyAchTransfer}
        isShow={showAchTransfer}
        onClose={handleCloseAchTransfer}
      />

      <FromConnectedAccount
        key={keyFromConnectedAccount}
        isShow={showFromConnectedAccount}
        onClose={handleFromConnectedAccount}
      />
      <div className={styles.container}>
        <div>
          <ListButton
            label={'Within Netevia'}
            key={'Within Netevia'}
          >
            <Button
              startIcon={<RefreshIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => setShowToMyOwnAccount(true)}
            >
              Between own accounts
            </Button>
            <Button
              startIcon={<ProfileInCircleIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => setShowToNeteviaCustomer(true)}
            >
              To Netevia’s customer
            </Button>
          </ListButton>
        </div>
        <div>
          <ListButton
            label={'Outside Netevia'}
            key={'Outside Netevia'}
          >
            <Button
              startIcon={<BankIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => setShowFromConnectedAccount(true)}
            >
              From connected account
            </Button>
            <Button
              startIcon={<SwapIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => setShowAchTransfer(true)}
            >
              ACH transfer
            </Button>
          </ListButton>
        </div>
      </div>
    </TransitionAnimation>
  )
}
