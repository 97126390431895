import { Skeleton } from "@mui/material";
import { useLimitsAndRewardsConditions } from "api/hooksApi/useLimitsAndRewardsConditions";
import { InfoItem } from "pages/settings/LimitsConditions/InfoItem";

interface TransactionLimitsProps {
  isLoading: boolean;
}

export const TransactionLimits = ({ isLoading }: TransactionLimitsProps) => {
  const { conditions, conditionsIsLoading } = useLimitsAndRewardsConditions();
  if (isLoading || conditionsIsLoading) return <Skeleton height={200} />;
  return (
    <div>
      {conditions?.transactionLimitsItems.map((item) => (
        <InfoItem key={item.name} {...item} />
      ))}
    </div>
  );
};
