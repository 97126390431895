import Agricultural from 'assets/svg/agricultural-services.svg'
import Contracted from 'assets/svg/contracted-services.svg'
import Transportation from 'assets/svg/transportation-services.svg'
import Utility from 'assets/svg/utility-services.svg'
import NeteviaCustomer from 'assets/svg/NeteviaCustomer.svg'
import RetailOutlet from 'assets/svg/retailOutlet-services.svg'
import ClothingStores from 'assets/svg/clothing-stores.svg'
import MiscellaneousStores from 'assets/svg/miscellaneousStores.svg'
import Business from 'assets/svg/business-services.svg'
import Professional from 'assets/svg/professional-services.svg'
import Government from 'assets/svg/goverment-services.svg'
import ACHicon from 'assets/svg/ACHtransfer.svg'
import Airlines from 'assets/svg/airlines.svg'
import CarRental from 'assets/svg/carRental.svg'
import Hotels from 'assets/svg/hotels.svg'
import CashBack from 'assets/svg/cash-back.svg'
import DebitSpending from 'assets/svg/debit-spending-xceptions.svg'
import SpendingCredit from 'assets/svg/spending-credit.svg'
import WelcomeBonus from 'assets/svg/welcome-bonus.svg'
import WelcomeBonusSpending from 'assets/svg/welcome-bonus-spending.svg'
import EarnedCredit from 'assets/svg/apr.svg'
import EarnedSpendingCredit from 'assets/svg/earned-spending-credit.svg'
import ManualAdjustment from 'assets/svg/manual-adjustment.svg'
import RewardTransfer from 'assets/svg/rewards-arrow.svg'
import RefundIcon from 'assets/svg/refund.svg'
export interface IState {
  value: number
  label: string
}

interface IHighNotePersonTitle {
  value: number
  label: string
}

interface IDocumentType {
  value: string
  label: string
}

interface IDocumentVerificationResult {
  key: string
  description: string
  verified: boolean
  title: string
}

interface IDepartment {
  id: string
  title: string
}

export interface ReportCategories{
  category: string
  color: string
  img: string
}
export interface REWARD_CATEGORY_TYPE {
  id: number
  title: string
  defaultTitle: string
  img: string
  color: string
}

export const STATES: IState[] = [
  { value: 1, label: 'Alaska' },
  { value: 2, label: 'Alabama' },
  { value: 3, label: 'Arkansas' },
  { value: 4, label: 'Arizona' },
  { value: 5, label: 'California' },
  { value: 6, label: 'Colorado' },
  { value: 7, label: 'Connecticut' },
  { value: 8, label: 'District of Columbia' },
  { value: 9, label: 'Delaware' },
  { value: 10, label: 'Florida' },
  { value: 11, label: 'Georgia' },
  { value: 12, label: 'Hawaii' },
  { value: 13, label: 'Iowa' },
  { value: 14, label: 'Idaho' },
  { value: 15, label: 'Illinois' },
  { value: 16, label: 'Indiana' },
  { value: 17, label: 'Kansas' },
  { value: 18, label: 'Kentucky' },
  { value: 19, label: 'Louisiana' },
  { value: 20, label: 'Massachusetts' },
  { value: 21, label: 'Maryland' },
  { value: 22, label: 'Maine' },
  { value: 23, label: 'Michigan' },
  { value: 24, label: 'Minnesota' },
  { value: 25, label: 'Missouri' },
  { value: 26, label: 'Mississippi' },
  { value: 27, label: 'Montana' },
  { value: 28, label: 'North Carolina' },
  { value: 29, label: 'North Dakota' },
  { value: 30, label: 'Nebraska' },
  { value: 31, label: 'New Hampshire' },
  { value: 32, label: 'New Jersey' },
  { value: 33, label: 'New Mexico' },
  { value: 34, label: 'Nevada' },
  { value: 35, label: 'New York' },
  { value: 36, label: 'Ohio' },
  { value: 37, label: 'Oklahoma' },
  { value: 38, label: 'Oregon' },
  { value: 39, label: 'Pennsylvania' },
  { value: 40, label: 'Puerto Rico' },
  { value: 41, label: 'Rhode Island' },
  { value: 42, label: 'South Carolina' },
  { value: 43, label: 'South Dakota' },
  { value: 44, label: 'Tennessee' },
  { value: 45, label: 'Texas' },
  { value: 46, label: 'Utah' },
  { value: 47, label: 'Virginia' },
  { value: 48, label: 'Virgin Islands, U.S.' },
  { value: 49, label: 'Vermont' },
  { value: 50, label: 'Washington' },
  { value: 51, label: 'Wisconsin' },
  { value: 52, label: 'West Virginia' },
  { value: 53, label: 'Wyoming' },
]

// export const states = {
//     AE: '91',
//     AL: '2',
//     AK: '1',
//     AS: '60',
//     AP: '92',
//     AZ: '4',
//     AR: '3',
//     CA: '5',
//     CO: '6',
//     CT: '7',
//     DE: '9',
//     FL: '10',
//     GA: '11',
//     GU: '66',
//     HI: '12',
//     ID: '14',
//     IL: '15',
//     IN: '16',
//     IA: '13',
//     KS: '17',
//     KY: '18',
//     LA: '19',
//     ME: '22',
//     MH: '68',
//     MD: '21',
//     MA: '20',
//     MI: '23',
//     FM: '64',
//     MN: '24',
//     MS: '26',
//     MO: '25',
//     MT: '27',
//     NE: '30',
//     NV: '34',
//     NH: '31',
//     NJ: '32',
//     NM: '33',
//     NY: '35',
//     NC: '28',
//     ND: '29',
//     MP: '69',
//     OH: '36',
//     OK: '37',
//     OR: '38',
//     PW: '70',
//     PA: '39',
//     PR: '40',
//     RI: '41',
//     SC: '42',
//     SD: '43',
//     TN: '44',
//     TX: '45',
//     VI: '48',
//     UT: '46',
//     VT: '49',
//     VA: '47',
//     WA: '50',
//     DC: '8',
//     WV: '52',
//     WI: '51',
//     WY: '53',
// }
export const states = [
  { label: 'AE', value: 91 },
  { label: 'AL', value: 2 },
  { label: 'AK', value: 1 },
  { label: 'AS', value: 60 },
  { label: 'AP', value: 92 },
  { label: 'AZ', value: 4 },
  { label: 'AR', value: 3 },
  { label: 'CA', value: 5 },
  { label: 'CO', value: 6 },
  { label: ' CT', value: 7 },
  { label: 'DE', value: 9 },
  { label: 'FL', value: 10 },
  { label: 'GA', value: 11 },
  { label: 'GU', value: 66 },
  { label: 'HI', value: 12 },
  { label: 'ID', value: 14 },
  { label: 'IL', value: 15 },
  { label: 'IN', value: 16 },
  { label: 'IA', value: 13 },
  { label: 'KS', value: 17 },
  { label: 'KY', value: 18 },
  { label: 'LA', value: 19 },
  { label: 'ME', value: 22 },
  { label: 'MH', value: 68 },
  { label: 'MD', value: 21 },
  { label: 'MA', value: 20 },
  { label: 'MI', value: 23 },
  { label: 'FM', value: 64 },
  { label: 'MN', value: 24 },
  { label: 'MS', value: 26 },
  { label: 'MO', value: 25 },
  { label: 'MT', value: 27 },
  { label: 'NE', value: 30 },
  { label: 'NV', value: 34 },
  { label: 'NH', value: 31 },
  { label: 'NJ', value: 32 },
  { label: 'NM', value: 33 },
  { label: 'NY', value: 35 },
  { label: 'NC', value: 28 },
  { label: 'ND', value: 29 },
  { label: 'MP', value: 69 },
  { label: 'OH', value: 36 },
  { label: 'OK', value: 37 },
  { label: 'OR', value: 38 },
  { label: 'PW', value: 70 },
  { label: 'PA', value: 39 },
  { label: 'PR', value: 40 },
  { label: 'RI', value: 41 },
  { label: 'SC', value: 42 },
  { label: 'SD', value: 43 },
  { label: 'TN', value: 44 },
  { label: 'TX', value: 45 },
  { label: 'VI', value: 48 },
  { label: 'UT', value: 46 },
  { label: 'VT', value: 49 },
  { label: 'VA', value: 47 },
  { label: 'WA', value: 50 },
  { label: 'DC', value: 8 },
  { label: 'WV', value: 52 },
  { label: 'WI', value: 51 },
  { label: 'WY', value: 53 },
]
const HighNotePersonTitle: IHighNotePersonTitle[] = [
  { value: 0, label: 'President' },
  { value: 1, label: 'Vice President' },
  { value: 2, label: 'Treasurer' },
  { value: 3, label: 'Owner' },
  { value: 4, label: 'Chief Executive Officer' },
  { value: 5, label: 'Chief Financial Officer' },
  { value: 6, label: 'Chief Operating Officer' },
]

const DocumentType: IDocumentType[] = [
  { value: 'SOCIAL_SECURITY_CARD', label: 'SOCIAL_SECURITY_CARD' },
  { value: 'BIRTH_CERTIFICATE', label: 'BIRTH_CERTIFICATE' },
  {
    value: 'US_MILITARY_RECORD_OF_SERVICE',
    label: 'US_MILITARY_RECORD_OF_SERVICE',
  },
  { value: 'MARRIAGE_CERTIFICATE', label: 'MARRIAGE_CERTIFICATE' },
  { value: 'DIVORCE_DECREE', label: 'DIVORCE_DECREE' },
  { value: 'STATE_ISSUED_PERMIT', label: 'STATE_ISSUED_PERMIT' },
  { value: 'STATE_ISSUED_BENEFIT_CARD', label: 'STATE_ISSUED_BENEFIT_CARD' },
  { value: 'US_TAX_FORM', label: 'US_TAX_FORM' },
  { value: 'W2', label: 'W2' },
  { value: 'FORM_1099', label: 'FORM_1099' },
  { value: 'FORM_5498', label: 'FORM_5498' },
  { value: 'FORM_1098', label: 'FORM_1098' },
  {
    value: 'CERTIFICATE_OF_CITIZENSHIP',
    label: 'CERTIFICATE_OF_CITIZENSHIP',
  },
  { value: 'MEDICARE_CARD', label: 'MEDICARE_CARD' },
  {
    value: 'COURT_ORDER_FOR_LEGAL_NAME_CHANGE',
    label: 'COURT_ORDER_FOR_LEGAL_NAME_CHANGE',
  },
  { value: 'MUNICIPAL_ID', label: 'MUNICIPAL_ID' },
  { value: 'DRIVERS_LICENSE', label: 'DRIVERS_LICENSE' },
  { value: 'PASSPORT', label: 'PASSPORT' },
  { value: 'STATE_ISSUED_ID', label: 'STATE_ISSUED_ID' },
  {
    value: 'STATE_US_MILITARY_ARMED_FORCES_ID',
    label: 'STATE_US_MILITARY_ARMED_FORCES_ID',
  },
  {
    value: 'STATE_US_PERMANENT_RESIDENT_CARD',
    label: 'STATE_US_PERMANENT_RESIDENT_CARD',
  },
  {
    value: 'STATE_CONSULAR_IDENTIFICATION_CARD',
    label: 'STATE_CONSULAR_IDENTIFICATION_CARD',
  },
  {
    value: 'STATE_US_BORDER_CROSSING_CARD',
    label: 'STATE_US_BORDER_CROSSING_CARD',
  },
  {
    value: 'STATE_US_EMPLOYMENT_AUTHORIZATION_CARD',
    label: 'STATE_US_EMPLOYMENT_AUTHORIZATION_CARD',
  },
  { value: 'STATE_TRIBAL_ID', label: 'STATE_TRIBAL_ID' },
  { value: 'UTILITY_BILL', label: 'UTILITY_BILL' },
  { value: 'PHONE_BILL', label: 'PHONE_BILL' },
  { value: 'CABLE_BILL', label: 'CABLE_BILL' },
  { value: 'INTERNET_BILL', label: 'INTERNET_BILL' },
  { value: 'BANK_STATEMENT', label: 'BANK_STATEMENT' },
  { value: 'STATEMENT_401K', label: 'STATEMENT_401K' },
  { value: 'BROKERAGE_STATEMENT', label: 'BROKERAGE_STATEMENT' },
  { value: 'LIFE_INSURANCE', label: 'LIFE_INSURANCE' },
  { value: 'HEALTH_INSURANCE', label: 'HEALTH_INSURANCE' },
  { value: 'AUTO_INSURANCE', label: 'AUTO_INSURANCE' },
  { value: 'PAY_STUB', label: 'PAY_STUB' },
  { value: 'LEASE_AGREEMENT', label: 'LEASE_AGREEMENT' },
  { value: 'ARTICLES_OF_INCORPORATION', label: 'ARTICLES_OF_INCORPORATION' },
  { value: 'NON_PROFIT_501_C3', label: 'NON_PROFIT_501_C3' },
  {
    value: 'FEDERAL_EMPLOYER_IDENTIFICATION_NUMBER',
    label: 'FEDERAL_EMPLOYER_IDENTIFICATION_NUMBER',
  },
  {
    value: 'SOLE_PROPRIETOR_SOCIAL_SECURITY_CARD',
    label: 'SOLE_PROPRIETOR_SOCIAL_SECURITY_CARD',
  },
  { value: 'OPERATING_AGREEMENT', label: 'OPERATING_AGREEMENT' },
  { value: 'PARTNERSHIP_AGREEMENT', label: 'PARTNERSHIP_AGREEMENT' },
  {
    value: 'CERTIFICATE_OF_GOOD_STANDING',
    label: 'CERTIFICATE_OF_GOOD_STANDING',
  },
  { value: 'IRS_ISSUED_TAX_RETURN', label: 'IRS_ISSUED_TAX_RETURN' },
  { value: 'TRUST_AGREEMENT', label: 'TRUST_AGREEMENT' },
  { value: 'BUSINESS_LICENSE', label: 'BUSINESS_LICENSE' },
  { value: 'BUSINESS_REGISTRATION', label: 'BUSINESS_REGISTRATION' },
  { value: 'FORM_CP_575', label: 'FORM_CP_575' },
  { value: 'TAX_ID_APPLICATION', label: 'TAX_ID_APPLICATION' },
  { value: 'PRISON_ID', label: 'PRISON_ID' },
  { value: 'PRISON_RELEASE_PAPERWORK', label: 'PRISON_RELEASE_PAPERWORK' },
  {
    value: 'CERTIFICATE_OF_RELEASE_OR_DISCHARGE_FROM_ACTIVE_DUTY',
    label: 'CERTIFICATE_OF_RELEASE_OR_DISCHARGE_FROM_ACTIVE_DUTY',
  },
  {
    value: 'VETERAN_AFFAIRS_BENEFITS_LETTER',
    label: 'VETERAN_AFFAIRS_BENEFITS_LETTER',
  },
  {
    value: 'IRS_ISSUED_FEDERAL_TAX_RETURN',
    label: 'IRS_ISSUED_FEDERAL_TAX_RETURN',
  },
  {
    value: 'IRS_ISSUED_STATE_TAX_RETURN',
    label: 'IRS_ISSUED_STATE_TAX_RETURN',
  },
]

const DocumentVerificationResult: IDocumentVerificationResult[] = [
  {
    key: `ADDRESS_MATCH`,
    description: `Business address was able to be verified`,
    verified: true,
    title: `Address`,
  },
  {
    key: 'ADDRESS_MISMATCH',
    description: `Business address was unable to be verified as provided`,
    verified: false,
    title: 'Address',
  },
  {
    key: `BUSINESS_VERIFICATION_SCORE_PASSED`,
    description: `The business' overall identity has been verified`,
    verified: true,
    title: `Business Verification`,
  },
  {
    key: `BUSINESS_VERIFICATION_SCORE_FAILED`,
    description: `The business' overall identity has not been verified`,
    verified: false,
    title: `Business Verification`,
  },
  {
    key: `BUSINESS_NAME_MATCH`,
    description: `The business’ name was able to be verified`,
    verified: true,
    title: `Name`,
  },
  {
    key: `BUSINESS_NAME_MISMATCH`,
    description: `The business' name provided was unable to be verified`,
    verified: false,
    title: `Name`,
  },
  {
    key: `FEIN_MATCH`,
    description: `Business EIN/FEIN was able to be verified`,
    verified: true,
    title: `FEIN`,
  },
  {
    key: `FEIN_MISMATCH`,
    description: `Business EIN/FEIN does not match a known EIN/FEIN for the business`,
    verified: false,
    title: `FEIN`,
  },
  {
    key: `REPRESENTATIVE_MATCH`,
    description: `The associated person(s) or authorized primary applicant has been associated with the business entity`,
    verified: true,
    title: `Representative Match`,
  },
  {
    key: `REPRESENTATIVE_MISMATCH`,
    description: `The associated person(s) or authorized primary applicant has not been associated with the business entity`,
    verified: false,
    title: `Representative Match`,
  },
  {
    key: `OFAC_MATCH`,
    description: `The business information has been associated with a business on the OFAC list`,
    verified: true,
    title: `OFAC`,
  },
  {
    key: `ADDRESS_MATCH`,
    description: `The individual’s address was able to be verified`,
    verified: true,
    title: `Address`,
  },
  {
    key: `ADDRESS_MISMATCH`,
    description: `Address was unable to be verified as provided`,
    verified: false,
    title: `Address`,
  },
  {
    key: `DOB_MATCH`,
    description: `Date of birth does not match the known date of birth of the individual`,
    verified: true,
    title: `Date of Birth`,
  },
  {
    key: `DOB_MISMATCH`,
    description: `Date of birth does not match the known date of birth of the individual`,
    verified: false,
    title: `Date of Birth`,
  },
  {
    key: `NAME_MATCH`,
    description: `The individual’s name was able to be verified`,
    verified: true,
    title: `Name`,
  },
  {
    key: `NAME_MISMATCH`,
    description: `The name provided was unable to be verified`,
    verified: false,
    title: `Name`,
  },
  {
    key: `SSN_MATCH`,
    description: `Social security number was able to be verified`,
    verified: true,
    title: `Identification Number`,
  },
  {
    key: `SSN_MISMATCH`,
    description: `Social security number does not match a known social security number for the individual`,
    verified: false,
    title: `Identification Number`,
  },
  {
    key: `PHONE_MATCH`,
    description: `Phone number was able to be verified`,
    verified: true,
    title: `Phone`,
  },
  {
    key: `PHONE_MISMATCH`,
    description: `Phone number does not match any known phone numbers for the individual`,
    verified: false,
    title: `Phone`,
  },
  {
    key: `WATCHLIST_HIT`,
    description: `The individual is currently on an active watchlist`,
    verified: false,
    title: ``,
  },
  {
    key: `DEVICE_WARNING`,
    description: `The individual’s device has been previously flagged for fraudulent activity`,
    verified: false,
    title: ``,
  },
  {
    key: `FOREIGN_DEVICE`,
    description: `The individual’s device has not been used in the past`,
    verified: false,
    title: ``,
  },
  {
    key: `HIGH_ABUSE_SCORE`,
    description: `High probability of synthetic fraud`,
    verified: false,
    title: ``,
  },
  {
    key: `HIGH_THEFT_SCORE`,
    description: `High probability of third party fraud`,
    verified: false,
    title: ``,
  },
  {
    key: `MEDIUM_ABUSE_SCORE`,
    description: `Moderate probability of synthetic fraud`,
    verified: false,
    title: ``,
  },
  {
    key: `MEDIUM_THEFT_SCORE`,
    description: `Moderate probability of third party fraud`,
    verified: false,
    title: ``,
  },
  {
    key: `LOW_ABUSE_SCORE`,
    description: `Low probability of synthetic fraud`,
    verified: true,
    title: ``,
  },
  {
    key: `LOW_THEFT_SCORE`,
    description: `Low probability of third party fraud`,
    verified: true,
    title: ``,
  },
  {
    key: `KYC_FRAUD_PASS`,
    description: `KYC FRAUD PASS`,
    verified: false,
    title: ``,
  },
  {
    key: `KYC_WARNING`,
    description: `KYC WARNING`,
    verified: false,
    title: ``,
  },
]

const Department: IDepartment[] = [
  { id: '1', title: 'Marketing' },
  { id: '2', title: 'Finance' },
  { id: '3', title: 'Operations management' },
  { id: '4', title: 'Human Resource' },
  { id: '5', title: 'Technology' },
  { id: '6', title: 'Risk Management' },
  { id: '7', title: 'Sales' },
  { id: '8', title: 'Support' },
  { id: '9', title: 'Top management' },
]
const TRANSFER_STATUSES = {
  processing: ['PROCESSING', 'INITIATED'],
  approved: [
    'APPROVED',
    'COMPLETED',
    'PROCESSED',
    'APPROVED_FOR_PARTIAL_AMOUNT',
    'APPROVED_FOR_PURCHASE_AMOUNT_ONLY',
  ],
  declined: [
    'DECLINED',
    'DO_NOT_HONOR',
    'INVALID_CARD_NUMBER',
    'INSUFFICIENT_FUNDS',
    'UNACTIVATED_CARD',
    'FAILED',
    'INCORRECT_PIN',
    'BAD_CVV',
    'BAD_CVV2',
    'BAD_CVC3',
    'INVALID_MERCHANT',
    'INVALID_TRANSACTION',
    'EXCEEDS_APPROVAL_AMOUNT_LIMIT',
    'EXCEEDS_WITHDRAWAL_FREQUENCY_LIMIT',
    'RESTRICTED_CARD',
    'PIN_DECRYPTION_ERROR',
    'NO_CARD_RECORD',
    'SUSPENDED_CARD',
    'TERMINATED_CARD',
    'INVALID_AUTHORIZATION_EXPIRATION',
    'BLOCKED_CARD',
    'RE_ENTER_TRANSACTION',
    'TRANSACTION_NOT_PERMITTED',
    'SPECIAL_CONDITION_NO_PICK_UP',
    'EXCEEDS_APPROVAL_AMOUNT_LIMIT',
    'EXCEEDS_WITHDRAWAL_FREQUENCY_LIMIT',
    'RESTRICTED_LOCATION',
  ],
}
const TRANSFER_STATUSES_DESCRIPTION = {
  APPROVED: 'The transaction was approved.',
  DO_NOT_HONOR:
    'The transaction was declined due to enforcement of internal rules such as spend controls. The amount on this transaction will not be applied to the balance of the card and any clearing event will not move money.',
  INVALID_CARD_NUMBER:
    'The card number provided for this transaction was invalid.',
  INSUFFICIENT_FUNDS:
    'The card had insufficient funds at the time of transaction.',
  EXPIRED_CARD: 'The card was expired at the time of transaction.',
  NO_CARD_RECORD: 'The card provided was not on file with bank.',
  INCORRECT_PIN: 'The pin provided for this transaction was incorrect',
  APPROVED_FOR_PARTIAL_AMOUNT:
    'The transaction was approved for only part of the requested amount.',
  APPROVED_FOR_PURCHASE_AMOUNT_ONLY:
    'The transaction was approved for only the purchase portion of requested amount.',
  BAD_CVV:
    'The transaction was declined because the provided CVV code was not valid.',
  BAD_CVV2:
    'The transaction was declined because the provided CVV2 code was not valid.',
  BAD_CVC3:
    'The transaction was declined because the provided CVC3 code was not valid.',
  UNACTIVATED_CARD:
    'The transaction was declined because the card provided has not been activated.',
  SUSPENDED_CARD:
    'The transaction was declined because the card provided has been suspended.',
  TERMINATED_CARD:
    'The transaction was declined because the card provided has been terminated.',
  INVALID_AUTHORIZATION_EXPIRATION:
    'The transaction was declined because the provided authorization expiration is invalid.',
  BLOCKED_CARD:
    'The transaction was declined because the PaymentCard is blocked from transacting.',
  RE_ENTER_TRANSACTION:
    'The transaction was declined because the transaction temporarily cannot be processed due to Address Verification failure or enforcement of internal rules such as spend controls.',
  TRANSACTION_NOT_PERMITTED:
    'The transaction was declined due to a permanent restriction placed at the program or account level. This may be due to enforcement of internal rules such as spend controls.',
  CASH_BACK_LIMIT_EXCEEDED: 'Cash request exceeded issuer or approved limit.',
  CLOSED_ACCOUNT: 'The account was closed at the time of transaction.',
  SPECIAL_CONDITION_NO_PICK_UP:
    'The transaction was declined due to special condition - new card not activated or card is temporarily blocked.',
  EXCEEDS_APPROVAL_AMOUNT_LIMIT:
    'Purchase amount exceeded issuer or approved limit over a set period of time.',
  EXCEEDS_WITHDRAWAL_FREQUENCY_LIMIT:
    'The transaction was declined because number of transaction exceeded the issuer or approved limit over a set period of time.',
  INVALID_MERCHANT:
    'The transaction was declined because the merchant is not authorized for this card.',
  INVALID_TRANSACTION:
    'The transaction was declined because of incorrect information or faulty operation, including trying to refund a refund. A generic catch-all response code for a transaction that cannot be declined under other specific response codes.',
  PIN_DECRYPTION_ERROR:
    'The transaction was declined because the PIN decryption failed due to technical error.',
  RESTRICTED_LOCATION: 'The card is restricted in the region or country.',
  INITIATED: 'A transfer request has been initiated.',
  PROCESSING:
    'The transfer is processing  at the bank, but funds are not available yet.',
  COMPLETED:
    'The transfer has successfully been completed by the bank and funds are available.',
  PROCESSED:
    'The transfer has successfully been processed by the bank and funds are available.',
  CANCELLED: 'You requested to cancel the transfer.',
  FAILED:
    'The transfer failed. Additional details are available in ACHTransferStatusCode.',
  RETURNED: 'The transfer has been returned by the bank.',
}

const reportCategories: ReportCategories[] = [
  {
    category: 'Agricultural Services',
    color: '#89E1D1',
    img: Agricultural,
  },
  {
    category: 'Transfer to Netevia customer',
    color: '#00BFA5',
    img: NeteviaCustomer,
  },
  {
    category: 'Contracted Services',
    color: '#DEAF69',
    img: Contracted,
  },
  {
    category: 'Transportation Services',
    color: '#6983DE',
    img: Transportation,
  },
  {
    category: 'Utility Services',
    color: '#9FDDF1',
    img: Utility,
  },
  {
    category: 'Retail Outlet Services',
    color: '#DF8F76',
    img: RetailOutlet,
  },
  {
    category: 'Clothing Stores',
    color: '#71A0D7',
    img: ClothingStores,
  },
  {
    category: 'Miscellaneous Stores',
    color: '#E2D380',
    img: MiscellaneousStores,
  },
  {
    category: 'Business Services',
    color: '#9A9999',
    img: Business,
  },
  {
    category: 'Professional Services',
    color: '#A389DA',
    img: Professional,
  },
  {
    category: 'Government Services',
    color: '#92CCEC',
    img: Government,
  },
  {
    category: 'ACH transfers',
    color: '#8DC9A5',
    img: ACHicon,
  },
  {
    category: 'Airlines, Air Carriers',
    color: '#C98D8D',
    img: Airlines,
  },
  {
    category: 'Car Rental Agencies',
    color: '#AF7CB4',
    img: CarRental,
  },
  {
    category: 'Hotels, Motels, and Resorts',
    color: '#E4954B',
    img: Hotels,
  },
]

const REWARD_CATEGORY: REWARD_CATEGORY_TYPE[] = [
  {
    id: 1,
    title: 'Rewards.Cash Back',
    defaultTitle: 'Spending rewards',
    img: CashBack,
    color: '#FF7043',
  },
  {
    id: 2,
    title: 'Rewards.Debit Spending Exceptions',
    defaultTitle: 'Bonus Categories Spending',
    img: DebitSpending,
    color: '#9575CD',
  },
  {
    id: 3,
    title: 'Rewards.Spending Credit',
    defaultTitle: 'Spending Credit',
    img: SpendingCredit,
    color: '#FFC107',
  },
  {
    id: 4,
    title: 'Rewards.Refund',
    defaultTitle: 'Refund',
    img: RefundIcon,
    color: '#78909C',
  },
  {
    id: 5,
    title: 'Rewards.Welcome Bonus',
    defaultTitle: 'Welcome Bonus',
    img: WelcomeBonus,
    color: '#29B6F6)',
  },
  {
    id: 6,
    title: 'Rewards.Welcome Bonus Spending',
    defaultTitle: 'Welcome Bonus Spending',
    img: WelcomeBonusSpending,
    color: '#00897B',
  },
  {
    id: 7,
    title: 'Rewards.Earned Credit',
    defaultTitle: 'Earned Credit',
    img: EarnedCredit,
    color: '#E57373',
  },
  {
    id: 8,
    title: 'Rewards.Earned Spending Credit',
    defaultTitle: 'Earned Spending Credit',
    img: EarnedSpendingCredit,
    color: '#D81B60',
  },
  {
    id: 9,
    title: 'Rewards.Manual Adjustment',
    defaultTitle: 'Manual Adjustment',
    img: ManualAdjustment,
    color: '#66BB6A',
  },
  {
    id: 10,
    title: 'Rewards.Cash Back',
    defaultTitle: 'Spending rewards',
    img: CashBack,
    color: '#FF7043)',
  },
  {
    id: 11,
    title: 'Converted to financial account',
    defaultTitle: 'Converted to financial account',
    img: RewardTransfer,
    color: '#78909C',
  },
  {
    id: 12,
    title: 'Rewards.Points transferred to',
    defaultTitle: 'Points transferred to',
    img: RewardTransfer,
    color: '#78909C',
  },
  {
    id: 13,
    title: 'Points received from',
    defaultTitle: 'Points received from',
    img: RewardTransfer,
    color: '#78909C',
  },
  {
    id: 14,
    title: 'Gift Card Purchase',
    defaultTitle: 'Gift Card Purchase',
    img: RewardTransfer,
    color: '#78909C',
  },
]

export default {
  HighNotePersonTitle,
  DocumentType,
  Department,
  DocumentVerificationResult,
  TRANSFER_STATUSES,
  TRANSFER_STATUSES_DESCRIPTION,
  reportCategories,
  REWARD_CATEGORY,
}
