import { useState, useEffect, useMemo } from 'react';
import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { Main } from './components/main';
import { Filter } from './components/filter';
import {
  useFinancialAccountTransactionsServerControllerQuery,
  Transaction,
  EnumTransactionStatuses,
} from 'api/endpoints/transaction';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { TransferDetail } from './components/transferDetail';

interface Props {
  isShow: boolean;
  onClose: () => void;
}

export interface ApiFilterOptions {
  financialAccountId: string;
  dateFrom: Dayjs;
  dateTo: Dayjs;
  fromAmount: string;
  toAmount: string;
}

export const useInitialApiFilterOptions = (financialAccountId: string) => {
  const initialApiFilterOptions: ApiFilterOptions = useMemo(
    () => ({
      financialAccountId,
      dateFrom: dayjs().startOf('month').startOf('day'),
      dateTo: dayjs().endOf('day'),
      fromAmount: '',
      toAmount: '',
    }),
    [financialAccountId]
  );

  return initialApiFilterOptions;
};

const useQueryParams = (apiFilterOptions: ApiFilterOptions) => {
  return useMemo(
    () => ({
      financialAccountId: apiFilterOptions.financialAccountId,
      dateFrom: moment(apiFilterOptions.dateFrom.toDate())
        .startOf('day')
        .utc()
        .format(),
      dateTo: moment(apiFilterOptions.dateTo.toDate())
        .endOf('day')
        .utc()
        .format(),
      filter: {
        transfers: {
          displayName: 'Transfers',
          value: true,
          items: null,
        },
      },
    }),
    [
      apiFilterOptions.financialAccountId,
      apiFilterOptions.dateFrom,
      apiFilterOptions.dateTo,
    ]
  );
};

export const TransferHistory = (props: Props) => {
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { isShow, onClose } = props;
  const initialApiFilterOptions = useInitialApiFilterOptions(
    unclosedFinancialAccounts[0]?.financialAccountId
  );

  const [apiFilterOptions, setApiFilterOptions] = useState<ApiFilterOptions>(
    initialApiFilterOptions
  );

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [updateTransfers, setUpdateTarsfer] = useState<Transaction[]>([]);
  const [selectTransfer, setSelectTransfer] = useState<Transaction | null>(
    null
  );

  const queryParams = useQueryParams(apiFilterOptions);

  useEffect(() => {
    if (initialApiFilterOptions.financialAccountId) {
      setApiFilterOptions(initialApiFilterOptions);
    }
  }, [initialApiFilterOptions.financialAccountId]);

  const { data: transfers, isFetching: isFetchingTransfers } =
    useFinancialAccountTransactionsServerControllerQuery(queryParams, {
      skip: !queryParams.financialAccountId,
    });

  useEffect(() => {
    if (transfers) {
      filtereByAmount(transfers);
    }
  }, [apiFilterOptions.fromAmount, apiFilterOptions.toAmount, transfers]);

  const filtereByAmount = (transfers) => {
    let completedTransers = transfers.data.filter(
      (transfer) => transfer.status === EnumTransactionStatuses.COMPLETED
    );

    if (apiFilterOptions.fromAmount) {
      completedTransers = completedTransers.filter(
        (transfer) =>
          transfer.amount.value >= parseFloat(apiFilterOptions.fromAmount)
      );
    }

    if (
      apiFilterOptions.toAmount &&
      parseFloat(apiFilterOptions.toAmount) !== 0
    ) {
      completedTransers = completedTransers.filter(
        (transfer) =>
          transfer.amount.value <= parseFloat(apiFilterOptions.toAmount)
      );
    }

    setUpdateTarsfer(completedTransers);
  };

  const closeDrawer = () => {
    setApiFilterOptions(initialApiFilterOptions);
    setSearchQuery('');
    setSelectTransfer(null);
    onClose && onClose();
  };

  const getFiltersLength = (): number => {
    let filtersCount = 0;

    switch (true) {
      case !!apiFilterOptions.dateFrom && !!apiFilterOptions.dateTo:
        filtersCount++;
        break;
      case !!apiFilterOptions.fromAmount || !!apiFilterOptions.toAmount:
        filtersCount++;
        break;
      case !!searchQuery:
        filtersCount++;
        break;
    }

    return filtersCount;
  };

  const steps: Array<[string, TStepOfDrawerStepper]> = [
    [
      '0',
      {
        id: '0',
        isCanGoBack: false,
        title: 'Transfer history',
        prevId: null,
        Element: (
          <Main
            loadingPage={financialAccountsIsLoading}
            loadingList={isFetchingTransfers}
            transfers={updateTransfers || []}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filtersLength={getFiltersLength()}
            setSelectTransfer={setSelectTransfer}
          />
        ),
      },
    ],
    [
      '1',
      {
        id: '1',
        isCanGoBack: true,
        title: 'Filters',
        prevId: '0',
        resetStepsOnReach: true,
        Element: (
          <Filter
            loadingFinancialAccounts={financialAccountsIsLoading}
            financialAccounts={unclosedFinancialAccounts}
            apiFilterOptions={apiFilterOptions}
            setApiFilterOptions={setApiFilterOptions}
            setSearchQuery={setSearchQuery}
            initialApiFilterOptions={initialApiFilterOptions}
          />
        ),
      },
    ],
    [
      '2',
      {
        id: '2',
        isCanGoBack: true,
        title: 'Transfer detail',
        prevId: '0',
        resetStepsOnReach: true,
        Element: (
          <TransferDetail
            selectAccount={apiFilterOptions.financialAccountId}
            selectTransfer={selectTransfer}
          />
        ),
      },
    ],
  ];

  const MapSteps = new Map(steps);
  return (
    <DrawerStepper
      {...props}
      startStep='0'
      steps={MapSteps}
      isShow={isShow}
      onClose={closeDrawer}
    />
  );
};
