import { useMemo } from "react";
import {
  useFinancialAccountsControllerQuery,
  FinancialAccount,
} from "../endpoints/financialAccounts";
import { useSubUsersAvailableFinancialAccountsControllerQuery } from "api/endpoints/subProfiles";

export interface SubUsersAvailableFinancialAccountsReturn {
  subUsersAvailableFinancialAccounts: ExtendedFinancialAccount[];
  subUsersAvailableFinancialAccountsIsLoading: boolean;
  subUsersAvailableFinancialAccountsRefetch: () => void;
}

interface ExtendedFinancialAccount extends FinancialAccount {
  selected: boolean;
  attached: boolean;
}

export const useSubUsersAvailableFinancialAccounts = (
  subUserId?
): SubUsersAvailableFinancialAccountsReturn => {
  const {
    data: subUsersAttachedAccounts,
    isLoading: subUsersAttachedAccountsIsLoading,
    refetch: subUsersAvailableFinancialAccountsRefetch,
  } = useSubUsersAvailableFinancialAccountsControllerQuery(
    { subUserId },
    { skip: !subUserId }
  );

  const { data: financialAccountsData, refetch: finAccountsRefetch } =
    useFinancialAccountsControllerQuery({});

  const financialAccounts = financialAccountsData?.financialAccounts;

  let financialAccountsActive = financialAccounts?.filter((fa) =>
    ["ACTIVE", "UNDER_REVIEW"].includes(fa.status)
  );

  const value = useMemo<any>(() => {
    if (subUsersAttachedAccounts && subUserId && financialAccounts) {
      const availableAccounts = financialAccountsActive?.map((fa) => {
        const isAttached = subUsersAttachedAccounts.some(
          (attachedAccount) => attachedAccount.id === fa.financialAccountId
        );

        return {
          ...fa,
          selected: isAttached,
          attached: isAttached,
        };
      });

      return availableAccounts || [];
    } else if (financialAccountsActive) {
      return financialAccountsActive;
    }
  }, [subUsersAttachedAccounts, financialAccounts, subUserId]);

  const subUserAttachedFinAccRefetch = () => {
    subUsersAvailableFinancialAccountsRefetch();
    finAccountsRefetch();
  };

  return {
    subUsersAvailableFinancialAccounts: value,
    subUsersAvailableFinancialAccountsRefetch: subUserAttachedFinAccRefetch,
    subUsersAvailableFinancialAccountsIsLoading:
      subUsersAttachedAccountsIsLoading || subUsersAttachedAccountsIsLoading,
  };
};
