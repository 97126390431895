export const categories = [
  'Cashflow',
  'Balance history'
];

export const formatYLabel = (valDollars: number) => {
  let resultValue = Math.round(valDollars);
  let resultEnd = '';

  if (Math.abs(valDollars) >= 1_000) {
    resultValue = Math.round(valDollars / 1_000);
    resultEnd = 'K';
  }

  if (Math.abs(valDollars) >= 1_000_000) {
    resultValue = Math.round(valDollars / 1_000_000);
    resultEnd = 'M';
  }

  return `$${resultValue}${resultEnd}`;
};

export function divideSegment(start: number, end: number): number[] {
  const segmentLength = end - start;
  const step = segmentLength / 4;

  const result: number[] = [start];
  for (let i = 1; i <= 4; i++) {
    result.push(Math.round(start + step * i));
  }

  return result;
}



