import { useState } from "react";
import { TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styled, { css } from "styled-components";
import {
  useCreateFinancialAccountControllerMutation,
  useFinancialAccountsControllerQuery,
} from "api/endpoints/financialAccounts";
import { useNavigate, useSearchParams } from "react-router-dom";
import helper from "services/helper";
import { useSnackBar } from "providers/snackBar.provider";

const dataErrorMaxCountAcc =
  "You have reached the maximum amount of financial accounts allowed";

const AddNewAccount = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setSnackBar } = useSnackBar();
  const [createAccountMutation] = useCreateFinancialAccountControllerMutation();
  const { refetch } = useFinancialAccountsControllerQuery({});
  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createAccount = async () => {
    try {
      setIsLoading(true);
      const res = await createAccountMutation({ Name: name }).unwrap();

      await helper.sleep(8000);
      await refetch();
      setIsLoading(false);
      setSearchParams({});
      setSnackBar({
        type: "success",
        message: "New account successfully created.",
        isShow: true,
      });

      navigate(`/account/${res}`);
    } catch (e: any) {
      setIsLoading(false);
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };
  return (
    <Container>
      <Description>
        Let`s get started on creating your new bank account. Please provide a
        nickname for your account.
      </Description>
      <TextField
        label={"Account name"}
        autoComplete="off"
        value={name}
        onChange={(e: any) => {
          setName(e.target.value);
        }}
        inputProps={{ maxLength: 20 }}
      />
      <SumbitButton
        variant="contained"
        disabled={!name}
        loading={isLoading}
        onClick={createAccount}
      >
        Create account
      </SumbitButton>
    </Container>
  );
};

export default AddNewAccount;

const Container = styled("div")`
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
`;
const Description = styled(Typography)`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;
const SumbitButton = styled(LoadingButton)`
  ${({ theme }) => css`
    background: ${theme.colors.primaryBlue};
    width: fit-content;
  `}
`;
