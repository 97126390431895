import { useState, useEffect } from 'react';
import OpenPhysicalCard from 'shared/components/newPhysicalCard/deliveryForm';
import styles from './style.module.scss';
import moment from 'moment';
import helper from 'services/helper';
import { useOpenPaymentCardForSubUserControllerMutation } from 'api/endpoints/subProfiles';
import { useCurrentUser } from 'providers/user.provider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSubUsersAvailablePaymentCards } from 'api/hooksApi/useSubUsersAvailablePaymentCards';
import { useSubUsersControllerQuery } from 'api/endpoints/subProfiles';
import { useSubUser } from 'api/hooksApi/useSubUser';
import {
  useSubProfilesMeQuery,
  OpenPaymentCardForSubUserArg,
} from 'api/endpoints/subProfiles';
import { usePaymentCards } from 'api/hooksApi/usePaymentCards';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useSnackBar } from 'providers/snackBar.provider';
import { useHolderV2ControllerQuery } from 'api/endpoints/account';
import { appRoutes } from '../../../routes';
import { Button } from 'shared/ui/buttons';
import { useBoolean } from 'shared/hooks/useBoolean';
import { PrimaryAccount } from './components/primaryAccount';
import { Typography } from 'shared/ui/typography';
import { Switch } from 'shared/ui/inputs';
import { Adress } from '../newPhysicalCard';
import { CountryCodeAlpha3 } from 'enums';
import { Skeleton } from 'components/skeleton';
import { TransitionAnimation } from 'shared/ui/animations';
import { useDrawerBehavior } from '../../../providers/drawer-behavior';
interface PropTypes {
  setPageStatus?: (pageStatus: string) => void;
  setOpenNewCardId?: (id: string) => void;
  backRoute?: string;
  onStart?(): void;
  onFinish?(): void;
}

interface Person {
  firstName: string;
  lastName: string;
}

const NewPrimaryCard = ({
  setPageStatus = () => {},
  setOpenNewCardId = () => {},
  backRoute = '',
  onStart,
  onFinish,
}: PropTypes) => {
  const { t } = useTranslationProvider();
  const [searchParams] = useSearchParams();
  const subUserId = searchParams.get('id') || '';
  const { setSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const drawerBehavior = useDrawerBehavior();

  const { isSubUser, user, userIsLoading } = useCurrentUser();
  const { paymentCardsRefetch } = usePaymentCards();
  const { data: holder } = useHolderV2ControllerQuery();
  const { refetch: refetchSubUsers } = useSubUsersControllerQuery();

  //if you subUser
  const { data: subUserInformation, isLoading: isLoadingSubUserInformation } =
    useSubProfilesMeQuery(undefined, { skip: !isSubUser });

  //if you owner
  const { subUser, subUserIsLoading } = useSubUser(subUserId);

  const [openPrimaryCardForSubUserMutation] =
    useOpenPaymentCardForSubUserControllerMutation();

  const { subUsersAvailablePaymentCardsRefetch } =
    useSubUsersAvailablePaymentCards();

  const [address, setAddress] = useState<Adress>({
    streetAddress: '',
    postalCode: '',
    region: '',
    locality: '',
  });
  const [person, setPerson] = useState<Person>({
    firstName: '',
    lastName: '',
  });
  const [deliveryChoise, setDeliveryChoise] = useState<string>('');

  const isLoading =
    isLoadingSubUserInformation || subUserIsLoading || userIsLoading;
  const loadingOpenCardBool = useBoolean();
  const openPhysicalCardBool = useBoolean();

  const physicalCardAddressBool =
    address?.streetAddress &&
    address?.postalCode &&
    address?.region &&
    address?.locality;

  const physicalCardEnotherNameBool = person.firstName && person.lastName;

  const holderLegalBusinessName =
    holder?.businessAccountHolder?.legalBusinessName || '';

  const warningText = t(
    'Card.Only one primary card is available per authorized user',
    {
      defaultValue:
        'Only one primary card is available per authorized user, and the primary card will use your billing address and phone number for verification purposes.',
    }
  );

  const primaryAccountName = isSubUser
    ? subUserInformation?.financialAccountName
    : subUser?.financialName;

  useEffect(() => {
    const firstName = isSubUser
      ? subUserInformation?.givenName
      : subUser?.givenName;

    const lastName = isSubUser
      ? subUserInformation?.familyName
      : subUser?.familyName;

    if (firstName && lastName) {
      setPerson({
        firstName,
        lastName,
      });
    }
  }, [isSubUser, isLoading]);

  const handlerChoiseDelevery = (e) => {
    setDeliveryChoise(e);
  };
  const navigateTo = (newCardId: string) => {
    if (backRoute === 'EditSubUserCard') {
      setPageStatus('manage');
      setOpenNewCardId(newCardId);
    } else if (backRoute === 'Home') {
      navigate(appRoutes.home());
    } else {
      navigate(appRoutes.cardTransactions(newCardId));
    }
  };

  const createPrimaryCardForSubUser = async () => {
    try {
      onStart && onStart();
      loadingOpenCardBool.setTrue();

      const subProfileId = isSubUser ? user?.id : subUserId;

      const orderPhysicalPaymentCard = {
        deliveryDetails: {
          name: {
            givenName: person.firstName,
            familyName: person.lastName,
          },
          companyName: holderLegalBusinessName,
          address: {
            streetAddress: address?.streetAddress,
            extendedAddress: '',
            postalCode: address.postalCode,
            locality: address.locality,
            region: address.region,
            countryCodeAlpha3: CountryCodeAlpha3.USA,
          },
        },
        courier: {
          method: deliveryChoise,
        },
      };
      const params: OpenPaymentCardForSubUserArg = {
        subProfileId: Number(subProfileId),
        expirationDate: moment().add(24, 'months').format('YYYY-MM-DD'),
        activateOnCreate: true,
        paymentCardName: '',
      };
      if (openPhysicalCardBool.value && physicalCardAddressBool) {
        params.orderPhysicalPaymentCard = orderPhysicalPaymentCard;
      }

      const newPrimaryCard =
        await openPrimaryCardForSubUserMutation(params).unwrap();

      await helper.sleep(10000);

      refetchSubUsers();
      if (!isSubUser) {
        subUsersAvailablePaymentCardsRefetch();
      }
      paymentCardsRefetch();
      loadingOpenCardBool.setFalse();
      setSnackBar({
        type: 'success',
        message: 'Congratulations! The cards has been created.',
        isShow: true,
      });
      navigateTo(newPrimaryCard.id);
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    } finally {
      loadingOpenCardBool.setFalse();
      onFinish && onFinish();
    }
  };

  const disabledPhysicalCard =
    openPhysicalCardBool.value &&
    (!deliveryChoise ||
      !physicalCardAddressBool ||
      !physicalCardEnotherNameBool);

  return isLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.boxTitle}>
          <Typography>
            {t('Card.Primary Card Disclosure', {
              defaultValue: 'Primary Card Disclosure',
            })}
          </Typography>
          <Typography>{warningText}</Typography>
        </div>
        {primaryAccountName && <PrimaryAccount name={primaryAccountName} />}

        <div className={styles.boxOrder}>
          <Typography>
            {t('Card.Order physical card', {
              defaultValue: 'Order physical card',
            })}
          </Typography>
          <Switch
            disabled={drawerBehavior.isCloseDisabled}
            checked={openPhysicalCardBool.value}
            onChange={() => openPhysicalCardBool.toggle()}
          />
        </div>

        {openPhysicalCardBool.value && (
          <OpenPhysicalCard
            setPerson={setPerson}
            setAddress={setAddress}
            handlerChoiseDelevery={handlerChoiseDelevery}
            deliveryChoise={deliveryChoise}
            primaryCard={true}
            isDisabled={drawerBehavior.isCloseDisabled}
          />
        )}

        <div className={styles.boxBtn}>
          <Button
            variant='contained'
            loading={loadingOpenCardBool.value}
            disabled={disabledPhysicalCard || drawerBehavior.isCloseDisabled}
            onClick={() => createPrimaryCardForSubUser()}
          >
            {t('common.Confirm', {
              defaultValue: 'Confirm',
            })}
          </Button>
        </div>
      </div>
    </TransitionAnimation>
  );
};
export default NewPrimaryCard;
