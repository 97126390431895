import { useMemo } from "react";
import {
  useMerchantCategoriesControllerQuery,
  useCountriesControllerQuery,
} from "../endpoints/enums";
import { usePaymentCardLimitsControllerQuery } from "api/endpoints/spendRules";

export const useCardLimits = (cardId) => {
  const {
    data: cardLimit,
    isFetching: cardLimitIsLoading,
    isSuccess: cardLimitIsSuccess,
    refetch,
  } = usePaymentCardLimitsControllerQuery({
    paymentCardId: cardId,
  });

  const {
    data: merchantCategory,
    isFetching: merchantCategoryIsLoading,
    isSuccess: merchantCategoryIsSuccess,
  } = useMerchantCategoriesControllerQuery();
  const {
    data: countriesAlpha3,
    isFetching: countriesAlpha3IsLoading,
    isSuccess: countriesAlpha3IsSuccess,
  } = useCountriesControllerQuery();

  const value: any = useMemo<any>(() => {
    if (!cardLimit || !merchantCategory || !countriesAlpha3) {
      return {};
    }

    if (cardLimit && merchantCategory && countriesAlpha3) {
      const attachedSpendRules = cardLimit?.node?.attachedSpendRules?.edges;
      const attachedVelocityRules =
        cardLimit?.node?.attachedVelocityRules?.edges;

      const findBlockedOrAllowed = (i, type, item, property) =>
        i?.node?.[`merchant${type}${property}`]?.some((i) => i === item.name);

      const mapSpendRules = (type, item) => {
        let rule: null | string = null;
        let visible = true;

        attachedSpendRules &&
          attachedSpendRules.forEach((i) => {
            if (i?.node?.__typename === `Merchant${type}SpendRule`) {
              const blocked = findBlockedOrAllowed(i, type, item, "Blocked");
              const allowed = findBlockedOrAllowed(i, type, item, "Allowed");
              if (blocked) {
                rule = "blocked";
              }
              if (allowed) {
                rule = "allowed";
              }
            }
          });

        return {
          ...item,
          rule,
          visible,
        };
      };

      const categoriesLimit = merchantCategory?.map((item) =>
        mapSpendRules("Category", item)
      );
      const countriesLimit = countriesAlpha3?.map((item) =>
        mapSpendRules("Country", item)
      );

      const amountLimitSpendRule =
        attachedSpendRules &&
        attachedSpendRules.find((i) => i.node.name === "Amount Limit");
      const amountLimit = amountLimitSpendRule?.node?.maximumAmount?.value
        ? `${amountLimitSpendRule.node.maximumAmount.value / 100}`
        : "";

      const amountLimitSpendRuleMonthly =
        attachedVelocityRules?.length &&
        attachedVelocityRules?.find(
          (i) => i.node?.cumulativeRule?.name === "Amount Limit [MONTHLY]"
        );
      const amountLimitMonthlyData = amountLimitSpendRuleMonthly?.node
        ?.cumulativeRule?.maximumAmount?.value
        ? `${
            amountLimitSpendRuleMonthly.node.cumulativeRule.maximumAmount
              .value / 100
          }`
        : "";

      return {
        categoriesLimit,
        countriesLimit,
        amountLimit,
        amountLimitMonthlyData,
        attachedSpendRules,
        amountLimitSpendRule,
        amountLimitSpendRuleMonthly,
      };
    }
  }, [cardLimit, cardId, merchantCategory, countriesAlpha3]);

  return {
    cardLimits: value,
    cardLimitsIsLoading: cardLimitIsLoading,
    cardLimitsIsSuccess:
      cardLimitIsSuccess &&
      merchantCategoryIsSuccess &&
      countriesAlpha3IsSuccess,
    cardLimitsRefetch: () => refetch(),
  };
};
