import { Select } from 'shared/ui/inputs';
import helper from 'services/helper';
import styles from './style.module.scss';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { Skeleton } from 'components/skeleton';
import { MenuItem } from '@mui/material';
import { Divider } from '@mui/material';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const SelectAccount = ({ value, onChange }: Props) => {
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='55px'
    />
  ) : (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {unclosedFinancialAccounts?.map((account) => (
        <MenuItem
          key={account.financialAccountId}
          value={account.financialAccountId}
        >
          <div className={styles.dropDown}>
            <div>
              {account.name}
              {account?.accountNumber && `(${account.accountNumber.slice(-4)})`}
            </div>
            {account?.availableCash && (
              <div>$ {helper.moneyFormat(account.availableCash?.value)}</div>
            )}
          </div>
        </MenuItem>
      ))}
      <Divider className={styles.divider} />
      <MenuItem
        key={'All accounts'}
        value={'All accounts'}
      >
        All accounts
      </MenuItem>
    </Select>
  );
};
