import React, { FC } from "react";
import PrimaryCarAccList from "../primaryCardAccList";
import NotificitionImg from "assets/svg/notification-black.svg";
import Add from "assets/svg/add.svg";
import styles from "./style.module.scss";
import TooltipChildren from "../toolTip/toolTipChildren";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Button } from "@mui/material";

interface ConnectedPrimaryCardAccountListProps {
  selectAccount: any;
  setCreateSubUserPageMode: (string) => void;
}

const ConnectedPrimaryCardAccountList: FC<
  ConnectedPrimaryCardAccountListProps
> = ({ selectAccount, setCreateSubUserPageMode }) => {
  const { t } = useTranslationProvider();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.boxTitle}>
          <p className={styles.boxTitle_text}>
            {t("common.Primary card account", {
              defaultValue: "Primary card account",
            })}
            :
          </p>
          <TooltipChildren description="Please note that primary account cannot be changed in the future">
            <img src={NotificitionImg} className={styles.boxTitle_img} />
          </TooltipChildren>
        </div>
        <Button
          onClick={() => setCreateSubUserPageMode("manage-primary-account")}
        >
          <div className={styles.boxManage}>
            <img src={Add} className={styles.boxManage_img} />
            <p className={styles.boxManage_text}>
              {t("common.add", {
                defaultValue: "add",
              })}
            </p>
          </div>
        </Button>
      </div>
      <PrimaryCarAccList financialAccountsArr={selectAccount} white />
    </div>
  );
};

export default ConnectedPrimaryCardAccountList;
