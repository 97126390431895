import styled, { css } from "styled-components";
import { ActionTile } from "../ActionTile";
import ChevronRightGray from "assets/svg/chevron-right-gray.svg";
import InfoOutlined from "assets/svg/InfoOutlinedGray.svg";
import KeyboardOutlined from "assets/svg/KeyboardOutlined.svg";
import { AnimateWrapper } from "components/animate-wrapper";
import { useEffect, useMemo, useState } from 'react';
import { SetUpLimits } from "./SetUpLimits.tsx";
import { useSearchParams } from "react-router-dom";
import { useConfirmDialog } from "providers/confirm.provider";
import {
  useAddAmountLimitMonthlyControllerMutation,
  useAddRuleAmountLimitControllerMutation,
  useAddRuleCountriesControllerMutation,
  useAddRuleMerchantCategoriesControllerMutation,
  useDetachAllRulesControllerMutation,
  useDetachSpendRulesControllerMutation,
  useDetachVelocityControllerMutation,
} from "api/endpoints/spendRules";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useAvailableToSpendCardControllerQuery } from "api/endpoints/transaction";
import { useCardLimits } from "api/hooksApi/useCardLimits";
import { TransactionLimits } from "./TransactionLimits";
import { dollarsToCents } from '../../utils/price';
import { useCardRouteParams } from '../../hooks/useCardRouteParams';
import { Skeleton } from '../../../components/skeleton';

interface CardLimitsProps {
  setMessage: (value: string) => void;
}

export const CardLimits = ({ setMessage }: CardLimitsProps) => {
  const { t } = useTranslationProvider();
  const [categories, setCategories] = useState<any>();
  const [countries, setCountries] = useState<any>();
  const [amountLimit, setAmountLimit] = useState<any>();
  const [amountLimitMonthly, setAmountLimitMonthly] = useState<any>();
  const { cardId } = useCardRouteParams()

  const [detachAllRulesMutation, { isLoading: detachAllRulesIsLoading }] =
    useDetachAllRulesControllerMutation();
  const { cardLimits, cardLimitsIsLoading, cardLimitsRefetch } =
    useCardLimits(cardId);
  const [detachSpendRulesMutation, { isLoading: detachSpendRulesIsLoading }] =
    useDetachSpendRulesControllerMutation();
  const [detachVelocityMutation, { isLoading: detachVelocityIsLoading }] =
    useDetachVelocityControllerMutation();
  const [addRuleCountriesMutation, { isLoading: addRuleCountriesIsLoading }] =
    useAddRuleCountriesControllerMutation();
  const [addRuleCategoriesMutation, { isLoading: addRuleCategoriesIsLoading }] =
    useAddRuleMerchantCategoriesControllerMutation();
  const [
    addRuleAmountLimitMutation,
    { isLoading: addRuleAmountLimitIsLoading },
  ] = useAddRuleAmountLimitControllerMutation();
  const [
    addRuleAmountLimitMonthlyMutation,
    { isLoading: addRuleAmountLimitMonthlyIsLoading },
  ] = useAddAmountLimitMonthlyControllerMutation();
  const { refetch: availableToSpendCardRefetch } =
    useAvailableToSpendCardControllerQuery({
      paymentCardId: cardId,
    });
  const [searchParams, setSearchParams] = useSearchParams();
  const currentMode = searchParams.get("mode") || "";
  const confirm = useConfirmDialog();

  const isLoading =
    detachAllRulesIsLoading ||
    cardLimitsIsLoading ||
    detachSpendRulesIsLoading ||
    detachVelocityIsLoading ||
    addRuleCountriesIsLoading ||
    addRuleCategoriesIsLoading ||
    addRuleAmountLimitIsLoading ||
    addRuleAmountLimitMonthlyIsLoading;

  useEffect(() => {
    if (cardLimits) {
      updateCardLimitsState(cardLimits);
    }
  }, [cardLimits]);

  const updateCardLimitsState = (cardLimits) => {
    setCountries(cardLimits?.countriesLimit);
    setCategories(cardLimits?.categoriesLimit);

    if (cardLimits?.amountLimit) {
      setAmountLimit(dollarsToCents(Number(cardLimits.amountLimit)).toString());
    }

    if (cardLimits?.amountLimitMonthlyData) {
      setAmountLimitMonthly(dollarsToCents(Number(cardLimits.amountLimitMonthlyData)).toString());
    }
  };

  const searchWithRule = (arr, type) => {
    const temptArr = arr?.filter((i) => i.rule === type)?.map((i) => i.name);
    return temptArr;
  };

  const compressionArr = (arr, originArr) => {
    const isEqual = arr.toString() === originArr?.toString();
    return isEqual;
  };

  const detachAllLimits = async () => {
    confirm.show({
      applyButtonText: t("common.Yes", {
        defaultValue: "Yes",
      }),
      dialogText: t("Card.Are you sure you want to remove all limits", {
        defaultValue: "Are you sure you want to remove all limits?",
      }),
      typeNew: true,
      cancelButtonType: "text",
      cancelError: true,
      async onApply() {
        try {
          await detachAllRulesMutation({ PaymentCardId: cardId }).unwrap();
          setMessage("Card limits successfully removed.");
          cardLimitsRefetch();
          setSearchParams({});
        } catch (e: any) {
          console.log("error", e);
        }
      },
    });
  };

  const isSaveDisabled = useMemo(() => {
    if (!amountLimit && !amountLimitMonthly && !cardLimits.amountLimit && !cardLimits.amountLimitMonthlyData) {
      return true;
    }

    return [
      [amountLimit, cardLimits?.amountLimit],
      [amountLimitMonthly, cardLimits?.amountLimitMonthlyData]
    ].every(([ limit, serverLimit ]) => {
      return limit && serverLimit && dollarsToCents(Number(serverLimit)).toString() === limit ||
        !limit && !serverLimit
    })
  }, [cardLimits, amountLimit, amountLimitMonthly]);

  const onSave = async () => {
    try {
      const promisesOperations = [
        addOrDetachCountries(),
        addOrDetachCategories(),
        addOrDetachAmountLimit(),
        addOrDetachAmountLimitMonthly(),
      ];
      await Promise.all(promisesOperations);
      setMessage("Card limits successfully applied and saved.");
      availableToSpendCardRefetch();
      cardLimitsRefetch();
      setSearchParams({});
    } catch (e: any) {
      console.dir(e)
      console.log("error", e.data[""][0]);
    }
  };
  const addOrDetachCategories = async () => {
    const blockedMerchantCategories = searchWithRule(categories, "blocked");
    const allowedMerchantCategories = searchWithRule(categories, "allowed");
    const blockedOriginCategories = searchWithRule(
      cardLimits?.categoriesLimit,
      "blocked"
    );
    const allowedOriginCategories = searchWithRule(
      cardLimits?.categoriesLimit,
      "allowed"
    );
    if (
      compressionArr(blockedMerchantCategories, blockedOriginCategories) &&
      compressionArr(allowedMerchantCategories, allowedOriginCategories)
    ) {
      return;
    } else if (
      !blockedMerchantCategories.length &&
      !allowedMerchantCategories.length
    ) {
      const categoriesSpendRule = cardLimits?.attachedSpendRules?.find(
        (i) => i.node.__typename === "MerchantCategorySpendRule"
      );
      await detachSpendRulesMutation({
        paymentCardId: cardId,
        spendRuleId: categoriesSpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleCategoriesMutation({
        paymentCardId: cardId,
        blocked: blockedMerchantCategories,
        allowed: allowedMerchantCategories,
      }).unwrap();
    }
  };
  const addOrDetachCountries = async () => {
    const blockedMerchantCountries = searchWithRule(countries, "blocked");
    const allowedMerchantCountries = searchWithRule(countries, "allowed");
    const blockedOriginCountries = searchWithRule(
      cardLimits?.countriesLimit,
      "blocked"
    );
    const allowedOriginCountries = searchWithRule(
      cardLimits?.countriesLimit,
      "allowed"
    );

    if (
      compressionArr(blockedMerchantCountries, blockedOriginCountries) &&
      compressionArr(allowedMerchantCountries, allowedOriginCountries)
    ) {
      return;
    }

    if (!blockedMerchantCountries.length && !allowedMerchantCountries.length) {
      const countriesSpendRule = cardLimits?.attachedSpendRules?.find(
        (i) => i.node.__typename === "MerchantCountrySpendRule"
      );
      await detachSpendRulesMutation({
        paymentCardId: cardId,
        spendRuleId: countriesSpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleCountriesMutation({
        paymentCardId: cardId,
        blocked: blockedMerchantCountries,
        allowed: allowedMerchantCountries,
      }).unwrap();
    }
  };
  const addOrDetachAmountLimit = async () => {
    if (amountLimit === cardLimits?.amountLimit || !amountLimit && !cardLimits?.amountLimit) {
      return;
    } else if (!Number(amountLimit) && cardLimits.amountLimit) {
      await detachSpendRulesMutation({
        paymentCardId: cardId,
        spendRuleId: cardLimits?.amountLimitSpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleAmountLimitMutation({
        paymentCardId: cardId,
        value: Number(amountLimit),
      }).unwrap();
    }
  };
  const addOrDetachAmountLimitMonthly = async () => {
    if (amountLimitMonthly === cardLimits?.amountLimitMonthlyData || !amountLimitMonthly && !cardLimits?.amountLimitMonthlyData) {
      return;
    } else if (!Number(amountLimitMonthly) && cardLimits.amountLimitMonthlyData) {
      await detachVelocityMutation({
        paymentCardId: cardId,
        spendRuleId: cardLimits?.amountLimitSpendRuleMonthly?.node?.id,
      }).unwrap();

      const amountLimitMonthlySpendRule = cardLimits?.attachedSpendRules?.find(
        (i) => i.node.name === "Amount Limit [MONTHLY]"
      );
      await detachSpendRulesMutation({
        paymentCardId: cardId,
        spendRuleId: amountLimitMonthlySpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleAmountLimitMonthlyMutation({
        paymentCardId: cardId,
        value: Number(amountLimitMonthly),
      }).unwrap();
    }
  };

  const actionsList = [
    {
      title: "Limits",
      onClick: () => handleChancheMode("limitsInfo"),
      infoImg: InfoOutlined,
      actionImg: ChevronRightGray,
      mode: "limitsInfo",
      component: <TransactionLimits isLoading={isLoading} />,
    },
    {
      title: "Set up limits",
      onClick: () => handleChancheMode("setLimits"),
      infoImg: KeyboardOutlined,
      actionImg: ChevronRightGray,
      mode: "setLimits",
      component: (
        <SetUpLimits
          setCountries={setCountries}
          setCategories={setCategories}
          setAmountLimit={setAmountLimit}
          setAmountLimitMonthly={setAmountLimitMonthly}
          amountLimit={amountLimit}
          amountLimitMonthly={amountLimitMonthly}
          countries={countries}
          categories={categories}
          onSave={onSave}
          detachAllLimits={detachAllLimits}
          isLoading={isLoading}
          isSaveDisabled={isSaveDisabled}
        />
      ),
    },
  ];

  const handleChancheMode = (value) => {
    setSearchParams({ mode: value });
  };
  return (
    <AnimateWrapper className="fade">
      {cardLimitsIsLoading ? (
        <div><Skeleton height={200} width="100%" /></div>
      ) : (
        <>
          {currentMode === "limits" && (
            <ActionsList>
              {actionsList.map((item) => (
                <ActionTile key={item.title} {...item} isFullClickable={true} />
              ))}
            </ActionsList>
          )}
          {actionsList.find((action) => action.mode === currentMode)?.component}
        </>
      )}
    </AnimateWrapper>
  );
};

const ActionsList = styled("div")`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.borderLightGrey};
    max-width: 530px;
    border-radius: 4px;
    & > div {
      border: none;
      border-bottom: 1px solid ${theme.colors.borderLightGrey};
      border-radius: 0;
      max-height: 60px;
      padding: 0;
      &:last-child {
        border-bottom: none;
      }
    }
  `}
`;
