import { z } from 'zod'

export const AchTransferSchema = z.object({
  forms: z.array(
    z
      .object({
        fromFinancialAccount: z.string().min(1),
        toPayee: z.string().min(1),
        amount: z.string(),
        memo: z.string().optional()
      })
      .refine(
        (data) => {
          const amountAsFloat = parseFloat(data.amount)
          return amountAsFloat !== 0 && !isNaN(amountAsFloat)
        },
        {
          message: 'Float value must not be zero',
          path: ['amount'],
        }
      )
  ),
})

export type AchTransfer = z.infer<typeof AchTransferSchema>
