import CustomContainer from 'shared/components/containers/customContainer';
import CompanyInformation from '../companyInformation';
import PrimaryAccount from '../primaryAccount';
import AuthorizedSubUser from '../AuthorizedSubUser';
import { AnimateWrapper } from 'components/animate-wrapper';
import { useSearchParams } from 'react-router-dom';
import DrawerModal from 'shared/components/drawerModal';
import ChangeCompanyInfo from 'pages/settings/changeCompanyInformation';
import PersonalInformation from '../personalInformation';
import ChangePersonalInfo from 'pages/settings/changePersonalInformation';
import { useCurrentUser } from 'providers/user.provider';
import {
  PERSONAL_INFORMATION,
  COMPANY_INFORMATION,
  PRIMARY_ACCOUNT,
  AUTHORIZED_USERS,
  LANGUAGE,
  LIMITS_CONDITIONS,
  CONNECTED_ACCOUNT,
  SUPPORT,
} from './searchParamsTypes';
import { useAccount } from './provider';
import SnackBarWarning from 'shared/components/snackBar/snackBarWarning';
import SnackBarSuccess from 'shared/components/snackBar/snackBarSuccess';
import userIcon from 'assets/svg/user-gray.svg';
import primaryIcon from 'assets/svg/square-success-gray.svg';
import authorizedIcon from 'assets/svg/add-user-gray.svg';
import supportIcon from 'assets/svg/question-mark-gray.svg';
import connectedAccountIcon from 'assets/svg/connected-account.svg';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { Language } from '../Language';
import { LimitsConditions } from '../LimitsConditions';
import { usePermission } from 'shared/hooks/usePermission';
import ConnectedAccount from '../ConnectedAccount';

const AccountSettings = () => {
  const {
    isShowSuccess,
    setIsShowSuccess,
    isShowWarning,
    setIsShowWarning,
    massage,
    setMassage,
    isShowDrawer,
    setIsShowDrawer,
  } = useAccount();

  const { t } = useTranslationProvider();
  const { isSubUser } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const {
    limitsAndConditionsPermission,
    rewardConditionsPermission,
    primaryAccountPermission,
    authorizedUsersPermission,
  } = usePermission();

  const personalInformation = {
    id: PERSONAL_INFORMATION,
    label: t('Settings.Personal Information', {
      defaultValue: 'Personal Information',
    }),
    img: userIcon,
    isShow: isSubUser,
  };
  const companyInformation = {
    id: COMPANY_INFORMATION,
    label: t('Settings.Company information', {
      defaultValue: 'Company information',
    }),
    img: userIcon,
    isShow: !isSubUser,
  };
  const support = {
    id: SUPPORT,
    label: t('Support.Support', {
      defaultValue: 'Support',
    }),
    img: supportIcon,
    isShow: true,
  };

  const primaryAccount = {
    id: 'PrimaryAccount',
    label: 'Primary Account',
    img: primaryIcon,
    isShow: primaryAccountPermission,
  };
  const language = {
    id: 'Language',
    label: 'Language',
    img: primaryIcon,
    isShow: true,
  };
  const limitsConditions = {
    id: LIMITS_CONDITIONS,
    label: 'Limits & Conditions',
    img: primaryIcon,
    isShow: limitsAndConditionsPermission || rewardConditionsPermission,
  };
  const authorizedUsers = {
    id: 'AuthorizedUsers',
    label: 'Authorized Users',
    img: authorizedIcon,
    isShow: authorizedUsersPermission,
  };

  const connectedAccount = {
    id: 'ConnectedAccount',
    label: 'Connected Accounts',
    img: connectedAccountIcon,
    isShow: authorizedUsersPermission,
  };

  const searchParamsArr = [
    personalInformation,
    companyInformation,
    primaryAccount,
    authorizedUsers,
    connectedAccount,
    language,
    limitsConditions,
  ].filter((item) => item.isShow);

  const handleCloseSnackBar = (isShowSuccess) => {
    isShowSuccess ? setIsShowSuccess(false) : setIsShowWarning(false);
    setMassage('');
  };

  return (
    <CustomContainer
      title={'Account Settings'}
      tabsArr={searchParamsArr}
    >
      <AnimateWrapper className='fade'>
        {page === COMPANY_INFORMATION && <CompanyInformation />}
        {page === PERSONAL_INFORMATION && <PersonalInformation />}
        {page === PRIMARY_ACCOUNT && <PrimaryAccount />}
        {page === AUTHORIZED_USERS && <AuthorizedSubUser />}
        {page === CONNECTED_ACCOUNT && <ConnectedAccount />}
        {page === LANGUAGE && <Language />}
        {page === LIMITS_CONDITIONS && <LimitsConditions />}

        <DrawerModal
          isShow={isShowDrawer}
          onClose={() => setIsShowDrawer(false)}
          goBack={() => {}}
        >
          {isSubUser ? <ChangePersonalInfo /> : <ChangeCompanyInfo />}
          <SnackBarSuccess
            message={massage}
            isOpen={isShowSuccess}
            onClose={() => handleCloseSnackBar(true)}
          />
          <SnackBarWarning
            message={massage}
            isOpen={isShowWarning}
            onClose={() => handleCloseSnackBar(false)}
          />
        </DrawerModal>
      </AnimateWrapper>
    </CustomContainer>
  );
};
export default AccountSettings;
