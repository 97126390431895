import { FC } from "react";
import { css, styled, Typography } from "@mui/material";
import ContactInformation from "../contackInformation";

interface CancelOrDiclinedLoansProps {
  cancel?: boolean;
}
const CancelOrDiclinedLoans: FC<CancelOrDiclinedLoansProps> = ({ cancel }) => {
  const diclinedText =
    "We`re sorry to inform you that your loan application has been declined. We understand this may be disappointing, but we`re here to assist you. You can reapply in 90 days for another chance at securing the loan. If you have any questions or would like more information, please don`t hesitate to reach out to our support team.";
  const cancelledText =
    "We've received your request to cancel your loan application. If you have any questions or need assistance, please feel free to reach out to our support team. You can reapply in 90 days. If you have any questions or would like more information, please don't hesitate to reach out to our support team.";
  return (
    <Container>
      <Title>Loan application update</Title>
      <SubTitle>{cancel ? cancelledText : diclinedText}</SubTitle>
      <ContactInformation />
    </Container>
  );
};

export default CancelOrDiclinedLoans;

const Container = styled("div")`
  ${({ theme }) => css`
    width: 470px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;
const Title = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
    ${theme.breakpoints.down("sm")} {
      font-size: 20px;
    }
  `}
`;
const SubTitle = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
  `}
`;
