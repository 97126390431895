import { css, styled, Typography } from "@mui/material";
import emptyIcon from "assets/svg/gift-gray.svg";

const EmptyEarnedCategories = () => {
  return (
    <Wrapper>
      <img src={emptyIcon} alt={"gift"} />
      <Text>
        You currently don`t have any points earned at this time. Start earning
        points by engaging with our services and enjoy exciting rewards!
      </Text>
    </Wrapper>
  );
};

export default EmptyEarnedCategories;

const Wrapper = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px;
    & > img {
      width: 24px;
      height: 24px;
    }
  `}
`;
const Text = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
  `}
`;
