import { z } from 'zod'

export const ExternalPayeeAddSchema = z
  .object({
    payeeName: z.string().min(1),
    payeeType: z.string().min(1),
    accountNumber: z.string().min(9),
    routingNumber: z.string().min(9).max(9),
  })
  .refine((data) => /^\d+$/.test(data.accountNumber), {
    message: 'Enter numbers only',
    path: ['accountNumber'],
  })
  .refine((data) => /^\d+$/.test(data.routingNumber), {
    message: 'Enter numbers only',
    path: ['routingNumber'],
  })
export type ExternalPayeeAdd = z.infer<typeof ExternalPayeeAddSchema>
