import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AnimateWrapper } from "components/animate-wrapper";
import { useCurrentUser } from "providers/user.provider";
import { useFinancialAccountsControllerQuery } from "api/endpoints/financialAccounts";
import styles from "./style.module.scss";
import cx from "classnames";
import { useTranslationProvider } from "providers/translation/translation.provider";
import accountIcon from "assets/svg/person-white.svg";
import securityIcon from "assets/svg/security-white.svg";
import connectedApps from "assets/svg/connectedApps.svg";
import supportIcon from 'assets/svg/support-outlined.svg';
import { usePermission } from "shared/hooks/usePermission";

const SettingsDropdown = () => {
  const { t } = useTranslationProvider();
  const {
    linkedAppsPermission,
  } = usePermission();
  const { pathname } = useLocation();

  const settingsMenuItems = [
    {
      id: "account-settings",
      label: t("SideBar.Account", {
        defaultValue: "Account",
      }),
      link: "/settings",
      img: accountIcon,
      isShow: true,
    },
    {
      id: "security",
      label: t("SideBar.Security", {
        defaultValue: "Security",
      }),
      link: "/settings/security",
      img: securityIcon,
      isShow: true,
    },
    {
      id: "integrations",
      label: t("Settings.Integrations", {
        defaultValue: "Integrations",
      }),
      link: "/settings/integrations",
      img: connectedApps,
      isShow: linkedAppsPermission,
    },
    {
      id: "support",
      label:'Support',
      link: "/settings/support",
      img: supportIcon,
      isShow: true,
    },
  ].filter((item) => item.isShow);

  const lastPart = pathname.substring(pathname.indexOf("/", 1) + 1);

  const activeLinkforSub = "account-settings";
  const [activeItem, setActiveItem] = useState<string>(activeLinkforSub);

  useEffect(() => {
    setActiveItem(lastPart === "/settings" ? activeLinkforSub : lastPart);
  }, [lastPart]);

  const handleItemClick = (e, itemId) => {
    e.stopPropagation();
    setActiveItem(itemId);
  };

  const activeStyleItem = (activeLink, itemId) =>
    cx(styles.item, {
      [styles.active_item]: activeLink === itemId,
    });

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        {settingsMenuItems.map((item) => (
          <Link
            to={item.link}
            key={item.id}
            className={activeStyleItem(activeItem, item.id)}
            onClick={(e) => handleItemClick(e, item.id)}
          >
            <img src={item.img} />
            <span className={styles.item_name}>{item.label}</span>
          </Link>
        ))}
      </div>
    </AnimateWrapper>
  );
};

export default SettingsDropdown;
