import { AnimateWrapper } from 'components/animate-wrapper';
import { useCardInformation } from './provider';
import styles from './style.module.scss';
import Card from 'shared/components/card';
import { ActionTile } from 'shared/components/ActionTile';
import CreditCardOutlined from 'assets/svg/CreditCardOutlined.svg';
import HomeGray from 'assets/svg/home-gray.svg';
import CopyBlue from 'assets/svg/copy-blue.svg';
import CreditCardFilled from 'assets/svg/CreditCardFilled.svg';
import EditFilled from 'assets/svg/EditFilled.svg';
import { BalanceBlock } from './components/BalanceBlock';
import PaperComponent from 'shared/components/PaperComponent';
import { StatusComponent } from 'shared/components/StatusComponent';
import AccordionComponent from 'shared/components/AccordionComponent';
import {  Checkbox, FormControlLabel, Skeleton } from '@mui/material';
import TableBlock from 'shared/components/TableBlock';
import { TransferCells } from 'shared/components/TableBlock/TableCells';
import { FilterButton } from 'pages/accountComponent/components/FilterButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DrawerModal from 'shared/components/drawerModal';
import TransactionDetails from 'shared/components/TransactionDetails';
import helper from 'services/helper';
import { useState } from 'react';
import { RenameCard } from '../../shared/components/RenameCard';
import SnackBarSuccess from 'shared/components/snackBar/snackBarSuccess';
import Filters from 'shared/components/Filters';
import { useBoolean } from '../../shared/hooks/useBoolean';
import { checkTransactionPositiveStatus } from '../../shared/utils/transactions';
import { EnumCardStatuses } from '../../api/endpoints/paymentCards';
import { useCardRouteParams } from '../../shared/hooks/useCardRouteParams';
import { appRoutes, CardSubRoute } from '../../routes';
import { useSnackBar } from '../../providers/snackBar.provider';
import { Button } from 'shared/ui/buttons';


export const CardInformationPage = () => {
  const {
    cardId,
    currentCard,
    paymentCardDetails,
    availableToSpend,
    approvedTransactions,
    pendingTransactions,
    matches,
    paymentCards,
    transactionsIsLoading,
    cardsIsLoading,
    renamePaymentCard,
    renamePaymentCardIsLoading,
    message,
    setMessage,
    handleChangePeriod,
    getAllTransactions,
    filter,
    periodDate,
    paymentCardsIsFetching,
  } = useCardInformation();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTransactionId = searchParams.get('transaction') || '';
  const showDeclinedTransactionsBool = useBoolean();
  const cardRouteParams = useCardRouteParams()

  const [isRenameMode, setIsRenameMode] = useState<boolean>(false);
  const currentMode = searchParams.get('mode') || '';
  const handleChangeTransaction = async (transaction) => {
    if (currentTransactionId) {
      setSearchParams({});
      await helper.sleep(300);
    }
    setSearchParams({ transaction });
  };
  const handleFilterClick = () => {
    setSearchParams({ mode: 'filters' });
  };
  const handleRenameCard = async (nickName: string) => {
    try {
      await renamePaymentCard({
        paymentCardId: cardId,
        nickName,
      }).unwrap();
      setMessage('The card has been successfully renamed');
    } catch (error: any) {
      const errMessage =
        error?.data?.NickName?.join(',') || 'Change nickname error';
      snackbar.setSnackBar({
        type: 'error',
        message: errMessage,
        isShow: true,
      })
    } finally {
      setIsRenameMode(false);
    }
  };
  const handleAddressCopy = async (address: string) => {
    try {
      await navigator.clipboard.writeText(address);
      setMessage('Address copied');
    } catch (err) {
    }
  };

  const handleLinckToManage = () => {
    navigate(appRoutes.cardById(cardRouteParams.accountId, cardRouteParams.cardId, CardSubRoute.manageCard));
  };

  const DeclinedTransactionsFilter = () => (
    <FormControlLabel
      sx={{ minWidth: '250px' }}
      control={
        <Checkbox
          onChange={showDeclinedTransactionsBool.toggle}
          checked={showDeclinedTransactionsBool.value}
        />
      }
      label={(
        <div style={{ userSelect: 'none' }}>Show declined transactions</div>
      )}
    />
  );

  const getRecentTransactions = () => {
    if (!approvedTransactions) {
      return [];
    }

    return showDeclinedTransactionsBool.value
      ? approvedTransactions
      : approvedTransactions.filter(checkTransactionPositiveStatus)
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div className={styles.manageCardButton}>
          <Button variant="text" className={styles.btnManage} onClick={handleLinckToManage} >
            <img src={CreditCardFilled}/>MANAGE CARD
          </Button>
        </div>

        <div className={styles.topBlock}>
          <div className={styles.topBlock_start}>
            <Card
              last4={currentCard?.last4}
              cardholder={currentCard?.cardholder}
              formFactor={currentCard?.formFactor}
              cardType={currentCard?.cardType}
              cardId={currentCard?.id}
              isClosed={currentCard?.status === EnumCardStatuses.CLOSED}
              isLocked={currentCard?.status === EnumCardStatuses.SUSPENDED}
              isActivate={currentCard?.status === EnumCardStatuses.ACTIVATION_REQUIRED}
              isAuthorizedUser={currentCard?.attachedToSubProfile}
              partnerIcons={currentCard?.partnerCardIcons}
              partnerName={currentCard?.partnerName}
              isCardLoading={!currentCard}
            />
          </div>

          <div className={styles.topBlock_middle}>
            {cardsIsLoading ? (
              <Skeleton height={200} width={'100%'}/>
            ) : (
              <>
                {isRenameMode ? (
                  <RenameCard
                    onSubmit={handleRenameCard}
                    isLoading={renamePaymentCardIsLoading}
                    currentName={currentCard?.cardName}
                  />
                ) : (
                  <ActionTile
                    title={'Card Nickname'}
                    subTitle={
                      paymentCardsIsFetching ? (
                        <Skeleton/>
                      ) : (
                        currentCard?.cardName || 'Business Debit'
                      )
                    }
                    onClick={() => setIsRenameMode(true)}
                    infoImg={CreditCardOutlined}
                    actionImg={EditFilled}
                    isFullClickable={true}
                  />
                )}
                <ActionTile
                  title={'Billing Address'}
                  subTitle={paymentCardDetails?.address}
                  onClick={() => handleAddressCopy(paymentCardDetails?.address)}
                  infoImg={HomeGray}
                  actionImg={CopyBlue}
                  isFullClickable={true}
                />
              </>
            )}
          </div>
          <div className={styles.topBlock_end}>
            {cardsIsLoading ? (
              <Skeleton height={200}/>
            ) : (
              <BalanceBlock
                title={'Available balance'}
                value={paymentCardDetails?.availableCash?.creditBalance?.value}
                availableToSpend={availableToSpend}
              />
            )}
          </div>
        </div>
        <div className={styles.filtersButtonContainer}>
          <FilterButton
            onClick={handleFilterClick}
            disabled={false}
            filter={filter}
          />
        </div>

        <div className={styles.tableBlock}>
          {transactionsIsLoading ? (
            <Skeleton height={200}/>
          ) : (
            <>
              {!!pendingTransactions?.length && (
                <PaperComponent>
                  <AccordionComponent
                    title="Pending Transactions"
                    bage={
                      <StatusComponent
                        counter={pendingTransactions.length}
                        status={'PROCESSING'}
                      />
                    }
                  >
                    <TableBlock
                      rows={pendingTransactions || []}
                      tabCells={TransferCells().filter(
                        (item) => item.name !== 'last4'
                      )}
                    />
                  </AccordionComponent>
                </PaperComponent>
              )}
              <PaperComponent>
                <TableBlock
                  rows={getRecentTransactions()}
                  tabCells={TransferCells().filter(
                    (item) => item.name !== 'last4'
                  )}
                  isRowsClickable
                  onClick={handleChangeTransaction}
                  title="Recent Transactions"
                  Action={DeclinedTransactionsFilter}
                />
              </PaperComponent>
            </>
          )}
        </div>
      </div>
      {approvedTransactions && pendingTransactions && (
        <DrawerModal
          isShow={!!currentTransactionId}
          onClose={() => {
          }}
          titleText={'Transaction details'}
        >
          <TransactionDetails
            allTransactions={[...approvedTransactions, ...pendingTransactions]}
            paymentCards={paymentCards}
            currentCartId={cardId}
          />
        </DrawerModal>
      )}
      <DrawerModal
        isShow={currentMode === 'filters'}
        onClose={() => {
        }}
        titleText={'Filters'}
      >
        <Filters
          filter={filter}
          handleDateChange={handleChangePeriod}
          date={periodDate}
          onSubmit={getAllTransactions}
          isRange
        />
      </DrawerModal>
      <SnackBarSuccess
        isOpen={!!message}
        message={message}
        onClose={() => setMessage('')}
      />
    </AnimateWrapper>
  );
};
