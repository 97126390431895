import { AnimateWrapper } from '../../../../../components/animate-wrapper';
import { useSearchParams } from 'react-router-dom';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import LogintOptionsList from './components/loginOptionsList';
import penIcon from 'assets/svg/pen-gray.svg';
import mfaIcon from 'assets/svg/mfa.svg';
import styles from './style.module.scss';
import ArrowLeft from 'assets/svg/arrow-left-gray.svg';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  CHANGE_LOGIN,
  LOGIN_OPTIONS,
  MFA_SETTINGS,
} from '../../securitySearchParams';
import DrawerModal from 'shared/components/drawerModal';
import ChangeLogin from './components/changeLogin';
import LoginHistory from './components/loginHistory';
import { MfaSettings } from '../mfaSettings';

const LoginOptions = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up('sm'));
  const { t } = useTranslationProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || LOGIN_OPTIONS;
  const pageMode = searchParams.get('pageMode');

  const loginArr = [
    {
      label: t('Settings.Change Login', {
        defaultValue: 'Change Login',
      }),
      img: penIcon,
      pageMode: CHANGE_LOGIN,
      isShow: true,
    },
    {
      label: 'MFA Settings',
      pageMode: MFA_SETTINGS,
      img: mfaIcon,
      isShow: true,
    }
  ].filter((item) => item.isShow);

  const openDraweChangeLogin = pageMode === CHANGE_LOGIN;
  const openMfaSettings = pageMode === MFA_SETTINGS;

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        {!matches && (
          <div className={styles.container_nav}>
            <img
              src={ArrowLeft}
              onClick={() => setSearchParams({})}
            />
            <span className={styles.container_nav_title}>
              {t('Settings.Login Options', {
                defaultValue: 'Multi-factor authentication',
              })}
            </span>
          </div>
        )}
        <LogintOptionsList buttonArr={loginArr} />
        <LoginHistory />
      </div>
      <DrawerModal
        isShow={openDraweChangeLogin || openMfaSettings}
        onClose={() => setSearchParams({ page })}
      >
        {pageMode === CHANGE_LOGIN && <ChangeLogin />}
        {pageMode === MFA_SETTINGS && <MfaSettings />}
      </DrawerModal>
    </AnimateWrapper>
  );
};

export default LoginOptions;
