import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import arrowIcon from "assets/svg/arrow-rigth-blue.svg";
import { useRewards } from "pages/reward/provider/useRewards";
import { replaceNumberWithComma } from "shared/utils/price";
import { Skeleton } from "components/skeleton";
import EarnderCategoriesItem from "./components/earnedCategoriesItem";
import { useNavigate } from "react-router-dom";
import { useTranslationProvider } from "providers/translation/translation.provider";
import EmptyEarnedCategories from "./components/emptyEarnedCategories";
import { appRoutes,RewardsRouteSubRoute } from "routes";

const EarnedMonthDashborad = () => {
  const { t } = useTranslationProvider();
  const {
    isDataLoading,
    isFetchingHistory,
    lastPeriodPoints,
    lastPeriodGrouped,
  } = useRewards();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(appRoutes.rewards(RewardsRouteSubRoute.history));
  };

  const renderItems = () => {
    if (!!lastPeriodGrouped?.length) {
      return lastPeriodGrouped.map((el, index) => (
        <EarnderCategoriesItem key={index} points={el.points} type={el.type} />
      ));
    } else {
      return <EmptyEarnedCategories />;
    }
  };

  return isDataLoading || isFetchingHistory ? (
    <Skeleton width="100%" height="194px" />
  ) : (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.box_title}>
          <span>
            {t("rewards.contentTitle3", {
              defaultValue: "Last earned points",
            })}
          </span>
          <span>
            {replaceNumberWithComma(lastPeriodPoints)}{" "}
            {t("Rewards.points", {
              defaultValue: "points",
            })}
          </span>
        </div>
        <Button className={styles.box_button} onClick={handleNavigate}>
          <span>
            {t("seeAll", {
              defaultValue: "See all",
            })}
          </span>
          <img src={arrowIcon} alt="Arrow Rigth" />
        </Button>
      </div>
      {renderItems()}
    </div>
  );
};

export default EarnedMonthDashborad;
