import { FC } from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

interface AccountListFooterProps {
  balance: string;
}

const AccountListFooter: FC<AccountListFooterProps> = ({ balance }) => {
  return (
    <Box>
      <Total>Total:</Total>
      <Amount>${balance}</Amount>
    </Box>
  );
};
export default AccountListFooter;

const Box = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Total = styled(Typography)`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
`;
const Amount = styled(Typography)`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;
