import LogOut from "assets/svg/logout-white.svg";
import SettingsDropdown from "shared/components/sidebar/components/dropDown/SettingsDropdown";
import { Link, useLocation } from "react-router-dom";
import { logOut } from "api/logOut";
import {
  hideAppLoader,
  showAppLoader,
} from "../../../../../components/appLoader";
import HomeIcon from "assets/svg/home-white.svg";
import TransferIcon from "assets/svg/transfer-white.svg";
import RewardIcon from "assets/svg/reward-white.svg";
import SettingsIcon from "assets/svg/settings-white.svg";
import LoansIcon from "assets/svg/loans-white.svg";
import cx from "classnames";
import styles from "./style.module.scss";
import AccordionSideBar from "../accordionSideBar";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { usePermission } from "shared/hooks/usePermission";
import { appRoutes, } from "routes";



export enum SideBarListLink {
  home = 'home',
  transfer = 'transfer',
  rewards = 'rewards',
  settings = 'settings',
  loans = 'loans',
  giftCard = 'giftCard',
}
interface Props {
  isSettingsOpen: boolean;
  setIsSettingsOpen: (value: boolean) => void;
}

const SideBarList = ({ isSettingsOpen, setIsSettingsOpen }: Props) => {
  const { t } = useTranslationProvider();
  const { pathname } = useLocation();
  const { rewardsPermission , giftCardPermission, transferPermission} = usePermission();


  const activeLink = pathname.split("/")[1].trim();
  const activeLinkHome = activeLink === SideBarListLink.home;
  const activeLinkTransfer = activeLink === SideBarListLink.transfer;
  const activeLinkRewards = activeLink === SideBarListLink.rewards;
  const activeLinkSettings = activeLink === SideBarListLink.settings;
  // const activeLinkLoans = activeLink === SideBarListLink.loans;
  const activeLinkGiftCard = activeLink === SideBarListLink.giftCard;

  const handleLogout = async () => {
    showAppLoader();
    logOut();
    hideAppLoader();
  };

  const activeStyleItem = (activeLink) =>
    cx(styles.item, { [styles.active_item]: activeLink });

  return (
    <div className={styles.container}>
      <div className={styles.container_box}>
        <Link to={appRoutes.home()} className={activeStyleItem(activeLinkHome)}>
          <img src={HomeIcon} />
          <span className={styles.item_text}>
            {t("SideBar.Home", {
              defaultValue: "Home",
            })}
          </span>
        </Link>

        {transferPermission && (
          <Link to={appRoutes.transfer()} className={activeStyleItem(activeLinkTransfer)}>
            <img src={TransferIcon} />
            <span className={styles.item_text}>
            {t("SideBar.Transfer", {
              defaultValue: "Transfer",
            })}
          </span>
          </Link>
        )}

        {rewardsPermission && (
          <Link to={appRoutes.rewards()} className={activeStyleItem(activeLinkRewards)}>
            <img src={RewardIcon} />
            <span className={styles.item_text}>
              {t("SideBar.Rewards", {
                defaultValue: "Rewards",
              })}
            </span>
          </Link>
        )}

        {giftCardPermission && (
          <Link to={`/${SideBarListLink.giftCard}`} className={activeStyleItem(activeLinkGiftCard)}>
            <img src={RewardIcon} />
            <span className={styles.item_text}>
            My gift cards
            </span>
          </Link>
        )}

        {/* <Link to="/loans" className={activeStyleItem(activeLinkLoans)}>
          <img src={LoansIcon} />
          <span className={styles.item_text}>Loans</span>
        </Link> */}

        <AccordionSideBar
          buttonLable={t("SideBar.Settings", {
            defaultValue: "Settings",
          })}
          img={SettingsIcon}
          expanded={isSettingsOpen}
          setExpanded={setIsSettingsOpen}
          activeSummary={activeLinkSettings}
        >
          <SettingsDropdown />
        </AccordionSideBar>
      </div>
      <div className={styles.container_logOut} onClick={handleLogout}>
        <Link to="#" className={styles.item}>
          <img src={LogOut} />
          <span className={styles.item_text}>
            {t("Verify.Log Out", {
              defaultValue: "Log Out",
            })}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default SideBarList;
