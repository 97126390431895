import React, { FC } from "react";
import { FormControl, TextField } from "@mui/material";
import styles from "../style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Button } from "shared/ui/buttons";

interface EditEmailaddressProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setErrors: React.Dispatch<React.SetStateAction<string>>;
  emailError: boolean;
  validateFormEmailOrPhone: any;
  handleSubmit: () => Promise<void>;
  isLoading: boolean;
  errors?: any;
}

const EditEmailaddress: FC<EditEmailaddressProps> = ({
  email,
  setEmail,
  setErrors,
  emailError,
  validateFormEmailOrPhone,
  handleSubmit,
  isLoading,
  errors,
}) => {
  const { t } = useTranslationProvider();
  return (
    <div className={styles.wrapper}>
      <FormControl fullWidth>
        <TextField
          id="outlined-required"
          label="Email address"
          onChange={(e: any) => {
            setEmail(e.target.value);
            setErrors("");
          }}
          value={email}
          onBlur={() => validateFormEmailOrPhone("Email")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              validateFormEmailOrPhone("Email");
            }
          }}
          error={emailError}
        />
        {emailError && <p className="text-red-500 ">Error: {errors.email}</p>}
      </FormControl>

      <Button
        onClick={handleSubmit}
        loading={isLoading}
        variant="contained"
        className={styles.button}
        disabled={emailError}
      >
          Save Changes
      </Button>
    </div>
  );
};
export default EditEmailaddress;
