import { useEffect, useState } from "react";
import {
  useLoanSettingsQuery,
  useLoanRequestMutation,
  useIsNotLoanMutation,
  useLoanLinkQuery,
  useMarketingWasOpenedMutation,
} from "api/endpoints/lendingFront";
import moment from "moment";
import { useViewLoansFrame } from "./useViewLoansFrame";
import { useSnackBar } from "providers/snackBar.provider";
import helper from "services/helper";

interface LoanLinkError {
  status: number;
  data: Data;
}

interface Data {
  message: string;
  link: string;
  status: string;
}
const loansLabelForStepper = [
  "Request a loan",
  "Submit application",
  "Get a loan",
];

const IN_REVIEW = "IN_REVIEW";
const NEED_COMPLETE = "Need complete application";

export const useLoans = () => {
  const { setSnackBar } = useSnackBar();
  const { data: loanSettings, isLoading: loanSettingIsLoading } =
    useLoanSettingsQuery({});
  const [isNotLoanMutation] = useIsNotLoanMutation({});
  const [loanRequestMutation, { isLoading: loanRequestIsLoading }] =
    useLoanRequestMutation();
  const [marketingWasOpenedMutation] = useMarketingWasOpenedMutation();
  const {
    data: loanLink,
    isLoading: loanLinkIsLoadign,
    error: loanLinkError,
  } = useLoanLinkQuery();
  const [loanLinkErrorMessage, setLoanLinkErrorMessage] = useState<string>("");

  useEffect(() => {
    if (!loanLinkIsLoadign && loanLinkError) {
      handleLoanLinkError(loanLinkError);
    }
  }, [loanLinkIsLoadign]);

  const handleLoanLinkError = (error) => {
    const errorMessage = (error as LoanLinkError)?.data?.message;
    setLoanLinkErrorMessage(errorMessage);
  };

  const loansRequest = async () => {
    try {
      await loanRequestMutation({}).unwrap();
    } catch (e: any) {
      console.log("error", e);
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const navigateToMarketingLink = async () => {
    try {
      await marketingWasOpenedMutation({ arg: true }).unwrap();
      window.open(loanSettings?.marketingLink);
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const navigateToEmbededLink = () => {
    window.open(loanLink?.loanLink);
  };

  const dontShowMeLoan = async () => {
    try {
      await isNotLoanMutation({ arg: true }).unwrap();
      setSnackBar({
        type: "success",
        message:
          "No worries! If things change, feel free to request a loan anytime in the 'Loan' section.",
        isShow: true,
        width: "400px",
      });
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const {
    requestALoan,
    specialLoan,
    submitloanApplication,
    loanInProgress,
    loanInReview,
  } = useViewLoansFrame({
    loansRequest,
    loanRequestIsLoading,
    navigateToMarketingLink,
    navigateToEmbededLink,
    amount: loanSettings?.amount || undefined,
    terms: loanSettings?.terms || undefined,
  });

  // current date change
  const currentMoment = moment.utc(new Date());
  const loanEndDate = moment.utc(loanSettings?.exportedLoanServie);
  const loanOfferValidDate = currentMoment.isAfter(loanEndDate);

  const hasBusinessInfo =
    Boolean(loanSettings?.revenue) && Boolean(loanSettings?.businessStartDate);

  const isShowGotLoanOffer =
    loanSettings?.marketingLink &&
    loanOfferValidDate &&
    !loanSettings?.isNotLoan &&
    !loanSettings?.marketingLinkOpened;

  //1.1
  const isRequestLoan = loanSettings?.isShowRequestLoan;

  //1.2
  const isDoneRequestLoan =
    !loanSettings?.isShowRequestLoan &&
    Boolean(loanSettings?.exportedLoanServie);

  //2.1
  const isSubmitLoan = !loanSettings?.marketingLinkOpened;

  //2.2
  const isSubmitLoanInRiview =
    loanSettings?.marketingLinkOpened && loanLinkErrorMessage === IN_REVIEW;

  //2.3
  const isSubmitLoanProgress =
    loanSettings?.marketingLinkOpened && loanLinkErrorMessage === NEED_COMPLETE;

  const isFirstStepper =
    (isRequestLoan || isDoneRequestLoan) && !loanSettings?.marketingLink;

  const activeStep = isFirstStepper ? 0 : 1;

  const firstFrame = isRequestLoan ? requestALoan : specialLoan;

  const getSecondFrame = () => {
    switch (true) {
      case isSubmitLoan:
        return submitloanApplication;
      case isSubmitLoanInRiview:
        return loanInReview;
      case isSubmitLoanProgress:
        return loanInProgress;
    }
  };

  const secondFrame = getSecondFrame();
  const loansFrameContent = [firstFrame, secondFrame];

  return {
    loanSettings,
    loanSettingIsLoading,
    loanLink,
    isShowGotLoanOffer,
    hasBusinessInfo,
    //func
    navigateToMarketingLink,
    dontShowMeLoan,
    //steps for stepper
    activeStep,
    loansLabelForStepper,
    loansFrameContent,
  };
};
