import { Control, useController } from 'react-hook-form'
import styles from './styles.module.scss'
import { TextInput } from 'shared/ui/inputs'
import { ExternalPayeeAdd } from 'features/transfers/transfer-ach/model/externalPayeeAddModel'
import { Select } from 'shared/ui/inputs'
import { Typography } from 'shared/ui/typography'
import { MenuItem } from '@mui/material'

interface ExternalPayeeAddForm {
  control: Control<ExternalPayeeAdd>
}

export const ExternalPayeeAddForm = (props: ExternalPayeeAddForm) => {
  const { control } = props

  const payeeName = useController({
    name: 'payeeName',
    control,
  })
  const payeeType = useController({ name: 'payeeType', control })
  const accountNumber = useController({ name: 'accountNumber', control })
  const routingNumber = useController({ name: 'routingNumber', control })

  return (
    <div className={styles.root}>
      <TextInput
        label='Beneficiary name'
        autoComplete='off'
        {...payeeName.field}
      />
      <Select
        label='Beneficiary type'
        {...payeeType.field}
      >
        <MenuItem
          key={'Checking'}
          value={'Checking'}
        >
          <Typography>Checking</Typography>
        </MenuItem>
        <MenuItem
          key={'Savings'}
          value={'Savings'}
        >
          <Typography>Savings</Typography>
        </MenuItem>
      </Select>
      <TextInput
        label='Account number'
        autoComplete='off'
        {...accountNumber.field}
        error={accountNumber.fieldState.error?.message}
      />
      <TextInput
        label='Routing number'
        autoComplete='off'
        {...routingNumber.field}
        error={routingNumber.fieldState.error?.message}
      />
    </div>
  )
}
