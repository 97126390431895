import { FC } from "react";
import HistoryItem from "../historyItem";
import styles from "./style.module.scss";
import { List, Divider } from "@mui/material";
import { TransitionAnimation } from "shared/ui/animations";
import Empty from "../empty";
import { TicketStatusEnum } from "pages/support/types/enums";

interface Ticket {
  createdDate: string;
  status: TicketStatusEnum;
  text: string;
  ticketId: number;
  topic: string;
  topicId: number;
}

interface HistoryListItemProsp {
  historyArr: Ticket[];
}

const HistoryListItem: FC<HistoryListItemProsp> = ({ historyArr }) => {
  const renderItems = () => {
    return historyArr.map((item, index) => (
      <div key={index}>
        <HistoryItem
          topic={item.topic}
          status={item.status}
          text={item.text}
          createdDate={item.createdDate}
          ticketId={item.ticketId}
          topicId={item.topicId}
        />
        {index < historyArr.length - 1 && <Divider />}
      </div>
    ));
  };
  return (
    <>
      {!historyArr.length ? (
        <Empty />
      ) : (
        <TransitionAnimation>
          <List disablePadding className={styles.wrapper}>
            {renderItems()}
          </List>
        </TransitionAnimation>
      )}
    </>
  )
};

export default HistoryListItem;
