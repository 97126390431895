import React, { FC } from "react";
import {
  FormControl,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  Box,
} from "@mui/material";
import { data } from "../../constants";
import styles from "./style.module.scss";
import { AnimateWrapper } from "../animate-wrapper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { DateValidationError } from "@mui/x-date-pickers/models";
import { Dayjs } from "dayjs";

interface FormNewAutorizedUserProps {
  name: string;
  setName: (name: string) => void;
  lastName: string;
  setLastName: (lastName: string) => void;
  email: string;
  setEmail: (email: string) => void;
  department: string;
  setDepartment: (department: string) => void;
  errors?: any;
  setErrors?: (string) => void;
  phone?: string;
  setPhone?: (phone: any) => void;
  dateOfBirth?: Dayjs | null;
  setDateOfBirth?: (dateOfBirth: Dayjs) => void;
  createSub: boolean;
}

const FormNewAutorizedUser: FC<FormNewAutorizedUserProps> = ({
  name,
  setName,
  lastName,
  setLastName,
  email,
  setEmail,
  department,
  setDepartment,
  errors,
  setErrors = () => {},
  phone,
  setPhone,
  dateOfBirth,
  setDateOfBirth,
  createSub,
}) => {
  const handleEmptyDateError = (error: DateValidationError) => {
    if (error === "invalidDate") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dateOfBirth: "Your date is not valid",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors }));
    }
  };

  const handleInputChange = (e, setValue, labelError) => {
    setValue(e.target.value);
    removeError(labelError);
  };

  const removeError = (labelError) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[labelError]) {
        delete newErrors[labelError];
      }
      return newErrors;
    });
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.wrapper}>
        <div className={styles.nameContainer}>
          <div>
            <TextField
              id="outlined-basic"
              value={name || undefined}
              onChange={(e) => handleInputChange(e, setName, "name")}
              label="First Name"
              inputProps={{ maxLength: 16 }}
              fullWidth
              error={errors.name}
              variant="outlined"
            />
            {errors?.name && (
              <p className="text-red-500 ">Error: {errors.name}</p>
            )}
          </div>
          <div>
            <TextField
              id="outlined-required"
              value={lastName}
              onChange={(e) => handleInputChange(e, setLastName, "lastName")}
              label="Last name"
              inputProps={{ maxLength: 16 }}
              fullWidth
              error={errors.lastName}
              variant="outlined"
            />
            {errors?.lastName && (
              <p className="text-red-500 ">Error: {errors.lastName}</p>
            )}
          </div>
        </div>

        <div>
          <TextField
            id="outlined-required"
            value={email}
            onChange={(e) => handleInputChange(e, setEmail, "email")}
            label="Email"
            inputProps={{ maxLength: 30 }}
            fullWidth
            error={errors.email}
          />
          {errors?.email && (
            <p className="text-red-500 ">Error: {errors.email}</p>
          )}
        </div>
        {createSub && (
          <div>
            <TextField
              id="outlined-required"
              value={phone}
              onChange={(e) => handleInputChange(e, setPhone, "phone")}
              label="Phone number"
              inputProps={{ maxLength: 10 }}
              fullWidth
              error={errors.phone}
            />
            {errors?.phone && (
              <p className="text-red-500 ">Error: {errors.phone}</p>
            )}
          </div>
        )}
        {createSub && (
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateField
                fullWidth
                label="Date of Birth"
                value={dateOfBirth}
                onChange={(newValue) => {
                  if (setDateOfBirth && newValue) {
                    setDateOfBirth(newValue);
                    removeError("dateOfBirth");
                  }
                }}
                onError={(error) => handleEmptyDateError(error)}
                slotProps={{
                  textField: { error: errors.dateOfBirth },
                }}
              />
            </LocalizationProvider>
            {errors?.dateOfBirth && (
              <p className="text-red-500 ">Error: {errors.dateOfBirth}</p>
            )}
          </div>
        )}

        <FormControl>
          <InputLabel id="demo-simple-select-label">Department</InputLabel>
          <Select
            id="demo-simple-select-label"
            label="Department"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            sx={{
              "& .MuiInputBase-input": {
                paddingLeft: "12px",
              },
              height: 54,
            }}
          >
            {data.Department?.map((i: any) => (
              <MenuItem key={i.id} value={i.title}>
                <div>{i.title}</div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </AnimateWrapper>
  );
};

export default FormNewAutorizedUser;
