import { Control, useController } from 'react-hook-form'
import styles from './styles.module.scss'
import { TextInput } from 'shared/ui/inputs'
import { TPayeeAdd } from 'features/transfers/transfer-to-netevia-customer/model'

interface PayeeAddFormrops {
  control: Control<TPayeeAdd>
}

export const NeteviaPayeeAddForm = (props: PayeeAddFormrops) => {
  const { control } = props

  const accountName = useController({
    name: 'accountName',
    control,
  })
  const accountNumber = useController({ name: 'accountNumber', control })

  return (
    <div className={styles.root}>
      <TextInput
        label='Account holder name'
        {...accountName.field}
      />
      <TextInput
        label='Account number'
        {...accountNumber.field}
        error={accountNumber.fieldState.error?.message}
      />
    </div>
  )
}
