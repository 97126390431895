import { z } from 'zod';

const AdressSchema = z.object({
  streetAddress: z.string(),
  locality: z.string(),
  region: z.string(),
  postalCode: z.string(),
});

type Address = z.infer<typeof AdressSchema>

export const formatAddress = (address: Address, line2?: string) => {
  AdressSchema.parse(address);
  return `${address.streetAddress},${line2 ? ` ${line2},` : ''} \n${address.locality}, ${address.region} ${address.postalCode}`;
};
